import {UI} from "../../../../../../stem-core/src/ui/UI";

import {BlinkButton} from "../../../../../ui/Button";
import {Messages} from "../../../../../Messages";
import {PaymentMethodStore, WalletStore} from "../../../../../State";
import {ADD_FUNDS_DISABLE_TIMEOUT} from "../../../../../Constants";
import {Toast} from "../../../../../ui/Toast";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {AddCardModal} from "./cards/AddCardModal";
import {Money} from "../../../../../../client/state/misc/Money";
import {Dispatcher} from "../../../../../../stem-core/src/base/Dispatcher";
import {apiUserTopUp, TopUpStatus} from "../../../../../../client/state/TopUpPaymentStore";


// TODO: Rename the file or move / refactor this.
export class AddFundsButton extends BlinkButton {
    disabled = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            amount: 0,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (this.disabled) {
            attr.setAttribute("disabled", true);
        }
    }

    getCurrency() {
        return WalletStore.getWallet().getCurrency();
    }

    render() {
        console.assert(this.getCurrency());
        return Messages.addFundsAmount((new Money(this.options.amount, this.getCurrency())).toMainUnitString({decimalsDisplayed: 0}));
    }

    showToastOnTransactionUpdate(transaction) {
        if (transaction.status === TopUpStatus.SUCCEEDED) {
            Toast.showSuccess(Messages.topUpSuccess(transaction.getAmount()));
        } else if (transaction.status === TopUpStatus.FAILED) {
            Toast.showError(Messages.failedAddFunds);
        }
    }

    getErrorMessage(error) {
        if (!error) {
            return Messages.failedAddFunds;
        }
        if (error.fields) {
            const remainingSeconds = error.fields.find(element => element.field === "remaining_seconds");
            if (remainingSeconds && remainingSeconds.message && remainingSeconds.message > 1) {
                // ensure we don't show "1 seconds"
                return Messages.topUpThrottle(remainingSeconds.message);
            }
        }
        return error.message;
    }

    indicateDisabled() {
        this.disabled = true;
        this.redraw();
        this.attachTimeout(() => {
            this.disabled = false;
            this.redraw()
        }, ADD_FUNDS_DISABLE_TIMEOUT);
    }

    addCardAddedListener(callback) {
        Dispatcher.Global.removeAllListeners("cardAdded");
        Dispatcher.Global.removeAllListeners("hideAddCard");

        const handler = this.attachListenerOnce(Dispatcher.Global, "cardAdded", callback);
        this.attachListenerOnce(Dispatcher.Global, "hideAddCard", () => {
            handler.remove();
        });
    }

    async addFunds() {
        const primaryCard = PaymentMethodStore.getPrimary();
        if (!primaryCard) {
            AddCardModal.show();
            this.addCardAddedListener(() => this.addFunds());
            return;
        }

        LoadingSpinner.show();
        try {
            const userWallet = WalletStore.getWallet();
            const transaction = await apiUserTopUp(this.options.amount, primaryCard, this.getCurrency(), userWallet);
            this.showToastOnTransactionUpdate(transaction);
        } catch (error) {
            Toast.showError(this.getErrorMessage(error));
        } finally {
            this.indicateDisabled();
            LoadingSpinner.hide();
        }
    }

    onMount() {
        super.onMount();

        this.addClickListener(() => this.addFunds());
    }
}
