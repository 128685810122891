import {UI} from "../../../../../stem-core/src/ui/UI";
import {DonationStore} from "../../../../State";
import {RecurringDonations} from "./sections/RecurringDonations";
import {PastRecurringDonations} from "./sections/PastRecurringDonations";
import {DonationPayments} from "./sections/DonationPayments";
import {Dispatcher} from "../../../../../stem-core/src/base/Dispatcher";
import {getQueryParam} from "../../../../UtilsLib";
import {userService} from "../../../../../client/connection/Services";


export class DonationsPage extends UI.Element {
    render() {
        return [
            DonationStore.getPresentRecurring().length ? <RecurringDonations testId="recurringDonationsCard"/> : null,
            DonationStore.getPastRecurring().length ? <PastRecurringDonations testId="pastRecurringDonationsCard"/>
                : null,
            <DonationPayments testId="donationPaymentsCard"/>,
        ];
    }

    onMount() {
        super.onMount();
        this.attachChangeListener(DonationStore, () => this.redraw());

        const dispatchEntryFocus = () => {
            userService.ensureUserDataRequested(() => {
                const focus = getQueryParam("focus");
                if (focus) {
                    Dispatcher.Global.dispatch("highlightDonation", focus);
                }
            });
        };
        this.attachTimeout(dispatchEntryFocus);
        this.addListener("urlEnter", dispatchEntryFocus);
    }
}
