import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {WebsocketStreamHandler} from "./WebsocketStreamHandler";
import {Logger} from "../../../blinkpay/Logger";
import {AuthToken} from "../AuthToken";
import {WS_API_URL} from "../../../blinkpay/Constants";

// TODO: Given our app, the client should use only static methods
class WebsocketSubscriber extends Dispatchable {
    static ConnectionStatus = {
        NONE: 0,
        CONNECTING: 1,
        CONNECTED: 2,
        DISCONNECTED: 3,
    };

    static STREAM_SUBSCRIBE_TIMEOUT = 3000;
    static STREAM_SUBSCRIBE_MAX_TIMEOUT = 30000;

    static CONNECT_RETRY_TIMEOUT = 3000;
    static CONNECT_RETRY_MAX_TIMEOUT = 30000;

    static HEARTBEAT_MESSAGE = "-hrtbt-";
    static STREAM_LISTENERS = new Map();

    constructor() {
        super();
        this._logger = new Logger("WebsocketSubscriber");
        this.streamHandlers = new Map();
        this.connectionStatus = WebsocketSubscriber.ConnectionStatus.NONE;
        this.websocket = null;
        this.failedReconnectAttempts = 0;
        this.numConnectionAttempts = 0;
    }

    addStreamSubscribeListener(streamName, callback) {
        const callbacks = this.constructor.STREAM_LISTENERS.get(streamName) || [];
        callbacks.push(callback);
        this.constructor.STREAM_LISTENERS.set(streamName, callbacks);
    }

    setConnectionStatus(connectionStatus) {
        this.connectionStatus = connectionStatus;
        this.dispatch("connectionStatus", connectionStatus);
    }

    setAuthToken(authToken) {
        this.authToken = authToken;
        if (!authToken) {
            return;
        }
        if (this.authToken.isAuthenticated()) {
            this.tryReconnect(true);
        }
        authToken.addListener("change", tokenEvent => {
            if (tokenEvent === AuthToken.EventTypes.LOGOUT) {
                this.disconnect();
                this.streamHandlers.clear();
            }
            if (tokenEvent === AuthToken.EventTypes.LOGIN || tokenEvent === AuthToken.EventTypes.REFRESH) {
                this.tryReconnect(true);
            }
        });
    }

    getUrl() {
        if (!this.authToken || this.authToken.usingMerchantToken) {
            return null;
        }
        const token = this.authToken.toString();
        if (!token) {
            return null;
        }
        return WS_API_URL + "/?token=" + token;
    }

    newConnection(url) {
        return new WebSocket(url);
    }

    connect() {
        this.disconnect();
        this._logger.debug("Trying to create a websocket connection");
        const url = this.getUrl();
        if (!url) {
            return;
        }
        this.setConnectionStatus(WebsocketSubscriber.ConnectionStatus.CONNECTING);
        try {
            this._logger.info("Connecting to " + url);
            this.websocket = this.newConnection(url);
            this.websocket.onopen = () => {
                this.onWebsocketOpen();
            };
            this.websocket.onmessage = event => {
                this.onWebsocketMessage(event);
            };
            this.websocket.onerror = event => {
                this.onWebsocketError(event);
            };
            this.websocket.onclose = event => {
                this.onWebsocketClose(event);
            };
        } catch (e) {
            this.tryReconnect();
            // We might not have a url when the token is invalid
            this._logger.warn("Failed to connect to ", url, "\nError: ", e.message);
        }
    }

    disconnect() {
        if (this.websocket) {
            this.websocket.close();
            this.websocket = null;
            this.connectionStatus = this.constructor.ConnectionStatus.DISCONNECTED;
        }
        if (this.reconnectTimeoutID) {
            clearTimeout(this.reconnectTimeoutID);
            this.reconnectTimeoutID = null;
        }
    }

    // TODO: add an optional parameter, in how many ms to try to reconnect at most
    tryReconnect(reconnectNow) {
        let reconnectWait = Math.min(
            this.constructor.CONNECT_RETRY_TIMEOUT * this.failedReconnectAttempts,
            this.constructor.CONNECT_RETRY_MAX_TIMEOUT
        );

        this.failedReconnectAttempts++;

        // TODO: wrap CallThrottler around this
        if (reconnectNow) {
            reconnectWait = 0;
            if (this.reconnectTimeoutID) {
                clearTimeout(this.reconnectTimeoutID);
                this.reconnectTimeoutID = null;
            }
        }

        if (!this.reconnectTimeoutID) {
            this.reconnectTimeoutID = setTimeout(() => {
                this.reconnectTimeoutID = null;
                this.connect();
            }, reconnectWait);
        }
    }

    getStreamStatus(streamName) {
        const streamHandler = this.getStreamHandler(streamName);
        return streamHandler && streamHandler.status;
    }

    subscribe(streamName) {
        // TODO: make sure to not explicitly support streams with spaces in the name
        this._logger.debug("Subscribing to stream ", streamName);

        if (this.streamHandlers.has(streamName)) {
            this._logger.debug("Already subscribed to stream ", streamName);
            return;
        }

        let streamHandler = new WebsocketStreamHandler(this, streamName);
        this.streamHandlers.set(streamName, streamHandler);

        // Check if the websocket connection is open, to see if we can send the subscription now
        if (this.isOpen()) {
            this.sendSubscribe(streamName);
        }

        return streamHandler;
    }

    isOpen() {
        return this.websocket && this.websocket.readyState === WebSocket.OPEN;
    }

    sendSubscribe(streamName) {
        if (this.isOpen()) {
            this.send("s " + streamName);
        }
    }

    sendResubscribe(streamName, index) {
        if (this.isOpen(streamName)) {
            this.send("r " + index + " " + streamName);
        }
    }

    resubscribe() {
        // Iterate over all streams and resubscribe to them
        for (let streamHandler of this.streamHandlers.values()) {
            streamHandler.sendSubscribe();
        }
    }

    onStreamSubscribe(streamName) {
        const callbacks = this.constructor.STREAM_LISTENERS.get(streamName);
        if (callbacks) {
            for (const callback of callbacks) {
                callback();
            }
        }
        const streamHandler = this.getStreamHandler(streamName);
        if (!streamHandler) {
            this._logger.error("Received subscribe success response for unrequested stream #" + streamName);
            return;
        }
        streamHandler.setStatusSubscribed();
    }

    onWebsocketOpen() {
        this.previousFailedReconnectAttempts = this.failedReconnectAttempts;
        this.failedReconnectAttempts = 0;
        this._logger.log("Websocket connection established!");

        this.reset();
        this.setConnectionStatus(WebsocketSubscriber.ConnectionStatus.CONNECTED);
        this.resubscribe();
    }

    processStreamPacket(packet) {
        let firstSpace = packet.indexOf(" ");
        let streamName, afterStreamName;
        if (firstSpace > 0) {
            streamName = packet.substr(0, firstSpace).trim();
            afterStreamName = packet.substr(firstSpace + 1).trim();
        } else {
            this._logger.warn("Could not process stream packet: " + packet);
            return;
        }

        let streamHandler = this.streamHandlers.get(streamName);
        // TODO: have a special mode if no handler is registered
        if (!streamHandler) {
            this._logger.error("No handler for websocket stream ", streamName);
            return;
        }
        streamHandler.processPacket(afterStreamName);
    }

    fatalErrorClose(data) {
        this.failedReconnectAttempts = this.previousFailedReconnectAttempts;
        this._logger.error("Server fatal error close: ", data);
        this.onWebsocketError(data);
    }

    onWebsocketMessage(event) {
        if (event.data === WebsocketSubscriber.HEARTBEAT_MESSAGE) {
            // TODO: keep track of the last heartbeat timestamp
        } else {
            let firstSpace = event.data.indexOf(" ");
            let type, payload;

            if (firstSpace > 0) {
                type = event.data.substr(0, firstSpace).trim();
                payload = event.data.substr(firstSpace + 1).trim();
            } else {
                type = event.data;
                payload = "";
            }

            if (type === "e" || type === "error") {
                // error
                this._logger.error("Websocket error: ", payload);
                payload = payload.split(" ");
                const errorType = payload[0];
                if (errorType === "invalidSubscription") {
                    // Stop trying to resubscribe to a stream that's been rejected by the server
                    const streamName = payload[1];
                    const streamHandler = this.getStreamHandler(streamName);
                    if (streamHandler) {
                        // TODO: set permission denied explicitly?
                        streamHandler.clearResubscribeTimeout();
                    }
                }
            } else if (type === "s") {
                // subscribed
                this._logger.debug("Successfully subscribed to stream ", payload);
                this.onStreamSubscribe(payload);
            } else if (type === "m") {
                // stream message
                this.processStreamPacket(payload);
            } else if (type === "c") {
                // command
                this.dispatch("serverCommand", payload);
            } else if (type === "efc") {
                // error - fatal - close
                this.fatalErrorClose(payload);
            } else {
                this._logger.error("Can't process " + event.data);
            }
        }
    }

    reset() {
        this.setConnectionStatus(WebsocketSubscriber.ConnectionStatus.DISCONNECTED);
        for (let streamHandler of this.streamHandlers.values()) {
            streamHandler.resetStatus();
        }
    }

    onWebsocketError(event) {
        this.dispatch("websocketError");
        this._logger.error("Websocket connection is broken!");
        this.reset();
        this.tryReconnect();
    }

    onWebsocketClose(event) {
        this._logger.log("Connection closed!");
        this.dispatch("websocketClosed");
        this.reset();
        this.tryReconnect();
    }

    send(message) {
        // TODO: if the websocket is not open, enqueue WebsocketSubscriber message to be sent on open or just fail?
        this.websocket.send(message);
    }

    getStreamHandler(streamName) {
        let streamHandler = this.streamHandlers.get(streamName);
        if (!streamHandler) {
            streamHandler = this.subscribe(streamName);
        }
        return streamHandler;
    }

    // this should be pretty much the only external function
    addStreamListener(streamName, callback) {
        let streamHandler = this.getStreamHandler(streamName);
        if (streamHandler.callbackExists(callback)) {
            return;
        }
        streamHandler.addListener(callback);
    }

    removeStreamListener(streamName, callback) {
        let streamHandler = this.streamHandlers.get(streamName);
        if (streamHandler) {
            streamHandler.removeListener(callback);
        }
    }

    static addListener(streamName, callback) {
        return this.Global.addStreamListener(streamName, callback);
    }
}

export {WebsocketSubscriber};
