import {styleRule} from "../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {UI} from "../../../../stem-core/src/ui/UIBase";
import {NOOP_FUNCTION} from "../../../../stem-core/src/base/Utils";
import {BlinkButton} from "../../../ui/Button";
import {Messages} from "../../../Messages";
import {BaseInput} from "../../../ui/Input";
import {isNetworkError} from "../../../Utils";
import {DefaultCodeConfirmationFormStyle} from "../../../common/DefaultCodeConfirmationForm";


class LongCodeEmailConfirmationFormStyle extends DefaultCodeConfirmationFormStyle {
    @styleRule
    inputElement = {
        margin: "48px auto 12px",
    }
}

@registerStyle(LongCodeEmailConfirmationFormStyle)
export class LongCodeEmailConfirmationForm extends UI.Element {
    hasPastedValue = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
        }
    }

    hasValidInput() {
        return this.codeInput && this.codeInput.isValid();
    }

    getInsertedCode() {
        return this.codeInput && this.codeInput.getValue().trim();
    }

    async confirmCode() {
        if (!this.hasValidInput()) {
            return;
        }

        try {
            await this.options.onSubmit(this.getInsertedCode());
            this.clearErrors();
        } catch (error) {
            this.codeInput.updateOptions({error: isNetworkError(error) ? error.message : Messages.incorrectConfirmationCode});
        }
    }

    handleInputChange() {
        this.clearErrors();
        this.redraw();

        if (this.hasPastedValue) {
            this.confirmCode();
            this.hasPastedValue = false;
        }
    }

    clearFormData() {
        this.clearErrors();
        if (this.codeInput) {
            this.codeInput.setValue("");
        }
    }

    render() {
        const {styleSheet} = this;

        return [
            <BaseInput className={this.styleSheet.inputElement} ref="codeInput" onFocus={() => this.clearErrors()}
                       onChange={() => this.handleInputChange()} onEnter={() => this.confirmCode()}
                       validators={[{condition: () => !this.codeInput.isEmpty()}]}/>,
            <BlinkButton label={Messages.confirm} className={styleSheet.button} disabled={!this.hasValidInput()} onClick={() => this.confirmCode()}/>
        ]
    }

    blur() {
        this.codeInput.blur();
    }

    clearErrors() {
        this.codeInput.updateOptions({error: null})
    }

    onMount() {
        this.codeInput.addNodeListener("paste", () => {
            this.hasPastedValue = true;
        });
    }
}
