import {apiClient} from "../../connection/BlinkApiConnection";

// TODO: move to StoreUtils, and rename misc folder to base, or rename the file
export async function apiFetchStorePage(store, url, params) {
    const response = await apiClient.get(url, params);

    // Add a field to the response with the store objects
    response.results = store.loadFromResponse(response);

    return response;
}
