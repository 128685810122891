import {iFrameConnection} from "./IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {CurrencyStore} from "../State";
import {
    GATE_TYPE,
    PANEL_FRAME,
    PANEL_TYPE
} from "../../blink-sdk/Constants";
import {
    ensureArray,
    ensureInEnum,
    ensureNonRecursivePlainObject,
    ensurePlainObject,
    isHTTPSUrl,
    isValidFontFace
} from "../panel/Security";
import {BlinkGlobal, cleanObject} from "../UtilsLib";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {iFrameMerchantService} from "./IframeMerchantService";
import {ProductStore} from "../../client/state/ProductStore.js";
import {filterStoreByMerchantAndLooseIdArray} from "../../client/state/misc/Filter.js";


// TODO @cleanup remove all getters
class IFrameState extends Dispatchable {
    iframeParams = {
        forceHideWallet: false,
        gateType: null,
        screen: null,
        allowUserToCoverFees: null,
        merchantPageUrl: "",
        merchantPageReferrerUrl: "",
        preferredEmail: null,
        paymentRequestStatus: null,
        activePanel: false,
    };

    init(iframeParams) {
        this.iframeParams = iframeParams;

        BlinkGlobal.iFrameState = this;

        iFrameConnection.addListener(IFrameMessages.UPDATE_APP_STATE, ({iframeParams}) => {
            this.iframeParams = iframeParams;
            this.dispatch("update", iframeParams);
        });
    }

    get activePanel() {
        return this.iframeParams.activePanel;
    }

    get forceHideWallet() {
        return this.iframeParams.forceHideWallet;
    }

    get preferredEmail() {
        return this.iframeParams.preferredEmail;
    }

    // These panel options get transferred from panel to modal when the flow switches iframe.
    getPanelToFlowOptions() {
        // TODO: Probably transfer unevaluated context? Not sure.
        // TODO @branch @cleanup there need to be a consistent rule on what gets passed from panel to flow.
        const copiedFields = ["subscriptionOffer", "donationOffer", "newsletter", "messages"];
        let inheritedOptions = {};
        for (const key of copiedFields) {
            if (this.iframeParams.hasOwnProperty(key)) {
                inheritedOptions[key] = this.iframeParams[key];
            }
        }

        return {
            ...inheritedOptions,
            metadata: this.getMetadata(),
            onFlowCheckpoint: this.iframeParams.onFlowCheckpoint,
        };
    }

    getMetadata() {
        return cleanObject({
            ...this.iframeParams.metadata,
            // Make sure to only overwrite what we explicitly have
            ...cleanObject({
                journeyId: this.iframeParams.journeyId,
                journeyAlias: this.iframeParams.journeyAlias,
            })
        });
    }

    get panelType() {
        return ensureInEnum(this.iframeParams.type, PANEL_TYPE);
    }

    get skipCTA() {
        return !!this.iframeParams.skipCTA;
    }

    get editRecurring() {
        return !!this.iframeParams.editRecurring;
    }

    get frame() {
        return ensureInEnum(this.iframeParams.frame, PANEL_FRAME);
    }

    get gateType() {
        return ensureInEnum(this.iframeParams.gateType, GATE_TYPE);
    }

    get gateStyle() {
        return ensurePlainObject(this.iframeParams.gateStyle);
    }

    get customGateStyle() {
        return ensurePlainObject(this.iframeParams.customGateStyle);
    }

    get subscriptionOffer() {
        const {subscriptionOffer} = this.iframeParams;
        return subscriptionOffer ? (Array.isArray(subscriptionOffer) ? subscriptionOffer : [subscriptionOffer]) : null;
    }

    get newsletter() {
        const {newsletter} = this.iframeParams;
        return newsletter ? (Array.isArray(newsletter) ? newsletter : [newsletter]) : null;
    }

    getProducts() {
        const {products} = this.iframeParams;
        const productObjects = ProductStore.all();
        if (!products) {
            return productObjects;
        }
        return filterStoreByMerchantAndLooseIdArray(productObjects, products, (obj, idOrAlias) => obj.id == idOrAlias || obj.alias == idOrAlias);
    }

    get dashboardTabs() {
        return ensureArray(this.iframeParams.dashboardTabs ?? iFrameMerchantService.getDefaultDashboardTabs());
    }

    get authSocialProviders() {
        // TODO @cleanup the lowercase change is not necessary. Ensure all values are valid from the backend.
        return ensureArray(this.iframeParams.authSocialProviders).map(value => value.toLowerCase());
    }

    get cancelButtonCustomStyle() {
        return ensurePlainObject(this.iframeParams.cancelButtonCustomStyle);
    }

    get theme() {
        return ensurePlainObject(this.iframeParams.theme);
    }

    get messages() {
        return ensureNonRecursivePlainObject(this.iframeParams.messages);
    }

    get panelContent() {
        return this.iframeParams.content;
    }

    get title() {
        return this.iframeParams.title;
    }

    get panelText() {
        return this.iframeParams.text;
    }

    get textStyle() {
        return ensurePlainObject(this.iframeParams.textStyle);
    }

    get panelButtonText() {
        return this.iframeParams.buttonText;
    }

    get footerText() {
        return this.iframeParams.footerText;
    }

    get footerSeparatorStyle() {
        return ensurePlainObject(this.iframeParams.footerSeparatorStyle);
    }

    get footerTextStyle() {
        return ensurePlainObject(this.iframeParams.footerTextStyle);
    }

    get allowUserToCoverFees() {
        // TODO: Make sure you support the fees checkbox while editing after some model update.
        return this.iframeParams.allowUserToCoverFees;
    }

    get actionButtonStyle() {
        return ensurePlainObject(this.iframeParams.actionButtonStyle);
    }

    get logoSrc() {
        return isHTTPSUrl(this.iframeParams.logoSrc) ? this.iframeParams.logoSrc : null;
    }

    get logoAlt() {
        return this.iframeParams.logoAlt;
    }

    get logoStyle() {
        return ensurePlainObject(this.iframeParams.logoStyle);
    }

    get fonts() {
        return ensureArray(this.iframeParams.fonts).filter(isValidFontFace);
    }

    get merchantPageUrl() {
        return this.iframeParams.merchantPageUrl;
    }

    get merchantPageReferrerUrl() {
        return this.iframeParams.merchantPageReferrerUrl;
    }

    get widgetVisible() {
        return this.iframeParams.visible;
    }

    get screen() {
        return this.iframeParams.screen;
    }

    get positionBottom() {
        return this.iframeParams.positionBottom;
    }

    get scrimClickCollapse() {
        return this.iframeParams.scrimClickCollapse;
    }

    get paymentDetails() {
        return this.iframeParams.paymentDetails;
    }

    get paymentRequestStatus() {
        return this.iframeParams.paymentRequestStatus;
    }

    isInlineDonationFlow() {
        return this.gateType === GATE_TYPE.popup && this.panelType === PANEL_TYPE.donation;
    }

    isInlineSubscriptionFlow() {
        return this.gateType === GATE_TYPE.popup && this.panelType === PANEL_TYPE.subscribe;
    }

    getPaymentDetailsCurrency() {
        // TODO(@deprecated): currencyIsoCode will be deprecated
        return CurrencyStore.getByIsoCode(this.paymentDetails.currencyIsoCode || this.paymentDetails.currencyCode)
            || iFrameMerchantService.getMerchant().getCurrency();
    }
}

export const iFrameState = new IFrameState();
