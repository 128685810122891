import {UI, styleRule, registerStyle, StyleSheet} from "../../stem-core/src/ui/UI";
import {Router} from "../../stem-core/src/ui/Router";

import {isIOS, isSmallScreen} from "../Utils";
import {CloseIcon} from "../SVGElements";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {MODAL_TRANSITION_TIME, IOS_BOTTOM_OFFSET} from "../Constants";
import {Scrim} from "./Scrim";


export class ModalStyle extends StyleSheet {
    @styleRule
    modal = {
        background: this.themeProps.MODAL_BACKGROUND,
        position: "relative",
        margin: "auto",
        zIndex: 10005,
        transform: "scale(.9)",
        opacity: 0,
        transition: MODAL_TRANSITION_TIME + "ms ease",
        boxShadow: this.themeProps.MODAL_SHADOW,
        padding: () => (isSmallScreen() ? "24px 12px" : 24),
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: this.themeProps.BASE_CARD_WIDTH,
        maxWidth: () => (isSmallScreen() ? "calc(100vw - 24px)" : this.themeProps.BASE_CARD_MAX_WIDTH),
        borderRadius: 12,
    };

    @styleRule
    title = {
        flex: 1,
        fontSize: 20,
        color: this.themeProps.BASE_CARD_TITLE_COLOR,
        marginBottom: () => (isSmallScreen() ? 12 : 18),
    };

    @styleRule
    close = {
        position: "absolute",
        padding: 8,
        top: 16,
        right: () => isSmallScreen() ? 8 : 16,
        cursor: "pointer",
        transition: ".2s",
        opacity: 0.48,
        ":hover": {
            opacity: 1,
        },
    };

    @styleRule
    visible = {
        ">*": {
            opacity: 1,
        },
        ">:nth-child(2)": {
            transform: "scale(1)",
        },
    };

    @styleRule
    modalContainer = {
        position: "fixed",
        margin: "auto",
        height: () => isIOS() ? `calc(100vh - ${16 + IOS_BOTTOM_OFFSET}px)` : "calc(100vh - 16px)",
        zIndex: 10005,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    };

    @styleRule
    autoWidth = {
        width: "initial",
        minWidth: "initial",
    }
}

@registerStyle(ModalStyle)
export class Modal extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.modalContainer);
    }

    getDefaultOptions() {
        return {
            title: null,
            hasCloseIcon: true,
            hideOnScrimClick: true,
            onHide: NOOP_FUNCTION,
            testId: "modalComponent",
            closeTestId: "modalCloseButton"
        }
    }

    static show(options) {
        return this.create(document.body, options);
    }

    // TODO: consider moving this in the Stem modal, and merging
    static async prompt(options) {
        const modal = this.show(options);
        return new Promise(resolve => {
            modal.resolvePromise = (result) => {
                delete modal.resolvePromise;
                resolve(result);
            };
        });
    }

    // Options for the actual dialog, the proper modal element
    getCardOptions() {
        const {styleSheet} = this;
        const {autoWidth, modalOptions} = this.options;
        let className = styleSheet.modal;
        if (autoWidth) {
            className += styleSheet.autoWidth;
        }
        if (modalOptions?.className) {
            className += modalOptions.className;
        }
        return {
            ...modalOptions,
            className,
        }
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {title, hasCloseIcon, hideOnScrimClick, closeTestId} = this.options;

        return [
            <Scrim hideOnClick={hideOnScrimClick} onHide={() => this.hide()}/>,
            <div {...this.getCardOptions()}>
                {title ? <div className={styleSheet.title}>{title}</div> : null}
                {this.render()}
                {hasCloseIcon ? <CloseIcon className={styleSheet.close} testId={closeTestId} onClick={() => this.hide()} /> : null}
            </div>
        ];
    }

    hide(response=null) {
        if (this.resolvePromise) {
            this.resolvePromise(response);
        }

        this.removeClass(this.styleSheet.visible);

        setTimeout(() => {
            this.options.onHide();
            this.destroyNode();
        }, MODAL_TRANSITION_TIME);
    }

    resolve(response) {
        this.hide(response);
    }

    onMount() {
        super.onMount();

        setTimeout(() => {
            this.addClass(this.styleSheet.visible);
            this.setAttribute("expanded", true);
        }, 100);
        this.attachListener(Router.Global, "change", () => this.hide());
    }
}
