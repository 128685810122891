import {UI} from "../../../stem-core/src/ui/UIBase";

import {Checkbox, CheckboxStyle} from "../../ui/Checkbox";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";


class MerchantCheckboxStyle extends CheckboxStyle {
    @styleRuleInherit
    checkbox = {
        border: "1px solid " + this.themeProps.MERCHANT_CHECKBOX_BORDER_COLOR,
        borderRadius: this.themeProps.MERCHANT_CHECKBOX_BORDER_RADIUS,
        height: 16,
        width: 16,
        transform: "",
    };

    @styleRule
    checkboxEnabled = {
        backgroundColor: this.themeProps.MERCHANT_CHECKBOX_ENABLED_BACKGROUND_COLOR,
        checkmarkColor: this.themeProps.MERCHANT_CHECKBOX_CHECKMARK_COLOR,
    };
}

@registerStyle(MerchantCheckboxStyle)
export class MerchantCheckbox extends Checkbox {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            checkboxSize: "1em",
        };
    }
}
