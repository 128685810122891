import {styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {UserPreferencesStore, WalletStore} from "../../../../../State";
import {Messages} from "../../../../../Messages";
import {Money} from "../../../../../../client/state/misc/Money";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {ToggleButton} from "../../../../../ui/Button";
import {Toast} from "../../../../../ui/Toast";
import {apiUpdatePreferences} from "../../../../../../client/state/UserPreferencesStore";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";


class AutoReloadStyle extends StyleSheet {
    @styleRule
    autoReloadInfo = {
        position: "relative",
        display: "inline-block",
        marginLeft: 6,
        transform: "translateY(2px)",
        cursor: "pointer",
    };

    @styleRule
    flexContainer = {
        display: "flex",
        justifyContent: "space-between",
    };

    @styleRule
    message = {
        color: this.themeProps.BASE_CARD_DESCRIPTION_COLOR,
        fontSize: 14,
        marginTop: 11,
        lineHeight: 20,
    }
}

@registerStyle(AutoReloadStyle)
@autoredraw(UserPreferencesStore, WalletStore)
export class AutoReload extends UI.Element {
    getPreferences() {
        return UserPreferencesStore.getPreferences();
    }

    render() {
        const {styleSheet} = this;

        const {autoTopUpAmount, autoTopUpThreshold, autoTopUpEnabled} = this.getPreferences();
        const currency = WalletStore.getWallet().getCurrency();

        return [
            <div className={styleSheet.flexContainer}>
                <div>{Messages.autoReload}</div>
                <ToggleButton testId="walletAutoReloadToggle" ref="autoReloadToggle" active={autoTopUpEnabled}
                                    onToggle={(active) => this.updateAutoReload(active)}/>
            </div>,
            autoTopUpEnabled ?
                <div className={styleSheet.message}>
                    {Messages.autoReloadWallet(new Money(autoTopUpAmount, currency),
                        new Money(autoTopUpThreshold, currency))}
                </div> : null
        ];
    }

    async updateAutoReload(autoReload) {
        const preferences = this.getPreferences();
        preferences.autoTopUpEnabled = autoReload;

        LoadingSpinner.show();
        try {
            await apiUpdatePreferences(preferences.getFields());
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        } finally {
            this.redraw();
            LoadingSpinner.hide();
        }
    }
}
