import {UI, Button, StyleSheet, styleRule, styleRuleInherit, registerStyle} from "../../stem-core/src/ui/UI";
import {Device} from "../../stem-core/src/base/Device";

import {BUTTON_TYPE} from "../Constants";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";


export class BaseButtonStyle extends StyleSheet {
    @styleRule
    button = {
        width: "100%",
        border: 0,
        borderRadius: "0.625rem",
        outline: 0,
        padding: 0,
        fontSize: 20,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
        cursor: "pointer",
        userSelect: "none",
        "-webkit-user-select": "none",
        ":hover": {
            opacity: "0.9",
            transition: ".2s",
        },
        ":disabled": {
            opacity: 0.55,
            cursor: "not-allowed",
            pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
        },
    };

    Size() {}
    Level() {}
}

@registerStyle(BaseButtonStyle)
export class BaseButton extends Button {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.button);
    }
}

/* TODO(@Vlad) Primary button style is done on desktop; everything else needs to be implemented.
    Also, check out the text shadows in the button label. */
export class BlinkButtonStyle extends StyleSheet {
    common = {
        padding: 13,
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        outline: 0,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
        border: "none",
        cursor: "pointer",
        transition: ".3s ease",
        "-webkit-user-select": "none",
        userSelect: "none",
        display: "inline-block",
    };

    primaryDisabled = {
        cursor: "not-allowed",
        textShadow: this.themeProps.BUTTON_PRIMARY_DISABLED_TEXT_SHADOW,
        background: this.themeProps.BUTTON_PRIMARY_DISABLED_BACKGROUND,
        color: this.themeProps.BUTTON_PRIMARY_DISABLED_COLOR,
        pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
    };

    @styleRule
    primaryButton = Object.assign({}, this.common, {
        boxShadow: this.themeProps.BUTTON_PRIMARY_SHADOW,
        background: this.themeProps.BUTTON_PRIMARY_BACKGROUND,
        textShadow: this.themeProps.BUTTON_PRIMARY_TEXT_SHADOW,
        color: this.themeProps.BUTTON_PRIMARY_COLOR,
        ":hover": {
            background: this.themeProps.BUTTON_PRIMARY_HOVER_BACKGROUND,
            boxShadow: this.themeProps.BUTTON_PRIMARY_HOVER_SHADOW,
        },
        ":disabled": this.primaryDisabled,
        ":hover:disabled": this.primaryDisabled,
    });

    secondaryDisabled = {
        cursor: "not-allowed",
        color: this.themeProps.BUTTON_PRIMARY_DISABLED_COLOR,
        pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
    };

    @styleRule
    secondaryButton = Object.assign({}, this.common, {
        background: this.themeProps.BUTTON_SECONDARY_BACKGROUND,
        color: this.themeProps.BUTTON_SECONDARY_COLOR,
        boxShadow: this.themeProps.BUTTON_SECONDARY_SHADOW,
        ":hover": {
            color: this.themeProps.BUTTON_SECONDARY_COLOR,
            boxShadow: this.themeProps.BUTTON_SECONDARY_HOVER_SHADOW,
        },
        ":disabled": this.secondaryDisabled,
        ":hover:disabled": this.secondaryDisabled,
    });

    Size() {}
    Level() {}
}

@registerStyle(BlinkButtonStyle)
export class BlinkButton extends Button {
    getDefaultOptions() {
        return Object.assign({}, super.getDefaultOptions() || {}, {
            type: BUTTON_TYPE.PRIMARY,
        });
    }

    extraNodeAttributes(attr) {
        if (this.options.type === BUTTON_TYPE.PRIMARY) {
            attr.addClass(this.styleSheet.primaryButton);
        } else {
            attr.addClass(this.styleSheet.secondaryButton);
        }
    }
}

export class BlinkLinkButton extends UI.Primitive(BlinkButton, "a") {}

export class ToggleButtonStyle extends StyleSheet {
    transitionTime = 0.18;

    @styleRule
    extraPadding = {
        padding: 4,
        margin: -4,
        cursor: "pointer",
        "[disabled]": {
            cursor: "not-allowed",
        },
    };

    @styleRule
    button = {
        width: 35,
        height: 15,
        position: "relative",
        background: this.themeProps.TOGGLE_BACKGROUND,
        borderRadius: 20,
        ">:first-child": {
            height: 20,
            width: 20,
            top: -3,
            borderRadius: "100%",
            position: "absolute",
            left: 0,
            background: this.themeProps.TOGGLE_DISABLED_BACKGROUND,
            boxShadow: this.themeProps.TOGGLE_SHADOW,
        },
    };

    @styleRule
    active = {
        background: this.themeProps.TOGGLE_BACKGROUND,
        ">:first-child": {
            left: 15,
            background: this.themeProps.TOGGLE_COLOR,
        },
    };

    @styleRule
    transition = {
        pointerEvents: "none",
        cursor: "initial",
        ">:first-child": {
            transition: "background " + this.transitionTime + "s ease",
            ">:first-child": {
                transitionProperty: "background, left",
                transition: this.transitionTime + "s ease",
            },
        }
    };
}

@registerStyle(ToggleButtonStyle)
export class ToggleButton extends UI.Element {
    getDefaultOptions() {
        return {
            active: false,
            autoChange: true,
            onToggle: () => {},
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.extraPadding);
        if (this.useTransition) {
            attr.addClass(this.styleSheet.transition);
        }
    }

    render() {
        const {styleSheet} = this;

        return <div className={styleSheet.button + (this.options.active ? styleSheet.active : "")} ref="button">
            <div/>
            {super.render()}
        </div>;
    }

    toggleActive(autoChange) {
        if (this.options.disabled) {
            return;
        }
        const {styleSheet} = this;
        this.useTransition = true;
        this.redraw();
        this.dispatch("toggle", {active: !this.options.active});

        // TODO: merge with the previous dispatch in a single method
        if (this.options.onToggle) {
            this.options.onToggle(!this.options.active);
        }

        if (autoChange) {
            this.updateOptions({active: !this.options.active});
        }
        setTimeout(() => {
            this.useTransition = false;
            this.redraw();
        }, styleSheet.transitionTime * 1000);
    }

    onMount() {
        super.onMount();
        this.addClickListener(() => this.toggleActive(this.options.autoChange));
    }
}

export class ActiveButtonStyle extends StyleSheet {
    @styleRule
    button = {
        fontSize: this.themeProps.ACTIVE_BUTTON_FONT_SIZE,
        padding: 0,
        textAlign: "center",
        color: this.themeProps.ACTIVE_BUTTON_INACTIVE_COLOR,
        background: this.themeProps.ACTIVE_BUTTON_INACTIVE_BACKGROUND,
        border: this.themeProps.ACTIVE_BUTTON_INACTIVE_BORDER,
        borderRadius: 0,
        userSelect: "none",
        "-webkit-user-select": "none",
        outline: 0,
        cursor: "pointer",
        // marginRight: 12,
        transition: ".2s ease",
        ":hover": {
            background: this.themeProps.ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND,
        },
    };

    @styleRule
    active = {
        color: this.themeProps.ACTIVE_BUTTON_COLOR,
        background: this.themeProps.ACTIVE_BUTTON_BACKGROUND,
        border: this.themeProps.ACTIVE_BUTTON_ACTIVE_BORDER,
        ":hover": {
            background: this.themeProps.ACTIVE_BUTTON_BACKGROUND,
        },
    };
}

@registerStyle(ActiveButtonStyle)
export class ActiveButton extends UI.Primitive("button") {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            label: "",
            active: false,
        }
    }

    getLabel() {
        return this.options.label;
    }

    getChildrenToRender() {
        return [this.getLabel(), this.render()];
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.button);
        if (this.options.active) {
            attr.addClass(this.styleSheet.active);
        }
    }
}

export class CheckboxLabelStyle extends ActiveButtonStyle {
    @styleRuleInherit
    button = {
        padding: ".2em 1em",
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        borderRadius: ".4em",
    };
}

@registerStyle(CheckboxLabelStyle)
export class CheckboxLabel extends ActiveButton {}

// TODO(@Vlad): This is finished.
export class RadioButtonsStyle extends StyleSheet {
    @styleRule
    radioButtons = {
        display: "flex",
        width: "100%",
        ">:first-child": {
            marginLeft: 0,
            borderRadius:  "4px 0 0 4px",
        },
        ">:last-child": {
            marginRight: 0,
            borderRadius: "0 4px 4px 0",
        },
    };

    @styleRule
    button = {
        padding: this.themeProps.RADIO_BUTTON_PADDING,
        flex: this.themeProps.RADIO_BUTTON_FLEX,
    };
}

// TODO this should be an input with onChange
@registerStyle(RadioButtonsStyle)
export class RadioButtons extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            activeValue: null,
            ActiveButtonClass: ActiveButton,
            onSelect: NOOP_FUNCTION,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.radioButtons);
    }

    render() {
        const {styleSheet} = this;
        const {ActiveButtonClass} = this.options;

        return this.options.values.map(value => (
            <ActiveButtonClass
                label={String(value)}
                active={value === this.getActive()}
                onClick={() => this.updateActive(value)}
                className={styleSheet.button}
            />
        ));
    }

    updateActive(value) {
        this.activeValue = value;
        this.redraw();
        this.options.onSelect(value);
        this.dispatch("active", value);
    }

    getActive() {
        return this.activeValue || this.options.activeValue || this.options.values[0];
    }

    // TODO @branch more consistency in UI APIs
    setValue(value) {
        this.updateActive(value);
    }

    getValue() {
        return this.getActive();
    }
}
