import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";

import {isIframe, isSmallScreen, normalize} from "../Utils";
import {styleRuleInherit} from "../../stem-core/src/decorators/Style";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Device} from "../../stem-core/src/base/Device";
import {Messages} from "../Messages";
import {KeyEvent} from "../KeyEvent";
import {ANDROID_KEYBOARD_TOGGLE_TIMEOUT} from "../widget/WidgetConstants";
import {Input} from "../../stem-core/src/ui/input/Input";


export class BaseInputStyle extends StyleSheet {
    @styleRule
    element = {
        fontSize: () => (isSmallScreen() ? 16 : 13),
        color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
    };

    @styleRule
    error = {
        borderColor: this.themeProps.MERCHANT_ERROR,
        ":hover": {
            borderColor: this.themeProps.MERCHANT_ERROR + " !important",
        },
        " input": {
            color: this.themeProps.MERCHANT_ERROR + " !important",
        },
        " input::placeholder": {
            color: this.themeProps.MERCHANT_ERROR + " !important",
        }
    };

    @styleRule
    success = {
        borderColor: this.themeProps.MERCHANT_SUCCESS,
    };

    @styleRule
    label = this.themeProps.INPUT_LABEL_STYLE;

    message = {
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translateY(-100%)",
        paddingBottom: 10,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_SMALL,
        color: this.themeProps.MERCHANT_SUCCESS,
    };

    @styleRule
    errorMessage = [
        this.message,
        {
            color: () => this.themeProps.MERCHANT_ERROR,
        },
    ];

    @styleRule
    successMessage = [
        this.message,
        {
            color: () => this.themeProps.MERCHANT_SUCCESS,
        },
    ];

    @styleRule
    inputContainer = {
        width: "100%",
        position: "relative",
        fontSize: "inherit",
        height: () => (isSmallScreen() ? 46 : 42),
        background: this.themeProps.MERCHANT_INPUT_BACKGROUND,
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        border: () => "1px solid " + this.themeProps.MERCHANT_INPUT_BORDER_COLOR,
        ":focus-within": {
            borderColor: this.themeProps.MERCHANT_INPUT_BORDER_ACTIVE_COLOR,
        },
        ...this.themeProps.MERCHANT_INPUT_STYLE,
    };

    @styleRule
    input = {
        caretColor: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        overflow: "hidden",
        padding: ".25rem 5px",
        background: "transparent",
        paddingLeft: () => (isSmallScreen() ? "0.666em" : "1em"),
        border: "none !important",
        height: 38,
        marginLeft: 2,
        marginTop: 2,
        fontSize: "inherit",
        outline: "none",
        color: () => this.themeProps.MERCHANT_INPUT_TEXT_COLOR + "!important",
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        width: "calc(100% - 4px)",
        boxShadow: () => "0 0 0px 1000px " + this.themeProps.MERCHANT_INPUT_BACKGROUND + " inset !important",
        backgroundClip: "padding-box !important",
        ":-webkit-autofill": {
            boxShadow: () => "0 0 0px 1000px " + this.themeProps.MERCHANT_INPUT_BACKGROUND + " inset !important",
            color: () => this.themeProps.MERCHANT_INPUT_TEXT_COLOR + "!important",
            "-webkit-text-fill-color": () => this.themeProps.MERCHANT_INPUT_TEXT_COLOR + "!important",
        },
        "::placeholder": {
            color: this.themeProps.MERCHANT_INPUT_PLACEHOLDER_COLOR,
        },
    };

    @styleRule
    notEditableInputContainer = {
        width: "100%",
        position: "relative",
        fontSize: "inherit",
        ">input:focus": {
            outline: "none",
            boxShadow: "none",
        }
    };

    @styleRule
    notEditableInput = {
        fontSize: "inherit",
        color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        border: "none",
        outline: "none",
        cursor: "default",
        background: "transparent",
        padding: 0,
        paddingBottom: 12,
        margin: 0,
        width: "100%",
    };

    @styleRule
    notEditableInputLabel = {
        ...this.themeProps.INPUT_LABEL_STYLE,
        color: this.themeProps.BASE_INPUT_NOT_EDITABLE_LABEL_COLOR,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
    };
}

@registerStyle(BaseInputStyle)
export class BaseInput extends UI.Element {
    error = null;
    initialValue = "";
    isEditable = true;

    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(),
            onEnter: NOOP_FUNCTION,
            onBlur: NOOP_FUNCTION,
            onFocus: NOOP_FUNCTION,
            onChange: NOOP_FUNCTION,
            onInvalidInput: NOOP_FUNCTION,
            onValidInput: NOOP_FUNCTION,
            InputClass: Input,
            inputPattern: /\\*/,
            // If you want to pass a value that might get changed by the user's input, pass it in this variable, instead
            // of passing it in the inputAttributes object.
            initialValue: null,
            inputAttributes: {},
            /*
                Validators array is used in determining if an input has a valid value or not.
                This array is composed of 'validator' objects having the following format:
                {
                  condition: <Boolean value / Function that returns boolean value>,
                  error: <String> | Default value: " "
                 }
             */
            validators: [],
            editable: true,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
    }

    setOptions(options) {
        options.inputAttributes = {
            ...this.getDefaultOptions(options).inputAttributes,
            ...(options.inputAttributes || {})
        };

        return super.setOptions(options);
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        let {success, label, inputAttributes, errorMessageTestId, successComponentId, InputClass, initialValue, editable}
            = this.options;
        const error = this.options.error || this.error;
        const {MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL} = this.styleSheet.themeProps;

        // We create a copy that we might modify, but we don't want to modify the options as well.
        let inputCopiedAttributes = {
            ...inputAttributes,
            ...(MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL && label ? {placeholder: label} : {}),
        };

        // The behaviour is the following: when the class' initial value is changed from options, it means it must be
        // set as the input's value; otherwise, don't modify the current input's value.
        if (initialValue !== this.initialValue) {
            this.initialValue = initialValue;
            inputCopiedAttributes.value = initialValue;
        }

        // If the editable status of the input changes, reset the input's value
        if (this.isEditable !== editable) {
            inputCopiedAttributes.value = this.initialValue;
            this.isEditable = editable;
        }

        if (!this.isEditable) {
            inputCopiedAttributes.readonly = true;
        }

        const inputContainerStyleClasses = this.isEditable ? `${styleSheet.inputContainer} ${
            success ? styleSheet.success : error ? styleSheet.error : ""
        }` : styleSheet.notEditableInputContainer;
        const inputLabelStyleClasses = this.isEditable ? styleSheet.label : styleSheet.notEditableInputLabel;
        const inputComponentStyleClasses = this.isEditable ? styleSheet.input : styleSheet.notEditableInput;

        return [
            label && !MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL ?
                <div className={inputLabelStyleClasses}>{label}</div> : <div/>,
            <div className={inputContainerStyleClasses}>
                {this.render()}
                <InputClass className={inputComponentStyleClasses} ref="input" {...inputCopiedAttributes}/>
                {this.isEditable ?
                    [<div testId={errorMessageTestId} className={styleSheet.errorMessage}>
                        {error}
                    </div>,
                    <div testId={successComponentId} className={styleSheet.successMessage}>
                        {success}
                    </div>
                    ] : null
                }
                {this.renderAfterInput()}
            </div>,
        ];
    }

    renderAfterInput() {
        return null;
    }

    getValue() {
        return (this.input && this.input.getValue()) || "";
    }

    setValue(value) {
        this.input.setValue(value);
    }

    focus() {
        this.input.node.focus();
    }

    focusToEnd() {
        this.focus();
        // The following sequence of steps sets the cursor at the end of the input.
        const value = this.input.node.value;
        this.input.node.value = undefined;
        this.input.node.value = value;
    }

    blur() {
        this.input.node.blur();
    }

    isValid() {
        return this.getValidationErrors().length === 0;
    }

    validate() {
        if (this.isValid()) {
            return true;
        }

        this.error = this.getValidationErrors()[0] || " ";
        this.redraw();

        return true;
    }

    getValidationErrors() {
        return this.options.validators.filter(validator => {
            const {condition} = validator;
            const conditionFulfilled = typeof condition === "function" ? condition() : condition;
            return !conditionFulfilled;
        }).map(failedValidator => failedValidator.error);
    }

    isEmpty() {
        return this.input.getValue().trim().length === 0;
    }

    clearError() {
        this.error = null;
        this.updateOptions({error: null});
    }

    isInView() {
        const {top, bottom} = this.node.getBoundingClientRect();
        for (let node = this.node; node && node !== document; node = node.parentNode) {
            if (window.getComputedStyle(node).overflowY === "auto" && node.scrollHeight !== node.offsetHeight) {
                const rect = node.getBoundingClientRect();
                return (top >= rect.top && top <= rect.bottom && bottom >= rect.top && bottom <= rect.bottom);
            }
        }
        return true;
    }

    saveSelection() {
        this.selectionStart = this.input.node.selectionStart;
        this.selectionEnd = this.input.node.selectionEnd;
    }

    initFocusListeners() {
        this.input.addNodeListener("blur", () => this.options.onBlur());
        this.input.addNodeListener("focus", (event) => {
            this.saveSelection();
            this.clearError();
            this.options.onFocus(event);
            if (Device.isMobileDevice() && isIframe()) {
                const scrollInputIntoView = () => {
                    if (this.input.node === document.activeElement && !this.isInView()) {
                        if (this.node.scrollIntoView) {
                            this.node.scrollIntoView({behavior: "smooth"});
                        }
                    }
                };

                for (let timeout = 0; timeout <= ANDROID_KEYBOARD_TOGGLE_TIMEOUT; timeout += 300) {
                    this.attachTimeout(scrollInputIntoView, timeout);
                }
            }
        });
    }

    initValidationListeners() {
        let value = this.getValue();

        const isInputValid = (value) => {
            const {inputAttributes, inputPattern} = this.options;
            const {min, max} = inputAttributes;
            if (!value.match(inputPattern)) {
                return false;
            }
            if (min != null && parseFloat(value) < min) {
                return false;
            }
            return !(max != null && parseFloat(value) > max);
        };

        this.input.addNodeListener("input", () => {
            const insertedValue = this.getValue().toString();
            if (!isInputValid(insertedValue)) {
                this.options.onInvalidInput(insertedValue);
                this.setValue(value);
                try {
                    this.input.node.setSelectionRange(this.selectionStart, this.selectionEnd);
                } catch (e) {
                }
                return;
            }
            this.options.onValidInput(insertedValue);

            value = this.getValue();

            this.saveSelection();
            this.clearError();
        });
    }

    initKeyListeners() {
        this.input.addNodeListener("keydown", () => {
            this.saveSelection();
        });

        this.input.addNodeListener("keyup", event => {
            this.saveSelection();
            const key = new KeyEvent(event);

            if (key.isEnter()) {
                this.options.onEnter();
            } else {
                this.options.onChange();
            }
        });

        this.input.addNodeListener("paste", () => {
            this.options.onChange();
            this.saveSelection();
        });
    }

    initListeners() {
        this.saveSelection();

        this.initFocusListeners();
        this.initValidationListeners();
        this.initKeyListeners();
    }

    onMount() {
        super.onMount();
        this.initListeners();
    }
}

export class BlinkInputStyle extends BaseInputStyle {
    @styleRuleInherit
    element = {
        fontSize: () => isSmallScreen() ? 16 : 14,
        marginTop: 18,
        marginBottom: 8,
    };

    @styleRuleInherit
    label = {
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
    };

    @styleRuleInherit
    notEditableInputLabel = {
        marginTop: 18,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
    };

    @styleRuleInherit
    inputContainer = {
        height: 42,
        transition: "box-shadow 0.2s ease",
    };

    @styleRuleInherit
    input = {
        margin: 0,
        boxShadow: "none",
        width: "100%",
        height: "100%",
        paddingLeft: 18,
        caretColor: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        borderRadius: () => (isSmallScreen() ? 6 : 8),
        color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        "::placeholder": {
            color: this.themeProps.MERCHANT_INPUT_PLACEHOLDER_COLOR,
        },
    };

    @styleRuleInherit
    errorMessage = {
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_SMALL,
        top: "100%",
        bottom: "auto",
        left: 0,
        transform: "translateY(3px)",
    };
}

@registerStyle(BlinkInputStyle)
export class BlinkInput extends BaseInput {}

export const PasswordInput = (InputClass) => {
    class PasswordInputClassStyle extends InputClass.styleSheet {
        @styleRule
        maskButton = {
            userSelect: "none",
            textSelection: "none",
            color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
            opacity: 0.6,
            ":hover": {
                opacity: 1,
            },
            cursor: "pointer",
            position: "absolute",
            fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
            padding: 0,
            top: 0,
            right: 0,
            fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
            paddingRight: 14,
            paddingTop: 14,
        };

        @styleRule
        narrowInput = {
            ">*>input": {
                width: "calc(100% - 54px)"
            }
        };

        @styleRuleInherit
        errorMessage = {
            right: 0,
            top: 0,
            transform: "translateY(-100%)",
            left: "auto",
        }
    }

    return (
        @registerStyle(PasswordInputClassStyle)
        class PasswordInputClass extends InputClass {
            inputType = "password";

            getDefaultOptions() {
                return {
                    ...super.getDefaultOptions(),
                    hasMaskButton: true
                }
            }

            extraNodeAttributes(attr) {
                super.extraNodeAttributes(attr);
                if (this.options.hasMaskButton) {
                    attr.addClass(this.styleSheet.narrowInput);
                }
            }

            onMaskButtonClick() {
                this.inputType = this.inputType === "password" ? "text" : "password";
                this.redraw();
            }

            setOptions(options) {
                if (options?.inputAttributes) {
                    options.inputAttributes.type = this.inputType;
                }
                super.setOptions(options);
            }

            render() {
                const {hasMaskButton} = this.options;

                return [
                    super.render(),
                    hasMaskButton ?
                        <div className={this.styleSheet.maskButton} onClick={() => this.onMaskButtonClick()}>
                            {this.inputType === "password" ? Messages.show : Messages.hide}
                        </div>
                        : null
                ];
            }
        }
    )
};

export class BasePasswordInput extends PasswordInput(BaseInput) {
}

export class BlinkPasswordInput extends PasswordInput(BlinkInput) {
}

export const InputAutocompleteStyle = InputStyle => class InputAutocompleteStyleClass extends InputStyle {
    @styleRuleInherit
    element = {
        position: "relative",
    };

    @styleRule
    suggestionContainer = {
        position: "absolute",
        width: "100%",
        zIndex: "9999",
        background: this.themeProps.MERCHANT_INPUT_BACKGROUND,
        border: () => "1px solid " + this.themeProps.MERCHANT_INPUT_BORDER_COLOR, // TODO: To add in merchant input.
    };

    @styleRule
    suggestionItem = {
        height: 40,
        display: "flex",
        alignItems: "center",
        paddingLeft: 12,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        cursor: "pointer",
        ":hover": {
            background: this.themeProps.MERCHANT_INPUT_BACKGROUND_HOVER,
        },
    };
};

@registerStyle(InputAutocompleteStyle(BaseInputStyle))
export class InputAutocomplete extends BaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            autocompleteList: [],
            maxSuggestionCount: 3,
            minAutocompleteStringLength: 2,
            onOptionSelect: NOOP_FUNCTION,
        };
    }

    constructor(...args) {
        super(...args);
        this.shouldShowAutocompleteOptions = false;
        this.selectedOption = null;
    }

    isAutocompleteOption(option) {
        const formattedOption = normalize(option);
        const inputValue = normalize(this.getValue());

        if (inputValue.length < this.options.minAutocompleteStringLength) {
            return false;
        }
        if (formattedOption.length < inputValue.length) {
            return false;
        }
        for (let i = 0; i < inputValue.length; i += 1) {
            if (formattedOption[i].toLowerCase() !== inputValue[i].toLowerCase()) {
                return false;
            }
        }
        return true;
    }

    getAutocompleteItemsToShow() {
        return this.options.autocompleteList
            .filter(option => this.isAutocompleteOption(option.name))
            .slice(0, this.options.maxSuggestionCount);
    }

    selectOption(option) {
        if (!option) {
            return;
        }
        this.setValue(option.display);
        this.selectedOption = option;
        this.dispatch("optionSelected", option);
    }

    renderAfterInput() {
        const {styleSheet} = this;
        const autocompleteItems = this.getAutocompleteItemsToShow();

        return [
            this.shouldShowAutocompleteOptions && autocompleteItems.length ? (
                <div className={styleSheet.suggestionContainer}>
                    {autocompleteItems.map(option => (
                        <div className={styleSheet.suggestionItem} onClick={() => this.selectOption(option)}>
                            {option.name}
                        </div>
                    ))}
                </div>
            ) : null,
        ];
    }

    setValue(value) {
        super.setValue(value);
        this.selectedOption = value.trim().length ? this.getExactlyMatchedOption() : null;
    }

    hideAutocompleteOptions() {
        this.shouldShowAutocompleteOptions = false;
        this.redraw();
    }

    showAutocompleteOptions() {
        this.shouldShowAutocompleteOptions = true;
        this.redraw();
    }

    getSelectedOption() {
        return this.selectedOption;
    }

    getExactlyMatchedOption() {
        const inputValue = this.getValue().trim();
        return this.options.autocompleteList.find(({name}) => {
            return inputValue.toLowerCase() === name.toLowerCase();
        });
    }

    initFocusListeners() {
        super.initFocusListeners();

        this.input.addNodeListener("blur", () => {
            if (!this.selectedOption) {
                const matchedOption = this.getExactlyMatchedOption() || this.selectedOption;
                this.selectOption(matchedOption);
            }
            this.options.onBlur();
        });

        this.input.addNodeListener("focus", (event) => {
            if (!this.selectedOption || this.selectedOption.name !== this.getValue().trim()) {
                this.showAutocompleteOptions();
            }
            this.options.onFocus(event);
        });

        document.body.addEventListener("click", event => {
            if (this.input && event.target !== this.input.node) {
                this.hideAutocompleteOptions();
            }
        });

        this.addListener("optionSelected", option => {
            this.options.onOptionSelect(option);
        });
    }

    initKeyListeners() {
        super.initKeyListeners();

        this.input.addNodeListener("keydown", event => {
            const key = new KeyEvent(event);
            if (key.isTab()) {
                this.hideAutocompleteOptions();
            }
        });

        this.input.addNodeListener("keyup", event => {
            this.clearError();
            this.selectedOption = null;
            this.showAutocompleteOptions();
            const key = new KeyEvent(event);

            if (key.isEnter()) {
                this.options.onEnter();
            }

            if (key.isEscape()) {
                this.hideAutocompleteOptions();
            }
        });
    }

    onMount() {
        super.onMount();
        this.showAutocompleteOptions();
    }
}
