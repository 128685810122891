import {UI} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {Toast} from "../../../../../ui/Toast";
import {CancelSubscriptionModal} from "./CancelSubscriptionModal";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {apiReactivateSubscription} from "../../../../../../client/state/SubscriptionStore";


export class ReactivateSubscriptionModal extends CancelSubscriptionModal {
    getDefaultOptions(options) {
        const {subscription} = options;
        return {
            ...super.getDefaultOptions(),
            title: Messages.reactivateSubscriptionTitle,
            description: Messages.reactivateSubscriptionDescription(`${subscription.getMerchantTitle()} - ${subscription.coverage.name}`),
            confirmAction: () => this.reactivate(),
            confirmLabel: "",
        };
    }

     async reactivate() {
        LoadingSpinner.show();
        try {
            await apiReactivateSubscription(this.options.subscription.id);
            Toast.show(Messages.reactivateSubscriptionSuccess);
            this.hide();
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }
}
