import {UI, styleRule, StyleSheet, registerStyle} from "../../stem-core/src/ui/UI";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {ChevronDownIcon, ChevronRightIcon} from "../SVGElements";

class RowExpansionPanelStyle extends StyleSheet {
    @styleRule
    label = {
        fontSize: "11px",
        color: this.themeProps.EXPANSION_PANEL_HEADING_COLOR,
        paddingBottom: "8px",
    };

    @styleRule
    content = {
        fontSize: "14px",
        color: this.themeProps.EXPANSION_PANEL_PARAGRAPH_COLOR,
        lineHeight: "18px",
    };

    @styleRule
    panelContainer = {
        paddingTop: "6px",
        paddingLeft: "20px",
    };

    @styleRule
    contentRow = {
        marginBottom: "18px",
    };
}

@registerStyle(RowExpansionPanelStyle)
class RowExpansionPanel extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions,
            expanded: true,
            contentRows: [],
        };
    }

    render() {
        const {styleSheet} = this;
        const {contentRows, expanded} = this.options;

        if (!expanded) {
            return [];
        }

        return (
            <div className={styleSheet.panelContainer}>
                {contentRows.map(row => {
                    return (
                        <div className={styleSheet.contentRow}>
                            {row.label ? <div className={styleSheet.label}>{row.label}</div> : null}
                            {row.value ? <div className={styleSheet.content}>{row.value}</div> : null}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export class FakeTableRowStyle extends StyleSheet {
    @styleRule
    bodyRow = {
        height: 42,
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    clickableRow = {
        cursor: "pointer"
    };

    @styleRule
    fakeTableRow = {
        padding: "0 8px",
        ":not(:last-child)": {
            borderBottom: "1px solid " + this.themeProps.TABLE_BORDER_COLOR,
        },
    };

    @styleRule
    chevron = {
        padding: "0 4px",
        color: this.themeProps.TABLE_CHEVRON,
        fontSize: 12,
        width: 18,
        display: "inline-block",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    };

    @styleRule
    column = {
        lineHeight: 18,
    }
}

@registerStyle(FakeTableRowStyle)
export class FakeTableRow extends UI.Element {
    isExpanded = this.options.expanded;

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.fakeTableRow);
        attr.setAttribute("expanded", this.isExpanded);
    }

    isExpandable() {
        const {entry, expandedRowContent} = this.options;
        const expansionPanelRows = expandedRowContent && expandedRowContent(entry);

        return expansionPanelRows && expansionPanelRows.length > 0;
    }

    isClickable() {
        return this.isExpandable();
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
        this.redraw();
    }

    render() {
        const {styleSheet} = this;
        const {columns, entry, expandedRowContent} = this.options;
        const expansionPanelRows = expandedRowContent && expandedRowContent(entry);
        const onRowClick = this.isClickable() ? () => this.toggle() : NOOP_FUNCTION;

        return [
            <div className={styleSheet.bodyRow + (this.isClickable() ? styleSheet.clickableRow : "")} onClick={onRowClick}>
                {columns.map((column, index) => {
                    let icon = null;
                    if (this.isExpandable() && index === 0) {
                        icon = (this.isExpanded ? <ChevronDownIcon className={styleSheet.chevron}/> :
                            <ChevronRightIcon className={styleSheet.chevron}/>);
                    }

                    let style = {flex: column.width, textAlign: column.align || "left", ...(column.style || {})};
                    if (column.overflowEllipsis) {
                        style = {
                            ...style,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            paddingRight: 8,
                            display: "block",
                        };
                    }
                    const value = column.value(entry)
                    return [
                        <div HTMLtitle={typeof value === "string" ? value : ""} className={styleSheet.column} style={style}>
                            {icon}
                            {value}
                        </div>,
                    ];
                })}
            </div>,
            this.isExpandable() ? <RowExpansionPanel expanded={this.isExpanded} contentRows={expansionPanelRows} /> : null,
        ];
    }
}

export class FakeTableStyle extends StyleSheet {
    @styleRule
    fakeTable = {
        position: "relative",
    };

    @styleRule
    header = {
        color: this.themeProps.TABLE_HEADER_TEXT_COLOR,
        background: this.themeProps.TABLE_HEADER_BACKGROUND_COLOR,
        width: "100%",
        padding: "5px 8px",
        fontSize: 11,
        fontWeight: "bold",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
    };

    @styleRule
    body = {
        color: this.themeProps.TABLE_ENTRY_TEXT_COLOR,
        fontSize: 14,
    };
}

@registerStyle(FakeTableStyle)
export class FakeTable extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            expandedRows: false,
            TableRowClass: FakeTableRow
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.fakeTable);
    }

    render() {
        const {styleSheet} = this;
        const {TableRowClass, columns, entries, expandedRowContent, expandedRows} = this.options;

        return [
            <div className={styleSheet.header}>
                {columns.map(column => {
                    return (
                        <div style={{flex: column.width, textAlign: column.align || "left"}}>{column.headerName}</div>
                    );
                })}
            </div>,
            <div className={styleSheet.body}>
                {entries.map((entry) => (
                    <TableRowClass ref={"row" + entry.id} columns={columns} entry={entry}
                                  expandedRowContent={expandedRowContent} expanded={expandedRows}/>
                ))}
            </div>,
        ];
    }

    getRowByEntryId(id) {
        return this["row" + id];
    }
}
