import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {SOUTHERN_THING_ALIAS} from "../../Constants"
import {iFrameState} from "../../services/IFrameState";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {
    apiCancelSubscription,
    apiChangeSubscription,
    apiSubscribe,
    apiSubscribeWithCds,
    SubscriptionApplyPolicy
} from "../../../client/state/SubscriptionStore";
import {Messages} from "../../Messages";
import {Router} from "../../../stem-core/src/ui/Router";
import {
    ADDRESS_PANEL_URL,
    INLINE_EDIT_SUBSCRIPTION_URLS,
    INLINE_SUBSCRIPTION_URLS,
    PAYMENT_FAILED_PANEL_URL, SUBSCRIPTION_PLAN_PANEL_URL
} from "../PanelConstants";
import {iFrameMerchantService} from "../../services/IframeMerchantService";
import {iFrameUserDataService} from "../../services/IFrameUserDataService";
import {FLOW_CHECKPOINT, FLOW_TYPE, GATE_TYPE} from "../../../blink-sdk/Constants";
import {CARD_SECTION_STATE, PaymentMethodController} from "../inline-donation/PaymentMethodController";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore";
import {ADDRESS_SECTION_STATE} from "../pages/panels/address/AddressPanel";
import {SUBSCRIBE_PLAN_STATE, SubscriptionPlanController} from "./SubscriptionPlanController";
import {collapseIframe, collapseIframeAndExpandWallet} from "../../widget/misc/WidgetUtils";
import {sendFlowCheckpoint} from "../flows/FlowController";
import {Controller} from "../Controller";
import {AddressController} from "./AddressController";
import {PANEL_TYPE} from "../../../blink-sdk/Constants";
import {iFrameAuthService} from "../../services/IFrameAuthService";
import {Toast} from "../../ui/Toast.jsx";


export class SubscribeFlowState extends Dispatchable {
    static subscriptionToCancel = null;

    async start() {
        const {panelType, gateType, editRecurring, skipCTA} = iFrameState;

        if (panelType !== PANEL_TYPE.subscribe) {
            return;
        }

        if (editRecurring && gateType === GATE_TYPE.popup && iFrameUserDataService.getActiveSubscription()) {
            this.initEditSubscription();
            Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.subscribe);
        } else {
            this.initSubscribe();
            if (gateType === GATE_TYPE.popup) {
                if (skipCTA) {
                    await iFrameAuthService.promptAuthenticationIfNeeded();
                    if (iFrameUserDataService.getActiveSubscription()) {
                        collapseIframeAndExpandWallet();
                        return;
                    }
                }
                Router.changeURL(SUBSCRIPTION_PLAN_PANEL_URL);
            } else {
                Router.changeToCustomPanel();
            }
        }
    }

    initSubscribe() {
        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            savePaymentMethod: true,
            getScope: () => PaymentMethodStore.Scope.PRIVATE, // TODO @cleanup is seem like getScope is useless now
            onSelectSuccess: () => this.subscribe(),
        });

        Controller.subscriptionPlan = new SubscriptionPlanController({
            subscriptionPlanState: SUBSCRIBE_PLAN_STATE.subscribe,
            selectedOffer: null,
            onSelectSuccess: async () => {
                await iFrameAuthService.promptAuthenticationIfNeeded();
                if (iFrameUserDataService.getActiveSubscription()) {
                    collapseIframeAndExpandWallet();
                    return;
                }
                // If we come from login page and switch to review, we want the back to take us to the plans
                // page. Currently, this is how we achieve that without disrupting the flow in any way.
                Router.localHistory.push(SUBSCRIPTION_PLAN_PANEL_URL);
                const offer = Controller.subscriptionPlan.selectedOffer;

                if (!offer.shouldCollectAddress()) {
                    await Controller.subscriptionPlan.fetchPrices(Controller.address?.selectedAddress);
                    Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscriptionCheckout);
                    return;
                }

                Controller.address.update({addressType: offer.coverage.requiresAddress ? null : "billing"});
                Router.changeURL(ADDRESS_PANEL_URL);
            },
            onCancel: collapseIframe,
        });

        Controller.address = new AddressController({
            addressSectionState: ADDRESS_SECTION_STATE.chooseAddress,
            onSelectSuccess: async () => {
                await Controller.subscriptionPlan.fetchPrices(Controller.address?.selectedAddress);
                Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscriptionCheckout)
            },
            onSelectCancel: () => Router.back(),
            continueLabel: Messages.reviewOrder,
        });
    }

    initEditSubscription() {
        const subscription = iFrameUserDataService.getActiveSubscription();

        Controller.subscriptionPlan = new SubscriptionPlanController({
            subscriptionPlanState: SUBSCRIBE_PLAN_STATE.editSubscription,
            selectedOffer: null,
            onSelectSuccess: async () => {
                const offer = Controller.subscriptionPlan.selectedOffer;
                console.assert(offer != null, "Selected subscription offer should not be null at this point.");

                if (!offer.shouldCollectAddress() || (offer.shouldCollectAddress() && Controller.address.selectedAddress)) {
                    await Controller.subscriptionPlan.fetchPrices(Controller.address.selectedAddress);
                    Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.subscribe);
                    return;
                }
                Controller.address.update({addressType: offer.coverage.requiresAddress ? null : "billing"});
                Router.changeURL(ADDRESS_PANEL_URL);
            },
            onSelectCancel: () => Router.back(),
            applyPolicy: SubscriptionApplyPolicy.NEXT_BILLING_CYCLE,
            discountCode: subscription.getDiscount()?.getCode() || null,
            onCancel: collapseIframe,
        });

        Controller.address = new AddressController({
            addressSectionState: ADDRESS_SECTION_STATE.chooseAddress,
            selectedAddress: subscription.getShippingAddress(),
            onSelectCancel: () => Router.back(),
            onSelectSuccess: async () => {
                await Controller.subscriptionPlan.fetchPrices(Controller.address?.selectedAddress);
                Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.subscribe)
            },
            continueLabel: Messages.continue,
        });

        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            entry: subscription,
            initialPaymentMethod: subscription.getPaymentMethod(),
            selectedPaymentMethod: subscription.getPaymentMethod(),
            savePaymentMethod: true,
            getChooseCardButtonLabel: () => Messages.updatePaymentMethod,
            getPayWithAddedCardButtonLabel: () => Messages.updatePaymentMethod,
            getScope: () => PaymentMethodStore.Scope.PRIVATE,
            onSelectSuccess: () => Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.subscribe),
        });
    }

    static getSubscriptionToCancel() {
        return this.subscriptionToCancel || iFrameUserDataService.getActiveSubscription();
    }

    static hasDiscountCodeSupport() {
        const merchant = iFrameMerchantService.getMerchant();
        return merchant && merchant.hasSubscriptionDiscountCodeEnabled();
    }

    static getSubscriptionOfferPrice(offer, discountInfo = null) {
        if (discountInfo) {
            const {discountedBeforeTaxesPrice, discountValue} = discountInfo;
            return discountValue ? offer.formatPrice(discountedBeforeTaxesPrice) : offer.getPrice();
        }

         return offer.getPrice();
    }

    async changeSubscription() {
        const shippingAddress = Controller.address.selectedAddress;
        const {selectedOffer, discountCode, applyPolicy} = Controller.subscriptionPlan;
        const discountInfo = Controller.subscriptionPlan.getDiscountInfo(selectedOffer);
        const activeSubscription = iFrameUserDataService.getActiveSubscription();

        let subscriptionDetails = {
            subscriptionId: activeSubscription.id,
            applyPolicy,
            recurringOfferId: selectedOffer?.id,
            discountCode: discountInfo?.discountValue ? discountCode : null,
            ...(await Controller.paymentMethod.extractPaymentMethodFields()),
        };

        if (activeSubscription.getPaymentMethod() === Controller.paymentMethod.selectedPaymentMethod) {
            subscriptionDetails.preferredPaymentMethodId = null;
        }

        if ((!selectedOffer && activeSubscription.getShippingAddressId()) || selectedOffer?.shouldCollectAddress()) {
            subscriptionDetails.userAddressId = shippingAddress.id;
        }

        LoadingSpinner.show();
        let subscription = null;
        let changeError = null;
        try {
            subscription = await apiChangeSubscription(subscriptionDetails);
        } catch (error) {
            Toast.showError(error);
            changeError = error;
            // TODO: Handle update error.
        } finally {
            LoadingSpinner.hide();
        }

        if (!changeError && (!subscription || !subscription.isActive())) {
            Toast.showError(Messages.subscribeChangeFailed);
            return;
        }
        Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.success);
    }

    static async cancel(subscription) {
        LoadingSpinner.show();
        try {
            const subscriptionId = subscription?.id || iFrameUserDataService.getActiveSubscription().id;
            await apiCancelSubscription(subscriptionId);
            Router.changeURL(INLINE_EDIT_SUBSCRIPTION_URLS.cancelSuccess);
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    async subscribe() {
        LoadingSpinner.show();
        const subscriptionDetails = await getSubscribeEndpointPayload();
        dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_SUBSCRIBE, {recurringOfferId: subscriptionDetails.recurringOfferId});

        let subscription = null;
        try {
            if (iFrameMerchantService.isCDSPaymentMethod()) {
                subscription = await apiSubscribeWithCds(subscriptionDetails);
            } else {
                subscription = await apiSubscribe(subscriptionDetails);
            }
        } catch (error) {
            if (error?.message) {
                Controller.paymentMethod.update({
                    paymentError: error,
                });
                Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            }
        } finally {
            LoadingSpinner.hide();
        }

        if (!subscription || !subscription.isActive()) {
            Toast.showError(Messages.subscribeFailed);
            return;
        }
        sendFlowCheckpoint(FLOW_TYPE.subscription, FLOW_CHECKPOINT.complete, {
            subscriptionId: subscription.id,
            offerId: subscription.recurringOfferId,
            coverageId: subscription.coverageId,
            amount: subscription.getPrice().toMainUnitString({includeSymbol: false}),
            currency: subscription.currency.isoCode,
        });
        Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscribeThankYou);
    }
}

export async function getSubscribeEndpointPayload() {
    const shippingAddress = Controller.address.selectedAddress;
    const {selectedOffer, discountCode} = Controller.subscriptionPlan;
    const discountInfo = Controller.subscriptionPlan.getDiscountInfo(selectedOffer);
    const subscriptionDetails = {
        recurringOfferId: selectedOffer.id,
        discountCode: discountInfo?.discountValue ? discountCode : null,
        metadata: {},
        ...(await Controller.paymentMethod.extractPaymentMethodFields()),
    };

    if (selectedOffer.shouldCollectAddress()) {
        subscriptionDetails.userAddressId = shippingAddress.id;
    }

    if (iFrameMerchantService.isMerchant(SOUTHERN_THING_ALIAS)) {
        subscriptionDetails.metadata.shareName = Controller.subscriptionPlan.shareName;
    }
    return subscriptionDetails;
}
