import {UI} from "../../../stem-core/src/ui/UIBase";

import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {authService} from "../../../client/connection/Services";
import {closeWindow, getQueryParam} from "../../Utils";
import {AuthHelper, useRedirectsInsteadOfTabs} from "../../AuthHelper";


export class BlinkServiceSetCookie extends UI.Element {
    render() {
        return <LoadingSpinner/>
    }

    onMount() {
        const token = getQueryParam("token");
        if (!token) {
            window.close();
            return;
        }
        authService.setAuthToken({
            token: {
                key: token.t,
                expiresAt: token.e,
            },
        });
        // Keeping legacy logic: always honor a redirectUrl, and if there is a merchantRedirectUrl, only redirect on Facebook browser
        // TODO possibly reconsider this logic
        const redirectUrl = getQueryParam("redirectUrl") ||
            (useRedirectsInsteadOfTabs() && getQueryParam("merchantRedirectUrl"));

        if (redirectUrl) {
            AuthHelper.redirectToUrl(redirectUrl);
        } else {
            closeWindow();
        }
    }
}
