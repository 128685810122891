import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {UI} from "../../stem-core/src/ui/UIBase";
import {PaymentMethodStore} from "../State";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {AddFundsIcon} from "../SVGElements";
import {Messages} from "../Messages";
import {RadioList} from "./RadioList";
import {ActiveCardInfo} from "./ActiveCardInfo";
import {iFrameUserDataService} from "../services/IFrameUserDataService";
import {MerchantUserResourcesAccessLink} from "./MerchantUserResourcesAccessLink";


class ElementStyle extends StyleSheet {
    @styleRule
    addNewCard = {
        cursor: "pointer",
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
        whiteSpace: "nowrap",
        marginTop: 14,
        display: "inline-block",
        ">*": {
            display: "inline-block",
        },
        ">:last-child": {
            marginLeft: 14,
        },
        ">:first-child": {
            transform: "translateY(1px)",
        },
    };

    @styleRule
    description = {
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
        marginBottom: 14,
    };

    @styleRule
    header = {
        display: "flex",
        justifyContent: "space-between",
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
        paddingBottom: 4,
        borderBottom: () => "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR,
    };

    @styleRule
    list = {
        marginRight: 0,
        width: "100%",
    };

    @styleRule
    activeCardInfo = {
        marginBottom: 18,
    };

    @styleRule
    accessResources = this.themeProps.PANEL_ACCESS_RESOURCES;
}

@registerStyle(ElementStyle)
export class ChooseCard extends UI.Element {
    activeCard = this.findActiveCard();
    scrollToActiveTimeout = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            addCardButtonLabel: Messages.addNewPaymentMethod,
            onAddCard: NOOP_FUNCTION,
            onCardSelected: NOOP_FUNCTION,
            addNewCardColor: this.styleSheet.themeProps.MERCHANT_LINK,
            CardItemClass: null,
            ListItemClass: null,
            hideActiveCard: false,
            padListItem: false,
            recurringPayment: null,
            showHeader: false,
            showDescription: false,
            isActive: card => card === PaymentMethodStore.getPrimary(),
        };
    }

    findActiveCard() {
        return PaymentMethodStore.all().find(card => this.options.isActive(card));
    }

    getCardsList() {
        if (this.options.recurringPayment) {
            return this.options.recurringPayment.getAvailablePaymentMethods();
        }

        return PaymentMethodStore.getAvailablePaymentMethods();
    }

    render() {
        const {styleSheet} = this;
        const {addCardButtonLabel, ListItemClass, CardItemClass, hideActiveCard, padListItem, addNewCardColor,
            onAddCard, showHeader, showDescription} = this.options;

        clearTimeout(this.scrollToActiveTimeout);
        this.scrollToActiveTimeout = this.attachTimeout(() => {
            const activeCard = this.findActiveCard();
            if (activeCard) {
                this["card" + activeCard.id].node.scrollIntoView({behavior: "smooth"})
            }
            this.scrollToActiveTimeout = null;
        });

        const extraPaymentMethodCount = iFrameUserDataService.getExtraPaymentMethodCount();

        return [
            showDescription ? <div className={styleSheet.description}>{Messages.chooseCardDescription}</div> : null,
            hideActiveCard ? null : <ActiveCardInfo card={this.activeCard} className={styleSheet.activeCardInfo}/>,
            showHeader && !!this.getCardsList().length ? <div className={styleSheet.header}>
                <div>{Messages.creditCardsListHeader}</div>
                <div>{Messages.expires}</div>
            </div> : null,
            <RadioList onItemSelect={(item) => this.handleCardItemSelection(item)} className={styleSheet.list}
                       ListItemClass={ListItemClass} padListItem={padListItem}>
                {this.getCardsList().map(card => <CardItemClass card={card} ref={"card" + card.id} selected={card === this.activeCard} />)}
            </RadioList>,
            <Link className={styleSheet.addNewCard} style={{color: addNewCardColor}} onClick={() => onAddCard()}>
                <AddFundsIcon size={12.25} color={addNewCardColor}/>
                <span>{addCardButtonLabel}</span>
            </Link>,
            extraPaymentMethodCount !== 0 ?
                <div className={styleSheet.accessResources}>
                    {Messages.or}
                    <MerchantUserResourcesAccessLink extraResourcesCount={extraPaymentMethodCount}/>
                </div> : null,
        ];
    }

    handleCardItemSelection(item) {
        const {card} = item.options;
        this.activeCard = card;
        this.options.onCardSelected(card);
        this.redraw();
    }

    getActive() {
        return this.activeCard;
    }

    setActive(card) {
        this.activeCard = card;
        this.redraw();
    }

    onMount() {
        super.onMount();

        let activeCard = this.findActiveCard();
        this.attachChangeListener(PaymentMethodStore, () => {
            if (activeCard !== this.findActiveCard()) {
                activeCard = this.findActiveCard();
                this.activeCard = activeCard;
                this.options.onCardSelected(activeCard);
            }
            this.redraw();
        });
    }
}
