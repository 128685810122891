import {registerStyle, styleRule, StyleSheet, UI} from "../../../../../../stem-core/src/ui/UI";
import {CreditCardIcon} from "../../../../../ui/CreditCardIcon";
import {Messages} from "../../../../../Messages";
import {MessageElement} from "../../../../../widget/ui/MessageElement";


class PaymentDescriptionStyle extends StyleSheet {
    @styleRule
    paymentDescriptionContainer = {
        display: "flex",
        alignItems: "center",
        fontSize: 12,
    };

    @styleRule
    cardIcon = {
        marginRight: 12,
    };

    @styleRule
    cardDescription = {
        fontSize: 12,
        color: this.themeProps.CARD_BRANDING_DESCRIPTION_LIGHTER,
    };

}

@registerStyle(PaymentDescriptionStyle)
export class PaymentDescription extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            payment: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.paymentDescriptionContainer);
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {payment} = this.options;
        const paymentMethod = payment?.getPaymentMethod();
        const cardDescription = paymentMethod ? Messages.usingCard(paymentMethod.getBrand(), paymentMethod.getLastDigits())
                                              : Messages.walletFunds;

        return [
                <CreditCardIcon className={styleSheet.cardIcon} brand={paymentMethod?.getBrand()} size={18}/>,
                <div>
                    {this.render()}
                    <MessageElement className={styleSheet.cardDescription} message={cardDescription} />
                </div>
        ];
    }
}
