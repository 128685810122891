import {MerchantStore} from "../../../../State";
import {OAuthError} from "../../../../../client/connection/services/BlinkOAuthService";
import {isIframe} from "../../../../Utils";

export class ClientOAuthApplication {
    constructor(clientId, redirectUrl, responseType) {
        if (!redirectUrl && !isIframe()) {
            throw new Error(OAuthError.errorCodes.INVALID_REDIRECT_URI)
        }

        clientId = parseInt(clientId, 10);
        const merchant = MerchantStore.get(clientId);

        if (!merchant) {
            throw new Error(OAuthError.errorCodes.INVALID_CLIENT_ID);
        }

        this.clientId = clientId;
        this.redirectUrl = redirectUrl;
        this.responseType = responseType;
        this.name = merchant.getName();
        this.logoUrl = merchant.wideLogoUrl;
        this.userAuthorizedAccess = false;
        this.isForceAuthorized = false;
    }

    needsAuthorizationAccept() {
        return !this.userAuthorizedAccess && !this.isForceAuthorized;
    }

    setAuthorizationAccepted(approved) {
        this.userAuthorizedAccess = approved;
    }

    forceAuthorize() {
        this.isForceAuthorized = true;
    }

    getInfo() {
        return {
            clientId: this.clientId,
            redirectUri: this.redirectUrl,
            responseType: this.responseType,
        };
    }
}
