import {StoreObject, Store} from "stem-core/src/state/Store";
import {field} from "./misc/StoreField";
import {apiConnection} from "../connection/BlinkApiConnection";
import {EditableObjectStatus} from "./misc/GenericEnums";
import {BUILD_VERSION} from "../../blink-sdk/Constants.js";


export class Merchant extends StoreObject {
    @field("Currency") currency;
    @field(String) name;
    @field(String) alias;
    @field(Date) createdAt;
    @field(String) url;
    @field(String) wideLogoUrl;

    @field(Boolean) isFake;
    @field(Boolean) isNonprofit;

    @field(Boolean) autoPay;
    @field(Boolean) allowEmailing;
    @field(Boolean) allowPurchases;
    @field(Boolean) allowSubscriptions;
    @field(Boolean) allowDonations;
    @field(Boolean) allowUnverifiedEmailUsers;

    @field("LegalEntity") legalEntity;

    @field(EditableObjectStatus) sdkStatus;

    getUrl() {
        if (BUILD_VERSION) {
            return this.url;
        }
        return `http://localhost:8077/?clientId=${this.alias}`;
    }

    getName() {
        return this.name;
    }

    getCurrency() {
        return this.currency;
    }

    isAutopayCompatible() {
        return this.autoPay === true;
    }

    toString() {
        return this.name;
    }

    hasPurchasesEnabled() {
        return !!this.allowPurchases;
    }

    hasSubscriptionsEnabled() {
        return !!this.allowSubscriptions;
    }

    hasSubscriptionDiscountCodeEnabled() {
        return !!this.hasSubscriptionDiscountCode;
    }

    hasDonationsEnabled() {
        return !!this.allowDonations;
    }
}

class MerchantStoreClass extends Store("Merchant", Merchant) {
    getAutopayCompatibleMerchants() {
        return this.all().filter(merchant => merchant.isAutopayCompatible());
    }

    getByIdOrAlias(key) {
        const entry = this.get(key);
        if (entry) {
            return entry;
        }
        return this.all().find(merchant => merchant.alias === key);
    }
}


export const MerchantStore = new MerchantStoreClass();

export async function apiMerchantEditSettings(request) {
    return apiConnection.post("/merchant/edit_global_settings", request);
}