import {StoreObject, Store, MakeStore} from "stem-core/src/state/Store";
import {field} from "./misc/StoreField";
import {apiClient} from "../connection/BlinkApiConnection";


class LegalEntity extends StoreObject {
    @field("User") owner;
    @field(Date) createdAt;
    @field(String) name;
    @field(String) legalName;
    @field("Jurisdiction") jurisdiction;
    @field(String) legalEntityType;
    @field(Boolean) isNonProfit;
    @field(String) taxId;
    @field(String) streetAddress;
    @field(String) stateAddress;
    @field(String) cardStatementName;
    @field(String) bankAccountLastDigits;

    toString() {
        return this.name;
    }
}

export const LegalEntityStore = MakeStore("LegalEntity", LegalEntity);

export async function apiUpdateLegalEntity(data) {
    return apiClient.post("admin/legal_entities/update", data)
}
