import {ensure} from "../../stem-core/src/base/Require";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";

export function ensureNaClScript(callback=NOOP_FUNCTION) {
    ensure(["/nacl-fast.min.js"], callback);
}

function bytesToHex(bytes) {
    const hexDigits = "0123456789ABCDEF";
    let hexDigitArray = [];
    for (const byte of bytes) {
        hexDigitArray.push(hexDigits[byte >> 4]);
        hexDigitArray.push(hexDigits[byte & 15]);
    }
    return hexDigitArray.join("");
}

function hexToBytes(hexString) {
    let bytesArray = [];
    for (let i = 0; i + 1 < hexString.length; i += 2) {
        bytesArray.push((parseInt(hexString[i], 16) << 4) + parseInt(hexString[i + 1], 16));
    }
    return new Uint8Array(bytesArray);
}

export async function encryptJSON(data, publicKeyHex) {
    return new Promise(async (resolve) => {
        ensureNaClScript(() => {
            const encodedData = btoa(JSON.stringify(data));  // ensure that it's ASCII so we can use Uint8Array
            const plaintext = new Uint8Array(encodedData.length);
            for (let i = 0; i < encodedData.length; i++) {
                plaintext[i] = encodedData.charCodeAt(i);
            }
            const senderKeyPair = nacl.box.keyPair();
            const nonce = nacl.randomBytes(nacl.box.nonceLength);
            const ciphertext = nacl.box(plaintext, nonce, hexToBytes(publicKeyHex), senderKeyPair.secretKey);
            resolve(`${bytesToHex(ciphertext)};${bytesToHex(nonce)};${bytesToHex(senderKeyPair.publicKey)}`);
        });
    });
}
