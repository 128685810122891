import {UI} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {DonationStore, PaymentStore} from "../../../../../State";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {SubscriptionPayments, SubscriptionPaymentsStyle} from "../../subscriptions/sections/SubscriptionPayments";
import {Dispatcher} from "../../../../../../stem-core/src/base/Dispatcher";
import {PaymentDescription} from "../../wallet/components/PaymentDescription";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {EndpointPaginator} from "../../../../../../client/state/EndpointPaginator";
import {UIPaginationManager} from "../../../../../ui/BaseCard";


class DonationPaymentsStyle extends SubscriptionPaymentsStyle {}

@registerStyle(DonationPaymentsStyle)
export class DonationPayments extends SubscriptionPayments {
    paginator = new EndpointPaginator(
        PaymentStore,
        PaymentStore.API_LIST_ENDPOINT,
        {resourceTypes: [PaymentStore.ResourceType.DONATION]},
        {resourceType: PaymentStore.ResourceType.DONATION},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.donationPayments,
            description: () => (this.getTransactions().length || !this.paginator.lastPageLoaded) ? "" : Messages.emptyDonationPayments,
        };
    }

    getTransactionDescription(transaction) {
        const donation = DonationStore.get(transaction.resourceId);
        return {
            title: `${donation.getMerchantTitle()}${donation.isRecurring() ? (" (" + Messages.recurring.toString().toLowerCase() + ")") : ""}`,
            highlighted: donation.isRecurring(),
        };
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        const {title, highlighted} = this.getTransactionDescription(payment);
        return <PaymentDescription payment={payment}>
            {highlighted ? <Link className={styleSheet.paymentTitle}
                                 style={{color: styleSheet.themeProps.MERCHANT_LINK}}
                                 onClick={() => Dispatcher.Global.dispatch("highlightDonation", payment.resourceId)}>
                                {title}
                            </Link>
                         : <div className={styleSheet.paymentTitle}>{title}</div>
            }
        </PaymentDescription>;
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
