import {MerchantStore} from "../../client/state/MerchantStore";
import {DonationOfferStore} from "../../client/state/DonationOfferStore";
import {TNR_ALIAS, PANEL_TYPE} from "blink-sdk/Constants";
import {BlinkGlobal} from "../UtilsLib";


class IframeMerchantService {
    init(merchantId) {
        this.merchantId = merchantId;
        BlinkGlobal.iFrameMerchantService = this;
    }

    getMerchant() {
        return MerchantStore.get(this.merchantId);
    }

    getDonationOffer() {
        return DonationOfferStore.getAvailable().find(offer => offer.merchantId === this.merchantId);
    }

    getDefaultDashboardTabs() {
        const tabs = [];
        if (this.getMerchant().allowSubscriptions) {
            tabs.push(PANEL_TYPE.subscribe);
        }
        if (this.getMerchant().allowDonations) {
            tabs.push(PANEL_TYPE.donation);
        }
        if (this.getMerchant().allowEmailing) {
            tabs.push(PANEL_TYPE.newsletter);
        }
        tabs.push(PANEL_TYPE.authentication);
        return tabs;
    }

    // For hardcoded stuff.
    isMerchant(alias) {
        return this.getMerchant()?.alias === alias;
    }

    isCDSPaymentMethod() {
        // return false;
        return this.isMerchant(TNR_ALIAS);
    }
}

export const iFrameMerchantService = new IframeMerchantService();
