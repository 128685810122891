import {styleRule} from "../../stem-core/src/decorators/Style";
import {dashCase, isBoolean, isNumber, isPlainObject, isString} from "../../stem-core/src/base/Utils";
import {defaultToPixelsAttributes} from "../../stem-core/src/ui/NodeAttributes";

export function isHTTPSUrl(rawUrl) {
    let url;
    try {
        url = new URL(rawUrl);
    } catch (e) {
        // not a valid URL
        return false;
    }
    return url.protocol === "https:";
}

// TODO: Sanitize all the URLs in the styleObj (e.g. "background: url(...);").
function sanitizeCss(styleObj) {
    return styleObj;
}

function makeAllStyleAttributesImportant(styleObj) {
    if (typeof styleObj !== "object" || styleObj === null) {
        return {};
    }
    for (const key of Object.keys(styleObj)) {
        let value = styleObj[key];
        if (isNumber(value) && value !== 0 && defaultToPixelsAttributes.has(dashCase(key))) {
            value += "px";
        }
        if ((isString(value) || isNumber(value)) && !value.toString().match("!important")) {
            styleObj[key] = value + " !important";
        } else if (typeof value === "object") {
            styleObj[key] = makeAllStyleAttributesImportant(value);
        }
    }
    return styleObj;
}

// Use this decorator instead of `styleRule` for styles received from the merchant.
export function merchantStyleRule(target, key, descriptor) {
    const {value, initializer} = descriptor;
    if (value) {
        descriptor.value = makeAllStyleAttributesImportant(sanitizeCss(descriptor.value));
    }
    if (initializer) {
        descriptor.initializer = function () {
            return makeAllStyleAttributesImportant(sanitizeCss(initializer.apply(this)));
        };
    }
    return styleRule(target, key, descriptor);
}

export function ensureInEnum(optionalValue, enumObject, defaultValue=null) {
    const enumValues = Object.values(enumObject);
    for (const value of enumValues) {
        if (optionalValue === value) {
            return optionalValue;
        }
    }
    return defaultValue;
}

export function ensurePlainObject(optionalValue) {
    const value = optionalValue ?? {};
    if (!isPlainObject(value)) {
        return {};
    }
    return value;
}

export function ensureNonRecursivePlainObject(optionalValue) {
    const value = ensurePlainObject(optionalValue);
    const reducedValue = {};
    for (const key of Object.keys(value)) {
        if (isString(key) && (isString(value[key]) || isNumber(value[key]) || isBoolean(value[key]))) {
            reducedValue[key] = value[key];
        }
    }
    return reducedValue;
}

export function ensureArray(optionalValue) {
    const value = optionalValue ?? [];
    return Array.isArray(value) ? value : [];
}

export function isValidFontFace(fontFace) {
    fontFace = ensurePlainObject(fontFace);
    // Check the shape of the fontFace object
    if (!isString(fontFace.family) || !isString(fontFace.src)) {
        return false;
    }
    if (fontFace.style != null && !isString(fontFace.style)) {
        return false;
    }
    if (fontFace.weight != null && !isNumber(fontFace.weight) && !isString(fontFace.weight)) {
        return false;
    }
    if (fontFace.unicodeRange != null && !isString(fontFace.unicodeRange)) {
        return false;
    }

    return true;
}
