import {UI, registerStyle, styleRule, StyleSheet} from "../../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../../Messages";
import {SingleCard} from "./SingleCard";
import {ConfirmationModal} from "../../../../../../ui/ConfirmationModal";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {PopupMenu} from "../../../../../../ui/Menu";
import {SubscriptionStore} from "../../../../../../../client/state/SubscriptionStore";
import {DonationStore} from "../../../../../../../client/state/DonationStore";
import {IrremovablePaymentMethodModal} from "./IrremovablePaymentMethodModal";
import {apiDeletePaymentMethod, apiSetPrimaryPaymentMethod} from "../../../../../../../client/state/PaymentMethodStore";
import {Toast} from "../../../../../../ui/Toast";


class SingleCardWidgetStyle extends StyleSheet {
    @styleRule
    cardWidget = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        color: this.themeProps.PAYMENT_METHOD_COLOR
    };

    @styleRule
    cardDetails = {
        marginRight: 6,
    };

    @styleRule
    menuItem = {
        padding: 6,
    };
}

class RemoveCardModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "removeCardModal",
            title: Messages.removeCard,
            confirmLabel: Messages.remove,
            confirmAction: () => this.removeCard(),
            description: Messages.removeCardEndingIn(this.options.card.getBrand(), this.options.card.getLastDigits()),
        };
    }

    async removeCard() {
        LoadingSpinner.show();
        try {
            await apiDeletePaymentMethod(this.options.card.id);
            Toast.show(Messages.cardRemoved);
        } catch (error) {
            Toast.show(error.message);
        } finally {
            LoadingSpinner.hide();
            this.hide();
        }
    }
}

@registerStyle(SingleCardWidgetStyle)
export class SingleCardWidget extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            card: null,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.cardWidget);
    }

    removeAction() {
        const {card} = this.options;
        const entries = [
            ...DonationStore.getActiveRecurring().filter(donation => !donation.isCanceled()),
            ...SubscriptionStore.getActiveAndNotCanceled()
        ].filter(recurringPayment => recurringPayment.getPaymentMethod() === card);

        if (entries.length) {
            IrremovablePaymentMethodModal.show({entries});
        } else {
            RemoveCardModal.show({card});
        }
    }

    async makeDefault() {
        try {
            await apiSetPrimaryPaymentMethod(this.options.card.id);
            Toast.show(Messages.changesSaved);
        } catch (e) {
            console.error(e);
        }
    }

    getMenuItems() {
        const {styleSheet} = this;
        const {card} = this.options;

        const items = [{
            content: <div className={styleSheet.menuItem}>{Messages.removeCard}</div>,
            onClick: () => this.removeAction()
        }];

        if (!card.isPrimary) {
            items.push({
                content: <div className={styleSheet.menuItem}>{Messages.makeDefault}</div>,
                onClick: () => this.makeDefault()
            });
        }

        return items;
    }

    render() {
        return [
            <SingleCard className={this.styleSheet.cardDetails} card={this.options.card} showPrimary={true} />,
            <PopupMenu items={this.getMenuItems()}/>
        ];
    }
}
