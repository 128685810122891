import {UI} from "../../../../../../stem-core/src/ui/UIBase";

import {ConfirmationModal} from "../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../Messages";
import {PanelChooseCard} from "../../../../../panel/pages/panels/donation/components/PanelChooseCard";
import {AddCardModal} from "../../wallet/components/cards/AddCardModal";
import {PaymentMethodStore} from "../../../../../../client/state/PaymentMethodStore";
import {Toast} from "../../../../../ui/Toast";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {Dispatcher} from "../../../../../../stem-core/src/base/Dispatcher";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";


@autoredraw(PaymentMethodStore)
export class ChoosePaymentMethodModal extends ConfirmationModal {
    lastAddedCardId = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            cancelLabel: Messages.close,
            confirmLabel: this.options.entry.isUnpaid() ? Messages.retry : Messages.updatePaymentMethod,
            title: Messages.choosePaymentMethod,
            confirmAction: () => this.updatePaymentMethod(),
            entry: null,
        };
    }

    getConfirmLabel() {
        const {entry} = this.options;
        return entry.isUnpaid() ? (!this.chooseCard || entry.getPaymentMethod().id === this.chooseCard.getActive().id ?
                Messages.retry : Messages.updateAndRetryPayment) : Messages.updatePaymentMethod;
    }

    render() {
        const {entry} = this.options;
        const entityCard = entry.getPaymentMethod();
        const entityCardId = entityCard?.id;
        const selectedCardId = this.chooseCard?.getActive()?.id || this.lastAddedCardId || entityCardId;
        this.confirmButtonDisabled = !this.chooseCard || this.chooseCard?.getActive()?.id === entityCardId;

        if (entityCardId === selectedCardId && entry.isUnpaid()) {
            this.confirmButtonDisabled = false;
        }

        return [
            <PanelChooseCard ref="chooseCard" hideActiveCard={true} selected={entityCard} recurringPayment={entry}
                             onAddCard={() => this.handleAddCardButtonClick()} onCardSelected={() => this.redraw()} />,
        ];
    }

    handleAddCardButtonClick() {
        AddCardModal.show();
        const handler = this.attachListenerOnce(Dispatcher.Global, "cardAdded", (card) => {
            this.lastAddedCardId = card.id;
            this.chooseCard.setValue(card);
            this.redraw();
        });
        this.attachListenerOnce(Dispatcher.Global, "hideAddCard", () => {
            handler.remove();
        });
    }

    async attemptPayEntry(entry) {
        await entry.reactivate();
        Toast.show(Messages.entrySuccessfullyPaid);
    }

    async updatePaymentMethod() {
        const {entry} = this.options;

        if (!entry) {
            return;
        }

        LoadingSpinner.show();
        try {
            if (entry.getPaymentMethod().id === this.chooseCard.getActive().id) {
                await this.attemptPayEntry(entry);
                this.hide();
                return;
            }

            await entry.updatePaymentMethod(this.chooseCard.getActive().id);
            this.hide();

            if (entry.isUnpaid()) {
                await this.attemptPayEntry(entry);
                return;
            }
            Toast.show(Messages.updateEntryPaymentMethodSuccess);
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }
}
