import {GenericObjectStore, MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {Money} from "./misc/Money";
import {apiConnection} from "../connection/BlinkApiConnection";
import {StemDate} from "../../stem-core/src/time/Date";
import {MerchantStore} from "./MerchantStore";
import {field} from "./misc/StoreField";
import {CycleUnit} from "./RecurringPaymentStoreObject";
import {EditableObjectStatus, InputFieldRequiredType, VisibilityStatus} from "./misc/GenericEnums";
import {BlinkGlobal} from "../../blinkpay/UtilsLib";
import {filterStoreByMerchantAndLooseIdArray} from "./misc/Filter";
import {ShippingPriceEntry} from "./misc/ShippingPrice.js";


export class BillingPlan extends StoreObject {
    @field("Merchant") merchant;
    @field("Currency") currency;
    @field(Number) maxPaymentAttemptsCount;
    @field(Number) paymentAttemptDelayHours;
    @field(Number) attemptsBeforeNextCycle;
    @field(Number) useCalendarPeriod;
    @field(Boolean) collectAddress;
    @field(Number) amount;
    @field(CycleUnit) cycleUnit;
    @field(Number) cycleUnitCount;
    @field(Object) shippingPrices; // An array of entries, of which the first matching one is used
    @field(Number) minNumberOfCycles;
    @field(Number) maxNumberOfCycles;
    @field(Boolean) autorenewEditable;
    @field(Boolean) autorenewDefault;
    @field(Number) freeTrialDays;
    @field(InputFieldRequiredType) trialPaymentMethodRequired;
    @field(EditableObjectStatus) status;

    // TODO @Mihai does this method really need to exist?
    hasShippingPrice() {
        // TODO: this is a strange method
        return Array.isArray(this.shippingPrices) && this.shippingPrices.length > 0;
    }

    // TODO @branch cleanup and merge with Product
    // Return an array of ShippingPrice objects. Will modify the objects inline.
    getShippingPrices() {
        return ShippingPriceEntry.loadArray(this.shippingPrices, this.currencyId);
    }

    getAbbreviatedCycleDuration() {
        const cycleUnitAbrev = this.cycleUnit.getAbrev();
        return this.cycleUnitCount > 1 ? this.cycleUnitCount + " " + cycleUnitAbrev : cycleUnitAbrev;
    }

    getCycleDuration(omitCountOnSingular=false) {
        return this.cycleUnit.timeUnit.formatCount(this.cycleUnitCount, omitCountOnSingular);
    }

    getCurrency() {
        return this.currency;
    }

    formatPrice(amount) {
        return new Money(amount, this.getCurrency());
    }

    // TODO @branch Replace usage with getBasePrice
    getPrice() {
        return this.formatPrice(this.amount);
    }

    getBasePrice() {
        return this.formatPrice(this.amount);
    }

    formatBasePrice() {
        return `${this.getBasePrice()}/${this.getCycleDuration(true)}`;
    }

    serializeForSDK() {
        const {amount, collectBillingAddress, currencyId, cycleUnit, cycleUnitCount, id, merchantId, shippingPrices, status} = this;
        return {amount, collectBillingAddress, currencyId, cycleUnit, cycleUnitCount, id, merchantId, shippingPrices, status};
    }
}

export const BillingPlanStore = MakeStore("BillingPlan", BillingPlan, {dependencies: ["Currency", "Merchant"]});

export class SubscriptionOffer extends StoreObject {
    @field("Merchant") merchant;
    @field("BillingPlan") billingPlan;
    @field("SubscriptionCoverage") coverage;
    @field(VisibilityStatus) visibility;

    toString() {
        return `${this.getName()} ${this.formatBasePrice()}`
    }

    getCoverage() {
        return this.coverage;
    }

    shouldCollectAddress() {
        return this.coverage.requiresAddress || this.collectAddress;
    }

    getMerchant() {
        return MerchantStore.get(this.merchantId);
    }

    hasShippingPrice() {
        return this.billingPlan.hasShippingPrice();
    }

    // Return an array of ShippingPrice objects. Will modify the objects inline.
    getShippingPrices() {
        return this.billingPlan.getShippingPrices();
    }

    getName() {
        return this.title || this.getCoverage()?.getName();
    }

    getAbbreviatedCycleDuration() {
        return this.billingPlan.getAbbreviatedCycleDuration();
    }

    getCycleDuration(omitCountOnSingular=false) {
        return this.billingPlan.getCycleDuration(omitCountOnSingular);
    }

    getCurrency() {
        return this.billingPlan.currency;
    }

    formatPrice(amount) {
        return this.billingPlan.formatPrice(amount);
    }

    // TODO @branch Replace usage with getBasePrice
    getPrice() {
        return this.billingPlan.getPrice();
    }

    getBasePrice() {
        return this.billingPlan.getBasePrice();
    }

    formatBasePrice() {
        return this.billingPlan.formatBasePrice();
    }

    getDescription() {
        return this.description || "";
    }

    // TODO @branch maybe rename to isActive?
    isAvailable() {
        return !this.revoked && !this.deletedAt && StemDate.now().getTime() >= (new StemDate(this.startDate)).getTime()
            && (!this.endDate || StemDate.now().getTime() <= (new StemDate(this.endDate)).getTime());
    }
}

class SubscriptionOfferStoreClass extends GenericObjectStore {
    constructor() {
        super("SubscriptionOffer", SubscriptionOffer, {dependencies: ["SubscriptionDiscountCode", "SubscriptionCoverage", "BillingPlan"]});
    }

    allUnfiltered() {
        // Because the IFrameState is able to overwrite the set of subscription offers, we overwrite the .all() method
        // on this store to take that into account. We need this method to be able to implement the filter in
        // IFrameState.
        // Order the offers decreasingly by start date.
        return super.all().sort((subA, subB) => new Date(subB.startDate).getTime() - new Date(subA.startDate).getTime());
    }

    // TODO @Mihai rewrite this
    all() {
        return filterStoreByMerchantAndLooseIdArray(this.allUnfiltered(), BlinkGlobal.iFrameState?.subscriptionOffer,
            (offer, idOrAlias) => offer.id == idOrAlias || offer.alias == idOrAlias);
    }
}

export const SubscriptionOfferStore = new SubscriptionOfferStoreClass();


export async function apiCalculateSubscriptionOffersPrice(calculatePriceRequest = {
    discountCode: null,
    merchantId: null,
    userAddressId: null,
}) {
    return apiConnection.get("/subscriptions/calculate_subscription_price/", calculatePriceRequest);
}

export async function apiCalculateSubscriptionEditOffersPrice(calculatePriceRequest={
    subscriptionId: null,
    discountCode: null,
    userAddressId: null,
}) {
    return apiConnection.get("/subscriptions/calculate_update_subscription_price/", calculatePriceRequest);
}

export async function apiMerchantCreateSubscriptionOffer(request) {
    const response = await apiConnection.post("/merchant/create_subscription_offer/", request);
    return SubscriptionOfferStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditSubscriptionOffer(request) {
    return apiConnection.post("/merchant/edit_subscription_offer/", request);
}

export async function apiMerchantDeleteSubscriptionOffer(request) {
    return apiConnection.post("/merchant/delete_subscription_offer/", request);
}

export async function apiMerchantCreateBillingPlan(request) {
    const response = await apiConnection.post("/merchant/create_billing_plan/", request);
    return BillingPlanStore.loadObjectFromResponse(response);
}

export function apiMerchantEditBillingPlan(request) {
    return apiConnection.post("/merchant/edit_billing_plan/", request);
}
