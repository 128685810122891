import {registerStyle, styleRule, UI, StyleSheet} from "../../../../stem-core/src/ui/UI";

import {authService} from "../../../../client/connection/Services";
import {AuthHelper} from "../../../AuthHelper";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives";
import {Messages} from "../../../Messages";
import {Router} from "../../../../stem-core/src/ui/Router";
import {CONFIRMATION_CODE_TYPES, LOGIN_URL} from "../../../Constants";
import {ShortCodeEmailConfirmationForm} from "../components/ShortCodeEmailConfirmationForm";
import {LongCodeEmailConfirmationForm} from "../components/LongCodeEmailConfirmationForm";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../../blink-sdk/utils/AnalyticsClient";
import {authFormService} from "../../../services/AuthFormService";
import {MessageElement} from "../../../widget/ui/MessageElement";
import {LoadingSpinner} from "../../../ui/LoadingSpinner";
import {Toast} from "../../../ui/Toast.jsx";


export class LoginWithEmailCodeStyle extends StyleSheet {
    @styleRule
    component = {
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        position: "relative",
    };

    @styleRule
    message = {
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
    };

    @styleRule
    text = {
        display: "inline",
    };

    @styleRule
    center = {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
    };

    @styleRule
    resendCode = {
        ...this.themeProps.TERTIARY_BUTTON,
        paddingTop: 6,
    };

    @styleRule
    link = {
        marginTop: 8,
        display: "flex",
        ...this.themeProps.PANEL_LINK,
    };

    @styleRule
    noPointerEvents = {
        pointerEvents: "none",
    };

    @styleRule
    invisible = {
        opacity: 0,
    };
}

@registerStyle(LoginWithEmailCodeStyle)
export class LoginWithEmailCode extends UI.Element {
    canDisplayResendCodeOption = true;
    confirmed = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onWrongEmailButtonClick: () => Router.changeURL(LOGIN_URL), // TODO @auth @cleanup this won't be necessary once we merge for TNR
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.component);
    }

    getBeforeInput() {
        const {styleSheet} = this;
        const messageFormatter = (authFormService.confirmationCodeType === CONFIRMATION_CODE_TYPES.SHORT) ?
            Messages.enterConfirmationCodeShort : Messages.enterConfirmationCodeLong;

        return [
            <MessageElement className={styleSheet.text} message={messageFormatter(authFormService.registrationMail)}/>,
            this.getWrongEmailLink(),
        ];
    }

    getWrongEmailLink() {
        const {styleSheet, confirmed} = this;

        return <Link className={styleSheet.link + (confirmed ? styleSheet.noPointerEvents : "")}
                     onClick={() => this.handleWrongEmailLinkClick()}>{Messages.wrongEmail}</Link>;
    }

    getEmailConfirmationFormClass() {
        return authFormService.confirmationCodeType === CONFIRMATION_CODE_TYPES.LONG
            ? LongCodeEmailConfirmationForm : ShortCodeEmailConfirmationForm;
    }

    render() {
        const {styleSheet} = this;
        const EmailConfirmationFormClass = this.getEmailConfirmationFormClass();

        return [
            <div className={styleSheet.message}>
                {this.getBeforeInput()}
            </div>,
            <EmailConfirmationFormClass ref="emailConfirmationForm"
                                        onCodeAutomaticallyVerified={() => {
                                            this.canDisplayResendCodeOption = false;
                                            this.redraw();
                                        }}
                                        onSubmit={(code) => this.handleSubmit(code)}/>,
            this.getAfterInput(),
        ];
    }

    getAfterInput() {
        const {confirmed, canDisplayResendCodeOption, styleSheet} = this;

        if (!canDisplayResendCodeOption) {
            return null;
        }

        return <div className={styleSheet.center}>
            <div className={styleSheet.resendCode + (confirmed ? (styleSheet.noPointerEvents + styleSheet.invisible) : "")}
                 onClick={() => this.resendCode()}>
                {Messages.resendCode}
            </div>
        </div>
    }

    handleWrongEmailLinkClick() {
        if (this.emailConfirmationForm) {
            this.options.onWrongEmailButtonClick();
        }
    }

    async handleSubmit(code) {
        dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_CONFIRM_EMAIL_CODE);
        LoadingSpinner.show()
        try {
            await this.confirmEmailCode(code);
            this.confirmed = true;
            this.onConfirmSuccess();
        } finally {
            LoadingSpinner.hide();
        }
    }

    onConfirmSuccess() {
        if (this.options.onSuccess) {
            this.options.onSuccess();
        }
        AuthHelper.confirmedAuthenticationAction();
    }

    async confirmEmailCode(code) {
        await AuthHelper.loginWithEmailCode(code);

        dispatchAnalyticsEvent(AnalyticsEventType.USER_LOGIN, {registration: authFormService.isRegistration});
    }

    async resendCode() {
        try {
            await AuthHelper.requestLoginCode();
            Toast.showSuccess(Messages.confirmationCodeResent);
        } catch (error) {
            Toast.showError(error, Messages.unexpectedError);
        }
    }

    onMount() {
        super.onMount();
        this.attachUpdateListener(authFormService, () => this.redraw());
        authService.executeWhenAuthenticated(() => {
            /*
            If the user completed email confirmation in another tab, redirect / close the current page.
            The timeout is needed because by confirming the code through this component the expected behaviour is not
            instant, and it is a bit hard to check whether or not the confirmation was made through this component
            otherwise.
             */
            setTimeout(() => {
                if (!this.confirmed) {
                    AuthHelper.confirmedAuthenticationAction();
                }
            }, 300);
        });
    }
}
