import {StemDate} from "../../stem-core/src/time/Date";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {Toast} from "../../blinkpay/ui/Toast";

const DATE_WITHOUT_YEAR = "MMM DD";
const DATE_WITH_YEAR = "MMM DD, YYYY";
export const NON_BREAKING_SPACE = String.fromCharCode(160); // Looks like a space, but never breaks when wrapping lines

export function CopyTextToClipboard(text) {
    // TODO: maybe use document.execCommand("copy") if this prompts a pop-up
    navigator.clipboard.writeText(text);
    Toast.show("Copied to clipboard");
}

// TODO do a better job with date formatting
export function formatDate(date, includingHour) {
    date = new StemDate(date);
    const dateFormat = (date.isSame(new StemDate(), TimeUnit.YEAR) ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR) +
        (includingHour ? " HH:mm AA" : ""); // TODO custom hour format
    return date.format(dateFormat);
}

export function formatDateWithHour(date) {
    return formatDate(date, true);
}

export function formatDateRange(startDate, endDate) {
    startDate = new StemDate(startDate);
    endDate = new StemDate(endDate);

    const sameYear = startDate.isSame(endDate, TimeUnit.YEAR);
    const bothCurrentYear = sameYear && startDate.isSame(new StemDate(), TimeUnit.YEAR);

    const endDateFormat = bothCurrentYear ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR;
    const startDateFormat = sameYear ? DATE_WITHOUT_YEAR : DATE_WITH_YEAR;

    if (startDate.isSame(endDate, TimeUnit.DAY)) {
        return endDate.format(endDateFormat);
    } else {
        return `${startDate.format(startDateFormat)} - ${endDate.format(endDateFormat)}`;
    }
}

// Format a number between 0.0 and 100.0 as a percent string
export function formatPercent(percent, numDigits=2) {
    // TODO: when localizing, we might want the percent sign in front:
    //  https://en.wikipedia.org/wiki/Percent_sign#Form_and_spacing
    return percent.toFixed(numDigits) + NON_BREAKING_SPACE + "%";
}

// Will always return a number
export function ratioToPercent(a, b) {
    if (a == null || isNaN(a)) {
        a = 0;
    }
    if (!b) {
        return 0.0;
    }
    return 100.0 * a / b;
}

export function formatPercentFromRatio(a, b, numDigits=2) {
    return formatPercent(ratioToPercent(a, b), numDigits);
}

export function isIntegerInput(value) {
    return /^\d+$/.test(value);
}

export function isEmptyValue(value) {
    if (value === "" || value === null || value === undefined) {
        return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
        return true;
    }
    return false;
}

export async function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, Math.max(milliseconds, 0)));
}

// Still does a sleep(0) if date
export async function sleepUntil(date) {
    const diff = (new StemDate(date)) - (new StemDate());
    return sleep(diff);
}
