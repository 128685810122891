import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {
    apiCalculateSubscriptionEditOffersPrice,
    apiCalculateSubscriptionOffersPrice,
    SubscriptionOfferStore
} from "../../../client/state/SubscriptionOfferStore";
import {iFrameMerchantService} from "../../services/IframeMerchantService";
import {iFrameUserDataService} from "../../services/IFrameUserDataService";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {Router} from "../../../stem-core/src/ui/Router";
import {SUBSCRIPTION_PLAN_PANEL_URL} from "../PanelConstants";


export const SUBSCRIBE_PLAN_STATE = {
    subscribe: "subscribe",
    editSubscription: "editSubscription",
    giftSubscription: "giftSubscription",
};

export class SubscriptionPlanController extends Dispatcher {
    discountCode = null;
    subscriptionPlanState = SUBSCRIBE_PLAN_STATE.subscribe;
    shareName = true;
    selectedOffer = null;
    applyPolicy = null;
    onSelectSuccess = NOOP_FUNCTION;
    onSelectCancel = NOOP_FUNCTION;
    onCancel = NOOP_FUNCTION;

    // Don't update these ones, they are used internally.
    discountCodeMap = new Map();
    instantChangeDiscountCodeMap = new Map();
    pendingRequests = 0;

    constructor(options={}) {
        super(options);

        this.update(options);
    }

    update(props) {
        Object.assign(this, props);
        this.dispatch();
    }

    userHasEquivalentSubscriptionToOffer(subscriptionOffer) {
        const subscription = iFrameUserDataService.getActiveSubscription();
        if (!subscription) {
            return false;
        }
        if (!subscriptionOffer) {
            return true;
        }
        return subscription.billingPlan === subscriptionOffer.billingPlan && subscription.coverage === subscriptionOffer.coverage;
    }

    userHasEquivalentSubscriptionToSelected() {
        return this.selectedOffer == null || this.userHasEquivalentSubscriptionToOffer(this.selectedOffer);
    }

    updateEditPricesFromExistingSubscription() {
        if (this.userHasEquivalentSubscriptionToSelected()) {
            const subscription = iFrameUserDataService.getActiveSubscription();
            this.discountCodeMap.set(null, subscription.nextCyclePrice);
        }
    }

    async fetchSubscriptionEditPrices(address) {
        this.pendingRequests += 1;

        try {
            const {recurringOffers} = await apiCalculateSubscriptionEditOffersPrice({
                discountCode: this.discountCode,
                userAddressId: address?.id,
                subscriptionId: iFrameUserDataService.getActiveSubscription().id,
            });
            this.instantChangeDiscountCodeMap.clear();
            this.discountCodeMap.clear();
            for (const info of recurringOffers) {
                const subscriptionOffer = info.recurringOfferId == null ? null : SubscriptionOfferStore.get(info.recurringOfferId);
                if (info.applyPolicy === "instantly") {
                    this.instantChangeDiscountCodeMap.set(subscriptionOffer, info);
                } else {
                    this.discountCodeMap.set(subscriptionOffer, info);
                }
            }
        } catch (error) {
            this.update({discountCode:  null});
        } finally {
            this.pendingRequests -= 1;
            if (!this.pendingRequests) {
                this.dispatch();
            }
        }
    }

    async checkDiscountCode(address) {
        this.pendingRequests += 1;
        this.discountCodeMap.clear();
        try {
            const {recurringOffers} = await apiCalculateSubscriptionOffersPrice({
                discountCode: this.discountCode,
                merchantId: iFrameMerchantService.merchantId,
                userAddressId: address?.id
            });
            for (const info of recurringOffers) {
                this.discountCodeMap.set(SubscriptionOfferStore.get(info.recurringOfferId), info);
            }
        } catch (error) {
            this.update({discountCode:  null});
            // TODO: Handle API error.
        } finally {
            this.pendingRequests -= 1;
            if (!this.pendingRequests) {
                this.dispatch();
            }
        }
    }

    getDiscountInfo(offer) {
        return this.discountCodeMap.get(offer);
    }

    getDiscountInfoOnInstantChangePolicy(offer) {
        return this.instantChangeDiscountCodeMap.get(offer);
    }

    async fetchPrices(address=null) {
        LoadingSpinner.show();
        if (this.subscriptionPlanState === SUBSCRIBE_PLAN_STATE.editSubscription) {
            await this.fetchSubscriptionEditPrices(address);
        } else {
            await this.checkDiscountCode(address);
        }
        LoadingSpinner.hide();
    }

    enterChangePlan() {
        this.update({onSelectCancel: () => {
            Router.back();
            // If we reach the plan panel again via backs, there should be no back on that panel
            // because it is the first panel in the flow.
            this.update({onSelectCancel: NOOP_FUNCTION});
        }});
        Router.changeURL(SUBSCRIPTION_PLAN_PANEL_URL);
    }
}
