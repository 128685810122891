import {UI} from "../../../stem-core/src/ui/UIBase";

import {FunnelSVG, ProductBackgroundSVG} from "../../SVGElements";
import {ProductPage, ProductPageStyle} from "../primitives/ProductPage";
import {Messages} from "../../../blinkpay/Messages";
import {SCREENSHOTS_FOLDER} from "../../Constants";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Device} from "../../../stem-core/src/base/Device";

class FunnelPageStyle extends ProductPageStyle {
    @styleRuleInherit
    section = {
        position: "relative",
        padding: () => (Device.isMobileDevice() ? "24px 0 48px" : "48px 0 80px"),
    };

    @styleRule
    curve = {
        position: "absolute",
        bottom: 2,
        transform: "translateY(100%)",
        width: "100%",
        pointerEvents: "none",
    };
}

@registerStyle(FunnelPageStyle)
export class FunnelPage extends ProductPage {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            icon: <FunnelSVG />,
            summary: Messages.funnelLongSummary,
            description: Messages.funnelLongText,
            mediaUrl: `${SCREENSHOTS_FOLDER}funnel${Device.isMobileDevice() ? "-mobile" : ""}.png`,
            mediaStyle: {transform: Device.isMobileDevice() ? "translate(-50%)" : "scale(.96)"}
        };
    }

    getChildrenToRender() {
        return [<ProductBackgroundSVG className={this.styleSheet.curve} />, super.getChildrenToRender()];
    }
}
