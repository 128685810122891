import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {ConfirmationModal} from "../../../../../../ui/ConfirmationModal";
import {AddressForm} from "./AddressForm";
import {Messages} from "../../../../../../Messages";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {SubscriptionStore, UserAddressStore} from "../../../../../../State";
import {apiEditAddress} from "../../../../../../../client/state/UserAddressStore";
import {autoredraw} from "../../../../../../../stem-core/src/decorators/AutoRedraw";
import {Toast} from "../../../../../../ui/Toast";


@autoredraw(SubscriptionStore, UserAddressStore)
export class EditAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            address: null,
            confirmLabel: Messages.editAddress,
            title: Messages.editAddress,
            confirmAction: () => this.editAddress(),
        };
    }

    async editAddress() {
        const validInputs = this.form && this.form.validateInputs();
        if (!validInputs || !this.options.address) {
            return;
        }

        LoadingSpinner.show();
        try {
            await apiEditAddress({
                ...this.form.getValues(),
                userAddressId: this.options.address.id
            });
            this.hide();
        } catch (error) {
            this.onErrorResponseReceived(error)
        } finally {
            LoadingSpinner.hide();
        }
    }

    onErrorResponseReceived(errorResponse) {
        this.form.displayFieldErrors(errorResponse);
        const nonFieldError = errorResponse.find(fieldError => fieldError.field === "non_field_errors");

        if (nonFieldError) {
            Toast.showError(nonFieldError);
        }
    }

    render() {
        return <AddressForm ref="form" address={this.options.address}/>;
    }
}
