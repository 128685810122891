import {UI, styleRule, StyleSheet, registerStyle} from "../../stem-core/src/ui/UI";
import {isSmallScreen} from "../Utils";
import {BlinkPayLogoText} from "../SVGElements";


export class LogoCardStyle extends StyleSheet {
    @styleRule
    card = {
        boxShadow: this.themeProps.LOGO_CARD_SHADOW,
        borderRadius: this.borderRadius,
        height: () => (isSmallScreen() ? "100vh" : this.themeProps.FIT_CONTENT),
        margin: () => (isSmallScreen() ? "0" : "0 auto"),
        display: () => (isSmallScreen() ? "flex" : ""),
        flexDirection: "column",
    };

    @styleRule
    logo = {
        textAlign: "center",
        marginBottom: 48,
    };

    @styleRule
    container = {
        background: this.themeProps.LOGO_CARD_CONTENT_BACKGROUND,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        position: "relative",
    };

    @styleRule
    content = {
        padding: isSmallScreen() ? 16 : 48,
    };
}

@registerStyle(LogoCardStyle)
export class LogoCard extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.card);
    }

    getChildrenToRender() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.container}>
                <div className={styleSheet.content}>
                    <BlinkPayLogoText size={24} backgroundColor={styleSheet.themeProps.WALLET_12}
                                      className={styleSheet.logo}/>
                    {this.render()}
                </div>
            </div>,
        ];
    }
}
