import {UI} from "../../../../../../stem-core/src/ui/UIBase";

import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {apiEditAddress} from "../../../../../../client/state/UserAddressStore";
import {AddressForm} from "../../../../../website/authenticated/pages/account/components/address/AddressForm";
import {Controller} from "../../../../Controller";

export class EditAddressComponent extends UI.Element {
    render() {
        return <AddressForm ref="form" address={Controller.address.selectedAddress}/>;
    }

    isValid() {
        return true;
    }

    async getAddress() {
        if (!this.form.validateInputs()) {
            throw null;
        }
        LoadingSpinner.show();
        try {
            return await apiEditAddress({...this.form.getValues(), userAddressId: Controller.address.selectedAddress.id});
        } catch (error) {
            if (error?.fields) {
                this.form.displayFieldErrors(error);
                throw null;
            } else {
                throw error;
            }
        } finally {
            LoadingSpinner.hide();
        }
    }
}
