import {UI, registerStyle, styleRule} from "../../../../../../stem-core/src/ui/UI";
import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {Messages} from "../../../../../Messages";
import {DividerPositions, List} from "../../../../../ui/List";
import {BlinkButton} from "../../../../../ui/Button";
import {CloseAccountConfirmationModal} from "../components/close-account/CloseAccountConfirmationModal";
import {CloseAccountReasonModal} from "../components/close-account/CloseAccountReasonModal";
import {AccountSuspendedModal} from "../components/close-account/AccountSuspendedModal";


class SecurityCardStyle extends BaseCardStyle {
    @styleRule
    closeAccountButton = {
        width: "100%",
    }

    @styleRule
    setting = {
        marginTop: 18,
    };

    @styleRule
    settingLabel = {
        ...this.themeProps.USER_DASHBOARD_SETTING_LABEL,
        marginBottom: 8,
    };

    @styleRule
    settingDescription = {
        ...this.themeProps.USER_DASHBOARD_SETTING_DESCRIPTION,
        margin: "8px 0px 18px",
    };

    @styleRule
    cardFooter = {
        marginTop: 0,
    }
}

@registerStyle(SecurityCardStyle)
export class SecurityCard extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.security,
            userProfile: null,
        }
    }

    render() {
        const {styleSheet} = this;
        const {userProfile} = this.options;

        return <List dividerOptions={{position: DividerPositions.TOP}}>
            <div className={styleSheet.setting}>
                <div className={styleSheet.settingLabel}>{Messages.closeAccountLabel}</div>
                <div className={styleSheet.settingDescription}>{Messages.closeAccountText}</div>
                <BlinkButton testId="closeAccountButton" className={this.styleSheet.closeAccountButton}
                            onClick={() => CloseAccountReasonModal.show({
                                    userProfile,
                                    onConfirmSuccess: () => this.handleAccountCloseRequestSuccess(),
                                })
                            } >
                    {Messages.closeAccountLabel}
                </BlinkButton>
            </div>
        </List>
    };

    handleAccountCloseRequestSuccess() {
        CloseAccountConfirmationModal.show({
            userProfile: this.options.userProfile,
            onConfirmSuccess: () => AccountSuspendedModal.show()
        });
    }
}
