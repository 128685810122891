import {UI, styleRule, StyleSheet, registerStyle} from "../../../../../../stem-core/src/ui/UI";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils";
import {ToggleButton} from "../../../../../ui/Button";

export class ToggleSettingStyle extends StyleSheet {
    @styleRule
    setting = {
        padding: "13px 0px",
    };

    @styleRule
    settingHeader = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 6,
    };

    @styleRule
    label = this.themeProps.USER_DASHBOARD_SETTING_LABEL;

    @styleRule
    settingContent = this.themeProps.USER_DASHBOARD_SETTING_DESCRIPTION;
}

@registerStyle(ToggleSettingStyle)
export class ToggleSetting extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onToggle: NOOP_FUNCTION,
            autoChange: true,
            active: false,
            label: ""
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.setting);
    }

    render() {
        const {styleSheet} = this;
        const {label, autoChange, active, onToggle} = this.options;

        return [
            <div className={styleSheet.settingHeader}>
                <div className={styleSheet.label}>{label}</div>
                <ToggleButton autoChange={autoChange} active={active} onToggle={onToggle} />
            </div>
        ]
    }

    getChildrenToRender() {
        const children = this.getGivenChildren();

        return [
            this.render(),
            children.length ? <div className={this.styleSheet.settingContent}>{children}</div> : null
        ];
    }
}
