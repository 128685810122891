import {UI} from "../../../../../../stem-core/src/ui/UIBase";

import {UserAddressStore} from "../../../../../../client/state/UserAddressStore";
import {Messages} from "../../../../../Messages";
import {RadioList} from "../../../../../common/RadioList";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {AddFundsIcon} from "../../../../../SVGElements";
import {MerchantListItem, MerchantListItemStyle} from "../../../../../common/MerchantListItem";
import {styleRule, styleRuleInherit} from "../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils";
import {StyleSheet} from "../../../../../../stem-core/src/ui/Style";
import {iFrameUserDataService} from "../../../../../services/IFrameUserDataService";
import {MerchantUserResourcesAccessLink} from "../../../../../common/MerchantUserResourcesAccessLink";
import {Controller} from "../../../../Controller";


class ChooseAddressListItemStyle extends MerchantListItemStyle {
    @styleRuleInherit
    item = {
        padding: "12px 0",
    }
}

@registerStyle(ChooseAddressListItemStyle)
class ChooseAddressListItem extends MerchantListItem {}

export class ChooseAddressComponentStyle extends StyleSheet {
    @styleRule
    addNewAddress = {
        color: this.themeProps.MERCHANT_LINK,
        width: this.themeProps.FIT_CONTENT,
        cursor: "pointer",
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
        whiteSpace: "nowrap",
        marginTop: "1.55rem",
        display: "inline-block",
        ">*": {
            opacity: 0.8,
            display: "inline-block",
        },
        ":hover>*": {
            opacity: 1,
        },
        " g": {
            fill: this.themeProps.MERCHANT_LINK,
        },
        ">:last-child": {
            marginLeft: 12,
        },
        ">:first-child": {
            transform: "translateY(1px)",
        },
    };

    @styleRule
    addressDetails = {
        display: "flex",
        flexDirection: "column",
    };

    @styleRule
    nameOnAddress = {
        marginBottom: 4,
    };

    @styleRule
    addressItem = {
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
    };

    @styleRule
    accessResources = this.themeProps.PANEL_ACCESS_RESOURCES;
}

@registerStyle(ChooseAddressComponentStyle)
export class ChooseAddressComponent extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onAddNewAddress: NOOP_FUNCTION,
            onChange: NOOP_FUNCTION,
        }
    }

    render() {
        const {styleSheet} = this;
        const extraAddressCount = iFrameUserDataService.getExtraUserAddressCount();

        return [
            <RadioList onItemSelect={(item) => this.handleListItemSelect(item)}
                       ListItemClass={ChooseAddressListItem}
                       padListItem={false}>
                {UserAddressStore.all().map(address => (
                    <div address={address} selected={Controller.address.selectedAddress?.id === address.id}
                         className={styleSheet.addressItem}>
                        <div className={styleSheet.nameOnAddress}>{address.getRecipientName()}</div>
                        <div className={styleSheet.addressDetails}>{address}</div>
                    </div>
                ))}
            </RadioList>,
            <Link className={styleSheet.addNewAddress} onClick={this.options.onAddNewAddress}>
                <AddFundsIcon size={12.25}/>
                <span>{Controller.address.getAddAddressLabel()}</span>
            </Link>,
            extraAddressCount !== 0 ?
                <div className={styleSheet.accessResources}>
                    {Messages.or}
                    <MerchantUserResourcesAccessLink extraResourcesCount={extraAddressCount}/>
                </div>: null,
        ];
    }

    isValid() {
        return !!Controller.address.selectedAddress;
    }

    getAddress() {
        return Controller.address.selectedAddress;
    }

    handleListItemSelect(item) {
        const {address} = item.options;
        Controller.address.update({selectedAddress: address});
        this.options.onChange();
    }

    onMount() {
        super.onMount();

        this.attachListener(Controller.address, () => this.redraw());
    }
}
