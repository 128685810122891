import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../misc/StoreField";

export class MerchantFacebookApp extends StoreObject {
    @field("Merchant") merchant;
    @field(String) name;
    @field(String) appId;
}

export const MerchantFacebookAppStore = MakeStore("MerchantFacebookApp", MerchantFacebookApp);

export class MerchantFacebookPixel extends StoreObject {
    @field("Merchant") merchant;
    @field(String) name;
    @field(String) pixelId;
    @field(Object) subscriptionConversionEvent;
    @field(Object) donationConversionEvent;
    @field(Object) newsletterConversionEvent;
}

export const MerchantFacebookPixelStore = MakeStore("MerchantFacebookPixel", MerchantFacebookPixel);
