import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {ConfirmationModal} from "../../../../../ui/ConfirmationModal";
import {RadioList} from "../../../../../common/RadioList";
import {MerchantListItem} from "../../../../../common/MerchantListItem";
import {UserAddressStore} from "../../../../../../client/state/UserAddressStore";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {AddAddressModal} from "../../account/components/address/AddAddressModal";
import {AddFundsIcon} from "../../../../../SVGElements";
import {Messages} from "../../../../../Messages";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {isSmallScreen} from "../../../../../UtilsLib";
import {Toast} from "../../../../../ui/Toast";
import {
    apiRequestSubscriptionPriceOnAddressChange,
    apiUpdateSubscriptionAddress
} from "../../../../../../client/state/SubscriptionStore";
import {MessageElement} from "../../../../../widget/ui/MessageElement";
import {Money} from "../../../../../../client/state/misc/Money";


class ChooseSubscriptionAddressStepStyle extends StyleSheet {
    @styleRule
    addNewAddress = {
        color: this.themeProps.MERCHANT_LINK,
        cursor: "pointer",
        fontSize: 14,
        fontWeight: "initial",
        whiteSpace: "nowrap",
        marginTop: "1.55rem",
        display: "inline-block",
        ">*": {
            opacity: 0.8,
            display: "inline-block",
        },
        ":hover>*": {
            opacity: 1,
        },
        " g": {
            fill: this.themeProps.MERCHANT_LINK,
        },
        ">:last-child": {
            marginLeft: 12,
        },
        ">:first-child": {
            transform: "translateY(1px)",
        },
    };

    @styleRule
    listItem = {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    };
}

@registerStyle(ChooseSubscriptionAddressStepStyle)
class ChooseSubscriptionAddressStep extends UI.Element {
    selectedAddress = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "chooseAddressModal",
            onAddressSelected: NOOP_FUNCTION,
            defaultSelectedAddress: null,
        }
    }

    render() {
        const {styleSheet} = this;
        this.selectedAddress = this.selectedAddress || this.options.defaultSelectedAddress;

        return [
            <RadioList onItemSelect={(item) => this.handleListItemSelect(item)} ListItemClass={MerchantListItem}
                       padListItem={true}>
                {UserAddressStore.all().map(address => (
                    <div address={address} selected={this.selectedAddress && this.selectedAddress.id === address.id}
                         className={this.styleSheet.listItem}>
                        {address}
                    </div>
                ))}
            </RadioList>,
            <Link className={styleSheet.addNewAddress} onClick={() => AddAddressModal.show()}>
                <AddFundsIcon size={12.25}/>
                <span>{Messages.addNewAddress}</span>
            </Link>,

        ]
    }

    handleListItemSelect(item) {
        const {address} = item.options;
        this.onSelectedAddressChange(address);
    }

    onSelectedAddressChange(address) {
        this.selectedAddress = address;
        this.options.onAddressSelected(address);
        this.redraw();
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserAddressStore, () => this.redraw());
        this.attachCreateListener(UserAddressStore, address => this.onSelectedAddressChange(address));
    }
}

class ConfirmSubscriptionPriceStepStyle extends StyleSheet {
    @styleRule
    element = {
        fontSize: () => (isSmallScreen() ? 14 : 16),
        color: this.themeProps.BASE_CARD_DESCRIPTION_COLOR,
        lineHeight: () => (isSmallScreen() ? 18 : 20),
    };

    @styleRule
    secondDescriptionLine = {
        marginTop: () => 12,
        ">*": {
            color: "#232323",
            fontWeight: "normal",
        }
    };
}

@registerStyle(ConfirmSubscriptionPriceStepStyle)
class ConfirmSubscriptionPriceStep extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            offer: null,
            currentPriceDetails: null,
            nextPriceDetails: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
    }

    render() {
        const {billingPlan, currentPriceDetails, nextPriceDetails} = this.options;

        return [
            Messages.updateShippingAddressDescriptionLine1,
            currentPriceDetails.finalPrice === nextPriceDetails.finalPrice ? null
                : <MessageElement className={this.styleSheet.secondDescriptionLine}
                                  message={
                                      Messages.updateShippingAddressDescriptionLine2(
                                          `${new Money(currentPriceDetails.finalPrice, billingPlan.currency)}/${billingPlan.cycleUnit}`,
                                          `${new Money(nextPriceDetails.finalPrice, billingPlan.currency)}/${billingPlan.cycleUnit}`
                                      )} />
        ]
    }
}

export class ChangeSubscriptionAddressModal extends ConfirmationModal {
    selectedAddress = this.options.subscription?.getShippingAddress();
    confirmButtonDisabled = true;
    newAddressConfirmed = false;
    computedSubscriptionNewPrice = null;
    chooseSubscriptionAddressStepOptions = {
        title: Messages.updateAddressTitle,
        confirmLabel: Messages.updateShippingAddress,
        cancelLabel: Messages.close,
        confirmAction: () => this.handleAddressChangeConfirmation(),
        infirmAction: () => this.hide(),
    };
    confirmNewPriceStepOptions = {
        title: Messages.updateShippingAddress,
        confirmLabel: Messages.confirm,
        cancelLabel: Messages.cancel,
        confirmAction: () => this.updateSubscriptionAddress(),
        infirmAction: () => {
            this.newAddressConfirmed = false;
            this.updateOptions(this.chooseSubscriptionAddressStepOptions)
        },
    };

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            subscription: null,
            title: Messages.updateAddressTitle,
            confirmLabel: Messages.updateShippingAddress,
            cancelLabel: Messages.close,
            confirmAction: () => this.handleAddressChangeConfirmation(),
            infirmAction: () => this.hide(),
        }
    }

    render() {
        if (this.newAddressConfirmed) {
            const {subscription} = this.options;
            // TODO: from @mihai: in the future, we may skip this confirmation step if the subscription price does not change
            return <ConfirmSubscriptionPriceStep billingPlan={subscription.billingPlan}
                                                 currentPriceDetails={subscription.nextCyclePrice}
                                                 nextPriceDetails={this.computedSubscriptionNewPrice} />
        }
        return <ChooseSubscriptionAddressStep defaultSelectedAddress={this.selectedAddress}
                                              onAddressSelected={address => this.onAddressSelected(address)} />;
    }

    onAddressSelected(address) {
        this.selectedAddress = address;
        this.confirmButtonDisabled = address && address === this.options.subscription.getShippingAddress();
        this.redraw();
    }

    async handleAddressChangeConfirmation() {
        const {subscription} = this.options;
        LoadingSpinner.show();
        try {
            const response = await apiRequestSubscriptionPriceOnAddressChange(subscription.id,
                subscription.merchantId,
                this.selectedAddress.id
            );
            this.newAddressConfirmed = true;
            this.computedSubscriptionNewPrice = response.recurringOffers.find(offer => !!offer.recurringOfferId);
            this.updateOptions(this.confirmNewPriceStepOptions);
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    async updateSubscriptionAddress() {
        const {subscription} = this.options;
        if (!subscription) {
            return;
        }

        LoadingSpinner.show();
        try {
            await apiUpdateSubscriptionAddress(subscription.id, this.selectedAddress.id);
            Toast.show(Messages.updateAddressSuccess);
            this.hide();
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }
}
