import {UI, styleRule, registerStyle, styleRuleInherit} from "../../../../../../stem-core/src/ui/UI";
import {Messages} from "../../../../../Messages";

import {Toast} from "../../../../../ui/Toast";
import {BlinkInput} from "../../../../../ui/Input";
import {EditableBaseCard, EditableBaseCardStyle} from "../../../../../ui/BaseCard";
import {EmailChangeConfirmationModal} from "../components/email-change/EmailChangeConfirmationModal";
import {isSmallScreen} from "../../../../../UtilsLib";
import {userService} from "../../../../../../client/connection/Services";

class PersonalDetailsStyle extends EditableBaseCardStyle {
    @styleRuleInherit
    cardFooter = {
        "> *": {
            marginTop: 36,
        },
    };

    @styleRuleInherit
    cardHeader = {
        "> *": {
            marginBottom: 18,
        }
    };

    @styleRule
    inputsContainer = {
        ">:not(:first-child)": {
            marginTop: 24,
        },
        ">:last-child": {
            marginBottom: 0,
        }
    };
}

@registerStyle(PersonalDetailsStyle)
export class PersonalDetails extends EditableBaseCard {
    insertedName = "";
    insertedEmail = "";

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.personalDetails,
        };
    }

    setOptions(options) {
        super.setOptions(options);
        const {fullName, email} = this.getUserProfileData();
        this.insertedName = this.insertedName || fullName;
        this.insertedEmail = this.insertedEmail || email;
    }

    render() {
        const {styleSheet} = this;
        const {fullName, email} = this.getUserProfileData();
        const onInputChange = () => {
            this.insertedName = this.nameInput.getValue().trim();
            this.insertedEmail = this.emailInput.getValue().trim();
            this.setConfirmButtonDisabled(!this.canUpdateUserDetails());
        }

        return <div className={styleSheet.inputsContainer}>
            <BlinkInput ref="nameInput" label={Messages.name} initialValue={fullName}
                        editable={this.isEditingEnabled()}
                        inputAttributes={{testId: "accountNameInput"}}
                        onChange={onInputChange}
                        onBlur={() => this.handleInputBlur()}
                        onEnter={() => this.updateUserDetails()}
            />
            <BlinkInput ref="emailInput" label={Messages.email} initialValue={email}
                        editable={this.isEditingEnabled()}
                        inputAttributes={{type: "email", testId: "accountEmailInput"}}
                        onChange={onInputChange}
                        onBlur={() => this.handleInputBlur()}
                        onEnter={() => this.updateUserDetails()}
            />
        </div>;
    }

    handleConfirmButtonClick() {
        this.updateUserDetails();
    }

    executeEditToggleActions() {
        const {fullName, email} = this.getUserProfileData();
        if (!this.editing) {
            this.insertedName = "";
            this.insertedEmail = "";
            this.nameInput.setValue(fullName)
            this.emailInput.setValue(email)
        } else {
            this.insertedName = fullName;
            this.insertedEmail = email;
        }
        this.setConfirmButtonDisabled(!this.canUpdateUserDetails());
    }

    handleInputBlur() {
        if (!isSmallScreen()) {
            return;
        }
        window.scrollTo(0, 0)
    }

    getUserProfileData() {
        const fullName = userService.getUserName();
        const email = userService.getUserEmail() || "";

        return {fullName: fullName.trim(), email: email.trim()};
    }

    canUpdateUserDetails() {
        return this.canUpdateName() || this.canUpdateEmail();
    }

    canUpdateEmail() {
        const currentEmail = this.getUserProfileData().email;
        const newEmail = this.insertedEmail.trim();

        return newEmail.length > 0 && newEmail !== currentEmail;
    }

    canUpdateName() {
        const currentName = this.getUserProfileData().fullName;
        const newName = this.insertedName.trim();

        return newName.length > 0 && newName !== currentName;
    }

    async updateUserDetails() {
        const requestedEmailChange = await userService.requestUpdateUserData(this.insertedName, this.insertedEmail);
        if (requestedEmailChange) {
            EmailChangeConfirmationModal.show({
                userProfile: userService.getUser(),
                newEmail: this.insertedEmail,
                onConfirmSuccess: () => {
                    this.toggleEditing();
                    Toast.show(Messages.emailSuccessfullyChanged);
                },
            });
        } else {
            this.toggleEditing();
        }
    }
}
