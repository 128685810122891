import {iFrameState} from "../../../services/IFrameState";
import {PANEL_TYPE} from "../../../../blink-sdk/Constants";
import {Router} from "../../../../stem-core/src/ui/Router";
import {iFrameAuthService} from "../../../services/IFrameAuthService";
import {INLINE_USER_DASHBOARD_URLS} from "../../PanelConstants";


export class DashboardFlowState {
    card = null;
    entries = null;

    async start() {
        const {panelType} = iFrameState;

        if (panelType !== PANEL_TYPE.dashboard) {
            return;
        }

        await iFrameAuthService.promptAuthenticationIfNeeded();
        Router.changeURL(INLINE_USER_DASHBOARD_URLS.home);
    }
}
