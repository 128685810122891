import {UI, styleRule, registerStyle, StyleSheet} from "../../../../stem-core/src/ui/UI";
import {Router} from "../../../../stem-core/src/ui/Router";
import {FormContainerPage} from "./FormContainerPage";
import {authService} from "../../../../client/connection/Services";
import {Messages} from "../../../Messages";
import {AuthHelper} from "../../../AuthHelper";
import {getQueryParam} from "../../../UtilsLib";


class LoginByEmailCodeUrlPageStyle extends StyleSheet {
    @styleRule
    text = {
        textAlign: "center",
        marginTop: 32,
    };
}

@registerStyle(LoginByEmailCodeUrlPageStyle)
export class LoginByEmailCodeUrlClass extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            text: Messages.loggingInByEmailKey,
        };
    }

    onRequestSuccess(redirectUrl) {
        this.updateOptions({text: Messages.redirectingToInitialPage});
        setTimeout(() => AuthHelper.completedLoginByEmailKeyAction(redirectUrl), 500);
    }

    onRequestError(error) {
        this.updateOptions({text: error.message});
    }

    render() {
        return <div className={this.styleSheet.text}>{this.options.text}</div>;
    }

    async onMount() {
        super.onMount();

        const redirectUrl = getQueryParam("redirectUrl");
        if (authService.isAuthenticated()) {
            this.onRequestSuccess(redirectUrl);
            return;
        }

        try {
            const response = await authService.loginWithEmailCode({uuid: Router.parseURL()[2]});
            this.onRequestSuccess(redirectUrl || response.redirectUrl);
        } catch (error) {
            this.onRequestError(error);
        }
    }
}

export const LoginByEmailCodeUrlPage = FormContainerPage(LoginByEmailCodeUrlClass);
