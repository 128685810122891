import {UI, styleRule, registerStyle} from "../../../../../../stem-core/src/ui/UI";
import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {DEFAULT_DATE_FORMAT, MISSING_INFO, MOBILE_NAV_HEIGHT} from "../../../../../Constants";
import {FakeTable} from "../../../../../ui/FakeTable";
import {Messages} from "../../../../../Messages";
import {StemDate} from "../../../../../../stem-core/src/time/Date";
import {RecurringPaymentStoreObject} from "../../../../../State";
import {Dispatcher} from "../../../../../../stem-core/src/base/Dispatcher";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {Toast} from "../../../../../ui/Toast";


export class MyRecurringPaymentStyle extends BaseCardStyle {
    @styleRule
    activeStatus = {
        color: this.themeProps.SUCCESS,
        textTransform: "capitalize",
    };

    @styleRule
    canceledStatus = {
        color: this.themeProps.ERROR,
        textTransform: "capitalize",
    };

    @styleRule
    unpaidStatus = {
        color: this.themeProps.ERROR,
        textTransform: "capitalize",
    };

    @styleRule
    revokedStatus = {
        color: this.themeProps.ERROR,
        textTransform: "capitalize",
    };

    @styleRule
    pendingStatus = {
        color: this.themeProps.MERCHANT_LINK,
        textTransform: "capitalize",
    };

    @styleRule
    finishedStatus = {
        color: this.themeProps.BASE_CARD_DESCRIPTION_COLOR,
        textTransform: "capitalize",
    };

     @styleRule
    button = {
        fontSize: "14px",
        marginTop: "24px",
        marginBottom: "24px",
        cursor: "pointer",
        display: "table",
    };

    @styleRule
    buttonClose = {
        color: this.themeProps.EXPANSION_PANEL_BUTTON_CLOSE,
    };

    @styleRule
    buttonRenew = {
        color: this.themeProps.EXPANSION_PANEL_BUTTON_RENEW,
    };

    transitionTime = 500;

    @styleRule
    rowHighlight = {
        background: this.themeProps.WALLET_2,
    };

    @styleRule
    rowRemoveHighlight = {
        transition: this.transitionTime + "ms ease",
        background: "transparent !important",
        userSelect: "none",
    };

    @styleRule
    failedPayment = {
        display: "flex",
        color: this.themeProps.ERROR,
    };

    @styleRule
    exclamationIcon = {
        marginRight: 8,
    };

    @styleRule
    retryPaymentButton = {
        margin: "16px auto 0",
        width: 214,
    };

    @styleRule
    center = {
        display: "flex",
        alignItems: "center",
    };
}

@registerStyle(MyRecurringPaymentStyle)
export class MyRecurringPayment extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            emptyMessage: "",
            expandedRows: false,
        };
    }

    formatDate(date) {
        return date ? StemDate.format(date, DEFAULT_DATE_FORMAT) : Messages.canceled;
    }

    async retryPayment(entry) {
        LoadingSpinner.show();
        try {
            await entry.storeObject.reactivate()
            Toast.show(Messages.entrySuccessfullyPaid);
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    getEntries() {
        let entries = [];
        for (const storeObject of this.getStore()) {
            const lastPaymentValue = storeObject.getLastPaymentDate();
            // TODO: keep as little data as possible in here and prefer to use xStore.get(id)
            // to get desired data; we should aim to have only data relevant for the FakeTable in here
            entries.push({
                storeObject,
                lastPayment: lastPaymentValue ? StemDate.format(lastPaymentValue, DEFAULT_DATE_FORMAT) : MISSING_INFO,
                nextPayment: this.formatDate(storeObject.getNextBillingDate()),
            });
        }
        return entries;
    }

    getStore() {}

    expandedEntry() {}

    getExpandedRowContent() {}

    getColumns() {
        const {styleSheet} = this;

        return [
            {
                headerName: Messages.publicationName,
                width: 0.4,
                align: "left",
                value: entry => entry.storeObject.getMerchantTitle(),
            },
            {
                headerName: Messages.status,
                width: 0.25,
                align: "left",
                value: entry => {
                    let statusStyle;
                    switch (entry.storeObject.status) {
                        case RecurringPaymentStoreObject.Status.ACTIVE:
                            statusStyle = styleSheet.activeStatus;
                            break;
                        case RecurringPaymentStoreObject.Status.CANCELED:
                            statusStyle = styleSheet.canceledStatus;
                            break;
                        case RecurringPaymentStoreObject.Status.UNPAID:
                            statusStyle = styleSheet.unpaidStatus;
                            break;
                        case RecurringPaymentStoreObject.Status.REVOKED:
                            statusStyle = styleSheet.revokedStatus;
                            break;
                        case RecurringPaymentStoreObject.Status.FINISHED:
                            statusStyle = styleSheet.finishedStatus;
                            break;
                        default:
                            break;
                    }

                    return <div className={statusStyle}>{entry.storeObject.status}</div>;
                },
            },
            {
                headerName: Messages.price,
                width: 0.35,
                align: "right",
                value: entry => entry.storeObject.formatPriceShortened(),
            },
        ];
    }

    render() {
        if (!this.getStore().length) {
            return [];
        }
        return <FakeTable ref="table" columns={this.getColumns()} entries={this.getEntries() || []}
                          expandedRowContent={entry => this.getExpandedRowContent(entry)}
                          expandedRows={this.options.expandedRows}/>;
    }

    getRowByEntryId(id) {
        return this.table && this.table.getRowByEntryId(id);
    }

    highlightEntry(id) {
        const {styleSheet} = this;
        const tableRow = this.getRowByEntryId(id);
        if (!tableRow) {
            return;
        }
        const {top} = tableRow.node.getBoundingClientRect();
        window.scrollTo({
            top: window.pageYOffset + (top - MOBILE_NAV_HEIGHT),
            behavior: "smooth"
        });
        if (!tableRow.isExpanded) {
            tableRow.toggle();
        }
        tableRow.addClass(styleSheet.rowHighlight);
        setTimeout(() => tableRow.addClass(styleSheet.rowRemoveHighlight));
        setTimeout(() => {
            tableRow.removeClass(styleSheet.rowHighlight);
            tableRow.removeClass(styleSheet.rowRemoveHighlight);
        }, styleSheet.transitionTime);
    }

    onMount() {
        super.onMount();

        this.attachListener(Dispatcher.Global, "highlightDonation", id => this.highlightEntry(id));
        this.attachListener(Dispatcher.Global, "highlightSubscription", id => this.highlightEntry(id));
    }
}
