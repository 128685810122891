import {styleRule, UI} from "../../stem-core/src/ui/UI";

import {registerStyle, styleRuleInherit} from "../../stem-core/src/ui/UI";
import {Section, SectionStyle} from "../primitives/Section";
import {Messages} from "../../blinkpay/Messages";
import {Device} from "../../stem-core/src/base/Device";
import {isDesktopSmall} from "../Utils";
import {Image} from "../../stem-core/src/ui/UIPrimitives";
import {StyleSheet} from "../../stem-core/src/ui/UI";
import {ILLUSTRATIONS_FOLDER} from "../Constants";

export class MissionItemStyle extends StyleSheet {
    @styleRule
    flex = {
        display: "flex",
        color: this.themeProps.LANDING_PAGE_MISSION_COLOR,
        flexDirection: () => (isDesktopSmall() ? "column-reverse" : "row"),
        ">*": {
            flex: 1,
        },
        marginBottom: () => (isDesktopSmall() ? 24 : 60),
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 18 : 24),
        marginBottom: () => (Device.isMobileDevice() ? 10 : 36),
    };

    @styleRule
    textSection = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };

    @styleRule
    listItem = {
        marginTop: () => (Device.isMobileDevice() ? "2.56rem" : 28),
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        fontWeight: "lighter",
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
    };

    @styleRule
    imageSection = [
        {textAlign: "center"},
        isDesktopSmall()
            ? {
                  width: "100%",
                  marginBottom: "1.92rem",
                  padding: "0 12.5%",
                  ">*": {
                      width: "100%",
                      maxHeight: 320,
                  },
              }
            : {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "41.58rem",
                  ">*": {
                      height: "100%",
                  },
              },
    ];
}

@registerStyle(MissionItemStyle)
export class MissionItem extends UI.Element {
    getDefaultOptions() {
        return Object.assign({}, super.getDefaultOptions(), {
            title: "",
            listItems: [],
            rightImage: false,
            imageSrc: "",
        });
    }

    render() {
        const {styleSheet} = this;
        const {listItems, title, rightImage, imageSrc, imageStyle} = this.options;

        let style = {};

        if (rightImage && !isDesktopSmall()) {
            style.flexFlow = "row-reverse";
        }

        return [
            <div className={styleSheet.flex} style={style}>
                <div
                    className={styleSheet.textSection}
                    style={isDesktopSmall() ? {} : rightImage ? {paddingLeft: 27} : {paddingRight: 27}}>
                    <div>
                        <div className={styleSheet.title}>{title}</div>
                        {listItems.map(li => (
                            <div className={styleSheet.listItem}>{li}</div>
                        ))}
                    </div>
                </div>
            </div>,
        ];
    }
}

class MissionStyle extends SectionStyle {
    @styleRuleInherit
    section = {
        background: () =>
            Device.isMobileDevice()
                ? this.themeProps.VALUE_PROPOSITION_MOBILE_BACKGROUND
                : this.themeProps.VALUE_PROPOSITION_BACKGROUND,
        color: this.themeProps.LANDING_PAGE_LIGHT_FONT_COLOR,
        paddingTop: () => (Device.isMobileDevice() ? 70 : 100),
        paddingBottom: () => (isDesktopSmall() ? 14 : 100),
        overflow: "hidden",
    };

    @styleRule
    democracy = {
        ">*>:last-child": {
            height: () => (isDesktopSmall() ? 384 : 324),
        },
        padding: "18px 0 0 0",
    };

    @styleRule
    missionSolutionContainer = {
        paddingTop: () => (isDesktopSmall() ? 36 : 90),
        marginBottom: () => (isDesktopSmall() ? "" : -100),
    };

    @styleRule
    cardsContainer = [
        {
            display: "flex",
            flexDirection: "row",
        },
        isDesktopSmall()
            ? {
                  ">*": {
                      flex: 1,
                      marginBottom: "3.84rem",
                  },
                  flexDirection: "column",
              }
            : {
                  ">:nth-child(2)": {
                      margin: "0 3.735rem",
                  },
                  ">*": {
                      flex: 1,
                      marginBottom: 0,
                  },
                  flexDirection: "row",
                  paddingBottom: 72,
              },
    ];

    @styleRule
    sectionsContainer = {
        display: "flex",
        flexDirection: () => (isDesktopSmall() ? "column-reverse" : "row"),
        width: () => (isDesktopSmall() ? "100%" : ""),
    };

    @styleRule
    imageContainer = {
        flex: 1,
        margin: () => (isDesktopSmall() ? "0 0 3.84rem" : "-20px -70px 0 0"),
        padding: () => (isDesktopSmall() ? "" : "0 40px"),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: () => (isDesktopSmall() ? "100%" : ""),
    };

    @styleRule
    textContainer = {
        flex: 1.4,
    };

    @styleRule
    ladderImage = {
        marginRight: () => (isDesktopSmall() ? "" : -280),
        transform: () => (isDesktopSmall() ? "translateX(-16%)" : "translate(-260px, 30px)"),
        width: () => (isDesktopSmall() ? "120%" : ""),
        maxWidth: () => (isDesktopSmall() ? 600 : ""),
    };

    @styleRule
    clickBaitImage = {
        height: () => (isDesktopSmall() ? "" : 300),
        width: () => (isDesktopSmall() ? "100%" : ""),
        maxWidth: () => (isDesktopSmall() ? 600 : ""),
    };

    @styleRule
    ladderContainer = {
        flexDirection: () => (isDesktopSmall() ? "column" : "row"),
        ">:first-child": {
            marginTop: () => (isDesktopSmall() ? "" : -60),
            marginLeft: () => (isDesktopSmall() ? "" : -70),
            marginRight: 0,
        },
    };
}

@registerStyle(MissionStyle)
class MissionSection1 extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.missionSolutionContainer);
    }

    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.sectionsContainer}>
                <div className={styleSheet.textContainer}>
                    <MissionItem title={Messages.clickBaitTitle} listItems={[Messages.clickBaitDescription]} />
                </div>
                <div className={styleSheet.imageContainer}>
                    <Image src={ILLUSTRATIONS_FOLDER + "M1-D.svg"} className={styleSheet.clickBaitImage} />
                </div>
            </div>,
        ];
    }
}

@registerStyle(MissionStyle)
class MissionSection2 extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.missionSolutionContainer);
    }

    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.sectionsContainer + styleSheet.ladderContainer}>
                <div className={styleSheet.imageContainer}>
                    <Image src={ILLUSTRATIONS_FOLDER + "M5-E.svg"} className={styleSheet.ladderImage} />
                </div>
                <div className={styleSheet.textContainer}>
                    <MissionItem
                        title={Messages.supportJournalistsTitle}
                        rightImage
                        listItems={[Messages.supportJournalistsDescription1, Messages.supportJournalistsDescription2]}
                    />
                    <MissionItem
                        title={Messages.valuePrivacyTitle}
                        rightImage
                        listItems={[Messages.valuePrivacyDescription1, Messages.valuePrivacyDescription2]}
                    />
                </div>
            </div>,
        ];
    }
}

@registerStyle(MissionStyle)
export class Mission extends Section {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.missionTitle,
            subtitle: Messages.missionSubtitle,
        };
    }
    render() {
        return [<MissionSection1 />, <MissionSection2 />];
    }
}
