import {registerStyle, styleRule, styleRuleInherit, UI} from "../../../../../../../stem-core/src/ui/UI";
import {Modal, ModalStyle} from "../../../../../../ui/Modal";
import {Messages} from "../../../../../../Messages";
import {UserProfileStore} from "../../../../../../../client/state/UserProfileStore";
import {BlinkButton} from "../../../../../../ui/Button";
import {MessageElement} from "../../../../../../widget/ui/MessageElement";


class EmailChangeUndoSuccessModalStyle extends ModalStyle {
    @styleRuleInherit
    title = {
        marginBottom: 12,
    };

    @styleRule
    content = {
        display: "flex",
        flexDirection: "column",
    };

    @styleRule
    confirmationButton = {
        marginTop: 36,
    };
}

@registerStyle(EmailChangeUndoSuccessModalStyle)
export class EmailChangeUndoSuccessModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.emailChangeUndoSuccessModalTitle,
            hasCloseIcon: false,
            hideOnScrimClick: false,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.content);
    }

    render() {
        console.assert(UserProfileStore.getUserProfile());

        const {styleSheet} = this;
        const userProfile = UserProfileStore.getUserProfile();

        return <div className={styleSheet.content}>
            <MessageElement message={Messages.emailChangeUndoSuccessText(userProfile.getEmail())} />
            <BlinkButton className={styleSheet.confirmationButton} onClick={() => this.hide()}>
                {Messages.ok}
            </BlinkButton>
        </div>
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserProfileStore, () => this.redraw());
    }
}
