import {apiConnection} from "../connection/BlinkApiConnection";
import {Money} from "./misc/Money";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {RecurringPaymentStoreObject} from "./RecurringPaymentStoreObject";
import {SubscriptionDiscountStore} from "./SubscriptionDiscountStore";
import {field} from "./misc/StoreField";
import {MerchantUserStore} from "./merchant/MerchantUserStore";


export const SubscriptionApplyPolicy = {
    INSTANTLY: "instantly",
    NEXT_BILLING_CYCLE: "next_billing_cycle",
};

export class Subscription extends RecurringPaymentStoreObject {
    @field("BillingPlan") billingPlan;
    @field("SubscriptionCoverage") coverage;
    @field("SubscriptionOffer") recurringOffer;
    @field("SubscriptionDiscount") discount;
    @field("Subscription") fromGroupSubscription;
    @field("UserProfile") user;
    @field("UserProfile") billedUser;
    // TODO @Mihai Only for group subscriptions, disabled for now
    @field(Boolean) isGroupSubscription;
    @field(Array) groupEmailDomains;
    @field(Array) groupIpV4Ranges;
    @field(Number) groupNumCustomBeneficiaries;
    @field(Boolean) ownerCanEditIpRanges;
    @field(Boolean) ownerCanEditDomains;

    async reactivate() {
       return apiReactivateSubscription(this.id);
    }

    async updatePaymentMethod(paymentMethodId) {
        return apiUpdateSubscriptionPaymentMethod(this.id, paymentMethodId);
    }

    getMerchantUser() {
        return MerchantUserStore.getByUserAndMerchant(this.userId, this.merchantId);
    }

    // TODO Deprecate this field
    getDiscount() {
        return SubscriptionDiscountStore.get(this.discountId);
    }

    getPriceBeforeTaxes() {
        return Money.optionally(this.lastCyclePrice?.discountedBeforeTaxesPrice, this.getCurrency());
    }

    getPrice() {
        return Money.optionally(this.lastCyclePrice?.finalPrice, this.getCurrency());
    }

    serializeForSDK() {
        const {id, deliveryAddressId, blinkSignature, nextPaymentAttempt, userId, amount, createdAt, canceledAt, activeUntil, billingPlanId} = this;
        let obj = {id, deliveryAddressId, blinkSignature, nextPaymentAttempt, userId, amount, createdAt, canceledAt, activeUntil, billingPlanId};
        obj.currencyCode = this.getCurrency().getIsoCode();
        obj.name = this.coverage.getName();
        obj.billingPlan = this.billingPlan.serializeForSDK();
        return obj;
    }

    formatPriceShortened() {
        return `${this.getPrice()}/${this.billingPlan.getAbbreviatedCycleDuration()}`;
    }

    formatPrice() {
        return `${this.getPrice()}/${this.billingPlan.getCycleDuration(true)}`;
    }

    getStatus() {
        return this.status;
    }
}

class SubscriptionStoreClass extends PaymentStatusDependentStore("subscription", Subscription, {dependencies: ["Currency", "Merchant", "SubscriptionCoverage", "SubscriptionOffer", "BillingPlan"]}) {
    Status = Subscription.Status;

    getActive() {
        return this.filter(subscription => subscription.isActive());
    }

    getUnpaid() {
        return this.filter(subscription => subscription.isUnpaid());
    }

    getActiveAndNotCanceled() {
        return this.filter(subscription => subscription.status === this.Status.ACTIVE);
    }

    getPast() {
        return this.filter(subscription => !subscription.isActive() && !subscription.isUnpaid());
    }

    getActiveWithAddress(address) {
        return this.getActive().filter(sub => sub.getShippingAddress() === address);
    }
}

export const SubscriptionStore = new SubscriptionStoreClass();

export async function apiSubscribe(subscriptionDetails = {}) {
    const response = await apiConnection.post("/subscriptions/subscribe/", subscriptionDetails);
    return SubscriptionStore.loadFromResponse(response).find(subscription => subscription.isActive());
}

export async function apiSubscribeWithCds(subscriptionDetails = {}) {
    const response = await apiConnection.post("/external_providers/subscribe_with_cds/", subscriptionDetails);
    return SubscriptionStore.loadFromResponse(response).find(subscription => subscription.isActive());
}

export async function apiChangeSubscription(subscriptionDetails={}) {
    const response = await apiConnection.post("/subscriptions/update_subscription/", subscriptionDetails);
    return SubscriptionStore.loadFromResponse(response).find(subscription => !subscription.isFinished());
}

export async function apiUpdateSubscriptionAddress(subscriptionId, userAddressId) {
    return apiConnection.post("/subscriptions/update_subscription/", {subscriptionId, userAddressId});
}

export async function apiRequestSubscriptionPriceOnAddressChange(subscriptionId, merchantId, userAddressId) {
    return apiConnection.get("/subscriptions/calculate_update_subscription_price/", {
        subscriptionId,
        merchantId,
        userAddressId,
    });
}

export async function apiCancelSubscription(subscriptionId) {
    return apiConnection.post("/subscriptions/cancel/", {subscriptionId});
}

export async function apiReactivateSubscription(subscriptionId) {
    return apiConnection.post("/subscriptions/reactivate/", {subscriptionId});
}

export async function apiUpdateSubscriptionPaymentMethod(subscriptionId, preferredPaymentMethodId) {
    return apiConnection.post("/subscriptions/update_subscription/", {subscriptionId, preferredPaymentMethodId});
}

export async function apiMerchantUpdateSubscriptionGracePeriod(request) {
    return apiConnection.post("/merchant/update_subscription_grace_period/", request);
}

export async function apiMerchantCancelSubscription(request) {
    return apiConnection.post("/merchant/cancel_subscription/", request);
}

export async function apiMerchantCreateGroupSubscription(request) {
    const response = await apiConnection.post("/merchant/create_group_subscription/", request);
    return SubscriptionStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditGroupSubscription(request) {
    return apiConnection.post("/merchant/edit_group_subscription/", request);
}

export async function apiMerchantProcessSubscriptionSentToOffline(request) {
    return apiConnection.post("/external_providers/process_subscription_sent_to_offline/", request);
}
