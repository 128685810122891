import {UI} from "../../../../../../../../stem-core/src/ui/UI";
import {registerStyle, styleRule} from "../../../../../../../../stem-core/src/ui/UI";

import {PaymentMethodStore} from "../../../../../../../State";
import {SingleCard} from "../../../../account/components/payment-method/SingleCard";
import {DropdownIcon} from "../../../../../../../SVGElements";
import {Dropdown, DropdownStyle} from "../../../../../../../ui/dropdown/Dropdown";
import {Messages} from "../../../../../../../Messages";
import {Toast} from "../../../../../../../ui/Toast";
import {AddCardButton} from "./AddCardButton";
import {CardsDropdownList} from "./CardsDropdownList";
import {apiSetPrimaryPaymentMethod} from "../../../../../../../../client/state/PaymentMethodStore";
import {autoredraw} from "../../../../../../../../stem-core/src/decorators/AutoRedraw";


export class CardsDropdownStyle extends DropdownStyle {
    @styleRule
    dropdown = {
        position: "relative",
        width: this.themeProps.FIT_CONTENT,
        height: 51,
        ">:nth-child(3)": {
            opacity: 0,
            pointerEvents: "none",
            transform: "scale(.9) translateY(-10px)",
        },
    };

    @styleRule
    expanded = {
        zIndex: 8000,
        ">:nth-child(3)": {
            opacity: 1,
            pointerEvents: "initial",
            transform: "scale(1) translateY(-.3em)",
        },
    };

    @styleRule
    singleCard = {
        paddingLeft: 12,
    };
}


@registerStyle(CardsDropdownStyle)
@autoredraw(PaymentMethodStore)
export class CardsDropdown extends Dropdown {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            DropdownListClass: CardsDropdownList,
        };
    }

    render() {
        const {styleSheet} = this;
        const activeCard = PaymentMethodStore.getPrimary();

        return [
            <div className={styleSheet.selected} ref="selected" style={{display: activeCard ? "flex" : "none"}}>
                {activeCard ? <SingleCard className={styleSheet.singleCard} card={activeCard} /> : null}
                <DropdownIcon/>
            </div>,
            <AddCardButton
                dropdown={this}
                className={styleSheet.selected}
                style={{display: !activeCard ? "flex" : "none"}}
            />,
        ];
    }

    getDropdownListOptions() {
        return {
            options: PaymentMethodStore.getAvailablePaymentMethods(),
            value: PaymentMethodStore.getPrimary()
        };
    }

    getValue() {
        return PaymentMethodStore.getPrimary();
    }

    setValue(value) {
        // TODO @cleanup this was never implemented?
    }

    async changeActive(item) {
        const activeCard = PaymentMethodStore.getPrimary();

        if (activeCard === item) {
            return;
        }
        item.isPrimary = true;
        try {
            await apiSetPrimaryPaymentMethod(item.id);
            this.dispatchChange(this.getValue());
            this.redraw();
            Toast.show(Messages.changesSaved);
        } catch (err) {
            // TODO: handle this case
            console.error(err);
        }
    }
}
