import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store.js";
import {field} from "../misc/StoreField.js";

class MerchantConversion extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantUser") merchantUser;
    @field(Date) createdAt;
}

export const MerchantConversionStore = MakeStore("MerchantConversion", MerchantConversion);
