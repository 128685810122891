import {styleRule, UI} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {
    MerchantStore,
    UserPreferencesStore,
    WalletStore
} from "../../../../../State";
import {AutopayLimit} from "../components/AutopayLimit";
import {AutopaySetting} from "../components/AutopaySetting";
import {ToggleButton} from "../../../../../ui/Button";
import {Toast} from "../../../../../ui/Toast";
import {List, DividerPositions} from "../../../../../ui/List";
import {IS_PRODUCTION} from "../../../../../Constants";
import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";
import {apiUpdatePreferences} from "../../../../../../client/state/UserPreferencesStore";


class AutopayCardStyle extends BaseCardStyle {
    @styleRule
    list = {
        ">:first-child": {
            border: "none !important",
        }
    };
}

@registerStyle(AutopayCardStyle)
@autoredraw(UserPreferencesStore, WalletStore, WalletStore)
export class AutopayCard extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.autopay,
            description: Messages.autopayDefaultDescription,
            action: () => this.getActionButton()
        };
    }

    getActionButton() {
        return <ToggleButton
            testId="autopayGlobalToggle"
            active={this.autopayIsEnabled()}
            onToggle={() => this.onAutopayToggle()}
        />
    }

    render() {
        if (!this.autopayIsEnabled()) {
            return [];
        }

        let merchants = MerchantStore.getAutopayCompatibleMerchants();
        if (IS_PRODUCTION) {
            merchants = merchants.filter(merchant => !merchant.isFake);
        }

        return [
            <AutopayLimit testId="autopayLimit"/>,
            <List testId="autopayMerchantsList" dividerOptions={{position: DividerPositions.TOP}} className={this.styleSheet.list}>
                {merchants.map(merchant => <AutopaySetting merchant={merchant}/>)}
            </List>,
        ];
    }

    async onAutopayToggle() {
        const preferences = this.getPreferences();
        preferences.autoPayEnabled = !this.autopayIsEnabled();

        try {
            await apiUpdatePreferences(preferences.getFields());
            Toast.show(Messages.changesSaved)
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        }
    }

    autopayIsEnabled() {
        const preferences = this.getPreferences();
        return preferences && preferences.autoPayEnabled;
    }

    getPreferences() {
        return UserPreferencesStore.getPreferences();
    }
}
