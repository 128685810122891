import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {GlobalState} from "../../../stem-core/src/state/State";
import {AuthToken} from "../AuthToken";
import {apiConnection} from "../BlinkApiConnection";
import {DEFAULT_CONFIRMATION_CODE_TYPE} from "../../../blinkpay/Constants";
import {apiLogoutUser} from "../../state/UserProfileStore";
import {authFormService} from "../../../blinkpay/services/AuthFormService";
import {socialAuthService} from "../../../blinkpay/services/SocialAuthService";


export class AuthService extends Dispatchable {
    static EventTypes = {
        CLEAR_CURRENT_INFO: "clearCurrentInfo", // Token has been cleared, user is now unauthenticated. The stores are going to get cleared.
        AUTHENTICATION_CHANGE: "authenticationChange",
        PUBLIC_DATA_FETCH: "publicDataFetch",
        APP_SETTINGS_UPDATE: "appSettingsUpdate",
        SET_MERCHANT_TOKEN: "setMerchantToken",
        USER_DATA_AVAILABLE: "userDataAvailable",
    };

    token = new AuthToken();
    merchantToken = new AuthToken();
    publicDataState = null;
    appSettings = {};
    loginAppName = null;

    constructor() {
        super();

        apiConnection.getAjaxHandler().addPreprocessor(options => {
            // We'll allow an overwrite, if we don't want to use the auth token
            if (!options.noAuthToken && this.isAuthenticated()) {
                options.data.auth = this.token.toString();
            }
        });

        // TODO: merge this in all clients
        apiConnection.getAjaxHandler().addPostprocessor(response => {
            const {appSettings} = response;
            if (appSettings) {
                this.setAppSettings(appSettings);
            }
        });

        this.token.addListener("change", tokenEvent => {
            this.dispatch(tokenEvent);
            this.dispatch(AuthService.EventTypes.AUTHENTICATION_CHANGE, tokenEvent);
        });
        this.addListener(AuthToken.EventTypes.LOGOUT, () => this.clearCurrentInfo());

        // If there is a token, will trigger an authentication event, which will fetch execute user fetch
        this.token.load();
    }

    // Used for endpoints that request / confirm an email login code, which for some reason require this information.
    setLoginAppName(loginAppName) {
        this.loginAppName = loginAppName;
    }

    setPublicData(data) {
        if (this.publicDataState) {
            return
        }

        this.publicDataState = data.state;
        this.setAppSettings(data.appSettings);

        GlobalState.importState(data.state);
        this.dispatch(AuthService.EventTypes.PUBLIC_DATA_FETCH);
    }

    setAppSettings(settings= {}) {
        this.appSettings = settings || {};
        this.dispatch(AuthService.EventTypes.APP_SETTINGS_UPDATE);
    }

    isAuthenticated() {
        return this.token.isAuthenticated();
    }

    async loginAtEndpoint(url, loginPostData) {
        const response = await apiConnection.post(url, {
            ...loginPostData,
            merchantId: socialAuthService.merchantId,
            appName: this.loginAppName,
        });
        this.setAuthToken(response);

        return response;
    }

    hasAuthToken(response) {
        return response.token != null || response.merchantToken != null;
    }

    setAuthToken(response) {
        // Warning: Don't change the order.
        if (response.merchantToken) {
            this.merchantToken.setToken(response.merchantToken.key, response.merchantToken.expiresAt);
            // TODO @cleanup we probably want to dispatch at the end.
            this.dispatch(AuthService.EventTypes.SET_MERCHANT_TOKEN, response.merchantToken);
        } else {
            this.merchantToken.setToken(null, null);
        }

        if (response.token) {
            this.token.setToken(response.token.key, response.token.expiresAt);
        }
    }

    async requestOAuthToken(url) {
        return apiConnection.post(url, {appName: this.loginAppName});
    }

    async logout() {
        try {
            await apiLogoutUser({useAuthToken: false});
            this.clearCurrentInfo();
        } catch (error) {
            console.debug("User logout failed.");
        }
    }

    clearCurrentInfo() {
        this.token.clear();
        this.dispatch(AuthService.EventTypes.CLEAR_CURRENT_INFO);
        // Clear only the non public stores.
        const publicStateKeys = Object.keys(this.publicDataState || {}).map(key => key.toLowerCase());
        for (const [key, store] of GlobalState.stores) {
            if (!publicStateKeys.find(publicStateKey => publicStateKey === key.toLowerCase())) {
                store.clear && store.clear();
            }
        }
    }

    async requestLoginCode(payload) {
        // Explicitly don't try to use an auth token here
        const extraOptions = {
            noAuthToken: true
        };
        const response = await apiConnection.post("/users/request_email_login_code/", {
                ...payload,
                merchantId: socialAuthService.merchantId,
                appName: this.loginAppName,
            },
            extraOptions
        );
        if (this.hasAuthToken(response)) {
            // We've been auto-authenticated via registration, since this merchant doesn't care about confirmation codes on new visits
            this.setAuthToken(response);
            this.dispatch(this.constructor.EventTypes.USER_DATA_AVAILABLE, response);
        } else {
            authFormService.setIsRegistration(response.registration);
            authFormService.setRegistrationMail(payload.email);
            // TODO @cleanup should be response.codeType instead of just type
            authFormService.setConfirmationCodeType(response.type || DEFAULT_CONFIRMATION_CODE_TYPE);
        }

        return response;
    }

    async loginWithEmailCode({code, uuid}) {
        const response = await apiConnection.post("/users/login_with_email_code/", {
            email: authFormService.registrationMail,
            code,
            uuid,
            termsAgreed: true,
            appName: this.loginAppName,
        });
        this.setAuthToken(response);

        return response;
    }

    onAuthentication(callback) {
        this.addListener(AuthToken.EventTypes.LOGIN, callback);
        if (this.isAuthenticated()) {
            callback();
        }
    }

    executeWhenAuthenticated(callback) {
        if (this.isAuthenticated()) {
            callback();
        } else {
            this.addListenerOnce(AuthToken.EventTypes.LOGIN, callback);
        }
    }

    onAuthenticationChange(callback) {
        this.addListener(AuthService.EventTypes.AUTHENTICATION_CHANGE, callback);
    }

    onAppSettingsUpdate(callback) {
        this.addListener(AuthService.EventTypes.APP_SETTINGS_UPDATE, callback)
    }
}

export const authService = new AuthService();
