import {UI} from "../../../../../../stem-core/src/ui/UI";

import {SubscriptionStore, SubscriptionOfferStore} from "../../../../../State";
import {Messages} from "../../../../../Messages";
import {MyRecurringPayment} from "./MyRecurringPayment";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";


@autoredraw(SubscriptionStore, SubscriptionOfferStore)
export class PastSubscriptions extends MyRecurringPayment {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.pastSubsctiptions,
        };
    }

    getStore() {
        return SubscriptionStore.getPast();
    }

    getExpandedRowContent(entry) {
        const contentRows = [
            {
                label: Messages.planDetails,
                value: entry.storeObject.coverage.name,
            }
        ];

        const shippingAddress = entry.storeObject.getShippingAddress();
        if (shippingAddress) {
            contentRows.push({
                label: Messages.shippingAddress,
                value: shippingAddress,
            });
        }
        contentRows.push({
            label: Messages.lastPayment,
            value: entry.lastPayment || "-",
        });

        return contentRows;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            width: 0.55,
            align: "left",
            overflowEllipsis: true,
            value: entry => `${entry.storeObject.getMerchantTitle()} - ${entry.storeObject.coverage.name}`,
        }, {
            headerName: Messages.amount,
            width: 0.2,
            align: "left",
            value: entry => entry.storeObject.formatPriceShortened(),
        }, {
            headerName: Messages.lastPayment,
            width: 0.25,
            align: "right",
            value: entry => entry.lastPayment,
            style: {color: this.styleSheet.themeProps.MERCHANT_2},
        }];
    }
}
