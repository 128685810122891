import {UI, styleRule, StyleSheet, registerStyle} from "../../../../stem-core/src/ui/UI";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives";
import {CloseIconWhite} from "../../../SVGElements";
import {PAGES} from "./Nav";

export class NavMobileExpandedStyle extends StyleSheet {
    transitionTime = 0.2;

    @styleRule
    scrim = {
        background: this.themeProps.MERCHANT_SPINNER_BACKGROUND,
        transition: this.transitionTime + "s ease",
        position: "fixed",
        zIndex: 10000,
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        opacity: 0,
    };

    @styleRule
    links = {
        ">*": {
            fontSize: 18,
            color: this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE + " !important",
            fontWeight: "normal",
            width: "100%",
            height: 56,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        transition: this.transitionTime + "s ease",
        transitionDelay: this.transitionTime + "s",
        flex: 1,
        opacity: 0,
    };

    @styleRule
    nav = {
        padding: "0 12px",
        display: "flex",
        width: "100%",
        position: "fixed",
        background: this.themeProps.NAVBAR_BACKGROUND,
        top: 0,
        zIndex: 10002,
        transform: "translateY(-208px)",
        opacity: 1,
        transition: this.transitionTime + "s ease",
        boxShadow: this.themeProps.NAV_MOBILE_SHADOW,
    };

    @styleRule
    visible = {
        ">*": {
            opacity: 1,
        },
        ">*>*": {
            opacity: 1,
        },
        ">:first-child": {
            transform: "translateY(0) scale(1)",
        },
    };

    @styleRule
    close = {
        color: this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE,
        alignSelf: "start",
        position: "fixed",
        top: 20,
        left: 12,
        cursor: "pointer",
        zIndex: 10003,
        transition: this.transitionTime + "s ease",
        transitionDelay: this.transitionTime + "s ease",
        opacity: "0",
    };
}

// This needs refactoring. We also need to rethink the animation.
@registerStyle(NavMobileExpandedStyle)
export class NavMobileExpanded extends UI.Element {
    static show(options) {
        this.create(document.body, options);
    }

    render() {
        const {styleSheet} = this;
        const items = PAGES.filter(page => !page.Icon);

        return [
            <div className={styleSheet.nav}>
                <div className={styleSheet.links} ref="links">
                    {items.map(page => (
                        <Link href={page.href} value={page.title} onClick={() => this.hide(page)} />
                    ))}
                </div>
            </div>,
            <div className={styleSheet.scrim} onClick={() => this.hide()} />,
            <CloseIconWhite ref="closeIcon" size={18} className={styleSheet.close} onClick={() => this.hide()} />,
        ];
    }

    hide(page) {
        if (page) {
            this.updateOptions({currentActivePage: page});
        }

        this.attachTimeout(() => {
            this.links.setStyle("transitionDelay", "0s");
            this.removeClass(this.styleSheet.visible);
        }, 0);

        this.attachTimeout(() => this.destroyNode(), this.styleSheet.transitionTime * 1000);
    }

    onMount() {
        this.attachTimeout(() => {
            this.addClass(this.styleSheet.visible);
        }, 100);
    }
}
