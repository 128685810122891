import {UI, styleRule, registerStyle, styleRuleInherit, Theme} from "../../stem-core/src/ui/UI";

import {BaseCard} from "./BaseCard";
import {Modal, ModalStyle} from "./Modal";
import {BlinkButton} from "./Button";
import {BUTTON_TYPE} from "../Constants";
import {CloseIcon} from "../SVGElements";
import {isSmallScreen, isString} from "../Utils";
import {Messages} from "../Messages";
import {Scrim} from "./Scrim";
import {BlinkInput} from "./Input";
import {apiConnection} from "../../client/connection/BlinkApiConnection";

export class ConfirmationModalStyle extends ModalStyle {
    @styleRule
    bodyTextStyle = {
        color: this.themeProps.BASE_CARD_DESCRIPTION_COLOR,
    };

    @styleRule
    buttons = {
        width: "100%",
        maxWidth: this.themeProps.BASE_CARD_WIDTH,
        display: "flex",
        margin: "36px auto auto",
        ">:nth-child(1)": {
            paddingRight: () => (isSmallScreen() ? 6 : 12),
        },
        ">:nth-child(2)": {
            paddingLeft: () => (isSmallScreen() ? 6 : 12),
        },
    };

    @styleRule
    buttonContainer = {
        width: "50%",
    };

    @styleRule
    button = {
        width: "100%",
        height: "100%",
    };

    @styleRuleInherit
    modal = {
        padding: () => `${(isSmallScreen() ? "24px 12px" : 24)} !important`,
    };
}

export function ErrorText(error) {
    if (!error) {
        return null;
    }

    const errorMessage = error.message || String(error);
    return <div style={{color: Theme.props.MERCHANT_ERROR}}>Error: {errorMessage}</div>
}


@registerStyle(ConfirmationModalStyle)
export class ConfirmationModal extends Modal {
    // TODO move this to getDefaultOptions
    confirmButtonDisabled = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            cancelLabel: "",
            confirmLabel: "",
            description: "",
            confirmId: "confirmationModalConfirm",
            title: "",
            confirmAction: () => this.resolve(this.getValue()),
            infirmAction: () => this.hide(),
        }
    }

    getValue() {
        return true;
    }

    getTitle() {
        return this.options.title;
    }

    getDescription() {
        return this.options.description;
    }

    getConfirmLabel() {
        return this.options.confirmLabel || Messages.confirm;
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {cancelLabel, confirmAction, infirmAction, confirmId} = this.options;
        // TODO: Fix the style of the buttons on mobile from the Button.jsx file.
        return [
            <Scrim onHide={() => this.hide()}/>,
            <BaseCard title={this.getTitle()} description={this.getDescription()} {...this.getCardOptions()}>
                {this.render()}
                {ErrorText(this.error)}
                <div className={styleSheet.buttons}>
                    <div className={styleSheet.buttonContainer}>
                        <BlinkButton
                            className={styleSheet.button}
                            testId="confirmationModalCancel"
                            type={BUTTON_TYPE.SECONDARY}
                            label={cancelLabel || Messages.cancel}
                            onClick={() => infirmAction(this)}
                        />
                    </div>
                    <div className={styleSheet.buttonContainer}>
                        <BlinkButton
                            disabled={this.confirmButtonDisabled}
                            className={styleSheet.button}
                            label={this.getConfirmLabel()}
                            ref="confirm"
                            testId={confirmId}
                            onClick={() => confirmAction(this)}
                        />
                    </div>
                </div>
                <CloseIcon className={styleSheet.close} testId="confirmationModalClose" onClick={() => this.hide()}/>
            </BaseCard>,
        ];
    }

    // Execute an asynchronous action (such as an API request),
    // and resolve() the modal using the response if no error
    // occurs.
    async resolveWithAsyncAction(action) {
        let response = null;
        this.waitingForResponse = true;
        this.error = null;
        this.redraw();
        try {
            response = await action();
        } catch (error) {
            this.error = error;
        } finally {
            this.waitingForResponse = false;
        }
        if (response) {
            this.resolve(response);
        } else {
            this.redraw();
        }
    }

    // Makes a request towards a function or enpoints string
    // If executed without errors, hide else set the error and redraw
    // Extend this is required
    async makeRequest(endpoint, ...args) {
        const requestFunc = isString(endpoint)
            ? () => apiConnection.post(endpoint, ...args)
            : () => endpoint(...args);

        return this.resolveWithAsyncAction(requestFunc);
    }
}

export class InputConfirmationModal extends ConfirmationModal {
    render() {
        return <BlinkInput ref="input" label={this.options.inputLabel}
                           initialValue={this.options.inputInitialValue}
                           inputAttributes={{placeholder: this.options.inputPlaceholder}}
                           validators={[{condition: () => !this.input.isEmpty()}]}/>;
    }
}
