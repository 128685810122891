import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {Modal, ModalStyle} from "../../../../../../ui/Modal";
import {BlinkButton} from "../../../../../../ui/Button";
import {Messages} from "../../../../../../Messages";
import {authService} from "../../../../../../../client/connection/Services";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";

class AccountSuspendedModalStyle extends ModalStyle {
    @styleRule
    button = {
        width: "100%",
    };

    @styleRule
    description = {
        fontSize: 16,
        lineHeight: 20,
        color: this.themeProps.MODAL_DESCRIPTION_COLOR,
        marginBottom: 24,
    };
}

@registerStyle(AccountSuspendedModalStyle)
export class AccountSuspendedModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.accountClosedModalTitle,
            hasCloseIcon: false,
            hideOnScrimClick: false,
        };
    }

    render() {
        const {styleSheet} = this;

        return [
          <div className={styleSheet.description}>{Messages.accountClosedModalText}</div>,
          <BlinkButton className={styleSheet.button} onClick={async () => {
              LoadingSpinner.show();
              await authService.logout();
              window.location.href = "/";
              LoadingSpinner.hide();
          }}>{Messages.logOut}</BlinkButton>
        ];
    }
}
