import {styleRuleInherit} from "../../stem-core/src/decorators/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {WalletListItem, WalletListItemStyle} from "./WalletListItem";

export class MerchantListItemStyle extends WalletListItemStyle {
    @styleRuleInherit
    item = {
        alignItems: "center",
        padding: "12px 0",
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
        borderTop: "",
        borderBottom: () => "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR,
        ":first-child": {
            borderTop: "none",
        }
    };

    @styleRuleInherit
    radioInput = {
        border: () => "2px solid " + this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
        width: 17,
        height: 17,
    };

    @styleRuleInherit
    selected = {
        cursor: "pointer",
        "::after": {
            height: 7,
            width: 7,
            background: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
            content: "\"\"",
        },
    };
}

@registerStyle(MerchantListItemStyle)
export class MerchantListItem extends WalletListItem {}
