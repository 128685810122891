import {registerStyle, styleRuleInherit, UI} from "../../../../../../stem-core/src/ui/UI";

import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {RadioButtons} from "../../../../../ui/Button";
import {AddFundsButton} from "../components/AddFundsButton";
import {isSmallScreen} from "../../../../../Utils";
import {PaymentMethodStore, WalletStore} from "../../../../../State";
import {CardInlineWidget} from "../components/cards/CardInlineWidget";
import {AutoReload} from "../components/AutoReload";
import {RollAnimatedBalance} from "../../../../../common/RollAnimatedBalance";
import {Money} from "../../../../../../client/state/misc/Money";
import {TOP_UP_VALUES} from "../../../../../Constants";
import {styleRule} from "../../../../../../stem-core/src/decorators/Style";


class AddFundsClassStyle extends BaseCardStyle {
    @styleRuleInherit
    card = {
        padding: () => isSmallScreen() ? "35px 18px 18px" : "60px 84px 66px",
        ">*": {
            maxWidth: 312,
            alignSelf: "center",
        }
    };

    @styleRule
    balance = {
        transform: "initial",
        fontWeight: "lighter",
        display: "block",
        color: this.themeProps.BALANCE_COLOR,
        fontSize: 60,
        marginBottom: 24,
        height: "1em",
    };

    @styleRule
    line = {
        margin: "24px 0",
    };

    @styleRule
    horizontalPadding = {
        padding: () => isSmallScreen() ? "" : "0 24px",
    };

    @styleRule
    button = {
        width: "100%",
    };
}

@registerStyle(AddFundsClassStyle)
export class AddFundsCard extends BaseCard {
    getCurrency() {
        return WalletStore.getWallet().getCurrency();
    }

    render() {
        console.assert(this.getCurrency());
        const {styleSheet} = this;

        const reloadOptions = TOP_UP_VALUES.map(value => this.mainUnitsToOption(value));
        this.activeValue = this.activeValue || reloadOptions[1];

        const onRadioSelect = value => {
            this.activeValue = value;
            this.redraw();
        };

        return [
            <RollAnimatedBalance ref="balance" className={styleSheet.balance}/>,
            <div className={styleSheet.horizontalPadding}>
                <RadioButtons ref="radioButtons" testId="addFundsAmountButtons" activeValue={this.activeValue}
                                    values={reloadOptions} onSelect={onRadioSelect}/>
                <CardInlineWidget className={styleSheet.line}/>
                <AutoReload className={styleSheet.line}/>
            </div>,
            <AddFundsButton testId="addFundsButton" amount={this.getAmountByOption(this.activeValue)}
                            className={styleSheet.button}/>
        ];
    }

    mainUnitsToOption(amount) {
        return (new Money(this.getCurrency().mainUnitsToAmount(amount), this.getCurrency())).toMainUnitString({decimalsDisplayed: 0});
    }

    getAmountByOption(option) {
        return this.getCurrency().mainUnitsToAmount(
            TOP_UP_VALUES.find(value => this.mainUnitsToOption(value) === option));
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(PaymentMethodStore, () => this.redraw());
        this.attachChangeListener(WalletStore, () => this.redraw());
    }
}
