import {registerStyle, styleRule, StyleSheet, UI} from "../../../stem-core/src/ui/UI";
import {Route, Router} from "../../../stem-core/src/ui/Router";
import {NavBar} from "./nav/NavBar";
import {Footer} from "./footer/Footer";
import {NavMobile} from "./nav/NavMobile";
import {isSmallScreen} from "../../Utils";
import {userService} from "../../../client/connection/Services";
import {PAGES} from "./nav/Nav";
import {LoadingSpinner} from "../../ui/LoadingSpinner";


class HomePageStyle extends StyleSheet {
    @styleRule
    app = {
        display: "flex",
        flexDirection: "column",
        minHeight: ["100vh !important", "-webkit-fill-available"],
        width: "100%",
        alignItems: "center",
        background: this.themeProps.USER_DASHBOARD_BODY_BACKGROUND,
    };

    @styleRule
    mainFrame = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        paddingTop: () => isSmallScreen() ? 56 + 18 : 72 + 36,
        paddingBottom: () => isSmallScreen() ? "0" : 12,
    };
}

@registerStyle(HomePageStyle)
export class HomePage extends UI.Element {
    urlPartsToSet = null;

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.app);
    }

    render() {
        if (!userService.isUserFetched()) {
            LoadingSpinner.show();
            return [];
        }
        const NavbarClass = isSmallScreen() ? NavMobile : NavBar;
        const dashboardRoutes = PAGES.map(
            page => new Route(page.href, page.Component, [], page.title.toString())
        );

        return [
            <NavbarClass ref="navBar" />,
            <div className={this.styleSheet.mainFrame}>
                <Router ref="router" routes={new Route(null, null, dashboardRoutes)} />
            </div>,
            <Footer />,
        ];
    }

    setURL(urlParts) {
        if (!userService.isUserFetched()) {
            // Save the params to re-call this function once
            // the user is fetched and the component will render the router and the navbar
            this.urlPartsToSet = urlParts;
            return;
        }

        let cleanUrlParts = urlParts;
        const hrefs = PAGES.map(page => page.href);

        if (hrefs.indexOf(urlParts[0]) === -1) {
            cleanUrlParts = [hrefs[0]];
            Router.changeURL("/" + hrefs[0], {replaceHistory: true});
        }

        this.router.setURL(cleanUrlParts);
        this.navBar.setURL(cleanUrlParts);
    }

    onMount() {
        // TODO: Do we still need this?
        this.currentScreenIsSmallScreen = isSmallScreen();
        this.attachEventListener(window, "resize", () => {
            if (this.currentScreenIsSmallScreen !== isSmallScreen()) {
                this.currentScreenIsSmallScreen = isSmallScreen();
                this.redraw();
            }
        });

        userService.ensureUserDataRequested(() => {
            LoadingSpinner.hide();
            this.redraw();

            if (this.urlPartsToSet) {
                this.setURL(this.urlPartsToSet);
                this.urlPartsToSet = null;
            }
        });
    }
}
