import {Theme} from "../stem-core/src/ui/style/Theme";

export function initTheme() {
    // TODO: Move these into blinkpay/StyleConstants.js file and rename them.
    Theme.setProperties({
        NAV_MANAGER_NAVBAR_HEIGHT: 0,
        COLOR_BACKGROUND_BODY: "#f5f7f8",
        LIGHT_BACKGROUND: "#fff",
        BIG_HEADER_TEXT_COLOR: "#EAF3F4",
        SIGN_UP_HOVER_BACKGROUND_COLOR: "#1C707C",
        CARD_SHADOW_1: "0 8px 16px -8px rgba(0, 0, 0, 0.15)",
        CARD_SHADOW_2: "0 13px 27px -5px rgba(50, 50, 93, 0.12)",
        MODAL_BACKGROUND_COLOR: "rgba(255,255,255,.84)",
        NAVBAR_ITEM_COLOR: "#fff",
        EMAIL_NEWSLETTER: "#378",
    });
}
