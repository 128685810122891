import {UI} from "../../../stem-core/src/ui/UIBase";

import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {ProductCard, ProductCardStyle} from "./ProductCard";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {Device} from "../../../stem-core/src/base/Device";

class ValuePropositionCardStyle extends ProductCardStyle {
    clickable = {};

    sizingFunction = () => Device.isMobileDevice();

    @styleRuleInherit
    card = {
        background: () => (Device.isMobileDevice() ? "" : this.themeProps.LANDING_PAGE_DARK_CARD_BACKGROUND),
        color: this.themeProps.LANDING_PAGE_LIGHT_FONT_COLOR,
        padding: () => (Device.isMobileDevice() ? "18px 24px" : "36px 12px 0"),
        marginBottom: () => (Device.isMobileDevice() ? 0 : 60),
        boxShadow: () => (Device.isMobileDevice() ? "" : this.themeProps.LANDING_PAGE_DARK_CARD_SHADOW),
        height: () => (Device.isMobileDevice() ? "" : 302),
    };

    @styleRuleInherit
    icon = {
        height: 80,
        marginBottom: 20,
        transformOrigin: () => (Device.isMobileDevice() ? "30% 50%" : ""),
        transform: "",
    };

    @styleRuleInherit
    description = {
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        opacity: () => (Device.isMobileDevice() ? ".88" : ""),
    };
}

@registerStyle(ValuePropositionCardStyle)
export class ValuePropositionCard extends ProductCard {}
