import {StoreObject, Store} from "../../stem-core/src/state/Store";
import {apiConnection} from "../connection/BlinkApiConnection";
import {UserPreferencesStore} from "./UserPreferencesStore";
import {WalletStore} from "./WalletStore";
import {BaseEnum, makeEnum} from "./misc/BaseEnum";
import {field} from "./misc/StoreField";
import {BlinkGlobal} from "../../blinkpay/UtilsLib";

@makeEnum
export class UserType extends BaseEnum {
    static REGULAR;
    static MERCHANT;
    static ADMIN;
}

export class UserProfile extends StoreObject {
    @field(UserType) userType;
    @field(String) email;
    @field(String) firstName;
    @field(String) lastName;
    @field(Boolean) isActive;
    @field(Date) createdAt;
    @field(Date) lastLogin;
    @field("Jurisdiction") jurisdiction;
    @field("Merchant") referralMerchant;

    isRegular() {
        return this.userType === UserType.REGULAR;
    }

    isAdmin() {
        return this.userType === UserType.ADMIN;
    }

    getEmail() {
        return this.email;
    }

    getName() {
        const parts = [this.firstName, this.lastName].filter(x => x);
        return parts.join(" ");
    }

    getPreferences() {
        return UserPreferencesStore.find(obj => obj.userId === this.id);
    }

    getWallets() {
        return WalletStore.filter(item => item.userId === this.id);
    }

    getDefaultWallet() {
        const defaultCurrencyId = this.getDefaultCurrency().id;
        return this.getWallets().find(w => w.currencyId === defaultCurrencyId);
    }

    getDefaultCurrency() {
        return this.getPreferences().getDefaultCurrency();
    }
}

class UserProfileStoreClass extends Store("UserProfile", UserProfile) {
    getUserProfile() {
        // TODO @branch this doesn't work in the admin
        return this.get(BlinkGlobal.userId) || this.all()[0];
    }
}

export const UserProfileStore = new UserProfileStoreClass();

export async function apiConfirmCloseAccount(data) {
    return apiConnection.post("/users/confirm_close_account/", data);
}

export async function apiRequestCloseAccount(data) {
    return apiConnection.post("/users/request_close_account/", data);
}

export async function apiUpdateUserProfile(data) {
    return apiConnection.post("/users/update_profile/", data);
}

export async function apiRequestUserEmailChange(data) {
    return apiConnection.post("/users/request_email_change/", data);
}

export async function apiConfirmUserEmailChange(data) {
    return apiConnection.post("/users/confirm_email_change/", data);
}

export async function apiUndoEmailChange(undoToken) {
    return apiConnection.post("/users/undo_email_change/", {undoToken});
}

export async function apiUndoCloseAccount(undoToken) {
    return apiConnection.post("/users/cancel_close_account/", {undoToken});
}

export async function apiUpdateUserMerchantActivity(merchantId) {
    return apiConnection.post("/users/update_merchant_activity/", {merchantId});
}

export async function apiLogoutUser(data = {}) {
    return apiConnection.post("/users/logout", data);
}
