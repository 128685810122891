import {iFrameConnection} from "../../services/IFrameConnection";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages";
import {iFrameState} from "../../services/IFrameState";
import {PANEL_TYPE} from "../../../blink-sdk/Constants";

export function startFlow(flowType, options={}) {
    iFrameConnection.sendToSDK(IFrameMessages.START_FLOW_REQUEST, {
        flowType,
        options: {
            type: flowType,
            ...iFrameState.getPanelToFlowOptions(),
            ...options,
        },
    });
}

export function startDonationFlow(editRecurring=false, oneTime=false) {
    if (oneTime) {
        startFlow(null, {
            type: PANEL_TYPE.donation,
            editRecurring,
            skipCTA: true,
        });
    } else {
        startFlow(PANEL_TYPE.donation, {
            editRecurring,
            skipCTA: true,
        });
    }
}

export function startSubscriptionFlow(editRecurring=false, skipCTA=false) {
    startFlow(PANEL_TYPE.subscribe, {
        editRecurring,
        skipCTA,
    });
}

export function startGiftSubscriptionFlow(editRecurring=false, skipCTA=false) {
    startFlow(PANEL_TYPE.giftSubscription, {
        editRecurring,
        skipCTA,
    });
}

export function startNewsletterFlow() {
    startFlow(null, {
        type: PANEL_TYPE.newsletter,
        skipCTA: true,
    });
}

export function startDashboardFlow() {
    startFlow(PANEL_TYPE.dashboard);
}
