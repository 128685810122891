import {AjaxHandler} from "../../stem-core/src/base/Ajax";

export class ResourceLoader {
    constructor() {
        this.ajaxHandler = new AjaxHandler(null, null);
        this.loaded = new Map();
    }

    loadJSON(path, callback) {
        this.load(path, "json", callback);
    }

    load(path, dataType, callback) {
        const cachedResource = this.loaded.get(path);
        if (!cachedResource) {
            this.fetchResource(path, dataType, callback);
            return;
        }

        callback(cachedResource);
    }

    fetchResource(path, dataType, callback) {
        return this.ajaxHandler.fetch(path, {dataType: dataType, data: {}, method: "GET"}).then(
            response => {
                this.loaded.set(path, response);
                callback(response);
            },
            error => {
                callback(null, error);
            }
        );
    }
}

export const resourceLoader = new ResourceLoader();
