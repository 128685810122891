import {UI} from "../../../../../../../stem-core/src/ui/UIBase.js";
import {Modal, ModalStyle} from "../../../../../../ui/Modal";
import {Messages} from "../../../../../../Messages";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {DefaultCodeConfirmationForm} from "../../../../../../common/DefaultCodeConfirmationForm";
import {NOOP_FUNCTION} from "../../../../../../../stem-core/src/base/Utils";
import {apiConfirmCloseAccount} from "../../../../../../../client/state/UserProfileStore";
import {MessageElement} from "../../../../../../widget/ui/MessageElement";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style.js";


export class CloseAccountConfirmationModalStyle extends ModalStyle {
    @styleRule
    cancel = {
        cursor: "pointer",
        padding: 6,
        width: this.themeProps.FIT_CONTENT,
        color: this.themeProps.CLOSE_ACCOUNT_CANCEL_COLOR,
        margin: "6px auto 0",
    };
}

@registerStyle(CloseAccountConfirmationModalStyle)
export class CloseAccountConfirmationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.closeAccountModalTitle,
            userProfile: null,
            closeTestId: "closeAccountConfirmationModal",
            hideOnScrimClick: false,
            onConfirmSuccess: NOOP_FUNCTION,
        };
    }

    render() {
        const {styleSheet} = this;
        const {userProfile} = this.options;

        return [
            <MessageElement className={styleSheet.textLine} message={Messages.closeAccountConfirmationLine1(userProfile.getEmail())}/>,
            <DefaultCodeConfirmationForm submitButtonLabel={Messages.closeAccountLabel} onSubmit={(code, callback, errorCallback) =>
                this.handleCloseConfirmationCodeSubmit(code, callback, errorCallback)}
            />,
            <div className={styleSheet.cancel} onClick={() => this.hide()}>{Messages.cancel}</div>
        ]
    }

    async handleCloseConfirmationCodeSubmit(code, successCallback = NOOP_FUNCTION, errorCallback = NOOP_FUNCTION) {
        const {onConfirmSuccess} = this.options;

        LoadingSpinner.show();
        try {
            const response = await apiConfirmCloseAccount({code});
            successCallback(response);
            onConfirmSuccess();
            this.hide();
        } catch (error) {
            errorCallback(error);
            throw error;
        } finally {
            LoadingSpinner.hide()
        }
    }
}
