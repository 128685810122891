import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {UI} from "../../stem-core/src/ui/UIBase";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {ShortCodeInput} from "../website/unauthenticated/components/ShortCodeInput";
import {Messages} from "../Messages";
import {Errors} from "../../client/connection/services/Errors";
import {isNetworkError} from "../Utils";
import {Router} from "../../stem-core/src/ui/Router";
import {MerchantButton} from "./MerchantButton";


export class DefaultCodeConfirmationFormStyle extends StyleSheet {
    @styleRule
    button = {
        ...this.themeProps.AUTH_BUTTON_STYLE,
        marginTop: 30,
    };

    @styleRule
    formComponent = {
        display: "flex",
        flexDirection: "column",
    }
}

@registerStyle(DefaultCodeConfirmationFormStyle)
export class DefaultCodeConfirmationForm extends UI.Element {
    error = null;
    attemptedKeyUpAutoConfirmation = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
            submitButtonLabel: Messages.confirm,
            ButtonClass: MerchantButton,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.formComponent);
    }

    render() {
        const {styleSheet} = this;
        const {submitButtonLabel, ButtonClass} = this.options;

        return [
            <ShortCodeInput ref={"codeInput"}
                            onClearError={() => {
                                this.error = null;
                                this.redraw();
                            }}
                            error={this.error}
                            onChange={() => this.redraw()}
                            onCodeInserted={() => this.handleCodeInserted()}
                            onEnter={() => this.handleEnterKeyPress()}/>,
            <ButtonClass label={submitButtonLabel} className={styleSheet.button}
                         disabled={!this.canConfirmInsertedCode()}
                         onClick={() => this.confirm()}/>,
        ];
    }

    handleEnterKeyPress() {
        if (this.canConfirmInsertedCode()) {
            this.confirm();
        }
    }

    handleCodeInserted() {
        if (!this.attemptedKeyUpAutoConfirmation) {
            this.confirm();
            this.attemptedKeyUpAutoConfirmation = true;
        }
    }

    async confirm() {
        try {
            // Careful with the order, the onSubmit might delete the element.
            this.handleConfirmationSuccess();
            await this.options.onSubmit(this.codeInput.getCode());
        } catch (error) {
            if (this.node) {
                this.handleConfirmationError(error);
            }
        }
    }

    getErrorMessageByCode(errorCode) {
        if (errorCode === Errors.confirmEmailCodeErrors.EMAIL_ADDRESS_ALREADY_IN_USE) {
            return Messages.emailAddressAlreadyInUse;
        }

        return Messages.incorrectConfirmationCode;
    }

    handleConfirmationError(error) {
        this.codeInput.blur();
        this.error = isNetworkError(error) ? error.message : this.getErrorMessageByCode(error.code);
        this.redraw();
    }

    handleConfirmationSuccess() {
        this.error = null;
        this.codeInput.blur();
        this.codeInput.updateOptions({success: true});
        this.redraw();
    }

    canConfirmInsertedCode() {
        return this.codeInput && this.codeInput.hasValidCode();
    }

    onMount() {
        super.onMount();

        this.attachListener(Router.Global, "change", () => {
            this.attemptedKeyUpAutoConfirmation = false;
        });
    }
}
