import {iFrameState} from "../services/IFrameState";
import {PANEL_TYPE, POPUP_GATE_TRANSITION_TIME} from "../../blink-sdk/Constants";
import {DonateMobileState} from "./inline-donation/DonateMobileState";
import {SubscribeFlowState} from "./inline-subscription/SubscribeFlowState";
import {GiftSubscriptionFlowState} from "./inline-subscription/GiftSubscriptionFlowState";
import {iFrameConnection} from "../services/IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {ShopFlowState} from "./pages/shop/ShopFlowState.js";
import {Controller} from "./Controller";
import {PaymentMethodStore} from "../State";
import {DashboardFlowState} from "./pages/inline-user-dashboard/DashboardFlowState";
import {Router} from "../../stem-core/src/ui/Router";
import {NewsletterFlowState} from "./pages/panels/newsletter/NewsletterFlowState";
import {FUNCTIONALITY_NOT_ENABLED_URL} from "./PanelConstants";
import {iFrameMerchantService} from "../services/IframeMerchantService";
import {collapseIframe} from "../widget/misc/WidgetUtils";
import {AUTH_MODAL_CLOSE_TIMEOUT} from "../Constants";
import {LoadingSpinner} from "../ui/LoadingSpinner";
import {iFrameAuthService} from "../services/IFrameAuthService";


class DefaultFlowState {
    async start() {
        const {panelType} = iFrameState;

        if (panelType === PANEL_TYPE.authentication) {
            await iFrameAuthService.promptAuthenticationIfNeeded();
            LoadingSpinner.show();
            setTimeout(collapseIframe, AUTH_MODAL_CLOSE_TIMEOUT);
        } else if (panelType !== PANEL_TYPE.empty) {
            Router.changeToCustomPanel();
        }
    }
}

// We populate these objects only for autocomplete reasons. We depopulate them in "initializeFlows".
// This gets used only in their corresponding flows (a flow state class doesn't instantiate in another flow).
export const Flow = {
    donation: new DonateMobileState(),
    subscribe: new SubscribeFlowState(),
    giftSubscription: new GiftSubscriptionFlowState(),
    shop: new ShopFlowState(),
    dashboard: new DashboardFlowState(),
    newsletter: new NewsletterFlowState(),
    default: new DefaultFlowState(),
};

let panelType = null;
let panelSkipCTA = null;
let panelEditRecurring = null;
let activeFlow = null;
let clearTimeoutId = null;

export function clearActiveFlow() {
    for (const key of Object.keys(Controller)) {
        Controller[key] = null;
    }
    for (const key of Object.keys(Flow)) {
        Flow[key] = null;
    }

    panelType = null;
    panelSkipCTA = null;
    panelEditRecurring = null;
    activeFlow = null;
    clearTimeout(clearTimeoutId);
    clearTimeoutId = null;
}

export function initializeFlows() {
    const clearActiveFlowDelayed = () => {
        clearTimeoutId = setTimeout(clearActiveFlow, POPUP_GATE_TRANSITION_TIME)
    };
    const updateActiveFlow = () => {
        const type = iFrameState.panelType;
        const {skipCTA, editRecurring} = iFrameState;
        if (!type || (panelType === type && panelSkipCTA === skipCTA && panelEditRecurring === editRecurring)) {
            return;
        }
        let newActiveFlow = null;
        let newPanelType = type;
        let newPanelSkipCTA = skipCTA;
        let newPanelEditRecurring = editRecurring;
        if (newPanelType === PANEL_TYPE.empty) {
            // This needs to be done here too because first route change isn't dispatched.
            clearActiveFlowDelayed();
            return;
        }

        const merchant = iFrameMerchantService.getMerchant();
        if ((iFrameState.panelType === PANEL_TYPE.shop && !merchant.allowPurchases)
            || (iFrameState.panelType === PANEL_TYPE.donation && !merchant.allowDonations)
            || (iFrameState.panelType === PANEL_TYPE.subscribe && !merchant.allowSubscriptions)
            || (iFrameState.panelType === PANEL_TYPE.giftSubscription && !merchant.allowSubscriptions)
            || (iFrameState.panelType === PANEL_TYPE.newsletter && !merchant.allowEmailing)) {
            Router.changeURL(FUNCTIONALITY_NOT_ENABLED_URL);
            return;
        }

        if (clearTimeoutId) {
            clearActiveFlow();
        }
        panelType = newPanelType;
        panelSkipCTA = newPanelSkipCTA;
        panelEditRecurring = newPanelEditRecurring;
        if (panelType === PANEL_TYPE.donation || panelType === PANEL_TYPE.donationPage) {
            newActiveFlow = Flow.donation = new DonateMobileState();
        } else if (panelType === PANEL_TYPE.subscribe) {
            newActiveFlow = Flow.subscribe = new SubscribeFlowState();
        } else if (panelType === PANEL_TYPE.giftSubscription) {
            newActiveFlow = Flow.giftSubscription = new GiftSubscriptionFlowState();
        } else if (panelType === PANEL_TYPE.shop) {
            newActiveFlow = Flow.shop = new ShopFlowState();
        } else if (panelType === PANEL_TYPE.dashboard) {
            newActiveFlow = Flow.dashboard = new DashboardFlowState();
        } else if (panelType === PANEL_TYPE.newsletter) {
            newActiveFlow = Flow.newsletter = new NewsletterFlowState();
        } else {
            newActiveFlow = Flow.default = new DefaultFlowState();
        }
        activeFlow = newActiveFlow;
        activeFlow.start();
    };

    clearActiveFlow();
    iFrameState.addUpdateListener(updateActiveFlow);
    updateActiveFlow();

    iFrameConnection.addListener(IFrameMessages.ROUTE_CHANGE, ({url}) => {
        if (url === PANEL_TYPE.empty) {
            clearActiveFlowDelayed();
        }
    });

    PaymentMethodStore.addChangeListener(() => {
        if (Controller.paymentMethod && !Controller.paymentMethod.initialPaymentMethod) {
            Controller.paymentMethod.update({
                initialPaymentMethod: PaymentMethodStore.getPrimary(),
                selectedPaymentMethod: PaymentMethodStore.getPrimary(),
            });
        }
    });
}
