import {UI, styleRule, registerStyle} from "../../../../../../stem-core/src/ui/UI";

import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../../ui/BaseCard";
import {Messages} from "../../../../../Messages";
import {isSmallScreen} from "../../../../../Utils";
import {FakeTable} from "../../../../../ui/FakeTable";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {ReadElement} from "../components/ReadElement";
import {DEFAULT_DATE_FORMAT} from "../../../../../Constants";
import {EndpointPaginator} from "../../../../../../client/state/EndpointPaginator";
import {PurchaseStore} from "../../../../../../client/state/PurchaseStore.js";
import {TopUpStatus} from "../../../../../../client/state/TopUpPaymentStore.js";


export class ArticlePaymentsStyle extends BaseCardStyle {
    purchaseTitleDesktop = {
        width: "230px",
    };

    purchaseTitleMobile = {
        width: "150px",
    };

    @styleRule
    purchaseTitleContainer = [
        {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        isSmallScreen() ? this.purchaseTitleMobile : this.purchaseTitleDesktop,
    ];

    @styleRule
    purchaseTitle = {
        fontWeight: "normal",
        color: this.themeProps.LABEL,
        ":hover": {
            textDecoration: "underline",
        },
    };
}

// TODO @branch @Mihai reimplement and rename to PurchasesList
@registerStyle(ArticlePaymentsStyle)
export class ArticlePayments extends BaseCard {
    paginator = new EndpointPaginator(
        PurchaseStore,
        "/payments/list_purchases",
        {status: TopUpStatus.SUCCEEDED},
        {status: TopUpStatus.SUCCEEDED},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "purchaseHistory",
            title: Messages.articlePayments,
            description: () => {
                if (!this.paginator.lastPageLoaded) {
                    return "";
                }

                // TODO @branch Use the paginator Luke
                return this.getPurchases().length ? Messages.articlePaymentsDescription
                    : Messages.emptyArticlePayments
            },
        };
    }

    getPurchases() {
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);
    }

    render() {
        const {styleSheet} = this;
        const purchases = this.getPurchases();

        if (!purchases.length) {
            return null;
        }

        return [
            <FakeTable
                columns={[
                    {
                        headerName: "TITLE",
                        width: 0.53,
                        align: "left",
                        value: purchase => {
                            let title = [
                                purchase.merchant + " - ",
                                <span style={{fontStyle: "italic"}}>{purchase.getTitle()}</span>
                            ];
                            if (purchase.url) {
                                title = <Link className={styleSheet.purchaseTitle} href={purchase.url} newTab>
                                    {title}
                                </Link>
                            }

                            return (
                                <div className={styleSheet.purchaseTitleContainer}>
                                    {title}
                                </div>
                            );
                        },
                    },
                    {
                        headerName: "DATE",
                        width: 0.3,
                        align: "left",
                        style: {color: this.styleSheet.themeProps.MERCHANT_2},
                        value: payment => {
                            return (
                                <div>
                                    {payment.createdAt.format(DEFAULT_DATE_FORMAT)}
                                </div>
                            );
                        },
                    },
                    {
                        headerName: "PRICE",
                        width: 0.17,
                        align: "right",
                        value: purchase => {
                            return <ReadElement purchase={purchase} />;
                        },
                    },
                ]}
                entries={purchases}
            />
        ];
    }

    onMount() {
        super.onMount();

        this.paginationHandler.attach();
    }
}
