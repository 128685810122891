import {UI} from "../../stem-core/src/ui/UI";

import {Device} from "../../stem-core/src/base/Device";
import {isIframe} from "../Utils";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {Link} from "../../stem-core/src/ui/UIPrimitives";


// TODO: using this class seems like a huge hack
export class ClickPreprocessors {
    static initialized = false;
    static clickPreprocessors = new Dispatcher();

    static init() {
        if (this.initialized) {
            return;
        }
        this.initialized = true;
        let clickPreprocessors = this.clickPreprocessors;
        // Clicking on mobile devices is tricky since there are also touch events involved, so we need to patch the way
        // we add click listeners; Note: this also fixes some issues with links on mobile where the browser actually
        // handles the route redirect instead of our router.
        // WARNING! The click listener now DOES NOT RETURN a handler.
        let lastEventTimestamp = -600;
        let lastEventNode = null;
        UI.Element.prototype.addClickListener = function(callback) {
            if (Device.isMobileDevice() && !(this instanceof Link) && isIframe()) {
                let clicked;
                let touchStartEvent = null;
                this.addNodeListener("touchstart", event => {
                    clicked = true;
                    touchStartEvent = event;
                });
                this.addNodeListener("touchmove", () => {
                    clicked = false;
                    touchStartEvent = null;
                });
                this.addNodeListener("touchend", (event, ...args) => {
                    if (clicked) {
                        if (touchStartEvent) {
                            touchStartEvent.preventDefault();
                            touchStartEvent.stopPropagation();
                        }
                        clickPreprocessors.dispatch(this);
                        callback(event, ...args);
                        lastEventTimestamp = event.timeStamp;
                        lastEventNode = this.node;
                    }
                    touchStartEvent = null;
                });
            } else {
                this.addNodeListener("click", (event, ...args) => {
                    if (event.timeStamp - lastEventTimestamp < 500 &&
                        (lastEventNode && !lastEventNode.contains(this.node))) {
                        return;
                    }
                    clickPreprocessors.dispatch(this);
                    callback(event, ...args);
                });
            }
        }
    }

    static addCallback(callback) {
        this.clickPreprocessors.addListener(callback);
    }

    static removeCallback(callback) {
        this.clickPreprocessors.removeListener(callback);
    }
}
