import {UI, registerStyle, styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/UI";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {ToggleSetting} from "../../account/components/ToggleSetting";
import {Toast} from "../../../../../ui/Toast";
import {Messages} from "../../../../../Messages";
import {userService} from "../../../../../../client/connection/Services";
import {apiUpdateUserMerchantPreferences} from "../../../../../../client/state/merchant/MerchantUserStore";


export class AutopaySettingStyle extends StyleSheet {
    @styleRule
    merchantName = {
        color: this.themeProps.LABEL,
        fontWeight: "normal",
    };
}

@registerStyle(AutopaySettingStyle)
export class AutopaySetting extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            merchant: null,
        };
    }

    getSettingLabel() {
        const {merchant} = this.options;

        return <Link className={this.styleSheet.merchantName} href={merchant.getUrl()} newTab>
            {merchant}
        </Link>;
    }

    async handleSettingToggle() {
        try {
            await apiUpdateUserMerchantPreferences({
                userId: userService.getUserId(),
                merchantId: this.options.merchant.id,
                autoPayEnabled: !userService.isAutoPayEnabledForMerchant(this.options.merchant),
            });
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        }
    }

    render() {
        const {merchant} = this.options;

        return <ToggleSetting label={this.getSettingLabel()} active={userService.isAutoPayEnabledForMerchant(merchant)}
                              onToggle={() => this.handleSettingToggle()}>
                {userService.isAutoPayEnabledForMerchant(merchant) ? null : Messages.autopayOffOnDomain}
            </ToggleSetting>

    }
}
