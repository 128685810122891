import {authService} from "../../client/connection/Services";
import {WebsiteIndex} from "./WebsiteIndex";

const options = {replaceHistory: true};

export class AppGuards {
    static checkNotAuthenticated() {
        if (!authService.isAuthenticated()) {
            return null;
        }
        return ["/wallet", options];
    }

    static checkAuthenticated(routeSnapshot) {
        if (authService.isAuthenticated()) {
            return null;
        }
        WebsiteIndex.setRedirectURL(routeSnapshot.path);
        return ["/", options];
    }

    static checkAuthenticatedForOAuth(routeSnapshot) {
        if (authService.isAuthenticated()) {
            return null;
        }
        WebsiteIndex.setRedirectURL(routeSnapshot.path);
        return ["/login", options];
    }
}
