import {PaymentMethodController} from "./inline-donation/PaymentMethodController";
import {SubscriptionPlanController} from "./inline-subscription/SubscriptionPlanController";
import {AddressController} from "./inline-subscription/AddressController";


// These are instantiated in the flow state classes and throughout the inline user dashboard
export const Controller = {
    paymentMethod: new PaymentMethodController(),
    subscriptionPlan: new SubscriptionPlanController(),
    address: new AddressController(),
}
