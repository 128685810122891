import {UI} from "../../../../../../../../stem-core/src/ui/UIBase";

import {DropdownList, DropdownListStyle} from "../../../../../../../ui/dropdown/DropdownList";
import {SingleCard} from "../../../../account/components/payment-method/SingleCard";
import {DropdownItem, DropdownItemStyle} from "../../../../../../../ui/dropdown/DropdownItem";
import {AddCardButton} from "./AddCardButton";
import {styleRule} from "../../../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../../../stem-core/src/ui/style/Theme";


class CardsDropdownListStyle extends DropdownListStyle {
    @styleRule
    dropdownItem = {
        paddingLeft: 12,
    };
}

@registerStyle(CardsDropdownListStyle)
export class CardsDropdownList extends DropdownList {
    render() {
        const {styleSheet} = this;
        const {options, active} = this.options;

        return [options.map(card =>
                <DropdownItem active={active === card} list={this} className={styleSheet.dropdownItem} item={card}>
                    <SingleCard card={card} />
                </DropdownItem>
            ),
            options.length ? null : <AddCardButton className={DropdownItemStyle.getInstance().item} />,
        ];
    }
}
