import {UI} from "../../stem-core/src/ui/UIBase";

import {StyleSheet} from "../../stem-core/src/ui/Style";
import {styleRule} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {CreditCardIcon} from "../ui/CreditCardIcon";
import {StemDate} from "../../stem-core/src/time/Date";
import {Messages} from "../Messages";


export class CardItemStyle extends StyleSheet {
    @styleRule
    cardItem = {
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    cardNumber = {
        marginLeft: 16,
    };

    @styleRule
    expiryDate = {
        flex: 1,
        textAlign: "right",
    };

    @styleRule
    icon = {
        marginLeft: 4,
        display: "flex",
        alignItems: "center",
        ">*>*": {
            display: "flex",
            alignItems: "center",
        }
    };
}

@registerStyle(CardItemStyle)
export class CardItem extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            showExpiryDate: true,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.cardItem);
    }

    render() {
        const {styleSheet} = this;
        const {card, showExpiryDate} = this.options;

        return [
            <CreditCardIcon size={15} brand={card.getBrand()} className={styleSheet.icon} />,
            <div className={styleSheet.cardNumber} testId={"cardNumber"}>
                {Messages.usingCard(card.getBrand(), card.getLastDigits())}
            </div>,
            showExpiryDate ?
                <div className={styleSheet.expiryDate}>{StemDate.format(card.getExpiryDate(), "MM/YY")}</div> : null
        ]
    }
}
