// TODO: Not 100% sure if we should export everything here
import {LanguageStore} from "../client/state/LanguageStore";
import {setLanguageStore} from "../stem-core/src/ui/Translation";
import {englishTranslationMap} from "./Messages";

export {MerchantStore} from "../client/state/MerchantStore";
export {MerchantPanelStore} from "../client/state/merchant/MerchantPanelStore";
export {MerchantSDKSettingsStore} from "../client/state/merchant/MerchantSDKSettingsStore";
export {MerchantUserJourneyStore} from "../client/state/merchant/MerchantUserJourneyStore";
export {PaymentStore} from "../client/state/PaymentStore";
export {TopUpPaymentStore} from "../client/state/TopUpPaymentStore";
export {WalletStore} from "../client/state/WalletStore";
export {PaymentMethodStore} from "../client/state/PaymentMethodStore";
export {UserPreferencesStore} from "../client/state/UserPreferencesStore";
export {CurrencyStore} from "../client/state/CurrencyStore";
export {SubscriptionOfferStore} from "../client/state/SubscriptionOfferStore";
export {SubscriptionStore} from "../client/state/SubscriptionStore";
export {UserProfileStore} from "../client/state/UserProfileStore";
export {UserAddressStore} from "../client/state/UserAddressStore";
export {CurrencySettingsStore} from "../client/state/CurrencySettingsStore";
export {DonationStore} from "../client/state/DonationStore";
export {DonationOfferStore} from "../client/state/DonationOfferStore";
export {RecurringPaymentStoreObject} from "../client/state/RecurringPaymentStoreObject";
export {LegalEntityStore} from "../client/state/LegalEntityStore";

import "../client/state/SubscriptionCoverageStore"; // We do this to fix an issue on website.
import "../client/state/SubscriptionChangeStore"; // We do this to avoid a warning 🙄
import "../client/state/UserSessionStore"; // We do this to avoid a warning 🙄
import "../client/state/merchant/external-providers/MerchantFacebookPixelStore"; // We do this to avoid a warning 🙄


LanguageStore.importState([
    {
        id: 1,
        translationMap: englishTranslationMap,
    },
]);

setLanguageStore(LanguageStore);
