import {UI} from "../../../stem-core/src/ui/UIBase";
import {convertHTMLStringToJSON} from "../../UtilsLib";
import {PanelLink} from "../../panel/panel-elements/PanelLink.jsx";


export function convertToUI(value, classMap, inRecursion = false) {
    if (typeof value === "string") {
        return inRecursion ? new UI.TextElement(value) : new (classMap.get("Text"))(value);
    }
    if (!value) {
        return null;
    }
    if (Array.isArray(value)) {
        return value.map(x => convertToUI(x, classMap, inRecursion));
    }
    const options = {...value};
    let children = [];
    if (options.children) {
        children = convertToUI(options.children, classMap, true);
        delete options.children;
    }
    if (options.class) {
        options.className = options.class;
        delete options.class;
    }
    if (options.classname) {
        options.className = options.classname;
        delete options.classname;
    }

    const classMapEntry = [...classMap.entries()].find(([key]) => key.toUpperCase() === value.tag.toUpperCase());
    if (!classMapEntry) {
        return null;
    }
    const classObject = classMapEntry[1];
    if (classObject.prototype instanceof UI.Element) {
        return UI.createElement(classObject, options, ...children);
    }
    return classObject(options, children);
}

const MessageValidTags = new Map([
    ["b", UI.Primitive("b")],
    ["i", UI.Primitive("i")],
    ["a", PanelLink],
    ["Text", UI.TextElement]
]);

export class MessageElement extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            message: "",
            args: [],
        }
    }

    render() {
        const message = typeof this.options.message === "function"
            ? this.options.message(...this.options.args)
            : this.options.message;

        const messageObject = convertHTMLStringToJSON(message);

        return convertToUI(messageObject, MessageValidTags);
    }
}
