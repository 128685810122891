import {UI} from "../../../../../../stem-core/src/ui/UIBase";

import {CardItem} from "../../../../../common/CardItem";
import {ChooseCard} from "../../../../../common/ChooseCard";
import {MerchantListItem} from "../../../../../common/MerchantListItem";


export class PanelChooseCard extends ChooseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            selected: null,
            addNewCardColor: this.styleSheet.themeProps.MERCHANT_LINK,
            ListItemClass: MerchantListItem,
            CardItemClass: CardItem,
            isActive: card => this.options.selected ? card === this.options.selected : card.isPrimary,
            recurringPayment: null,
            showHeader: false,
            showDescription: false,
        };
    }

    setOptions(options) {
        if (options.selected && this.options.selected && options.selected !== this.options.selected) {
            super.setOptions(options);
            this.activeCard = this.findActiveCard();
            this.redraw();
            this.options.onCardSelected(this.activeCard);
        } else {
            super.setOptions(options);
        }
    }
}
