import {UI} from "../stem-core/src/ui/UIBase";
import {StemApp} from "stem-core/src/app/StemApp";
import {ViewportMeta} from "../stem-core/src/ui/UIPrimitives";
import {GlobalStyleSheet} from "./GlobalStyleSheet";
import {apiConnection} from "../client/connection/BlinkApiConnection";
import {authService, userService} from "../client/connection/Services";
import {PageTitleManager} from "../stem-core/src/base/PageTitleManager";
import {initializeTheme} from "./StyleConstants";
import {ClickPreprocessors} from "./ui/ClickPreprocessors";
import {ApiErrors} from "../client/connection/ApiErrors";
import {APP_NAME, IS_PRODUCTION, TEST_MODE, WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED} from "./Constants";
import {AuthToken} from "../client/connection/AuthToken";
import {isString} from "./Utils";
import {Device} from "../stem-core/src/base/Device";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../blink-sdk/utils/AnalyticsClient";


class BlinkViewportMeta extends ViewportMeta {
    getContent() {
        const windowWidth = (Device.isMobileDevice() && window.screen?.width) || window.innerWidth;
        const screenToSmall = windowWidth < WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED;

        let rez = "width=device-width";
        rez += ",initial-scale=" + (screenToSmall ? windowWidth / WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED : this.options.scale)
        rez += ",maximum-scale=" + this.options.scale;
        rez += ",user-scalable=no";
        return rez;
    }
}

export class App extends StemApp {
    static init() {
        this.initializeTheme();
        this.initializeGlobalStyle();
        this.initializeViewportMeta();
        this.addAjaxProcessors();
        this.initializeAuthentication();
        return super.init();
    }

    static initializeViewportMeta() {
        BlinkViewportMeta.create(document.head);
    }

    static initializeAuthentication() {
        authService.setLoginAppName(APP_NAME);

        // TODO: This is currently disabled because it seems to trigger
        //  worse issues than it actually solves.
        // const onWsDisconnect = () => {
        //     userService.fetchUserData(NOOP_FUNCTION, () => {
        //         authService.clearCurrentInfo();
        //         Router.changeURL("/");
        //         authService.getToken().addListenerOnce("change", () => {
        //             apiConnection.initWsConnection(authService.getToken());
        //         });
        //     });
        // };
        //
        // apiConnection.wsSubscriber.addListener("websocketError", onWsDisconnect);
        // apiConnection.wsSubscriber.addListener("websocketClosed", onWsDisconnect);

        const attemptWSConnection = () => {
            apiConnection.initWsConnection(authService.token);
        }

        attemptWSConnection();

        authService.addListener(AuthToken.EventTypes.REFRESH, attemptWSConnection);
        authService.addListener(AuthToken.EventTypes.LOGIN, attemptWSConnection);

        let wsListenerSet = false;

        authService.onAuthentication(() => {
            userService.ensureUserFetched(() => {
                if (!wsListenerSet) {
                    this.addWsListener("user-" + authService.token.getUserId() + "-events");
                    wsListenerSet = true;
                }
            });
        });
        authService.addListener(AuthToken.EventTypes.LOGOUT, () => {
            apiConnection.clearWsConnection();
            wsListenerSet = false;
        });
    }

    static addWsListener() {}

    static initializeTheme() {
        initializeTheme();
    }

    static initializeGlobalStyle() {
        GlobalStyleSheet.initialize();
    }

    getContainer() {
        return this.getRouter();
    }

    static addAjaxProcessors() {
        const ajaxHandler = apiConnection.getAjaxHandler();
        ajaxHandler.addPostprocessor(response => {
            let {error} = response;
            if (!error) {
                return response;
            }
            if (
                error &&
                (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED)
            ) {
                // If token is invalid, log out the user.
                // TODO all BLINK API errors should be available via an enum
                // TODO we should not redirect to home page inside this low level service
                authService.clearCurrentInfo();
                return null;
            }
            return response;
        });
        ajaxHandler.addPreprocessor((options) => {
            options.analytics = {requestStartTime: performance.now()};
        });
        ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {error} = response;
            const {options, request} = xhrPromise;
            let eventType = AnalyticsEventType.AJAX_REQUEST_SUCCEEDED;
            const eventProperties = {
                method: request.method,
                httpStatusCode: xhrPromise.getXHR().status,
                endpoint: (new URL(request.url)).pathname,
                duration: performance.now() - options.analytics.requestStartTime,
            };

            if (error) {
                eventType = AnalyticsEventType.AJAX_REQUEST_FAILED;
                eventProperties.errorCode = error.code;
                eventProperties.errorMessage = error.message;
            }

            dispatchAnalyticsEvent(eventType, eventProperties);
        });
    }

    onMount() {
        super.onMount();
        // This is so that the elements on ios get unfocused when tapping on the body.
        document.body.tabIndex = 1;
    }
}

PageTitleManager.setDefaultTitle("Blink");
// Click preprocessors alter the way "addClickListener" works, so it needs to be initialized before any click listener
// is added. Also, it improves the way a click is perceived on mobile devices so that it works better.
ClickPreprocessors.init();

// TODO: this should be for all our code
if (!IS_PRODUCTION || TEST_MODE) {
    // For non-production builds we insert the testId attribute directly into the DOM
    UI.Element.domAttributesMap.setAttribute("testId", {domName: "test-id"});
}

// Add some polyfills
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(pattern, replacement) {
        if (isString(pattern)) {
            pattern = new RegExp(pattern, "g");
        }
        if (this?.replace) {
            return this.replace(pattern, replacement);
        }
        return this;
	};
}
