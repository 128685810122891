import {Store, StoreObject} from "../../stem-core/src/state/Store";
import {apiConnection} from "../connection/BlinkApiConnection";
import {field} from "./misc/StoreField";
import {CycleUnit} from "./RecurringPaymentStoreObject";


export class DonationOffer extends StoreObject {
    @field("Merchant") merchant;
    @field("Currency") currency;
    @field(Number) maxPaymentAttemptsCount;
    @field(Number) paymentAttemptDelayHours;
    @field(Number) attemptsBeforeNextCycle;
    @field(Number) useCalendarPeriod;
    @field(Array) frequencyOptions;
    @field(CycleUnit) defaultFrequency;
    @field(Array) predefinedAmounts;
    @field(Number) defaultPredefinedAmountIndex;
    @field(Number) maxPredefinedAmountsSize;
    @field(Boolean) allowCustomAmount;
    @field(Boolean) collectAddress;

    shouldCollectAddress() {
        return this.collectAddress;
    }

    // TODO deprecate
    getCurrency() {
        return this.currency;
    }

    hasAmountChoice() {
        return this.allowCustomAmount || this.predefinedAmounts.length > 1;
    }

    hasFrequencyChoice() {
        return this.getFrequencyOptions().length > 1;
    }

    getFrequencyOptions() {
        return this.frequencyOptions.map(option => CycleUnit.fromValue(option));
    }

    getMaxPredefinedAmountsSize() {
        return this.maxPredefinedAmountsSize;
    }

    getName() {
        return "";
    }
}

class DonationOfferStoreClass extends Store("donationOffer", DonationOffer) {
    getAvailable() {
        return this.all().filter(offer => !offer.deletedAt);
    }

    getByMerchantId(id) {
        return this.all().filter(offer => offer.merchantId === id);
    }
}

export const DonationOfferStore = new DonationOfferStoreClass();

export async function apiUpdateDonationOffer(data) {
    return apiConnection.post("/merchant/update_donation_offer/", data);
}
