import {UI, styleRule, registerStyle, Theme} from "../../../../../../stem-core/src/ui/UI";
import {Messages} from "../../../../../Messages";

import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {BlinkButton} from "../../../../../ui/Button";
import {List} from "../../../../../ui/List";
import {AddAddressModal} from "../components/address/AddAddressModal";
import {AddressListItem} from "../components/address/AddressListItem";


export class MyAddressesStyle extends BaseCardStyle {
    @styleRule
    addNewAddressButton = {
        width: "100%",
    };

    @styleRule
    listItem = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: "normal",
        fontSize: 16,
        padding: "12px 0",
        color: this.themeProps.ADD_NEW_ADDRESS_COLOR,
    };

    @styleRule
    listElement = {
        ">:first-child": {
            marginTop: 12,
            borderTop: `1px solid ${Theme.props.DIVIDER_LINE_COLOR} !important`,
        },
        ">:last-child": {
            marginBottom: 12,
        },
    };
}

@registerStyle(MyAddressesStyle)
export class MyAddresses extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.myAddresses,
            userAddresses: []
        };
    }

    getDescription() {
        return this.options.userAddresses?.length ? "" : Messages.emptyAddressListDescription;
    }

    render() {
        return <List testId="accountAddresses" className={this.styleSheet.listElement}>
            {this.options.userAddresses?.map(address => <AddressListItem address={address}
                                                                         className={this.styleSheet.listItem}/>)}
        </List>;
    }

    renderFooter() {
        return <BlinkButton testId="accountAddAddressButton" onClick={() => AddAddressModal.show()}
                            className={this.styleSheet.addNewAddressButton}>
            {Messages.addNewAddress}
        </BlinkButton>;
    }
}
