import {UI, styleRule, styleRuleInherit, registerStyle} from "../../../../../../stem-core/src/ui/UI";

import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../Messages";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {Toast} from "../../../../../ui/Toast";
import {Dropdown} from "../../../../../ui/dropdown/Dropdown";
import {apiUserRequestPurchaseRefund} from "../../../../../../client/state/PurchaseStore.js";


class RefundModalStyle extends ConfirmationModalStyle {
    @styleRuleInherit
    modal = {
        overflow: "visible",
    };

    @styleRule
    dropdown = {
        marginTop: 18,
        marginBottom: 36,
    };
}

// TODO @branch this now takes in a purchase
@registerStyle(RefundModalStyle)
export class RefundModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "refundModal",
            title: Messages.refund,
            confirmAction: () => this.refund(),
        }
    }

    async refund() {
        const {purchase} = this.options;
        LoadingSpinner.show();
        try {
            await apiUserRequestPurchaseRefund({
                purchaseId: purchase.id,
                refundReason: this.dropdown.getValue(),
            })
            Toast.show(Messages.refundTransactionSuccess);
        } catch (error) {
            Toast.showError(error);
        } finally {
            this.hide();
            LoadingSpinner.hide();
        }
    }

    render() {
        const {styleSheet} = this;
        const refundReasons = [
            "I accidentally opened the article",
            "Title is misleading, clickbait",
            "Article is fake news",
            "Poorly written",
            "Article is overpriced",
            "Other reason"
        ];

        return (
            <Dropdown
                ref="dropdown"
                className={styleSheet.dropdown}
                options={refundReasons}
                label={Messages.selectRefundReason}
            />
        );
    }

    onMount() {
        super.onMount();
        this.confirm.setAttribute("disabled", true);
        this.dropdown.addListener("change", () => {
            this.confirm.setAttribute("disabled", false);
        });
    }
}
