import {UI} from "../../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Device} from "../../../stem-core/src/base/Device";
import {isDesktopMedium} from "../../Utils";

export class ProductCardStyle extends StyleSheet {
    clickable = {
        cursor: "pointer",
        transition: ".3s ease box-shadow",
        ":hover": {
            boxShadow: () => (isDesktopMedium() ? "" : this.themeProps.LANDING_PAGE_CARD_HOVER_SHADOW),
        },
        userSelect: "none",
    };

    sizingFunction = () => isDesktopMedium();

    @styleRule
    card = {
        background: this.themeProps.LANDING_PAGE_CARD_BACKGROUND,
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        boxShadow: () => (this.sizingFunction() ? "" : this.themeProps.LANDING_PAGE_CARD_SHADOW),
        height: () => (this.sizingFunction() ? "" :  302),
        width: () => (this.sizingFunction() ? "100%" : 300),
        padding: 21,
        textAlign: "center",
        display: "flex",
        flexDirection: () => (this.sizingFunction() ? "row" : "column"),
        borderRadius: 6,
        ...this.clickable,
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 18 : 24),
        marginBottom: () => (Device.isMobileDevice() ? 7 : 20),
    };

    @styleRule
    description = {
        fontSize: () => (Device.isMobileDevice() ? 14 : 21),
        fontWeight: "lighter",
        lineHeight: () => (Device.isMobileDevice() ? 24 : 30),
    };

    @styleRule
    icon = {
        height: () => (this.sizingFunction() ? "" : 100),
        width: () => (this.sizingFunction() ? 80 : ""),
        marginBottom: () => (this.sizingFunction() ? "" : 18),
        display: "flex",
        alignItems: "center",
        ">:first-child": {
            margin: "auto",
        },
        transform: () => (this.sizingFunction() ? "scale(.73)" : ""),
    };

    @styleRule
    textContainer = {
        flex: () => (this.sizingFunction() ? "1" : ""),
        marginLeft: () => (this.sizingFunction() ? 10 : ""),
        textAlign: () => (this.sizingFunction() ? "left" : ""),
    };
}

@registerStyle(ProductCardStyle)
export class ProductCard extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            icon: null,
            title: null,
            description: null,
            iconStyle: null,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.card);
    }

    render() {
        const {styleSheet} = this;
        const {icon, title, description, iconStyle} = this.options;

        return [
            <div className={styleSheet.icon} style={iconStyle}>
                {icon}
            </div>,
            <div className={styleSheet.textContainer}>
                <div className={styleSheet.title}>{title}</div>
                <div className={styleSheet.description}>{description}</div>
            </div>,
        ];
    }
}
