import {UI} from "../../../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../../../stem-core/src/ui/Style";

import {CreditCardIcon} from "../../../../../../ui/CreditCardIcon";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style";
import {isSmallScreen} from "../../../../../../UtilsLib";
import {MessageElement} from "../../../../../../widget/ui/MessageElement";


class CardDetailsStyle extends StyleSheet {
    @styleRule
    cardDetails = {
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    icon = {
        " svg": {
            border: "1px solid " + this.themeProps.WALLET,
            borderRadius: 2,
        },
        marginRight: () => (isSmallScreen() ? 6 : 24),
        display: "flex",
        alignItems: "baseline",
    };

    @styleRule
    cardNumber = {
        color: this.themeProps.SINGLE_CARD_NUMBER_COLOR,
    };
}

@registerStyle(CardDetailsStyle)
export class CardDetails extends UI.Element {
    getDefaultOptions() {

        return {
            ...super.getDefaultOptions(),
            card: null,
            cardDetailsMessage: "",
            iconSize: null,
            iconMargin: 0,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.cardDetails);
    }

    render() {
        const {card, cardDetailsMessage, iconSize, iconMargin} = this.options;
        const {styleSheet} = this;

        return [
            <CreditCardIcon brand={card.getBrand()} className={styleSheet.icon} size={iconSize} style={{marginRight: iconMargin}}/>,
            <MessageElement className={styleSheet.cardNumber} message={cardDetailsMessage} />
        ];
    }
}
