import {StemDate} from "../../stem-core/src/time/Date";
import {apiConnection} from "../connection/BlinkApiConnection";
import {MoneyObject} from "./misc/Money";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {PaymentMethodStore} from "./PaymentMethodStore";
import {BaseEnum, makeEnum} from "./misc/BaseEnum.js";
import {field} from "./misc/StoreField.js";

@makeEnum
export class TopUpStatus extends BaseEnum {
    static NOT_PROCESSED;
    static FAILED;
    static SUCCEEDED;
    static REVERTED;
}

export class TopUpPayment extends MoneyObject {
    @field("Merchant") merchant;
    @field("User") user;
    @field("PaymentMethod") paymentMethod;
    @field(Date) createdAt;
    @field(TopUpStatus) status;
    @field(Boolean) isAutomatic;
    // TODO @branch other fields are still required

    // TODO @branch deprecate
    getPaymentMethod() {
        return PaymentMethodStore.get(this.paymentMethodId);
    }

    canBeReverted() {
        return this.status === TopUpStatus.SUCCEEDED;
    }
}

class TopUpPaymentStoreClass extends PaymentStatusDependentStore("TopUpPayment", TopUpPayment) {
    Source = {
        PAYMENT_METHOD: "payment_method",
        CREDIT: "blink_credit",
    };

    getTransactionFromTopUpResponse(response) {
        return this.loadObjectFromResponse(response);
    }

    getSucceeded() {
        return this.filterBy({status: TopUpStatus.SUCCEEDED});
    }
}

export const TopUpPaymentStore = new TopUpPaymentStoreClass();

export async function apiUserTopUp(amount, paymentMethod, currency, userWallet) {
    const response = await apiConnection.post("/top_up_payments/top_up/", {
        amount,
        paymentMethodId: paymentMethod.id,
        currencyId: currency.id,
        walletId: userWallet.id,
    });
    return TopUpPaymentStore.loadObjectFromResponse(response);
}
