import {UI} from "../../stem-core/src/ui/UIBase";
import {iFrameConnection} from "../services/IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {iFrameState} from "../services/IFrameState";
import {GATE_TYPE, POPUP_GATE_TRANSITION, POPUP_GATE_TRANSITION_TIME} from "../../blink-sdk/Constants";
import {BlinkGlobal, isSmallScreen} from "../UtilsLib";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../stem-core/src/decorators/Style";
import {StyleSheet} from "../../stem-core/src/ui/Style";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {delayThrottled} from "../../blink-sdk/utils/Utils";


export class PanelPageStyle extends StyleSheet {
    @styleRule
    panelPage = {
        fontFamily: this.themeProps.MERCHANT_FONT_FAMILY,
        color: this.themeProps.MERCHANT_TEXT_PRIMARY_COLOR,
        top: 0,
        margin: "auto",
    };

    @styleRule
    visibilityTransition = {
        transition: POPUP_GATE_TRANSITION,
    };

    @styleRule
    visible = {
        opacity: 1,
    };

    @styleRule
    hidden = {
        opacity: 0,
    };
}

let lastDispatchedHeight = null;
let lastDispatchedWidth = null;
let lastDispatchedElement = null;

@registerStyle(PanelPageStyle)
export class PanelPage extends UI.Element {
    previouslyHidden = false;
    hidden = false;
    inTransition = false;
    recomputeOnTransitionEnd = false;

    getMaxHeight() {
        const screenHeight = iFrameState.screen.height;
        return isSmallScreen() ? screenHeight - 40 : screenHeight;
    }

    getMaxWidth() {
        if (iFrameState.gateType === GATE_TYPE.banner) {
            return "100%";
        }
        if (iFrameState.gateType !== GATE_TYPE.popup && window.innerWidth) {
            return window.innerWidth;
        }
        const screenWidth = iFrameState.screen.width;
        return isSmallScreen() ? screenWidth - 20 : screenWidth;
    }

    applyHiddenStyle(attr) {
        if (this.hidden) {
            attr.removeClass(this.styleSheet.visible)
            attr.addClass(this.styleSheet.hidden);
            if (this.widthValue) {
                attr.setStyle("width", this.widthValue);
            }
            if (this.heightValue) {
                attr.setStyle("height", this.heightValue);
            }
        } else {
            attr.removeClass(this.styleSheet.hidden)
            attr.addClass(this.styleSheet.visible);
            attr.setStyle({
                width: "100%",
            });
            if (this.heightValue) {
                attr.setStyle({
                    minHeight: "100%",
                })
            }
        }

        if (this.hidden !== this.previouslyHidden) {
            clearTimeout(this.transitionTimeout);
            this.inTransition = true;
            this.transitionTimeout = this.attachTimeout(() => {
                this.inTransition = false;
                if (this.recomputeOnTransitionEnd) {
                    this.recomputeOnTransitionEnd = false;
                    this.dispatchSizeChange();
                }
            }, POPUP_GATE_TRANSITION_TIME)
        }

        this.previouslyHidden = this.hidden;
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.setStyle("maxWidth", this.getMaxWidth());
        attr.setStyle("maxHeight", this.getMaxHeight());
        attr.addClass(this.styleSheet.panelPage);
        attr.addClass(this.styleSheet.visibilityTransition);
        this.applyHiddenStyle(attr);
        if (!iFrameState.panelContent || iFrameState.gateType !== GATE_TYPE.banner) {
            attr.setStyle({
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%",
            });
        }
    }

    dispatchSizeChange(bypassTransition=false) {
        if (this.isInDocument() && !this.inactive) {
            // Currently we dispatch a size change only if it is actually a change. Otherwise, it might create some
            // code loops.
            const height = Math.min(this.heightValue || this.getHeight(), this.getMaxHeight());
            const width = iFrameState.gateType === GATE_TYPE.banner ? "100%" :
                Math.min(this.widthValue || this.getWidth(), this.getMaxWidth());

            if (bypassTransition && this.inTransition) {
                this.recomputeOnTransitionEnd = true;
                return;
            }

            if (this.inTransition && lastDispatchedElement === this) {
                return;
            }

            if (height === lastDispatchedHeight && width === lastDispatchedWidth) {
                return;
            }

            lastDispatchedElement = this;
            lastDispatchedHeight = height;
            lastDispatchedWidth = width;
            BlinkGlobal.appInstance.sizeChange.dispatch({height, width});
            iFrameConnection.sendToSDK(IFrameMessages.UPDATE_PANEL_SIZE, {height, width});
        }
    }

    preRender(node) {
        this.hidden = true;
        this.mount(node);
        this.dispatchSizeChange();
        this.preRenderTimeout = this.attachTimeout(() => {
            this.hidden = false;
            this.redraw();
            this.setStyle("zIndex", 2);
        })
    }

    cancelPreRender() {
        this.hidden = false;
        this.removeClass(this.styleSheet.visibilityTransition);
        clearTimeout(this.preRenderTimeout);
        this.setStyle("zIndex", "");
    }

    onMount() {
        super.onMount();
        this.inactive = false;

        this.attachTimeout(() => {
            if (!this.hidden) {
                this.redraw();
                this.dispatchSizeChange();
            }
            this.attachUpdateListener(iFrameState, delayThrottled(() => this.redraw(), 50));
        });

        this.addListener("urlExit", () => {
            this.inactive = true;
            this.hidden = true;
            this.setStyle("minHeight", "100%");
        });

        this.attachListener(Dispatcher.Global, "iframeSizeChange", () => this.dispatchSizeChange(true));
        this.attachTimeout(() => {
            this.attachListener(iFrameConnection, IFrameMessages.REQUEST_UPDATE_PANEL_SIZE, () => {
                if (iFrameState.panelContent) {
                    this.setStyle("width", "");
                    this.dispatchSizeChange();
                    this.setStyle("width", "100%");
                } else {
                    this.dispatchSizeChange();
                }
            });

            // TODO @branch do we attach a new observer on every transition timeout? WTF?
            const observer = new MutationObserver(() => this.dispatchSizeChange());
            observer.observe(this.node, {
                childList: true,
                subtree: true,
                characterData: true,
                attributes: true,
            });
        }, POPUP_GATE_TRANSITION_TIME + 16);

        this.attachTimeout(() => {
            this.dispatchSizeChange(true);
        }, POPUP_GATE_TRANSITION + 1000);
    }
}
