import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {BaseEnum, makeEnum} from "./misc/BaseEnum.js";
import {field} from "./misc/StoreField.js";


@makeEnum
export class PaymentProcessorType extends BaseEnum {
    static BLINK_CREDIT;
    static STRIPE;
    static ADYEN;
    static CDS;
}

class PaymentProcessor extends StoreObject {
    @field(PaymentProcessorType) provider;
    @field(String) publicKey;
    @field(Boolean) isPublic;
}

export const PaymentProcessorStore = MakeStore("PaymentProcessor", PaymentProcessor);
