import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {DonationStore} from "../../client/state/DonationStore";
import {iFrameMerchantService} from "./IframeMerchantService";
import {SubscriptionStore} from "../../client/state/SubscriptionStore";
import {userService} from "../../client/connection/Services";
import {AsyncAggregateDispatcher} from "../AsyncAggregateDispatcher";
import {MerchantAudienceMemberStore} from "../../client/state/merchant/MerchantAudienceMemberStore";
import {PaymentMethodStore, UserAddressStore} from "../State";
import {MerchantAudienceStore} from "../../client/state/merchant/MerchantAudienceStore";


function extractMerchantEntries(entries) {
    const merchantId = iFrameMerchantService.merchantId;
    return entries.filter(e => e.merchantId === merchantId);
}

function extractLatestRecurringPayment(recurringPayments) {
    const userId = userService.getUserId();

    recurringPayments = extractMerchantEntries(recurringPayments).filter(rp => rp.userId === userId);
    if (!recurringPayments.length) {
        return null;
    }

    return recurringPayments.sort((a, b) => b.createdAt - a.createdAt)[0];
}

// TODO: Merge most of the logic from IFrameBlinkService and multi-store-dependent-state here.
class IFrameUserDataService extends Dispatchable {
    userAddressTotalCount = null;
    paymentMethodPublicCount = null;
    paymentMethodPrimaryExists = null;

    constructor() {
        super();

        this.addCleanupJob(new AsyncAggregateDispatcher({
            listener: () => this.dispatch("update"),
            changeDispatchers: [
                DonationStore,
                SubscriptionStore,
            ],
        }));

        userService.addListener("userDataFetched", response => {
            this.userAddressTotalCount = response.userAddressTotalCount;
            this.paymentMethodPublicCount = response.paymentMethodPublicCount;
            this.paymentMethodPrimaryExists = response.paymentMethodPrimaryExists;
            this.canEditProfile = response.canEditProfile;
        });
    }

    getExtraPaymentMethodCount() {
        if (!this.paymentMethodPublicCount) {
            return 0;
        }
        return Math.max(this.paymentMethodPublicCount - PaymentMethodStore.getAvailablePaymentMethods().length, 0);
    }

    getExtraUserAddressCount() {
        if (!this.userAddressTotalCount) {
            return 0;
        }
        return Math.max(this.userAddressTotalCount - UserAddressStore.all().length, 0);
    }

    hasPrimaryPaymentMethod() {
        return this.paymentMethodPrimaryExists;
    }

    getLatestDonation() {
        return extractLatestRecurringPayment(DonationStore.all());
    }

    getLatestOneTimeDonation() {
        return extractLatestRecurringPayment(DonationStore.getOneTime());
    }

    getActiveRecurringDonation() {
        return extractLatestRecurringPayment(DonationStore.filter(
            donation => donation.isRecurring() && donation.isActive() && !donation.isActiveButCanceled())
        );
    }

    getActiveSubscription() {
        return extractLatestRecurringPayment(SubscriptionStore.getActive());
    }

    getGiftSentActiveSubscriptions() {
        return extractMerchantEntries(SubscriptionStore.getActive())
            .filter(s => s.metadata?.gift && s.billedUserId === userService.getUserId());
    }

    getGiftReceivedActiveSubscriptions() {
        return extractMerchantEntries(SubscriptionStore.getActive())
            .filter(s => s.metadata?.gift && s.billedUserId !== userService.getUserId());
    }

    getMerchantUser() {
        return userService.getMerchantUser(iFrameMerchantService.getMerchant());
    }

    isAutoPayEnabled() {
        return userService.isAutoPayEnabledForMerchant(iFrameMerchantService.getMerchant());
    }

    isUserSubscribedToAllGivenNewsletters() {
        return !MerchantAudienceStore.all().find(
            audience => !MerchantAudienceMemberStore.all().find(
                audienceMember => audienceMember.audience === audience))
    }
}

export const iFrameUserDataService = new IFrameUserDataService();
