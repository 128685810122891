import {UI} from "stem-core/src/ui/UI";
import {GetExternalAuthClient} from "./ExternalAuthConfigs.jsx";
import {AuthHelper} from "../../../AuthHelper";
import {getQueryParam} from "../../../UtilsLib";
import {socialAuthService} from "../../../services/SocialAuthService";


// This is the redirect page with the oauth token from the client url.
// The external provider sends us here.
export class LoginRedirectCallback extends UI.Element {
    setURL(urlParts) {
        const state = getQueryParam("state");
        if (state && state.merchantId) {
            socialAuthService.setMerchantId(state.merchantId);
        }

        try {
            const oauthClient = GetExternalAuthClient(urlParts[0]);
            const urlParams = new URL(window.location.href).searchParams;
            AuthHelper.oauthHandleLoginRedirect(oauthClient, urlParams);
        } catch (e) {
            console.error("Login error", e);
            AuthHelper.oauthHandleError();
        }
    }
}
