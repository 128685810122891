import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {apiRequestUserEmailChange, apiUpdateUserProfile, UserProfileStore} from "../../state/UserProfileStore";
import {NOOP_FUNCTION, unwrapArray} from "../../../stem-core/src/base/Utils";
import {apiConnection} from "../BlinkApiConnection";
import {executeWhenDocumentVisible, isFacebookBrowser} from "../../../blinkpay/Utils";
import {authService, AuthService} from "./AuthService";
import {queueMicrotaskWrapper} from "../../../blinkpay/AsyncAggregateDispatcher";
import {ApiErrors} from "../ApiErrors";
import {Messages} from "../../../blinkpay/Messages";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {MerchantUserStore} from "../../state/merchant/MerchantUserStore";
import {Toast} from "../../../blinkpay/ui/Toast.jsx";


class UserService extends Dispatchable {
    finishedUserDataRequest = false;
    initiatedUserDataRequest = false;
    userData = null;

    constructor() {
        super();
        authService.addListener(AuthService.EventTypes.CLEAR_CURRENT_INFO, () => this.clearUserInfo());
        authService.addListener(AuthService.EventTypes.USER_DATA_AVAILABLE, (response) => {
            this.setUserData(response);
        });
    }

    getUser() {
        return UserProfileStore.getUserProfile();
    }

    getUserId() {
        return this.getUser()?.id;
    }

    getUserName() {
        const {firstName, lastName} = this.getUser();
        return unwrapArray([firstName, lastName]).join(" ");
    }

    getUserEmail() {
        return this.getUser()?.getEmail();
    }

    isUserFetched() {
        return this.getUser() != null;
    }

    getMerchantUser(merchant) {
        return MerchantUserStore.getByUserAndMerchant(this.getUserId(), merchant.id);
    }

    isAutoPayEnabledForMerchant(merchant) {
        const merchantUser = this.getMerchantUser(merchant);
        const userPreferences = this.getUser()?.getPreferences();
        if (!userPreferences?.autoPayEnabled) {
            return false;
        }
        return merchantUser?.autoPayEnabled ?? true;
    }

    setUserData(userData) {
        this.initiatedUserDataRequest = true;
        this.userData = userData;
        this.dispatch("userDataFetched", this.userData);
        this.finishedUserDataRequest = true;
        this.dispatch("userDataRequestFinished");
    }

    async forceFetchUserData() {
        this.initiatedUserDataRequest = true;
        try {
            this.userData = await apiConnection.get("/user_data/");
            this.dispatch("userDataFetched", this.userData);
        } catch(e) {
            this.userData = null;
            if (e.code === ApiErrors.AUTHENTICATION_FAILED || e.code === ApiErrors.NOT_AUTHENTICATED) {
                authService.clearCurrentInfo();
            } else {
                throw e;
            }
        } finally {
            this.finishedUserDataRequest = true;
            this.dispatch("userDataRequestFinished");
        }
    }

    fetchUserData() {
        // Sometimes a request is blocked if the tab isn't the active one, so we execute the fetch only when the tab is
        // active.
        executeWhenDocumentVisible(() => this.forceFetchUserData());

        // TODO: Facebook browser caches all first requests for a given URL. That is why we need here to make another
        //  request so that the real data gets fetched; perhaps we can avoid this by using a POST instead of a GET?
        if (isFacebookBrowser()) {
            setTimeout(() => this.forceFetchUserData(), 1000);
        }
    }

    onUserFetched(callback) {
        return this.addListenerOnce("userDataFetched", callback);
    }

    onUserDataRequestFinished(callback) {
        return this.addListenerOnce("userDataRequestFinished", callback);
    }

    isUserDataRequestFinished() {
        return this.isUserFetched() || this.finishedUserDataRequest;
    }

    ensureUserFetched(callback = NOOP_FUNCTION) {
        if (this.isUserFetched()) {
            callback();
            return;
        }
        this.onUserFetched(callback);

        if (!this.initiatedUserDataRequest) {
            this.fetchUserData();
        }
    }

    ensureUserDataRequested(callback = NOOP_FUNCTION) {
        if (this.isUserDataRequestFinished()) {
            queueMicrotaskWrapper(callback);
            return;
        }
        this.onUserDataRequestFinished(() => queueMicrotaskWrapper(callback));

        if (!this.initiatedUserDataRequest) {
            this.fetchUserData();
        }
    }

    clearUserInfo() {
        this.finishedUserDataRequest = false;
        this.initiatedUserDataRequest = false;
    }

    async requestUpdateUserData(newName, newEmail) {
        const currentName = this.getUserName().trim();
        newName = (newName || "").trim();
        LoadingSpinner.show();
        try {
            if (currentName !== newName) {
                const [firstName, ...lastName] = (newName || "").split(" ");
                try {
                    await apiUpdateUserProfile({firstName, lastName: lastName.join(" ")});
                } catch (error) {
                    Toast.showError(error, Messages.errorWhileSaving);
                    return false;
                }
            }
            const currentEmail = this.getUserEmail().trim();
            newEmail = (newEmail || "").trim();
            if (currentEmail !== newEmail) {
                try {
                    await apiRequestUserEmailChange({newEmail});
                    return true;
                } catch (error) {
                    Toast.showError(error, Messages.errorWhileSaving);
                }
            }
            return false;
        } finally {
            LoadingSpinner.hide();
        }
    }
}

export const userService = new UserService();
