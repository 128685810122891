import {Store} from "../../stem-core/src/state/Store";
import {StemDate} from "../../stem-core/src/time/Date";
import {Money, MoneyObject} from "./misc/Money";
import {WalletStore} from "./WalletStore";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {TopUpPaymentStore} from "./TopUpPaymentStore";
import {apiConnection} from "../connection/BlinkApiConnection";
import {BaseEnum, makeEnum} from "./misc/BaseEnum";
import {field} from "./misc/StoreField";


// TODO: standardize enums
export const PaymentType = Object.freeze({
    PURCHASE: "purchase",
    SUBSCRIPTION: "subscription",
    DONATION: "donation",
});

// TODO use this and uncomment @field(PaymentStatus)
@makeEnum
class PaymentStatus extends BaseEnum {
    static SUCCEEDED;
    static REVERTED;
}

export class Payment extends MoneyObject {
    @field("Merchant") merchant;
    @field(Date) createdAt;
    //@field(PaymentStatus) status;  TODO not used right now since we need to go through usage

    getMerchant() {
        return this.merchant;
    }

    getComment() {
        return this.comment && JSON.parse(this.comment);
    }

    getTitle() {
        return this.getComment()?.title || "";
    }

    getUrl() {
        return this.getComment()?.url || "";
    }

    getDebitedAmount() {
        // TODO: use this.debitedCurrencyId instead of wallet currency id when it is retrieved by the API
        const walletCurrency = WalletStore.getWallet()?.getCurrency();
        return new Money(this.debitedAmount, walletCurrency);
    }

    getType() {
        return this.resourceType;
    }

    // TODO: rename this and refactor or remove
    // TODO: implement isRecurring() method
    getCategory() {
        if (this.getType() === PaymentType.DONATION) { // TODO: unify and standardize enums
            if (this.isOneTime) {
                return "One-time";
            } else {
                return "Recurring";
            }
        }
        return null;
    }

    // This is arbitrary chosen.
    isRefundable() {
        return this.isAutomatic && StemDate.now().diff(this.createdAt) < TimeUnit.DAY;
    }

    getTopUpPayment() {
        return TopUpPaymentStore.get(this.topUpId);
    }

    // Return the payment method used by the top-up, if this payment triggered a top-up
    getPaymentMethod() {
        const topUpPayment = this.getTopUpPayment();
        if (topUpPayment) {
            return topUpPayment.getPaymentMethod();
        }
        if (this.resourceType === "donation" || this.resourceType === "subscription") {
            const resource = this.getStore(this.resourceType).get(this.resourceId);
            if (resource && resource.preferredPaymentMethodId) {
                return resource.getPaymentMethod();
            }
        }
        return null;
    }

    isRefunded() {
        return this.status === "reverted"; // TODO @branch Damn enums
    }
}

class PaymentStoreClass extends Store("payment", Payment, {dependencies: ["TopUpPayment", "PaymentMethod", "Wallet"]}) {
    API_LIST_ENDPOINT = "/payments/list_payments";

    ResourceType = PaymentType;
    // TODO @branch make enum
    Status = {
        SUCCEEDED: "succeeded",
        REVERTED: "reverted",
    };

    getSucceeded() {
        return this.filterBy({status: this.Status.SUCCEEDED});
    }
}

export const PaymentStore = new PaymentStoreClass();
