import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {CreditCardIcon} from "../ui/CreditCardIcon";
import {Messages} from "../Messages";
import {capitalize} from "../Utils";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../stem-core/src/decorators/Style";
import {MessageElement} from "../widget/ui/MessageElement";
import {iFrameMerchantService} from "blinkpay/services/IframeMerchantService";


class ActiveCardInfoStyle extends StyleSheet {
    @styleRule
    element = {
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    usingCardText = {
        display: "inline-block",
        alignSelf: "flex-end",
    };

    @styleRule
    cardIcon = {
        display: "inline-block",
        marginRight: 12,
    };
}

@registerStyle(ActiveCardInfoStyle)
export class ActiveCardInfo extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hideIcon: false,
            card: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
    }

    render() {
        const {styleSheet} = this;
        const {card, hideIcon} = this.options;

        if (!card) {
            return null;
        }

        if (iFrameMerchantService.isCDSPaymentMethod()) {
            if (!card.cardNumber) {
                return null;
            }
            return [
                hideIcon ? null : <CreditCardIcon className={styleSheet.cardIcon} size={15}/>,
                <MessageElement className={styleSheet.usingCardText} message={Messages.usingCardSubscribe("CARD", card.cardNumber.slice(-4))}/>,
            ]
        }

        return [
            hideIcon ? null : <CreditCardIcon className={styleSheet.cardIcon} brand={card.getBrand()} size={15}/>,
            <MessageElement className={styleSheet.usingCardText}
                            message={Messages.cardComponentUsingCard(capitalize(card.getBrand()), card.getLastDigits())} />
        ]
    }

    isValid() {
        return true;
    }
}
