export const WidgetRoutes = {
    INDEX_COLLAPSED: "empty", // We use this because it is identical to the empty route in panel. (QA testing purposes)
    INDEX_EXPANDED: "widget-index-expanded",
    ADD_FUNDS: "widget-add-funds",
    AUTOPAY: "widget-autopay",
    ADD_CARD: "widget-add-card",
    CHOOSE_CARD: "widget-choose-card",
    WELCOME: "widget-welcome",
    INSUFFICIENT_FUNDS: "widget-insufficient-funds",
};

// Note: If you add any new route here, make sure to update WidgetCloseButton
