import {apiClient} from "../../connection/BlinkApiConnection";
import {loadVersionedObjectFromResponse} from "../misc/StoreUtils";
import {MerchantSDKResource, MerchantSDKResourceStoreClass} from "../misc/MerchantSDKResource.js";
import {toArray} from "../../../stem-core/src/base/Utils.js";


export class MerchantUserJourney extends MerchantSDKResource {
    getActions() {
        return toArray(this.options?.action);
    }

    usesPanel(panel) {
        for (const action of this.getActions()) {
            if (action.panel == panel.id || action.panel == panel.alias) {
                return true;
            }
        }
        return false;
    }
}

export async function apiCreateMerchantUserJourney(data) {
    const response = await apiClient.post("/merchant/create_user_journey", data);
    return loadVersionedObjectFromResponse(MerchantUserJourneyStore, response, null);
}

export async function apiEditMerchantUserJourney(data) {
    const response = await apiClient.post("/merchant/edit_user_journey", data);
    return loadVersionedObjectFromResponse(MerchantUserJourneyStore, response, data.userJourneyId);
}

export async function apiDeleteMerchantUserJourney(data) {
    return apiClient.post("/merchant/delete_user_journey", data);
}

export const MerchantUserJourneyStore = new MerchantSDKResourceStoreClass("MerchantUserJourney", MerchantUserJourney, ["MerchantPanel"]);
