import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {Router} from "../../../stem-core/src/ui/Router";
import {PaymentMethodStore} from "../../State";
import {iFrameMerchantService} from "../../services/IframeMerchantService";
import {encryptJSON} from "../../../client/crypto/encryptJSON";
import {authService} from "../../../client/connection/services/AuthService";
import {ApiErrors} from "../../../client/connection/ApiErrors";
import {Messages} from "../../Messages.js";


export const CARD_SECTION_STATE = {
    chooseCard: "chooseCard",
    payWithAddedCard: "payWithAddedCard",
    usingCard: "usingCard",
    addCard: "addCard",
};

/* TODO: These controllers can be made smarter by having an "init(params)" that applies all the params and defaults the
    rest of them. However, since such a controller is always updated and cleared, it can have the wrong properties while
    not in a flow, but it doesn't bother us.
*/
// TODO: If the selected payment method gets deleted, this won't update. Do something about it at some point.
export class PaymentMethodController extends Dispatcher {
    cardHolderName = "";
    cardSectionState = null;
    initialPaymentMethod = null;
    selectedPaymentMethod = null;
    showSaveCardCheckbox = true; // If you want to allow an iframe option to override this, track where this variable is used
    entry = null;
    savePaymentMethod = true;
    getPayWithAddedCardButtonLabel = () => Messages.finalizePayment;
    getChooseCardButtonLabel = () => Messages.finalizePayment;
    onSelectSuccess = NOOP_FUNCTION;
    onSelectCancel = () => Router.back();
    retryPayment = null;
    getScope = NOOP_FUNCTION;
    paymentError = null;

    async extractPaymentMethodFields() {
        if (iFrameMerchantService.isCDSPaymentMethod()) {
            const {cardNumber, cardExpiry, cardCvv} = (this.selectedPaymentMethod || {});
            return {
                paymentInfo: await encryptJSON({
                    card_number: cardNumber,
                    card_expiry: cardExpiry,
                    card_cvv: cardCvv,
                }, authService.appSettings.blinkPaymentMethodEncryptionPublicKey),
            }
        }
        return {
            preferredPaymentMethodId: this.selectedPaymentMethod?.id,
        }
    }

    constructor(options={}) {
        super(options);

        this.update(options);
    }

    update(props) {
        Object.assign(this, props);
        this.dispatch();
    }

    getInitialPaymentMethod() {
        return this.initialPaymentMethod || (iFrameMerchantService.isCDSPaymentMethod() ? null : PaymentMethodStore.getPrimary());
    }

    getPaymentErrorMessage() {
        if (this.paymentError && this.paymentError.code === ApiErrors.CDS_NEW_ORDER_ERROR) {
            return "";
        }
        return this.paymentError?.message || "";
    }

    isCDSPaymentError() {
        return this.paymentError?.code >= 9000 && this.paymentError?.code <= 9100;
    }
}
