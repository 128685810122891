import {AuthHelper} from "./AuthHelper";
import {authService} from "../client/connection/Services";
import {DonationOfferStore, PaymentMethodStore} from "./State";
import {apiConnection} from "../client/connection/BlinkApiConnection";
import {apiCancelDonation} from "../client/state/DonationStore";
import {apiCancelSubscription, apiSubscribe} from "../client/state/SubscriptionStore";
import {Flow} from "./panel/initializeFlows";
import {Controller} from "./panel/Controller";
import {PaymentProcessorStore} from "../client/state/PaymentProcessorStore.js";


class AutomationHelperClass {
    async authenticate(userDetails = {email: "", firstName: "", lastName: ""}) {
        const automationEmailCode = "434343";
        const payload = {
            ...userDetails,
            withSignup: true,
            secure: false,
        };
        const response = await AuthHelper.requestLoginCode(payload);
        if (response.token) {
            return response;
        }
        return AuthHelper.loginWithEmailCode(automationEmailCode);
    }

    async addCard(cardDetails) {
        const request = {
            paymentProcessorId: PaymentProcessorStore.findBy({isPublic: true}).id,
            ...cardDetails,
        }
        return apiConnection.post("/payment_methods/dev_add_payment_method", request);
    }

    async donate(payload) {
        const donationOffer = DonationOfferStore.all().find(offer => offer.id === payload.recurringOfferId);
        if (payload.preferredPaymentMethodId != null) {
            Controller.paymentMethod.update({
                selectedPaymentMethod: PaymentMethodStore.get(payload.preferredPaymentMethodId)
            });
        }
        if (!donationOffer) {
            throw new Error(`Donation offer with id ${payload.recurringOfferId} not found.`);
        }
        Flow.donation.setPayload(payload);
        return Flow.donation.donate();
    }

    async cancelDonation(donationId) {
        return apiCancelDonation(donationId);
    }

    async subscribe(subscriptionDetails) {
        return apiSubscribe(subscriptionDetails);
    }

    async cancelSubscription(subscriptionId) {
        return apiCancelSubscription(subscriptionId);
    }

    async addAddress(addressDetails) {
        return apiConnection.post("/user_addresses/create", addressDetails);
    }

    async logout() {
        return authService.logout();
    }
}

export const AutomationHelper = new AutomationHelperClass();
window.AutomationHelper = AutomationHelper;
