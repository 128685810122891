import {UI} from "../../../../../stem-core/src/ui/UI";

import {TopUpHistory} from "./sections/TopUpHistory";
import {AddFundsCard} from "./sections/AddFundsCard";


export class WalletPage extends UI.Element {
    render() {
        return [
            <AddFundsCard testId="walletAddFunds" />,
            <TopUpHistory testId="walletCardCharges" />,
        ];
    }
}
