import {UI} from "../../stem-core/src/ui/UIBase";

import {styleRuleInherit} from "../../stem-core/src/ui/Style";
import {Device} from "../../stem-core/src/base/Device";
import {WalletButton, WalletButtonStyle} from "../widget/ui/WalletButton";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";


export class MerchantButtonStyle extends WalletButtonStyle {
    @styleRuleInherit
    blinkButton = {
        color: this.themeProps.MERCHANT_BUTTON_PRIMARY_TEXT_COLOR,
        background: this.themeProps.MERCHANT_BUTTON_PRIMARY_BACKGROUND,
        border: () => "1px solid " + this.themeProps.MERCHANT_BUTTON_PRIMARY_BORDER_COLOR,
        ":hover": {
            background: this.themeProps.MERCHANT_BUTTON_PRIMARY_BACKGROUND_HOVER,
            color: this.themeProps.MERCHANT_BUTTON_PRIMARY_TEXT_COLOR_HOVER,
            borderColor: this.themeProps.MERCHANT_BUTTON_PRIMARY_BORDER_COLOR_HOVER,
        },
        ":disabled": {
            color: this.themeProps.MERCHANT_BUTTON_PRIMARY_INACTIVE_TEXT_COLOR,
            cursor: "not-allowed",
            pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
        },
        ":disabled:hover": {
            background: this.themeProps.MERCHANT_BUTTON_PRIMARY_BACKGROUND,
            borderColor: this.themeProps.MERCHANT_BUTTON_PRIMARY_BORDER_COLOR,
        },
        boxShadow: "none",
        textShadow: "none",
        padding: "12px !important",
        textAlign: "center",
        display: "block",
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        ...this.themeProps.MERCHANT_BUTTON_PRIMARY_STYLE,
    };
}

@registerStyle(MerchantButtonStyle)
export class MerchantButton extends WalletButton {}


class WalletMerchantButtonStyle extends WalletButtonStyle {
    @styleRuleInherit
    blinkButton = {
        background: this.themeProps.WALLET_MERCHANT_BUTTON_BACKGROUND,
        ":hover": {
            background: this.themeProps.WALLET_MERCHANT_BUTTON_BACKGROUND,
        },
        ":disabled": {
            color: this.themeProps.WALLET_BUTTON_DISABLED_COLOR,
            cursor: "not-allowed",
            pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
        },
        boxShadow: "none",
        textShadow: "none",
        padding: "12px !important",
        textAlign: "center",
        display: "block",
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
    };
}

@registerStyle(WalletMerchantButtonStyle)
export class WalletMerchantButton extends WalletButton {}
