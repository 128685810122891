import {StoreObject, Store} from "../../stem-core/src/state/Store";
import {StemDate} from "../../stem-core/src/time/Date";
import {apiConnection} from "../connection/BlinkApiConnection";
import {capitalize} from "../../blinkpay/Utils";
import {field} from "./misc/StoreField.js";


export class PaymentMethod extends StoreObject {
    @field("PaymentProcessor") paymentProcessor;
    @field(Date) createdAt;
    @field(Date) deletedAt;
    @field(Boolean) isPrimary;
    @field(Object) details;
    @field(String) scope; // TODO Use enum

    getLastDigits() {
        const {last4} = this.details;
        return last4;
    }

    getMaskedDigits() {
        return "**** " + this.getLastDigits();
    }

    getBrand() {
        let brand = this.details.brand || "Card";
        brand = brand.toLowerCase();
        if (brand === "american express") {
            // American Express is too long in string messages and breaks some UI elements
            brand = "AmEx";
        }
        brand = capitalize(brand);
        return brand;
    }

    getExpiryDate() {
        const {expMonth, expYear} = this.details;
        // TODO @Mihai end of month?
        return new StemDate(expMonth + "/01/" + expYear);
    }

    isAvailable() {
        return !this.deletedAt && this.scope === PaymentMethodStore.Scope.PUBLIC;
    }
}

class PaymentMethodStoreClass extends Store("PaymentMethod", PaymentMethod) {
    // TODO @cleanup make this enum
    Scope = {
        PUBLIC: "public",
        PRIVATE: "private",
    };

    getPaymentMethodsForRecurringPayment(recurringPayment) {
        // TODO @payments: This is wrong. What if recurringPayment is a DONATION and the payment
        //  method was used for a SUBSCRIPTION with the same ID?
        return this.all().filter(paymentMethod => {
            return !paymentMethod.deletedAt && paymentMethod.scope === PaymentMethodStore.Scope.PRIVATE
            && (paymentMethod.scopeResourceId === recurringPayment.id || paymentMethod.scopeResourceId == null)
        });
    }

    getPrimary() {
        return this.getAvailablePaymentMethods().find(paymentMethod => paymentMethod.isPrimary);
    }

    getAvailablePaymentMethods() {
        const paymentMethods = this.all().filter(paymentMethod => paymentMethod.isAvailable());
        const primaryPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isPrimary);
        const nonPrimaryPaymentMethods = paymentMethods.filter(paymentMethod => !paymentMethod.isPrimary);

        return primaryPaymentMethod ? [primaryPaymentMethod, ...nonPrimaryPaymentMethods] : nonPrimaryPaymentMethods;
    }

    applyDeleteEvent(event) {
        const deletedObj = this.getObjectForEvent(event);
        if (!deletedObj) {
            return;
        }
        if (!deletedObj.deletedAt) {
            deletedObj.deletedAt = StemDate.now();
        }
        deletedObj.dispatch("delete", event, deletedObj);
        this.dispatch("delete", deletedObj, event);
    }
}

export const PaymentMethodStore = new PaymentMethodStoreClass();

export async function apiAddPaymentMethodToken(token, scope, paymentProcessor) {
    const response = await apiConnection.post("/payment_methods/add_payment_method/", {
        tokenId: token.id,
        scope,
        rawToken: JSON.stringify(token),
        paymentProcessorId: paymentProcessor.id,
    });
    return PaymentMethodStore.loadObjectFromResponse(response);
}

export async function apiSetPrimaryPaymentMethod(paymentMethodId) {
    const response = await apiConnection.post("/payment_methods/set_primary_payment_method/", {paymentMethodId});
    return PaymentMethodStore.loadObjectFromResponse(response);
}

export async function apiDeletePaymentMethod(paymentMethodId) {
    return apiConnection.post("/payment_methods/delete_payment_method/", {paymentMethodId});
}
