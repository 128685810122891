import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiConnection} from "../../connection/BlinkApiConnection";
import {BaseEnum, makeEnum} from "../misc/BaseEnum";

@makeEnum
export class MerchantAudienceMemberStatus extends BaseEnum {
    static SELF_SUBSCRIBED;
    static MANUALLY_SUBSCRIBED;
    static AUTO_SUBSCRIBED;
    static SELF_UNSUBSCRIBED;
    static MANUALLY_UNSUBSCRIBED;
    static AUTO_UNSUBSCRIBED;
}

export const ProperSubscribedStatuses = [MerchantAudienceMemberStatus.SELF_SUBSCRIBED, MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED];
export const SubscribedStatuses = [...ProperSubscribedStatuses, MerchantAudienceMemberStatus.AUTO_SUBSCRIBED];
export const ProperUnsubscribedStatuses = [MerchantAudienceMemberStatus.SELF_UNSUBSCRIBED, MerchantAudienceMemberStatus.MANUALLY_UNSUBSCRIBED];
export const UnsubscribedStatuses = [...ProperUnsubscribedStatuses, MerchantAudienceMemberStatus.AUTO_UNSUBSCRIBED];
export const ProperStatuses = [...ProperSubscribedStatuses, ...ProperUnsubscribedStatuses];

export class MerchantAudienceMember extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantAudience") audience;
    @field("UserProfile") user;
    @field(MerchantAudienceMemberStatus) status;
    @field(Date) unsubscribedAt;
    @field(Date) subscribedAt;
    @field(Date) createdAt;

    @field("MerchantConversion") conversion;

    isSubscribed() {
        return SubscribedStatuses.indexOf(this.status) >= 0;
    }

    isManuallyAdded() {
        return this.status === MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED;
    }
}

export const MerchantAudienceMemberStore = MakeStore("MerchantAudienceMember", MerchantAudienceMember);

export async function apiGetNewsletterSubscriptions(request, noAuthToken=false) {
    return apiConnection.get("/users/get_newsletter_subscriptions/", request, {noAuthToken});
}

export const NewsletterSubscriptionUpdateType = {
    SUBSCRIBE: "subscribe",
    UNSUBSCRIBE: "unsubscribe",
    REPLACE: "replace",
}

export async function apiUserUpdateNewsletterSubscriptions(request, noAuthToken=false) {
    return apiConnection.post("/users/update_newsletter_subscriptions/", request, {noAuthToken});
}

export async function apiMerchantCreateAudienceMembers(request) {
    return apiMerchantEditAudienceMembers(request);
}

export async function apiMerchantEditAudienceMembers(request) {
    return apiConnection.post("/merchant/edit_audience_members/", request);
}
