import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {Modal, ModalStyle} from "../../../../../../ui/Modal";
import {Messages} from "../../../../../../Messages";
import {BlinkButton} from "../../../../../../ui/Button";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style";
import {DONATIONS_URL, SUBSCRIPTIONS_URL} from "../../../../../../Constants";
import {DonationStore} from "../../../../../../../client/state/DonationStore";
import {SubscriptionStore} from "../../../../../../../client/state/SubscriptionStore";
import {Link} from "../../../../../../../stem-core/src/ui/UIPrimitives";


class IrremovablePaymentMethodModalStyle extends ModalStyle {
    @styleRule
    button = {
        width: "100%",
    };

    @styleRule
    description = {
        fontSize: 16,
        lineHeight: 20,
        color: this.themeProps.MODAL_DESCRIPTION_COLOR,
        marginBottom: 24,
    };
}

@registerStyle(IrremovablePaymentMethodModalStyle)
export class IrremovablePaymentMethodModal extends Modal {
    getDefaultOptions() {
        const entry = this.options.entries[0];
        const descriptionSuffix = this.options.entries.length > 1 ? (
                this.options.entries.length > 2 ?
                Messages.irremovablePaymentMethodDescription2c(this.options.entries.length - 1) :
                Messages.irremovablePaymentMethodDescription2b(this.options.entries.length - 1)
            ) :
            Messages.irremovablePaymentMethodDescription2a;
        const paymentHref = entry.getStore() === DonationStore ? DONATIONS_URL
                : entry.getStore() === SubscriptionStore ? SUBSCRIPTIONS_URL : "";

        return {
            ...super.getDefaultOptions(),
            title: Messages.irremovablePaymentMethodTitle,
            description: [
                Messages.irremovablePaymentMethodDescription1,
                <Link href={`/${paymentHref}?focus="${entry.id}"`} value={entry.getMerchantTitle()}
                      style={{color: this.styleSheet.themeProps.MERCHANT_LINK, fontWeight: "initial"}}/>,
                descriptionSuffix
            ]
        }
    }

    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.description}>{this.options.description}</div>,
            <BlinkButton className={styleSheet.button} onClick={() => this.hide()}>
                {Messages.ok}
            </BlinkButton>
        ]
    }
}
