import {BaseEnum, makeEnum} from "./misc/BaseEnum";
import {MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {field} from "./misc/StoreField";
import {apiConnection} from "../connection/BlinkApiConnection";

@makeEnum
export class PolicyType extends BaseEnum {
    static COOKIE;
    static TOS;
    static PRIVACY;
}

class PolicyTerms extends StoreObject {
    @field(Date) createdAt;
    @field(PolicyType) type;
    @field(String) htmlTemplate;
}

export const PolicyTermsStore = MakeStore("PolicyTerms", PolicyTerms);

export async function apiAdminCreatePolicy(request) {
    return apiConnection.post("/compliance/create_policy/", request);
}
