import {registerStyle, styleRule, StyleSheet, UI} from "../../../../../../stem-core/src/ui/UI";
import {Messages} from "../../../../../Messages";
import {BlinkButton} from "../../../../../ui/Button";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils";


class AuthorizationPanelStyle extends StyleSheet {
    @styleRule
    authorizationPanel = {
        textAlign: "center",
    };

    @styleRule
    prompt = {
        color: this.themeProps.LABEL,
    };

    @styleRule
    confirmButton = this.themeProps.AUTH_BUTTON_STYLE;

    @styleRule
    cancelButton = {
        userSelect: "none",
        margin: "18px auto 0",
        width: this.themeProps.FIT_CONTENT,
        cursor: "pointer",
        transition: ".2s ease",
        color: this.themeProps.AUTHORIZATION_PANEL_CANCEL_COLOR,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        opacity: 0.6,
        ":hover": {
            opacity: 1,
        },
    };
}

@registerStyle(AuthorizationPanelStyle)
export class AuthorizationPanel extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            applicationName: "",
            onAccept: NOOP_FUNCTION,
            onReject: NOOP_FUNCTION,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.authorizationPanel);
    }

    render() {
        const {styleSheet} = this;
        const {onAccept, onReject} = this.options;

        return [
            <div className={styleSheet.prompt}>{Messages.authPrompt(this.options.applicationName)}</div>,
            <BlinkButton className={styleSheet.confirmButton} onClick={onAccept}>
                {Messages.confirmAccessAuthorization}
            </BlinkButton>,
            <div className={styleSheet.cancelButton} onClick={onReject}>{Messages.cancel}</div>
        ];
    }
}
