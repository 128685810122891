import {UI, styleRule, registerStyle, Theme} from "../../../../../../stem-core/src/ui/UI";
import {Messages} from "../../../../../Messages";

import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {BlinkButton} from "../../../../../ui/Button";
import {List} from "../../../../../ui/List";
import {SingleCardWidget} from "../components/payment-method/SingleCardWidget";
import {AddCardModal} from "../../wallet/components/cards/AddCardModal";
import {LoadingSpinner} from "../../../../../ui/LoadingSpinner";
import {ConfirmationModal} from "../../../../../ui/ConfirmationModal";
import {Toast} from "../../../../../ui/Toast";
import {apiSetPrimaryPaymentMethod} from "../../../../../../client/state/PaymentMethodStore";


export class PaymentMethodsStyle extends BaseCardStyle {
    @styleRule
    addCardButton = {
        width: "100%",
    };

    @styleRule
    listElement = {
        ">:first-child": {
            marginTop: 12,
            borderTop: `1px solid ${Theme.props.DIVIDER_LINE_COLOR} !important`,
        },
        ">:last-child": {
            marginBottom: 12,
        },
    };
}

@registerStyle(PaymentMethodsStyle)
export class PaymentMethods extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.paymentMethods,
            userPaymentMethods: [],
        };
    }

    getDescription() {
        return this.options.userPaymentMethods?.length ? "" : Messages.emptyPaymentMethodsListDescription;
    }

    render() {
        return <List testId="accountPaymentMethods" className={this.styleSheet.listElement}>
            {this.options.userPaymentMethods?.map(paymentMethod => <SingleCardWidget card={paymentMethod}/>)}
        </List>;
    }

    renderFooter() {
        let addCardModal;

        const cardAddedCallback = () => {
            LoadingSpinner.show();
            addCardModal.cardInput.confirmCard(card => {
                    LoadingSpinner.hide();
                    addCardModal.hide();
                    Toast.show(Messages.cardAdded);

                    if (!card.isPrimary) {
                        const setAddedCardAsPrimaryModal = ConfirmationModal.show({
                            testId: "setPrimaryModal",
                            confirmId: "setPrimaryConfirm",
                            title: Messages.promptSetCardAsDefault,
                            description: Messages.usingCard(card.getBrand(), card.getLastDigits()),
                            confirmLabel: Messages.yes,
                            cancelLabel: Messages.no,
                            confirmAction: async () => {
                                try {
                                    await apiSetPrimaryPaymentMethod(card.id)
                                } catch (error) {
                                //    TODO: handle this case
                                    console.error(error);
                                } finally {
                                    setAddedCardAsPrimaryModal.hide();
                                }
                            }
                        });
                    }
                },
                () => {
                    LoadingSpinner.hide();
                });
        };

        return <BlinkButton testId="accountAddCardButton" className={this.styleSheet.addCardButton}
                            onClick={() => {
                                addCardModal = AddCardModal.show({confirmAction: cardAddedCallback});
                            }}>
            {Messages.addCard}
        </BlinkButton>;
    }
}
