import {GenericObjectStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiClient} from "../../connection/BlinkApiConnection";
import {BaseEnum, makeEnum} from "../misc/BaseEnum";
import {BlinkGlobal} from "../../../blinkpay/UtilsLib";
import {filterStoreByMerchantAndLooseIdArray} from "../misc/Filter";

@makeEnum
export class AudienceNewsletterFrequency extends BaseEnum {
    static DAILY;
    static WEEKLY;
    static MONTHLY;
    static CUSTOM;
}

class MerchantAudience extends StoreObject {
    @field("Merchant") merchant;
    @field(String) name;
    @field(Boolean) isPublic;
    @field(AudienceNewsletterFrequency) defaultFrequency;
    @field(Array) defaultFrequencyOffset;
    @field(Date) defaultSendTime;
    @field(Boolean) autosubscribeNewUsers;

    toString() {
        return this.name;
    }

    getSize() {
        if (this.size >= 0) {
            return this.size;
        }
        return null;
    }

    async ensureSizeLoaded() {
        if (this.size != null) {
            return;
        }
        this.size = -1; // Mark that we're in the process of fetching the size
        const response = await apiMerchantGetAudienceMembers({
            audienceId: this.id,
            pageSize: 0,
        });
        this.size = response.count;
        this.dispatch("update");
    }
}

class MerchantAudienceStoreClass extends GenericObjectStore {
    constructor() {
        super("MerchantAudience", MerchantAudience);
    }

    allUnfiltered() {
        return super.all();
    }

    all() {
        return filterStoreByMerchantAndLooseIdArray(this.allUnfiltered(), BlinkGlobal.iFrameState?.newsletter,
            (audience, idOrName) => audience.id == idOrName || audience.name == idOrName);
    }
}

export const MerchantAudienceStore = new MerchantAudienceStoreClass();

export async function apiMerchantCreateAudience(request) {
    return apiClient.post("/merchant/create_audience", request);
}

export async function apiMerchantEditAudience(request) {
    return apiClient.post("/merchant/edit_audience", request, {allowNulls: true});
}

export async function apiMerchantDeleteAudience(request) {
    return apiClient.post("/merchant/delete_audience", request);
}

export async function apiMerchantGetAudienceMembers(request) {
    return await apiClient.get("/merchant/get_audience_members/", request);
}
