import {UI} from "../../stem-core/src/ui/UIBase";

import {Modal, ModalStyle} from "../../blinkpay/ui/Modal";
import {BlinkPayLogoText} from "../../blinkpay/SVGElements";
import {authFormService} from "../../blinkpay/services/AuthFormService";
import {registerStyle, Theme} from "../../stem-core/src/ui/style/Theme";
import {AuthFlowPanel} from "../../blinkpay/website/unauthenticated/slides/AuthFlowPanel.jsx";
import {styleRuleInherit} from "../../stem-core/src/decorators/Style.js";
import {isSmallScreen} from "../../blinkpay/UtilsLib.js";


class AuthenticationModalStyle extends ModalStyle {
    @styleRuleInherit
    modal = {
        background: this.themeProps.WHITE,
        padding: "36px 30px",
        maxWidth: isSmallScreen() ? 375 : 400,
        fontSize: 14,
        display: "flex",
        flexDirection: "column",
        boxShadow: this.themeProps.MODAL_LIGHTER_SHADOW,
    };

    @styleRuleInherit
    title = {
        color: this.themeProps.MODAL_LIGHTER_TITLE_COLOR,
        fontWeight: "lighter",
        marginBottom: 18,
    };
}


@registerStyle(AuthenticationModalStyle)
export class AuthenticationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            autoWidth: false,
            onHide: () => authFormService.setRegistrationMail(null),
        };
    }

    render() {
        return [
            <BlinkPayLogoText size={24} backgroundColor={Theme.props.WALLET_12} style={{
                textAlign: "center",
                marginBottom: 24,
            }}/>,
            <AuthFlowPanel
                externalAuthProviders={["facebook", "google", "twitter", "linkedin"]}
            />
        ];
    }

    onMount() {
        super.onMount();
        this.attachUpdateListener(authFormService, () => this.redraw());
    }
}
