import {Dispatchable} from "../../../../stem-core/src/base/Dispatcher";
import {CARD_SECTION_STATE, PaymentMethodController} from "../../inline-donation/PaymentMethodController";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {
    ADDRESS_PANEL_URL,
    INLINE_SHOP_URLS,
    PAYMENT_DETAILS_PANEL_URL,
    PAYMENT_FAILED_PANEL_URL
} from "../../PanelConstants.js";
import {ADDRESS_SECTION_STATE} from "../panels/address/AddressPanel.jsx";
import {Messages} from "../../../Messages.js";
import {PaymentMethodStore} from "../../../../client/state/PaymentMethodStore.js";
import {iFrameMerchantService} from "../../../services/IframeMerchantService.js";
import {apiUserPurchaseProduct} from "../../../../client/state/PurchaseStore.js";
import {LoadingSpinner} from "../../../ui/LoadingSpinner.jsx";
import {Controller} from "../../Controller";
import {AddressController} from "../../inline-subscription/AddressController";
import {iFrameState} from "../../../services/IFrameState";
import {PANEL_TYPE} from "../../../../blink-sdk/Constants";
import {CalculateShippingPrice} from "../../../../client/state/misc/ShippingPrice";
import {iFrameAuthService} from "../../../services/IFrameAuthService";


export class ShopFlowState extends Dispatchable {
    selectedProduct = null;

    start() {
        const {panelType} = iFrameState;

        if (panelType !== PANEL_TYPE.shop) {
            return;
        }

        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            savePaymentMethod: true,
            getScope: () => PaymentMethodStore.Scope.PRIVATE,
            onSelectSuccess: () => this.purchaseSelectedProduct(),
        });

        Controller.address = new AddressController({
            addressSectionState: null,
            selectedAddress: null,
        });

        Router.changeURL(INLINE_SHOP_URLS.products);
    }

    async purchaseSelectedProduct() {
        const product = this.selectedProduct;

        const request = {
            merchantId: iFrameMerchantService.getMerchant().id,
            paymentMethodId: Controller.paymentMethod.selectedPaymentMethod.id,
            products: {
                entries: [{
                    productId: product.id,
                }]
            }
        }

        const {selectedAddress} = Controller.address;
        if (selectedAddress) {
            request.deliveryAddressId = selectedAddress.id;
        }

        request.currencyId = product.currencyId;
        request.amount = product.amount + CalculateShippingPrice(selectedAddress, product.getShippingPrices()).amount;

        LoadingSpinner.show();

        try {
            await apiUserPurchaseProduct(request);
        } catch (error) {
            Controller.paymentMethod.update({
                paymentError: error,
                editPaymentDetails: () => Router.changeURL(PAYMENT_DETAILS_PANEL_URL),
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            return;
        } finally {
            LoadingSpinner.hide();
        }

        Router.changeURL(INLINE_SHOP_URLS.orderSuccess);
    }

    processProductAfterAddress() {
        Router.changeURL(INLINE_SHOP_URLS.reviewOrder);
    }

    async selectProduct(product) {
        this.selectedProduct = product;

        await iFrameAuthService.promptAuthenticationIfNeeded();

        if (product.requiresAddress) {
            // TODO @Mihai @Denis try to do this with an async call to AddressController
            Controller.address.update({
                addressSectionState: ADDRESS_SECTION_STATE.chooseAddress,
                onSelectSuccess: () => this.processProductAfterAddress(),
                onSelectCancel: () => Router.back(),
                continueLabel: Messages.reviewOrder,
            });
            Router.changeURL(ADDRESS_PANEL_URL);
            return;
        }

        this.processProductAfterAddress();
    }
}
