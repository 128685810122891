import {Router} from "../../../stem-core/src/ui/Router";
import {
    ADDRESS_PANEL_URL,
    DONATE_PAGE_URLS,
    INLINE_DONATION_URLS,
    INLINE_EDIT_DONATION_URLS, PAYMENT_DETAILS_PANEL_URL, PAYMENT_FAILED_PANEL_URL
} from "../PanelConstants";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {
    apiCancelDonation, apiEditDonationWithCds,
    apiUpdateDonationAmount,
    apiUpdateDonationPaymentMethod, DonationStore
} from "../../../client/state/DonationStore";
import {iFrameUserDataService} from "../../services/IFrameUserDataService";
import {apiDonate, apiDonateWithCds} from "../../../client/state/DonationStore";
import {iFrameState} from "../../services/IFrameState";
import {iFrameMerchantService} from "../../services/IframeMerchantService";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient";
import {Messages} from "../../Messages";
import {Money} from "../../../client/state/misc/Money";
import {FLOW_CHECKPOINT, FLOW_TYPE, GATE_TYPE, PANEL_TYPE} from "../../../blink-sdk/Constants";
import {Device} from "../../../stem-core/src/base/Device";
import {CARD_SECTION_STATE, PaymentMethodController} from "./PaymentMethodController";
import {ADDRESS_SECTION_STATE} from "../pages/panels/address/AddressPanel";
import {UserAddressStore} from "../../../client/state/UserAddressStore";
import {sendFlowCheckpoint} from "../flows/FlowController";
import {Controller} from "../Controller";
import {AddressController} from "../inline-subscription/AddressController";
import {iFrameAuthService} from "../../services/IFrameAuthService";
import {collapseIframeAndExpandWallet} from "../../widget/misc/WidgetUtils";
import {authService} from "../../../client/connection/services/AuthService";
import {Toast} from "../../ui/Toast.jsx";


export class DonateMobileState extends Dispatchable {
    payload = null;

    frequency = null;
    isCustomAmount = false;
    donationAmount = "";
    latestDonationFee = null;
    payDonationFeeCheckboxChecked = false;

    previouslyCanceledDonation = null;

    async start() {
        const {panelType, gateType, editRecurring, skipCTA} = iFrameState;

        if (panelType !== PANEL_TYPE.donation && panelType !==PANEL_TYPE.donationPage) {
            return;
        }

        const offer = iFrameMerchantService.getDonationOffer();
        if (offer && !iFrameUserDataService.getActiveRecurringDonation()) {
            if (!offer.hasAmountChoice()) {
                this.donationAmount = offer.predefinedAmounts[0];
            }
            if (!offer.hasFrequencyChoice()) {
                this.frequency = offer.getFrequencyOptions()[0];
            }
        }

        if (editRecurring && iFrameUserDataService.getActiveRecurringDonation()) {
            this.initEditDonation();
        } else {
            this.initDonate();
        }

        if (panelType === PANEL_TYPE.donation) {
            if (gateType === GATE_TYPE.popup) {
                if (editRecurring) {
                    Router.changeURL(INLINE_EDIT_DONATION_URLS.donate);
                } else if (skipCTA) {
                    if (authService.isAuthenticated()) {
                        // This is a "convention" of options that leads to a one-time donation
                        // flow (for example, from the wallet's 'Make another donation' button).
                        // TODO: Make this more explicit, what this does is very well hidden.
                        Router.changeURL(INLINE_DONATION_URLS.donate);
                        return;
                    }
                    await iFrameAuthService.promptAuthenticationIfNeeded();
                    if (iFrameUserDataService.getActiveRecurringDonation()) {
                        collapseIframeAndExpandWallet();
                    } else {
                        Router.changeURL(INLINE_DONATION_URLS.donate);
                    }
                } else {
                    Router.changeToCustomPanel();
                }
            } else {
                Router.changeToCustomPanel();
            }
        } else {
            Router.changeURL(DONATE_PAGE_URLS.home);
        }
    }

    initDonate() {
        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            getChooseCardButtonLabel: () => Device.isMobileDevice() ? Messages.finalizePayment : Messages.selectCard,
            getPayWithAddedCardButtonLabel: () => Device.isMobileDevice() ? Messages.finalizePayment : Messages.continue,
            getScope: () => PaymentMethodStore.Scope.PRIVATE,
            onSelectSuccess: async () => {
                if (!Device.isMobileDevice()) { // TODO: This is not that legit.
                    Router.changeURL(INLINE_DONATION_URLS.donate);
                    return;
                }
                await this.donateInlineFlowDonate();
            },
        });
    }

    initEditDonation() {
        const donation = iFrameUserDataService.getActiveRecurringDonation();
        this.payDonationFeeCheckboxChecked = donation.isDonationFeePaid();
        this.frequency = donation.frequency;
        this.donationAmount = donation.computeAmount();
        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            entry: donation,
            savePaymentMethod: true,
            initialPaymentMethod: donation.getPaymentMethod(),
            selectedPaymentMethod: donation.getPaymentMethod(),
            getChooseCardButtonLabel: () => Messages.updatePaymentMethod,
            getPayWithAddedCardButtonLabel: () => Messages.updatePaymentMethod,
            getScope: () => PaymentMethodStore.Scope.PRIVATE,
            onSelectSuccess: () => Router.changeURL(INLINE_EDIT_DONATION_URLS.donate),
        });
    }

    getFrequency() {
        return this.frequency || iFrameMerchantService.getDonationOffer().defaultFrequency;
    }

    getDonationAmount() {
        const {predefinedAmounts, defaultPredefinedAmountIndex} = iFrameMerchantService.getDonationOffer();
        return this.donationAmount || predefinedAmounts[defaultPredefinedAmountIndex];
    }

    getDonationFee() {
        if (this.donationAmount) {
            this.latestDonationFee = DonationStore.computeFeeFromAmount(this.donationAmount, iFrameMerchantService.merchantId);
        }
        return this.latestDonationFee;
    }

    getTotalAmount() {
        return this.donationAmount + (this.payDonationFeeCheckboxChecked ? this.getDonationFee() : 0);
    }

    setPayload(payload) {
        this.payload = payload;
    }

    getDonation() {
        return iFrameUserDataService.getActiveRecurringDonation();
    }

    isValid() {
        return !!this.donationAmount;
    }

    formatAmount(amount) {
        return new Money(amount, iFrameMerchantService.getDonationOffer().getCurrency()).toString();
    }

    // TODO: These are only here temporarily.

    getDonateComponentButtonLabel() {
        if (!this.donationAmount || !this.getFrequency()) {
            return Messages.donate;
        }
        const formattedAmount = this.formatAmount(this.getTotalAmount());
        const formattedFrequency = this.getFrequency().isOneTime() ? "" : "/" + this.getFrequency().name.toLowerCase();
        return Messages.donateInlineFlowButton(formattedAmount, formattedFrequency);
    }

    shouldRenderDonatePage() {
        return Device.isMobileDevice() && (!this.donationAmount || !this.frequency || iFrameState.allowUserToCoverFees);
    }

    getEditDonationComponentButtonLabel() {
        return !this.donationAmount ? Messages.update : Messages.editDonationInlineFlowButton(
            this.formatAmount(this.getTotalAmount()),
            this.getFrequency().name.toLowerCase()
        );
    }

    getDonatePageEditDonationComponentButtonLabel() {
        return !this.donationAmount ? Messages.update : Messages.editDonationInlineFlowButton(
            this.formatAmount(this.getTotalAmount()),
            this.getFrequency().name.toLowerCase()
        );
    }

    // TODO: Rename all of these methods.

    async donate(successCallback=NOOP_FUNCTION, errorCallback=NOOP_FUNCTION) {
        const donate = async () => {
            LoadingSpinner.show();
            try {
                const apiDonateFunction = iFrameMerchantService.isCDSPaymentMethod() ? apiDonateWithCds : apiDonate;
                const donation = await apiDonateFunction({
                    ...this.payload,
                    recurringOfferId: iFrameMerchantService.getDonationOffer().id,
                    ...(await Controller.paymentMethod.extractPaymentMethodFields()),
                });
                if (donation.isActive() || donation.isFinished()) {
                    LoadingSpinner.hide();
                    sendFlowCheckpoint(FLOW_TYPE.donation, FLOW_CHECKPOINT.complete, {
                        donationId: donation.id,
                        offerId: donation.recurringOfferId,
                        // TODO @Mihai: Change this
                        amount: donation.getPrice().toMainUnitString({includeSymbol: false}),
                        currency: donation.currency.isoCode,
                        recurring: donation.isRecurring(),
                        frequency: donation.frequency.toString()
                    });
                    successCallback();
                }
            } catch (error) {
                errorCallback(error);
            } finally {
                LoadingSpinner.hide();
            }
        }

        if (iFrameMerchantService.getDonationOffer().shouldCollectAddress() && !this.payload.userAddressId) {
            Controller.address = new AddressController({
                addressSectionState: ADDRESS_SECTION_STATE.chooseAddress,
                onSelectSuccess: async () => {
                    this.payload.userAddressId = Controller.address.selectedAddress.id;
                    await donate();
                },
                onSelectCancel: () => {
                    delete this.payload.userAddressId;
                    Router.back();
                },
                continueLabel: Messages.finalizeDonation,
            });
            Router.changeURL(ADDRESS_PANEL_URL);
        } else {
            await donate();
        }
    }

    async donateInlineFlowDonate() {
        const commonPayload = {
            amount: this.getTotalAmount(),
            frequency: this.getFrequency(),
            coversFees: this.payDonationFeeCheckboxChecked,
        };
        const payload = {
            ...commonPayload,
            ...(await Controller.paymentMethod.extractPaymentMethodFields()),
        };
        this.setPayload(payload);
        dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_DONATE, {
            ...commonPayload,
            isCustomAmount: this.isCustomAmount,
        });
        return this.donate(() => Router.changeURL(INLINE_DONATION_URLS.thankYou),
        error => {
            Controller.paymentMethod.update({
                paymentError: error,
                retryPayment: () => this.donateInlineFlowDonate(),
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
        });
    }

    async cancel() {
        const donation = this.getDonation();

        LoadingSpinner.show();
        try {
            await apiCancelDonation(donation.id);
            this.previouslyCanceledDonation = donation;
            Router.changeURL(INLINE_EDIT_DONATION_URLS.cancelSuccess);
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    async updateDonation(donationAmount) {
        const donation = this.getDonation();

        LoadingSpinner.show();
        const handleRequestError = error => {
            LoadingSpinner.hide();
            Toast.showError(error);
        };

        try {
            if (iFrameMerchantService.isCDSPaymentMethod()) {
                if (this.payload?.paymentInfo) {
                    const activeDonation = iFrameUserDataService.getActiveRecurringDonation();
                    await apiEditDonationWithCds({
                        amount: donationAmount,
                        frequency: activeDonation.frequency,
                        userAddressId: activeDonation.getShippingAddress()?.id || UserAddressStore.all()[0]?.id,
                        recurringOfferId: activeDonation.recurringOfferId,
                        paymentInfo: this.payload.paymentInfo,
                    });
                    Router.changeURL(INLINE_EDIT_DONATION_URLS.thankYou);
                } else {
                    Controller.paymentMethod.update({
                        getPayWithAddedCardButtonLabel: () => Messages.continue,
                        getScope: () => PaymentMethodStore.Scope.PRIVATE,
                        onSelectSuccess: async () => {
                            this.setPayload({
                                coversFees: this.payDonationFeeCheckboxChecked,
                                ...(await Controller.paymentMethod.extractPaymentMethodFields())
                            });
                            await this.updateDonation(donationAmount);
                        }
                    });
                    Router.changeURL(PAYMENT_DETAILS_PANEL_URL);
                }
            } else {
                await apiUpdateDonationAmount(donation.id, donationAmount, this.payDonationFeeCheckboxChecked);
                await apiUpdateDonationPaymentMethod(donation.id, Controller.paymentMethod.selectedPaymentMethod?.id);
                Router.changeURL(INLINE_EDIT_DONATION_URLS.thankYou);
            }
        } catch (error) {
            handleRequestError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    async donatePageDonate(changeURL=NOOP_FUNCTION) {
        return this.donate(() => changeURL(DONATE_PAGE_URLS.thankYou),
            () => changeURL(DONATE_PAGE_URLS.paymentFailed, true));
    }

    async donatePageCancel(changeURL=NOOP_FUNCTION) {
        const donation = this.getDonation();

        LoadingSpinner.show();
        try {
            await apiCancelDonation(donation.id);
            this.previouslyCanceledDonation = donation;
            changeURL(DONATE_PAGE_URLS.donationCanceled);
        } catch (error) {
            // TODO: handle API request errors.
        } finally {
            LoadingSpinner.hide();
        }
    }

    async donatePageUpdateDonation(changeURL=NOOP_FUNCTION) {
        const donation = this.getDonation();

        LoadingSpinner.show();
        try {
            await apiUpdateDonationAmount(donation.id, this.donationAmount, this.payDonationFeeCheckboxChecked);
            await apiUpdateDonationPaymentMethod(donation.id, Controller.paymentMethod.selectedPaymentMethod?.id);
            changeURL(DONATE_PAGE_URLS.donationUpdated);
        } catch (error) {
            // TODO handle API requests errors.
        } finally {
            LoadingSpinner.hide();
        }
    }
}
