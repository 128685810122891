import {MerchantDropdown} from "../../../../../../widget/ui/MerchantDropdown";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {UI} from "../../../../../../../stem-core/src/ui/UIBase";
import {BlinkInput} from "../../../../../../ui/Input";
import {Messages} from "../../../../../../Messages";
import {MerchantInputAutocomplete} from "../../../../../../common/MerchantInput";
import {StyleSheet} from "../../../../../../../stem-core/src/ui/Style";
import {
    countriesDataService,
    getUSStateFromZip,
    getStateShortCode
} from "../../../../../../services/CountriesDataService";
import {UserProfileStore} from "../../../../../../State";


export class AddressFormStyle extends StyleSheet {
    @styleRule
    country = {
        width: "100%",
        marginBottom: 8,
        marginTop: 10,
    };

    @styleRule
    state = {
        flex: 1,
    };

    @styleRule
    postalCode = {
        width: "25%",
        marginLeft: 10,
        minWidth: 90,
    };

    @styleRule
    street = {
        marginTop: 18,
        marginBottom: 8,
    };

    @styleRule
    stateZipCodeContainer = {
        display: "flex",
        marginTop: -10,
    };
}

@registerStyle(AddressFormStyle)
export class AddressForm extends UI.Primitive("form") {
    country = null;

    getDefaultOptions() {
        return {
            address: null,
            ...super.getDefaultOptions(),
        }
    }

    getInsertedValues() {
        return {
            recipientName: this.nameInput ? this.nameInput.getValue() : "",
            street: this.streetInput ? this.streetInput.getValue() : "",
            city: this.cityInput ? this.cityInput.getValue() : "",
            state: this.stateInput ? this.stateInput.getValue() : "",
            postalCode: this.postalCodeInput ? this.postalCodeInput.getValue() : "",
            country: this.country,
        }
    }

    render() {
        const {address} = this.options;
        const {styleSheet} = this;
        const insertedValues = this.getInsertedValues();
        const states = insertedValues.country ? countriesDataService.getStatesNames(insertedValues.country.code2) : [];

        const onCitySelected = (selected) => {
            const {state, countryCode, city} = selected.extra;
            this.cityInput.setValue(city);
            this.stateInput.setValue(state);
            this.country = countriesDataService.getCountryByCode(countryCode);
            this.redraw();
        };

        const onCountrySelected = (countryName) => {
            const selectedCountry = countriesDataService.getCountries().find(country => country.name === countryName);
            if (selectedCountry && selectedCountry !== insertedValues.country) {
                if (!countriesDataService.getStatesNames(selectedCountry.code2).indexOf(this.stateInput.getValue()) === -1) {
                    this.stateInput.setValue(selectedCountry.verified ?
                        countriesDataService.getStatesNames(selectedCountry.code2)[0] : "");
                }
                this.country = selectedCountry;
                this.redraw();
            }
        };

        return [
            <BlinkInput ref="nameInput" testId="nameInput" label={Messages.name}
                        initialValue={address?.getRecipientName() || this.getUserFullName()}
                        inputAttributes={{placeholder: Messages.name}}
                        validators={[{condition: () => !this.nameInput.isEmpty()}]}
            />,
            <BlinkInput ref="streetInput" testId="streetInput" className={styleSheet.street}
                        label={Messages.addressStreet} initialValue={address?.streetAddress || ""}
                        inputAttributes={{placeholder: Messages.addressStreet}}
                        validators={[{condition: () => !this.streetInput.isEmpty()}]}
            />,
            <MerchantInputAutocomplete ref="cityInput" testId="cityInput" label={Messages.addressCity}
                                        initialValue={address?.city || ""} autocompleteList={countriesDataService.getCities()}
                                        inputAttributes={{placeholder: Messages.addressCity}}
                                        validators={[{condition: () => !this.cityInput.isEmpty()}]}
                                        onOptionSelect={onCitySelected}
            />,
            <div className={styleSheet.stateZipCodeContainer}>
                <BlinkInput ref="stateInput" testId="stateInput" className={this.styleSheet.state}
                            label={Messages.addressStateRegion} initialValue={address?.state || ""}
                            inputAttributes={{
                                placeholder: states.length ? Messages.addressState : Messages.addressStateNotRequired,
                                disabled: states.length === 0,
                            }}
                            validators={[{
                                condition: () => states.length > 0 && !this.stateInput.isEmpty()
                            }, {
                                condition: () => this.validateUSState(),
                                error: Messages.invalidStateName,
                            }]}
                            onBlur={() => this.redraw()}
                />
                <BlinkInput ref="postalCodeInput" testId="postalCodeInput" className={styleSheet.postalCode}
                            label={Messages.addressZipCode} initialValue={address?.postalCode || ""}
                            inputAttributes={{placeholder: Messages.addressZipCode}}
                            onEnter={() => this.postalCodeInput.blur()}
                            validators={[
                                {
                                    condition: () => !this.postalCodeInput.isEmpty()
                                },
                                {
                                    condition: () => this.validZipCodeInserted(),
                                    error: Messages.addressZipCodeInvalid
                                },
                                {
                                    condition: () => this.validateUSZipCode(),
                                    error: Messages.zipCodeDoesNotMatchState
                                }
                            ]}
                />
            </div>,
            <MerchantDropdown ref="countryDropdown" testId="countryDropdown" className={styleSheet.country}
                              label={Messages.addressCountry} onChange={onCountrySelected}
                              options={countriesDataService.getCountries().map(country => country.name)}
                              initialValue={insertedValues.country?.name}
                              onExpand={() => this.cityInput && this.cityInput.hideAutocompleteOptions()}/>,
        ];
    }

    validateUSState() {
        if (this.country.name !== "United States" || !this.stateInput) {
            return true;
        }
        return getStateShortCode(this.stateInput.getValue()) !== null;
    }

    validateUSZipCode() {
        if (this.country.name !== "United States") {
            return true;
        }
        if (!this.stateInput || !this.postalCodeInput) {
            return true;
        }
        const stateName = this.stateInput.getValue();
        const zipCode = this.postalCodeInput.getValue();

        const stateShortCode = getStateShortCode(stateName);
        if (!stateShortCode) {
            // Don't throw an error on the zip code if the state code validation fails
            return true;
        }
        const stateCodeForZip = getUSStateFromZip(zipCode);
        return stateShortCode === stateCodeForZip;
    }

    validateInputs() {
        this.nameInput.validate();
        this.streetInput.validate();
        this.cityInput.validate();
        this.stateInput.validate();
        this.postalCodeInput.validate();

        return this.nameInput.isValid() && this.streetInput.isValid() &&
            this.cityInput.isValid() && this.stateInput.isValid() && this.postalCodeInput.isValid();
    }

    validZipCodeInserted() {
        const {country, postalCode} = this.getInsertedValues();

        if (!country) {
            return false;
        }

        if (!country.verified) {
            return true;
        }

        if (country.postal_code_regex.length === 0) {
            return true;
        }
        const pattern = new RegExp(country.postal_code_regex);

        return pattern.test(postalCode);
    }

    getValues() {
        const {recipientName, street, city, state, postalCode, country} = this.getInsertedValues();
        return {
            recipientName: recipientName.trim(),
            streetAddress: street.trim(),
            city: city.trim(),
            country: country.name,
            countryCode: country.code2,
            state: state,
            postalCode: postalCode,
        };
    }

    displayFieldErrors(error) {
        const inputByField = {
            recipientName: this.nameInput,
            street_address: this.streetInput,
            city: this.cityInput,
            postal_code: this.postalCodeInput,
            state: this.stateInput,
            country: this.countryDropdown,
        };

        if (!error || !error.fields) {
            return;
        }

        for (let fieldError of error.fields) {
            const input = inputByField[fieldError.field];
            if (input) {
                input.updateOptions({error: fieldError.message});
            }
        }
    }

    getUserFullName() {
        const {firstName, lastName} = UserProfileStore.getUserProfile();
        return `${firstName || ""} ${lastName || ""}`.trim();
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserProfileStore, () => this.redraw());
        countriesDataService.loadCountriesData(() => {
            this.country = countriesDataService.getCountries().find(({name}) => name === this.options.address?.country)
                        || countriesDataService.getCountries()[0];
            this.redraw();
        });
    }
}
