import {Dispatchable, Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {getQueryParam} from "../UtilsLib";
import {DEFAULT_CONFIRMATION_CODE_TYPE} from "../Constants";
import {iFrameState} from "./IFrameState";


class AuthFormService extends Dispatchable {
    isRegistration = false;
    changeToEmailAddress = null;

    constructor() {
        super();

        // TODO @cleanup Say Email everywhere instead of Mail
        this.registrationMail = getQueryParam("registrationMail") || iFrameState.preferredEmail;
        this.confirmationCodeType = getQueryParam("confirmationCodeType") || DEFAULT_CONFIRMATION_CODE_TYPE;

        this.attachListener(Dispatcher.Global, "collapseIframe", () => this.setRegistrationMail(null));
        this.attachUpdateListener(iFrameState, () => {
            if (!this.registrationMail && iFrameState.preferredEmail
                    && this.registrationMail !== iFrameState.preferredEmail) {
                this.setRegistrationMail(iFrameState.preferredEmail);
            }
        });
    }

    setRegistrationMail(registrationMail) {
        // When the user types an email or a password, save them in the state; if he is on the wrong auth page (for
        // instance, he is on the sign up but he meant to log in) and he switches to the right one, we will complete
        // those inputs with the values he wrote in the wrong auth page.
        this.registrationMail = registrationMail;
        this.dispatch("update");
    }

    setConfirmationCodeType(confirmationCodeType) {
        this.confirmationCodeType = confirmationCodeType;
        this.dispatch("update");
    }

    setIsRegistration(isRegistration) {
        this.isRegistration = isRegistration;
        this.dispatch("update");
    }
}

export const authFormService = new AuthFormService();
