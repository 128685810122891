import {UI} from "../../stem-core/src/ui/UI";
import {
    AmExIcon,
    DinersClubIcon,
    DiscoverIcon,
    JCBIcon,
    MasterCardIcon,
    UnionPayIcon,
    VisaIcon, WalletPaymentCardIcon
} from "../SVGElements";

const CARD_ICON_CLASS = {
    MASTERCARD: MasterCardIcon,
    VISA: VisaIcon,
    AMEX: AmExIcon,
    DISCOVER: DiscoverIcon,
    DINERS_CLUB: DinersClubIcon,
    JCB: JCBIcon,
    UNION_PAY: UnionPayIcon,
    DEFAULT: WalletPaymentCardIcon,
};

class BrandedCardIcon {
    static cardBrands = {
        MASTERCARD: "mastercard",
        VISA: "visa",
        AMEX: "amex",
        DISCOVER: "discover",
        DINERS_CLUB: "diners club",
        JCB: "jcb",
        UNION_PAY: "unionpay",
    };

    static getIconClassByBrand(brand) {
        for (const key of Object.keys(this.cardBrands)) {
            if (brand && this.cardBrands[key].toUpperCase() === brand.toUpperCase() && CARD_ICON_CLASS[key]) {
                return CARD_ICON_CLASS[key];
            }
        }

        return this.getDefaultIconClass();
    }

    static getDefaultIconClass() {
        return CARD_ICON_CLASS.DEFAULT;
    }
}

export class CreditCardIcon extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            brand: "",
            size: null,
        };
    }

    render() {
        const {brand, size} = this.options;
        const IconClass = BrandedCardIcon.getIconClassByBrand(brand);
        const iconProperties = {};

        if (size) {
            iconProperties.size = size;
        }

        return <IconClass {...iconProperties}/>;
    }
}
