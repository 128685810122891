// TODO: Maybe move these to a separate file now?
export const {
    LOG_LEVEL,
    IS_PRODUCTION,
    TEST_MODE,
    BLINK_PAY_URL,
    BLINK_API_URL,
    PUBLIC_DATA_BASE_URL,
    BUILD_VERSION,
    BUILD_TIMESTAMP,
    WS_API_URL,
    GOOGLE_PAY_ENV,
    LINKEDIN_CLIENT_ID,
    GOOGLE_CLIENT_ID,
    FACEBOOK_CLIENT_ID,
    TWITTER_CLIENT_ID,

    SOUTHERN_THING_ALIAS, // TODO @Mihai @cleanup remove whatever is ST specific
    TNR_ALIAS,

// DEPLOY_CONFIG is replaced at build time with the configuration JSON.
// See the replace({...}) plugin in BundleDescription's getRollupPlugins()
// and getDeployConfig() in buildEnvironment.js
} = DEPLOY_CONFIG;

export const BLINK_SDK_SETUP_EVENT = "blinkSDKSetup";
export const BLINK_SDK_READY_EVENT = "blinkSDKReady";

export const IFRAME_HTML_FILE = "iframe.html";
export const IFRAME_APP_TYPE = {
    widget: "widget",
    panel: "panel",
};

export const IFRAME_TEST_ID = {
    widgetCloseButton: "blinkWidgetCloseButton",
    widgetButton: "blinkWidgetButton",
    iframeElement: "blinkIframe",
};

// TODO: Deprecate donationPage, use donation!
export const PANEL_TYPE = {
    subscribe: "subscribe",
    giftSubscription: "giftSubscription",
    payment: "payment",
    donation: "donation",
    donationPage: "donationPage",
    shop: "shop",
    custom: "custom",
    newsletter: "newsletter",
    dashboard: "dashboard",
    authentication: "internalAuthentication", // TODO @mihai @Darius Rename to auth
    empty: "empty",
};

export const GATE_TYPE = {
    cover: "cover",
    preview: "preview",
    popup: "popup",
    banner: "banner",
};

export const GATE_COVER_PANEL_POSITION = {
    top: "top",
    center: "center",
    bottom: "bottom",
};

export const PANEL_FRAME = {
    none: "none",
    logo: "logo",
};

export const GATE_MOUNT_TYPE = {
    replaceChildren: "replaceChildren",
    firstChild: "firstChild",
    lastChild: "lastChild",
};

export const DEFAULT_PANEL_CONTAINERS = [
    {
        selector: ".blink-container,#blink-container",
        type: PANEL_TYPE.payment,
    },
    {
        selector: ".blink-donation-container,#blink-donation-container",
        type: PANEL_TYPE.donation,
    },
    {
        selector: ".blink-subscription-container,#blink-subscription-container",
        type: PANEL_TYPE.subscribe,
        gateType: GATE_TYPE.preview,
    },
    {
        selector: ".blink-purchase-container,#blink-purchase-container",
        type: PANEL_TYPE.payment,
        gateType: GATE_TYPE.preview,
    },
    {
        selector: ".blink-newsletter-container,#blink-newsletter-container",
        type: PANEL_TYPE.newsletter,
    },
];
export const PANEL_INSERTED_ATTR = "data-blink-panel-inserted";

export const BLINK_ACTION_URL_PARAM = "blinkaction";
export const BLINK_VERSION_URL_PARAM = "blinkversion";
export const PUBLIC_DATA_SOURCE_PARAM = "blink-public-data-source";

// TODO @branch make Enum
export const USER_JOURNEY_ACTION_TYPE = {
    blinkAction: "blinkAction",
    createPanel: "createPanel",
    editPanel: "editPanel",
    callback: "callback",
    setVariable: "setVariable",
};

// The period that marks a one-time donation and considers it
// TODO this should be configurable per merchant
export const DONATION_COOLDOWN_PERIOD = 90 * 24 * 60 * 60 * 1000; // 90 days

export const WIDGET_BACKGROUND = "linear-gradient(60deg, #085B68, #064953)";
export const POPUP_GATE_TRANSITION_TIME = 300; // TODO expose this (maybe through Theme) so that the testing framework could set it to 0
export const POPUP_GATE_TRANSITION = POPUP_GATE_TRANSITION_TIME + "ms opacity cubic-bezier(0.11, 1, 0.76, 1)";
export const WIDGET_TRANSITION = POPUP_GATE_TRANSITION_TIME + "ms ease";
export const WALL_TRANSITION = POPUP_GATE_TRANSITION_TIME + "ms ease";
export const MIN_SUPPORTED_WINDOW_WIDTH = 375;


export const ANALYTICS_ENDPOINT = BLINK_API_URL + "/e/";
export const ANALYTICS_SUBMIT_DEBOUNCE_MS = 100;
export const ANALYTICS_MAX_QUEUED_MESSAGES = 16;

export const MERCHANT_STORAGE_KEY_PREFIX = "blink-|-";

// The whitelist of keys we may add to the host website's localStorage
// or sessionStorage. The real keys used in the storage will have a
// prefix that almost guarantees uniqueness ("blink-|-").
export const MERCHANT_STORAGE_KEYS = {
    loggerLevel: "Logger.loggerLevel",
    onBoardingBehaviour: "UXManager.onboardingBehaviour",
    merchantSessionId: "Analytics.sessionId",
    merchantLocalId: "Analytics.localId",
    selectedUserJourneys: "UJ-selected",
    pageVisits: "UJ-visits-",
    variables: "variables",
    authToken: "authToken",
    utm: "utm",
    referrer: "referrer",
};

export const UTM_PARAM_KEYS = ["medium", "campaign", "source", "term", "content"];

export const INSUFFICIENT_FUNDS_ERROR_CODE = 1207;

export const ACCESS_AUTHORIZATION_REJECTED_ERROR_CODE = 1911;
export const ACCESS_AUTHORIZATION_REJECTED_ERROR_MESSAGE = "The user refused to grant access to his profile details.";

// TODO @cleanup These match SocialAccountProviderEnum on the backend
export const SOCIAL_APPS = {
    Facebook: "facebook",
    Google: "google",
    Twitter: "twitter",
    LinkedIn: "linkedin",
    Github: "github",
    CDS: "cds",
};

export const FLOW_TYPE = {
    subscription: "subscription",
    donation: "donation",
    newsletter: "newsletter",
};

export const FLOW_CHECKPOINT = {
    complete: "complete",
};

export const BASE_PANEL_WIDTH = 660;

export const DEFAULT_PANEL_CONTENT = {
    [PANEL_TYPE.subscribe]: `
        <PanelFrame>
            <Text></Text>
            <SubscribeButton></SubscribeButton>
            <SubscribeLoginFooter></SubscribeLoginFooter>
            <PoweredByBlink></PoweredByBlink>
        </PanelFrame>`,
    [PANEL_TYPE.donation]: `
        <PanelFrame>
            <Text></Text>
            <DonateButton></DonateButton>
            <DonateLoginFooter></DonateLoginFooter>
            <PoweredByBlink></PoweredByBlink>
        </PanelFrame>`,
    // TODO: The credit value should be taken from the public data.
    [PANEL_TYPE.payment]: `
        <PanelFrame>
            <Text></Text>
            <PurchaseButton></PurchaseButton>
            <If expr="!blinkSDK.isAuthenticated()">
                <Text>Get <b>$2</b> credit when you create an account.</Text>            
            </If>
            <PurchaseLoginFooter></PurchaseLoginFooter>
            <PoweredByBlink></PoweredByBlink>
        </PanelFrame>`,
    [PANEL_TYPE.newsletter]: `
        <PanelFrame>
            <Text></Text>
            <NewsletterButton></NewsletterButton>
            <PoweredByBlink></PoweredByBlink>
        </PanelFrame>`,
    [PANEL_TYPE.giftSubscription]: `
        <PanelFrame>
            <Text></Text>
            <GiftSubscriptionButton></GiftSubscriptionButton>
            <PoweredByBlink></PoweredByBlink>
        </PanelFrame>`,
};
