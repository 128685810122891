import {UI} from "../../../../stem-core/src/ui/UIBase";

import {PanelPage, PanelPageStyle} from "../../PanelPage";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {styleRule, styleRuleInherit} from "../../../../stem-core/src/decorators/Style";
import {isSmallScreen} from "../../../UtilsLib";
import {PanelFrame} from "./PanelFrame";


export class BasePanelPageStyle extends PanelPageStyle {
    @styleRuleInherit
    panelPage = {
        overflow: "auto",
    };

    @styleRule
    button = {
        margin: "auto",
        marginTop: 10,
        width: "100%",
        maxWidth: isSmallScreen() ? "" : 368,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
    };

    @styleRule
    title = this.themeProps.PANEL_TITLE;

    @styleRule
    secondaryButton = this.themeProps.TERTIARY_BUTTON;

    @styleRule
    link = this.themeProps.PANEL_LINK;

    @styleRule
    center = {
        width: "100%",
        textAlign: "center",
    };

    @styleRule
    frame = {
        // TODO @branch why is this in a different order sometimes?
        width: "100% !important", // Overwrite the original width which is only used in CTAs with panel content.
    };
}

// TODO @Mihai this should probably be renamed to BasePanel and BasePanel to BasePanel
@registerStyle(BasePanelPageStyle)
export class BasePanelPage extends PanelPage {
    widthValue = 440;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "",
        };
    }

    shouldWrapRenderInFrame() {
        return true;
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const title = typeof this.options.title === "function" ? this.options.title() : this.options.title;
        const titleElement = title ? <div className={styleSheet.title}>{title}</div> : null;

        return this.shouldWrapRenderInFrame() ? [
            <PanelFrame className={styleSheet.frame}>
                {titleElement}
                {this.render()}
            </PanelFrame>
        ] : [
            titleElement,
            this.render()
        ];
    }
}
