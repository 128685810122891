import {UI, styleRule, StyleSheet, registerStyle} from "stem-core/src/ui/UI";
import {StemDate} from "stem-core/src/time/Date";

import {isSmallScreen} from "../../../../../../Utils";
import {Messages} from "../../../../../../Messages";
import {CardDetails} from "./CardDetails";


class SingleCardStyle extends StyleSheet {
    @styleRule
    detailsSection = {
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    singleCard = {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 8,
        width: "100%",
        padding: "12px 0",
        fontSize: isSmallScreen() ? 14 : 16,
    };

    @styleRule
    expiryDate = {
        marginLeft: () => (isSmallScreen() ? 6 : 16),
        color: "#A0A0A0",
    };

    @styleRule
    default = {
        textTransform: "capitalize",
        color: this.themeProps.MERCHANT_SUCCESS,
    };
}

@registerStyle(SingleCardStyle)
export class SingleCard extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.singleCard);
    }

    getDefaultOptions() {
        return {
             ...super.getDefaultOptions(),
            card: null,
            showPrimary: false,
        };
    }

    render() {
        const {styleSheet} = this;
        const {card, showPrimary} = this.options;

        return [
            <CardDetails card={card} cardDetailsMessage={"**** " + card.getLastDigits()}
                         iconMargin={24} iconSize={16}/>,
            <div className={styleSheet.detailsSection}>
                {showPrimary && card.isPrimary ? <div className={styleSheet.default}>{Messages.default}</div> : null}
                <div className={styleSheet.expiryDate}>{StemDate.format(card.getExpiryDate(), "MM/YY")}</div>
            </div>
        ];
    }
}
