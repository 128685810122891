import {UI, styleRule, registerStyle, StyleSheet} from "../../../../../../stem-core/src/ui/UI";
import {Messages} from "../../../../../Messages";
import {RefundModal} from "./RefundModal";
import {PopupMenu} from "../../../../../ui/Menu";

class ReadElementStyle extends StyleSheet {
    @styleRule
    element = {
        display: "flex",
        position: "relative",
        marginRight: -8,
        justifyContent: "flex-end",
    };

    @styleRule
    price = {
        fontSize: 14
    };
}

// TODO @branch rename this to PriceElement
@registerStyle(ReadElementStyle)
export class ReadElement extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            transaction: null
        };
    }

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.element);
    }

    render() {
        const {purchase} = this.options;

        return [
            <div className={this.styleSheet.price}>{purchase.getAmount().toMainUnitString()}</div>,
            <PopupMenu items={[
                {
                    content: <div>{Messages.refund}</div>,
                    disabled: () => !purchase.isRefundable(),
                    onClick: () => RefundModal.show({purchase}),
                }
            ]}/>
        ];
    }

}
