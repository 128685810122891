import {UI} from "../../../../../stem-core/src/ui/UIBase";

import {BasePanelPage} from "../BasePanelPage";
import {ChooseAddressComponent} from "./components/ChooseAddressComponent";
import {Controller} from "../../../Controller";
import {UserAddressStore} from "../../../../../client/state/UserAddressStore";
import {MerchantButton} from "../../../../common/MerchantButton";
import {Messages} from "../../../../Messages";
import {AddAddressComponent} from "./components/AddAddressComponent";
import {EditAddressComponent} from "./components/EditAddressComponent";
import {iFrameUserDataService} from "../../../../services/IFrameUserDataService";
import {Router} from "../../../../../stem-core/src/ui/Router";
import {Toast} from "../../../../ui/Toast.jsx";


export const ADDRESS_SECTION_STATE = {
    chooseAddress: "chooseAddress",
    addAddress: "addAddress",
    continueWithAddedAddress: "continueWithAddedAddress",
    editAddress: "editAddress",
};

const ADDRESS_SECTION_MAP = {
    [ADDRESS_SECTION_STATE.chooseAddress]: ChooseAddressComponent,
    [ADDRESS_SECTION_STATE.addAddress]: AddAddressComponent,
    [ADDRESS_SECTION_STATE.continueWithAddedAddress]: AddAddressComponent,
    [ADDRESS_SECTION_STATE.editAddress]: EditAddressComponent,
};

export class AddressPanel extends BasePanelPage {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: () => Controller.address.addressSectionState === ADDRESS_SECTION_STATE.chooseAddress ?
                Messages.chooseAddress : Controller.address.getAddAddressLabel(),
        };
    }

    getAddressSectionState() {
        if (!Controller.address.selectedAddress && !iFrameUserDataService.getExtraUserAddressCount()) {
            return ADDRESS_SECTION_STATE.continueWithAddedAddress;
        }
        return Controller.address.addressSectionState;
    }

    isButtonDisabled() {
        return !(this.addressComponent && this.addressComponent.isValid());
    }

    render() {
        if (!Controller.address.selectedAddress && Controller.address.addressSectionState !== ADDRESS_SECTION_STATE.addAddress) {
            const selectedAddress = UserAddressStore.all()[0];
            Controller.address.update({
                selectedAddress,
                addressSectionState: (selectedAddress || iFrameUserDataService.getExtraUserAddressCount()) ?
                    ADDRESS_SECTION_STATE.chooseAddress : ADDRESS_SECTION_STATE.continueWithAddedAddress,
            });
        }

        const {styleSheet} = this;
        const AddressComponent = ADDRESS_SECTION_MAP[this.getAddressSectionState()];
        const onAddNewAddress = () => {
            Controller.address.addressSectionState = ADDRESS_SECTION_STATE.addAddress;
            Router.updateURL();
        };

        const buttonLabelMap = {
            [ADDRESS_SECTION_STATE.chooseAddress]: Controller.address.continueLabel || Messages.confirmChooseAddress,
            [ADDRESS_SECTION_STATE.editAddress]: Messages.editAddress,
            [ADDRESS_SECTION_STATE.continueWithAddedAddress]: Controller.address.continueLabel || Messages.addNewAddress,
            [ADDRESS_SECTION_STATE.addAddress]: Controller.address.getAddAddressLabel(),
        }

        return [
            <AddressComponent ref="addressComponent" onChange={() => this.redraw()}
                              onAddNewAddress={onAddNewAddress}/>,
            <MerchantButton disabled={this.isButtonDisabled()} onClick={() => this.handlePrimaryButtonClick()}
                            testId="addressButton" className={styleSheet.button}>
                {buttonLabelMap[this.getAddressSectionState()]}
            </MerchantButton>,
            <div className={styleSheet.center}>
                <div className={styleSheet.secondaryButton} onClick={() => {
                    if (Controller.address.addressSectionState === ADDRESS_SECTION_STATE.addAddress) {
                        Controller.address.addressSectionState = ADDRESS_SECTION_STATE.chooseAddress;
                        Router.updateURL();
                    } else {
                        Controller.address.onSelectCancel();
                    }
                }}>
                    {Messages.back}
                </div>
            </div>,
        ];
    }

    async handlePrimaryButtonClick() {
        try {
            Controller.address.update({selectedAddress: await this.addressComponent.getAddress()});
            if (Controller.address.addressSectionState === ADDRESS_SECTION_STATE.addAddress) {
                Controller.address.addressSectionState = ADDRESS_SECTION_STATE.chooseAddress;
                Router.updateURL();
            } else {
                await Controller.address.onSelectSuccess();
            }
        } catch (error) {
            Toast.showError(error);
        }
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserAddressStore, () => this.redraw());
    }
}
