import {Route, Router, TerminalRoute} from "../../stem-core/src/ui/Router";
import {App} from "../App";
import {AppGuards} from "./AppGuards";

import {LoginRedirectCallback} from "./unauthenticated/socialaccount/LoginRedirectCallback";
import {HomePage} from "./authenticated/HomePage";
import {CookiePolicyPage, PrivacyPolicyPage, TermsPolicyPage} from "./unauthenticated/PolicyPage";
import {LoginByEmailCodeUrlPage} from "./unauthenticated/slides/LoginByEmailCodeUrlPage";
import {AuthorizeApplicationPage} from "./authenticated/pages/authorize-application/AuthorizeApplicationPage";
import {WebsiteIndex} from "./WebsiteIndex";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {PublisherIndex} from "../../landingpage/PublisherIndex";
import {PUBLISHER_URL, READER_URL, COMPANY_URL} from "../../landingpage/Constants";
import {ReaderIndex} from "../../landingpage/ReaderIndex";
import {CompanyIndex} from "../../landingpage/CompanyIndex";
import {BlinkServiceSetCookie} from "./unauthenticated/BlinkServiceSetCookie";
import {AuthFlowPanel} from "./unauthenticated/slides/AuthFlowPanel.jsx";
import {Device} from "../../stem-core/src/base/Device";
import {UndoChangeEmailPage} from "./unauthenticated/UndoChangeEmailPage";
import {UndoCloseAccountPage} from "./unauthenticated/UndoCloseAccount";
import {authService} from "../../client/connection/Services";
import {AuthService} from "../../client/connection/services/AuthService";
import {EmailPreferencesPage} from "./unauthenticated/EmailPreferencesPage";
import {apiConnection} from "../../client/connection/BlinkApiConnection";
import {GlobalState} from "../../stem-core/src/state/State";
import {AuthHelper} from "../AuthHelper";
import {iFrameConnection} from "../services/IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {FormContainerPage} from "./unauthenticated/slides/FormContainerPage.jsx";
import {LOGIN_URL} from "../Constants.js";


export const appTitles = {
    LoginForm: "Login",
    LoginRedirectCallback: "Login Redirect",
    BlinkServiceLogin: "Login",
    BlinkServiceSignUp: "Sign Up",
    RequestPasswordResetForm: "Request password reset",
    PasswordResetRequested: "Password reset requested",
    ResetPasswordForm: "Reset password",
    PrivacyPolicyPage: "Privacy policy",
    CookiePolicyPage: "Cookie policy",
    TermsOfServicePage: "Terms of service",
    LoadingSpinner: "Blink Pay",
    UnconfirmedEmailPage: "Email unconfirmed",
    HomePage: "Blink",
    EmailConfirmationByCode: "Confirm email",
    LoginByEmailCodeUrl: "Login",
    SetCookie: "Blink",
    GetCookie: "Blink",
    EmailPreferences: "Email preferences",
};

export class WebsiteApp extends App {
    static init() {
        super.init();
    }

    static initializeTheme() {
        super.initializeTheme();
        // TODO: why is this here?
        Theme.setProperties({
            HTML_FONT_SIZE: 10,
            FONT_SIZE_DEFAULT: 16,
            NAV_MANAGER_NAVBAR_HEIGHT: 0,
            COLOR_BACKGROUND_BODY: Theme.props.APP_BACKGROUND,
        });
    }

    static addWsListener(streamName) {
        apiConnection.addWsListener(streamName, event => {
            if (event.type === "logout") {
                authService.clearCurrentInfo();
            } else {
                GlobalState.applyEvent(event);
            }
        });
    }

    getRoutes() {
        return new Route(
            [],
            WebsiteIndex,
            [
                new Route([LOGIN_URL], FormContainerPage(AuthFlowPanel), [], {
                    title: appTitles.LoginForm,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                // Redirect of external auth
                new TerminalRoute(["login-redirect"], LoginRedirectCallback, [], appTitles.LoginRedirectCallback),
                new Route(["email", "login", "%s"], LoginByEmailCodeUrlPage, [], {
                    title: appTitles.LoginByEmailCodeUrl,
                }),
                new Route(["login-with-blink"], AuthorizeApplicationPage, [], {
                    title: appTitles.BlinkServiceLogin,
                    beforeEnter: AppGuards.checkAuthenticatedForOAuth,
                }),
                new Route(["privacy-policy"], PrivacyPolicyPage, [], appTitles.PrivacyPolicyPage),
                new Route(["cookie-policy"], CookiePolicyPage, [], appTitles.CookiePolicyPage),
                new Route(["terms"], TermsPolicyPage, [], appTitles.TermsOfServicePage),
                // TODO @auth don't set the cookie, use a one-time code that gets converted to a token
                new Route(["set-cookie"], BlinkServiceSetCookie, [], {title: appTitles.SetCookie}),
                new Route(["email-preferences"], EmailPreferencesPage, [], {title: appTitles.EmailPreferences}),
                new Route(["undo-change-email"], UndoChangeEmailPage, []),
                new Route(["cancel-close-account"], UndoCloseAccountPage, []),
                new Route([PUBLISHER_URL], PublisherIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                new Route([READER_URL], ReaderIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                new Route([COMPANY_URL], CompanyIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),

                new TerminalRoute([], HomePage, {title: appTitles.HomePage, beforeEnter: AppGuards.checkAuthenticated, doNotCache: true}),
            ],
            appTitles.HomePage
        );
    }

    onMount() {
        super.onMount();
        if (Device.isMobileDevice()) {
            window.scrollTo({top: 0, behavior: "smooth"});
        }

        authService.addListener(AuthService.EventTypes.CLEAR_CURRENT_INFO, () => {
            Router.changeURL("/");
        });

        iFrameConnection.addListener(IFrameMessages.USER_TOKEN_UPDATE, ({token}) => {
            const {t, e} = JSON.parse(token);
            authService.token.setToken(t, e);
            AuthHelper.confirmedAuthenticationAction();
        });
    }
}
