import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {styleRule, StyleSheet} from "../../../../../../../stem-core/src/ui/Style";
import {PopupMenu} from "../../../../../../ui/Menu";
import {Messages} from "../../../../../../Messages";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {EditAddressModal} from "./EditAddressModal";
import {DeleteAddressModal} from "./DeleteAddressModal";
import {AddressInUseModal} from "./AddressInUseModal";
import {SubscriptionStore} from "../../../../../../../client/state/SubscriptionStore";


class AddressListItemStyle extends StyleSheet {
    @styleRule
    item = {
        display: "flex",
        justifyContent: "space-between",
        overflow: "initial !important",
    };

    @styleRule
    menuItem = {
        padding: 6,
    };

    @styleRule
    addressLine = {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}

@registerStyle(AddressListItemStyle)
export class AddressListItem extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            address: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.item);
    }

    promptEdit() {
        const {address} = this.options;

        if (SubscriptionStore.getActiveWithAddress(address).length) {
            AddressInUseModal.show({address});
            return;
        }
        EditAddressModal.show({address});
    }

    promptDelete() {
        const {address} = this.options;

        if (SubscriptionStore.getActiveWithAddress(address).length) {
            AddressInUseModal.show({address});
            return;
        }
        DeleteAddressModal.show({address});
    }

    getMenuItems() {
        const {styleSheet} = this;

        return [{
            content: <div className={styleSheet.menuItem}>{Messages.edit}</div>,
            onClick: () => this.promptEdit(),
        }, {
            content: <div className={styleSheet.menuItem}>{Messages.deleteMessage}</div>,
            onClick: () => this.promptDelete(),
        }];
    }

    render() {
        const {address} = this.options;

        return [
            <span className={this.styleSheet.addressLine}>{address}</span>,
            <PopupMenu items={this.getMenuItems()}/>
        ]
    }
}
