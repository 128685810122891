import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiClient} from "../../connection/BlinkApiConnection";


class MerchantSDKSettings extends StoreObject {
    @field("self") parentVersion; // TODO @cleanup rename to parent

    @field("Merchant") merchant;

    @field(Date) createdAt;
    @field(Boolean) isMaster;
    @field(Boolean) isArchive;
    @field(String) key;
    @field(String) name;
    @field(String) description;

    @field(Object) options; // TODO JSON
}

export const MerchantSDKSettingsStore = MakeStore("MerchantSDKSettings", MerchantSDKSettings, {
    dependencies: ["Merchant"],
});

export async function apiEditMerchantSDKSettings(data) {
    const response = await apiClient.post("/merchant/edit_sdk_settings", data);
    const objects = MerchantSDKSettingsStore.loadFromResponse(response);
    if (objects.length <= 1) {
        return objects[0];
    }
    // We forked the published version of merchant settings with this request
    // Find the object that is part of the forked settings and has the expected
    // version parent.
    return objects.find(obj => obj.parentVersionId === data.sdkSettingsId);
}

export async function apiMerchantChangeSettingsVersionMaster(data) {
    return apiClient.post("/merchant/change_settings_version_master/", data);
}

export async function apiMerchantDeleteSettingsVersion(data) {
    return apiClient.post("/merchant/delete_settings_version/", data);
}
