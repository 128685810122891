import {UI, styleRule, registerStyle} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";

import {BaseCard, BaseCardStyle} from "../../../../../ui/BaseCard";
import {Toast} from "../../../../../ui/Toast";
import {DividerPositions, List} from "../../../../../ui/List";
import {ToggleSetting} from "../components/ToggleSetting";
import {apiUpdatePreferences} from "../../../../../../client/state/UserPreferencesStore";


class UserSettingsStyle extends BaseCardStyle {
    @styleRule
    settingDescription = {
        paddingTop: "2px"
    };
}

@registerStyle(UserSettingsStyle)
export class UserSettings extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.settings,
            userPreferences: null,
        };
    }

    render() {
        const {styleSheet} = this;
        const {userPreferences} = this.options;

        return (
            <List dividerOptions={{position: DividerPositions.TOP}}>
                <ToggleSetting label={Messages.alwaysShowBalance} active={userPreferences.shouldShowBalanceInline()}
                               onToggle={active => this.updateUserSetting("alwaysShowBalance", active)}
                               testId="accountShowBalanceToggle">
                    <div className={styleSheet.settingDescription}>{Messages.alwaysShowBalanceDescription}</div>
                </ToggleSetting>
                <ToggleSetting label={Messages.showPaymentAnimation} active={userPreferences.shouldShowPaymentAnimation()}
                               onToggle={active => this.updateUserSetting("showPaymentAnimation", active)}
                               testId="accountPaymentAnimationToggle">
                    <div className={styleSheet.settingDescription}>{Messages.showPaymentAnimationDescription}</div>
                </ToggleSetting>
            </List>
        );
    }

    async updateUserSetting(setting, value) {
        const {userPreferences} = this.options;
        userPreferences[setting] = value;

        try {
            await apiUpdatePreferences(userPreferences.getFields());
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error && error.message ? error.message : Messages.errorWhileSaving);
        }
    }
}
