import {UI, StyleSheet, registerStyle, styleRule} from "../../../../../stem-core/src/ui/UI";

import {AuthorizationPanel} from "./components/AuthorizationPanel";
import {ErrorPanel} from "../../../components/ErrorPanel";
import {LoadingSpinner} from "../../../../ui/LoadingSpinner";
import {FormContainerPage} from "../../../unauthenticated/slides/FormContainerPage";
import {BlinkOAuthService} from "../../../../../client/connection/services/BlinkOAuthService";
import {isIframe, NOOP_FUNCTION} from "../../../../Utils";
import {getQueryParam} from "../../../../UtilsLib";
import {OAuthError} from "../../../../../client/connection/services/BlinkOAuthService";
import {ClientOAuthApplication} from "./ClientOAuthApplication";


export class AuthorizeApplicationPageStyle extends StyleSheet {
    @styleRule
    pageContent = {
        minHeight: 144,
        marginTop: 36,
    };
}

@registerStyle(AuthorizeApplicationPageStyle)
export class AuthorizeApplicationPageClass extends UI.Element {
    clientApplication = null;
    authorizationError = null;
    loading = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            clientId: getQueryParam("clientId"),
            onRedraw: NOOP_FUNCTION,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.pageContent)
    }

    redraw() {
        super.redraw();
        this.options.onRedraw();
    }

    render() {
        if (this.authorizationError) {
            return <ErrorPanel errorMessage={this.authorizationError}/>;
        }

        if (this.loading) {
            return <LoadingSpinner/>;
        }

        if (this.clientApplication && this.clientApplication.needsAuthorizationAccept()) {
            return <AuthorizationPanel applicationName={this.clientApplication.name}
                                       onAccept={() => this.authorizeClientApplication()}
                                       onReject={() => this.onAuthorizationReject()}/>;
        }

        return null;
    }

    redirectUserTo(url) {
        window.location.href = url;
    }

    onAuthorizationReject() {
        this.redirectUserTo(this.clientApplication.redirectUrl);
    }

    handleAuthorizationRequestSuccess(response) {
        this.redirectUserTo(response.redirect_to);
    }

    handleErrorResponse(error={}) {
        this.authorizationError = error.message;
        this.redraw();
    }

    async authorizeClientApplication() {
        this.setLoading(true);
        try {
            const response = await BlinkOAuthService.requestClientAuthorization(
                {...this.clientApplication.getInfo(), redirect: !isIframe()});
            this.handleAuthorizationRequestSuccess(response);
        } catch (error) {
            this.handleErrorResponse(error);
        }
    }

    async setClientAuthorizationStatus() {
        const response = await BlinkOAuthService.getAuthorizedClients()
        const alreadyAuthorized = !!response.find(app => app.clientId === this.clientApplication.clientId);
        this.clientApplication.setAuthorizationAccepted(alreadyAuthorized);
        this.redraw();
    }

    getClientApplication() {
        let redirectUrl = isIframe() ? null : getQueryParam("redirectUrl");
        let responseType = getQueryParam("responseType") || "code";

        return new ClientOAuthApplication(this.options.clientId, redirectUrl, responseType);
    }

    setLoading(loading) {
        this.loading = loading;
        this.redraw();
    }

    async ensureClientApplicationSetup() {
        if (!this.options.clientId || this.clientApplication) {
            return;
        }

        this.setLoading(true);

        try {
            this.clientApplication = this.getClientApplication();
        } catch (e) {
            this.handleErrorResponse(OAuthError.getErrorObjectByCode(e.message))
        }

        if (!this.clientApplication) {
            return;
        }

        try {
            await this.setClientAuthorizationStatus();
            // Proceed authorization flow
            if (!this.clientApplication.needsAuthorizationAccept()) {
                await this.authorizeClientApplication();
            }
        } catch (error) {
            this.handleErrorResponse(error);
        } finally {
            this.setLoading(false);
        }

    }

    onMount() {
        super.onMount();
        this.ensureClientApplicationSetup();
    }
}

export const AuthorizeApplicationPage = FormContainerPage(AuthorizeApplicationPageClass);
