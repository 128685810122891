import {UI, StyleSheet, registerStyle, styleRule} from "../../../../stem-core/src/ui/UI";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives";
import {BlinkPayLogoText, MenuIcon} from "../../../SVGElements";
import {NavMobileExpanded} from "./NavMobileAnimated";
import {Nav, PAGES} from "./Nav";
import {MOBILE_NAV_HEIGHT} from "../../../Constants";


export class NavMobileStyle extends StyleSheet {
    @styleRule
    navMobileContainer = {
        backgroundColor: this.themeProps.NAVBAR_BACKGROUND,
        boxShadow: this.themeProps.NAVBAR_BOX_SHADOW,
        position: "fixed",
        zIndex: 10000,
        padding: "0 12px",
        height: MOBILE_NAV_HEIGHT,
        width: "100vw",
        display: "flex",
        flexDirection: "column",
    };

    @styleRule
    nav = {
        height: "100%",
        display: "flex",
    };

    @styleRule
    activeHeaderElementContainer = {
        cursor: "pointer",
        color: this.themeProps.NAV_MOBILE_COLOR,
        fontSize: 18,
        padding: "0 16px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    };

    @styleRule
    menuIconContainer = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: 24,
        cursor: "pointer",
    };

    @styleRule
    accountIconContainer = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: 24,
        position: "relative",
    };

    @styleRule
    accountIconLink = {
        position: "absolute",
        right: 0,
    };
}

@registerStyle(NavMobileStyle)
export class NavMobile extends Nav {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.navMobileContainer);
    }

    render() {
        const {styleSheet} = this;
        const {currentActivePage} = this.options;
        const iconItems = PAGES.filter(page => page.Icon);

        return [
            <div className={styleSheet.nav}>
                <div className={styleSheet.menuIconContainer}>
                    <MenuIcon />
                </div>
                <div className={styleSheet.activeHeaderElementContainer}>
                    {currentActivePage?.title?.toString() || <BlinkPayLogoText size={16}/>}
                </div>
                <div className={styleSheet.accountIconContainer}>
                    {iconItems.map(page => {
                        const active = page === this.options.currentActivePage;
                        const {Icon, IconActive} = page;
                        return (
                            <Link href={page.href} className={styleSheet.accountIconLink}>
                                {active ? <IconActive size={28} /> : <Icon size={24} />}
                            </Link>
                        );
                    })}
                </div>
            </div>,
        ];
    }

    onMount() {
        super.onMount();
        this.addClickListener(() =>
            NavMobileExpanded.show({currentActivePage: this.options.currentActivePage}));
    }
}
