import {UI} from "../../../../stem-core/src/ui/UI";
import {Messages} from "../../../Messages";
import {WalletPage} from "../pages/wallet/WalletPage";
import {SubscriptionsPage} from "../pages/subscriptions/SubscriptionsPage";
import {AccountPage} from "../pages/account/AccountPage";
import {ArticlesPage} from "../pages/articles/ArticlesPage";
import {DonationsPage} from "../pages/donations/DonationsPage";
import {DONATIONS_URL, SUBSCRIPTIONS_URL} from "../../../Constants";

export const PAGES = [
    {
        href: "wallet",
        title: Messages.walletPage,
        Component: WalletPage,
    },
    {
        href: SUBSCRIPTIONS_URL,
        title: Messages.subscriptionsPage,
        Component: SubscriptionsPage,
    },
    {
        href: DONATIONS_URL,
        title: Messages.donationsPage,
        Component: DonationsPage,
    },
    {
        href: "articles",
        title: Messages.articlesPage,
        Component: ArticlesPage,
    },
    {
        href: "account",
        title: Messages.accountPage,
        Component: AccountPage,
    },
];

export class Nav extends UI.Element {
    static getCurrentActivePageFromURL() {
        for (const page of PAGES) {
            if (window.location.href.endsWith(page.href)) {
                return page;
            }
        }
        return null;
    }

    static getEndingSubroutes(page) {
        return page.href && page.href.split("/").slice(-1)[0];
    }

    getDefaultOptions() {
        return {
            currentActivePage: this.constructor.getCurrentActivePageFromURL(),
        };
    }

    setURL(urlParts) {
        for (const page of PAGES) {
            if (this.constructor.getEndingSubroutes(page) === urlParts[0]) {
                this.updateOptions({
                    currentActivePage: page,
                });
                return;
            }
        }
    }
}
