import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";
import {StemDate} from "../../stem-core/src/time/Time";

import {WalletStore} from "../State";
import {Money} from "../../client/state/misc/Money";


export class Balance extends UI.Primitive("span") {
    render() {
        this.getBalance();
    }

    getBalance() {
        return WalletStore.getWallet().getBalance();
    }

    onMount() {
        super.onMount();
        this.attachChangeListener(WalletStore, () => this.redraw());
    }
}

export class AnimatedBalanceStyle extends StyleSheet {
    transitionDuration = 200;
    keepDuration = 2000;
    overlap = true;

    @styleRule
    balance = {
        userSelect: "none",
        position: "relative",
        ">:first-child": {
            opacity: "1",
            transition: `opacity ${this.transitionDuration / 1000}s ease`,
        },
    };

    @styleRule
    updateValue = {
        position: "absolute",
        right: () => (this.overlap ? "50%" : -8),
        bottom: "0px",
        transform: () => (this.overlap ? "translate(50%, .7em)" : "translateY(0)"),
        transition: `all ease ${this.transitionDuration / 1000}s`,
    };

    @styleRule
    transition = {
        transform: () => (this.overlap ? "translate(50%, 0)" : "translateY(-1em)"),
        opacity: "1",
    };

    @styleRule
    transparent = {
        opacity: () => (this.overlap ? ".2 !important" : "initial"),
    };

    @styleRule
    keepUpdateValue = {
        transform: () => (this.overlap ? "translate(50%, -.7em)" : "translateY(-1em)"),
        opacity: () => (this.overlap ? "0" : "1"),
    };
}

@registerStyle(AnimatedBalanceStyle)
export class AnimatedBalance extends Balance {
    constructor(...args) {
        super(...args);
        this.styleSheet.overlap = this.options.overlap;
    }

    getDefaultOptions() {
        return Object.assign({}, super.getDefaultOptions(), {
            overlap: true,
            showDelta: true,
        });
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.balance);
    }

    render() {
        if (this.balance == null) {
            return "";
        }
        const {styleSheet} = this;
        let updateValueElement = null;
        let transparent = "";
        if (this.updateValue || this.keepUpdate) {
            updateValueElement = (
                <div
                    className={
                        styleSheet.updateValue +
                        " " +
                        (this.addTransition ? styleSheet.transition : "") +
                        (this.keepUpdate ? styleSheet.keepUpdateValue : "")
                    }>
                    {this.updateValue}
                </div>
            );
            transparent = styleSheet.transparent;
        }
        return [
            this.options.overlap ? (
                <div className={transparent}>{this.balance.toMainUnitString()}</div>
            ) : (
                this.balance.toMainUnitString()
            ),
            this.options.showDelta ? updateValueElement : null,
        ];
    }

    redraw() {
        if (this.balance == null || document.hidden) {
            this.balance = this.getBalance();
            super.redraw();
            return;
        }
        if (!this.inAnimation && this.balance.getAmount() !== this.getBalance().getAmount()) {
            this.animate();
        } else {
            super.redraw();
        }
    }

    animate() {
        const startBalance = this.balance;
        const endBalance = this.getBalance();
        const balancesDiff = endBalance.substract(startBalance).getAmount();
        const start = StemDate.now();
        this.inAnimation = true;
        this.keepUpdate = false;
        this.addTransition = false;
        this.updateValue = new Money(Math.abs(balancesDiff), startBalance.getCurrency());

        const updateAnimated = () => {
            const delta = StemDate.now() - start;
            if (delta >= this.styleSheet.transitionDuration) {
                this.balance = endBalance;
                this.redraw();
                setTimeout(() => {
                    this.inAnimation = false;
                    this.keepUpdate = true;
                    this.addTransition = false;
                    this.redraw();
                    setTimeout(() => {
                        this.keepUpdate = false;
                        this.updateValue = null;
                        this.redraw();
                    }, this.styleSheet.transitionDuration);
                }, this.styleSheet.keepDuration);
                return;
            }

            this.balance = (delta / this.styleSheet.transitionDuration) * balancesDiff + startBalance.getAmount();
            this.redraw();
            requestAnimationFrame(updateAnimated);
            this.addTransition = true;
        };

        requestAnimationFrame(updateAnimated);
    }
}
