import {UI} from "../../../stem-core/src/ui/UI";
import {Router} from "../../../stem-core/src/ui/Router";
import {getQueryParam} from "../../UtilsLib";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {authService} from "../../../client/connection/Services";
import {apiUndoCloseAccount} from "../../../client/state/UserProfileStore";
import {Toast} from "../../ui/Toast.jsx";


export class UndoCloseAccountPage extends UI.Element {
    async undoCloseAccount(undoToken) {
        LoadingSpinner.show();
        try {
            const response = await apiUndoCloseAccount(undoToken);
            authService.setAuthToken(response);
        } catch (error) {
            Toast.showError(error);
        } finally {
            Router.changeURL("/");
            LoadingSpinner.hide();
        }
    }

    onMount() {
        super.onMount();
        const undoToken = getQueryParam("undoToken");
        if (!undoToken) {
            Router.changeURL("/");
            return;
        }

        requestAnimationFrame(() => this.undoCloseAccount(undoToken));
    }
}
