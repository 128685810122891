import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {apiFetchStorePage} from "./misc/BaseState";
import {isDeepEqual} from "../../blinkpay/UtilsLib";


export class EndpointPaginator extends Dispatchable {
    totalEntriesCount = 0; // The number of total objects we're paginating
    lastPageRequested = null; // The last page we requested
    lastPageLoaded = null; // The last page we successfully received
    fetchingNow = false;
    lastResponse = null;

    constructor(store, endpoint, apiFilters = {}, storeFilters= {}) {
        super();
        this.store = store;
        this.endpoint = endpoint;
        this.filters = apiFilters;
        this.storeFilters = storeFilters;
        this.filters.pageSize = this.filters.pageSize || 10;
    }

    isLoading() {
        return this.fetchingNow;
    }

    getError() {
        return this.error;
    }

    getPageSize() {
        return this.filters.pageSize;
    }

    getTotalEntries() {
        return this.totalEntriesCount;
    }

    getCurrentPageEntries() {
        return this.lastResponse?.results;
    }

    // TODO deprecate this / move
    getRange(page = this.lastPageLoaded) {
        const pageSize = this.getPageSize();
        return [
            Math.max(0, Math.min(this.getTotalEntries(), (page - 1) * pageSize + 1)),
            Math.min(this.getTotalEntries(), page * pageSize),
        ]
    }

    // Fetches the page and returns the new objects
    // Catching errors is left to the upper layers
    async fetchPage(page = this.lastPageRequested, passErrors = true) {
        page = Math.min(page, this.getNumPages());

        const request = {
            page,
            ...this.filters, // Also includes the page size
        };

        let response = null;

        this.error = null;
        this.fetchingNow = true;
        this.lastPageRequested = page;
        this.dispatch("pageRequested", page, this);
        try {
            response = this.lastResponse = await apiFetchStorePage(this.store, this.endpoint, request);
        } catch (error) {
            this.error = error;
            this.dispatch("pageLoadFailed", error);
            if (passErrors) {
                throw error;
            }
            return;
        } finally { // All error are thrown up here
            this.fetchingNow = false;
        }

        // TODO: handle multiple pending requests that can come back out of order
        //  Should block a new request until the previous hasn't finished probably
        this.lastPageLoaded = page;
        this.totalEntriesCount = response.count;

        const lastFetchedObjects = response.results;
        this.loadedLastPage = (lastFetchedObjects.length < this.filters.pageSize) || (response.count === this.getPageSize() * page);

        this.dispatch("pageLoaded", response.results, response, this);

        return response;
    }

    // Return true if we've ever started a fetch
    haveInitiatedFetch() {
        return this.lastPageRequested != null;
    }

    async fetchNextPage() {
        return this.fetchPage(this.lastPageRequested + 1);
    }

    async fetchFirstPage() {
        return this.fetchPage(1);
    }

    async fetchLastPage() {
        if (this.lastPageRequested == this.getNumPages()) {
            return;
        }
        return this.fetchPage(this.getNumPages());
    }

    getNumPages() {
        return Math.max(1, Math.ceil(this.totalEntriesCount / this.getPageSize()));
    }

    getLastResponse() {
        return this.lastResponse;
    }

    setPageSize(pageSize) {
        this.updateFilter({pageSize});
    }

    // Update the filters and also fetch the first page
    updateFilter(filters) {
        let haveChange = false;
        for (const [key, value] of Object.entries(filters || {})) {
            if (!isDeepEqual(this.filters[key], value)) {
                this.filters[key] = value;
                haveChange = true;
            }
        }
        if (haveChange || !this.lastPageRequested) {
            this.fetchFirstPage(); // TODO throttle this and maybe debounce this like enqueueMicrotask
        }
    }

    haveLoadedLastRequestedPage() {
        return this.lastPageRequested && this.lastPageRequested === this.lastPageLoaded;
    }

    // TODO: pattern for naming bool getters/fields with the logic behind them?
    isFetching() {
        return this.fetchingNow;
    }

    all() {
        return this.store.filterBy(this.storeFilters);
    }
}

export class ArrayPaginator extends Dispatchable {
    constructor(entries, pageSize=10) {
        super();
        this.entries = entries;
        this.pageSize = pageSize;
        this.fetchFirstPage(); // Load first page entries
    }

    getTotalEntries() {
        return this.entries.length;
    }

    getNumPages() {
        return Math.ceil(this.getTotalEntries() / this.pageSize);
    }

    getPageSize() {
        return this.pageSize;
    }

    getRange(page = this.lastPageLoaded) {
        const pageSize = this.getPageSize();
        return [
            Math.max(0, Math.min(this.getTotalEntries(), (page - 1) * pageSize + 1)),
            Math.min(this.getTotalEntries(), page * pageSize),
        ]
    }

    getCurrentPageEntries() {
        return this.pageEntries;
    }

    // page is an integer between 1 and numPages
    fetchPage(page) {
        page = Math.min(page, this.getNumPages());
        const entries = [];
        for (let index = this.pageSize * (page - 1); index < this.pageSize * page; index++) {
            if (index >= 0 && index < this.entries.length) {
                entries.push(this.entries[index]);
            }
        }
        this.lastPageRequested = this.lastPageLoaded = page;
        this.pageEntries = entries;
        this.dispatch("pageLoaded", entries, {entries}, this);
        return entries;
    }

    fetchFirstPage() {
        this.fetchPage(1);
    }

    haveInitiatedFetch() {
        return this.lastPageLoaded != null;
    }
}
