import {UI} from "../../../../../stem-core/src/ui/UI";
import {ArticlePayments} from "./sections/ArticlePayments";
import {AutopayCard} from "./sections/AutopayCard";


export class ArticlesPage extends UI.Element {
    render() {
        return [
            <AutopayCard testId="autopayCard"/>,
            <ArticlePayments testId="articlePaymentsCard"/>
        ];
    }
}
