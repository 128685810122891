import {styleRule, UI, registerStyle, StyleSheet} from "../../../../../../stem-core/src/ui/UI";

import {UserPreferencesStore, WalletStore} from "../../../../../State";
import {isSmallScreen} from "../../../../../Utils";
import {Toast} from "../../../../../ui/Toast";
import {Messages} from "../../../../../Messages";
import {RangeSlider} from "../../../../../ui/RangeSlider";
import {Money} from "../../../../../../client/state/misc/Money";
import {apiUpdatePreferences} from "../../../../../../client/state/UserPreferencesStore";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";


const USD_CENTS_STOPS = [0, 1, 2, 5,
    10, 15, 20, 25, 30, 40, 50, 60, 75,
    100, 150, 200, 300, 400, 500, 750, 1000
];

const USD_CENTS_CONVERTER = {
    // From a value between 0 and 1.0, return a value between Min and Max
    getValue(value) {
        let index = Math.floor(value * (USD_CENTS_STOPS.length - 2)) + 1;
        // We want to obtain the 0 stop only when the slider is at the beginning.
        if (!value) {
            index = 0;
        }
        return USD_CENTS_STOPS[index];
    },

    // For a value between Min and Max, return a value between 0 and 1.0
    reverseValue(value) {
        for (let i = 0; i < USD_CENTS_STOPS.length; i++) {
            if (value <= USD_CENTS_STOPS[i]) {
                return i / (USD_CENTS_STOPS.length - 1);
            }
        }
        return 1.0;
    },

    getMin() {
        return USD_CENTS_STOPS[0];
    },

    getMax() {
        return USD_CENTS_STOPS[USD_CENTS_STOPS.length - 1];
    }
};

class AutopayLimitStyle extends StyleSheet {
    @styleRule
    content = {
        fontSize: 16,
        color: () => (isSmallScreen() ? this.themeProps.LABEL : this.themeProps.AUTOPAY_COLOR),
        marginTop: () => (isSmallScreen() ? 10 : 0),
        marginBottom: isSmallScreen() ? 8 : 16,
    };

    @styleRule
    rangeSlider = {
        width: "100%",
        margin: "36px 0 48px",
    };

    @styleRule
    autopayListLabel = {
        marginTop: 16,
    };

    @styleRule
    autopayValue = {
        color: this.themeProps.MERCHANT_4,
    };
}

@registerStyle(AutopayLimitStyle)
@autoredraw(UserPreferencesStore, WalletStore)
export class AutopayLimit extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.content);
    }

    render() {
        console.assert(this.getCurrency() && this.getPreferences());

        const {styleSheet} = this;
        const currency = this.getCurrency();
        const preferences = this.getPreferences();

        const formatFunction = (value) =>
            new Money(currency.subunitsToAmount(parseInt(value, 10)), currency).toMainUnitString();
        const initialValue = currency.amountToSubunits(preferences.articlePriceLimit);

        return [
            <RangeSlider {...{formatFunction, initialValue}}
                         className={styleSheet.rangeSlider}
                         converter={USD_CENTS_CONVERTER}
                         ref="slider"
                         onChange={value => this.autopayValue && this.autopayValue.setChildren([formatFunction(value)])}
                         onRelease={value => this.onLimitSave(value)} />,
            Messages.autoPayAndBelow1,
            <span ref="autopayValue" className={styleSheet.autopayValue}>{formatFunction(initialValue)}</span>,
            Messages.autoPayAndBelow2,
            <div className={styleSheet.autopayListLabel}>{Messages.autoPayAndBelow3}</div>
        ];
    }

    async onLimitSave(value) {
        const preferences = this.getPreferences();
        const limitInSubunits = parseInt(value, 10);

        preferences.autoPayEnabled = true;
        const articlePriceLimit = this.getCurrency().subunitsToAmount(limitInSubunits);
        if (articlePriceLimit === preferences.articlePriceLimit) {
            return;
        }
        preferences.articlePriceLimit = articlePriceLimit;

        try {
            await apiUpdatePreferences(preferences.getFields());
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        } finally {
            this.redraw();
        }
    }

    getPreferences() {
        return UserPreferencesStore.getPreferences();
    }

    getCurrency() {
        return WalletStore.getWallet().getCurrency();
    }
}
