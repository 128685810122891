import {apiConnection} from "../BlinkApiConnection";
import {Errors} from "./Errors";

// TODO: Remove this stub.
export class OAuthError {
    static errorCodes = Errors.oauthErrorCodes;

    static getErrorObjectByCode(errorCode) {
        errorCode = parseInt(errorCode, 10);

        for (const key in Errors.oauthErrorCodes) {
            if (Errors.oauthErrorCodes[key] === errorCode && Errors.oauthErrorMessages.hasOwnProperty(key)) {
                return {code: errorCode, message: Errors.oauthErrorMessages[key]};
            }
        }

        // we do not have a specific error object for the given code
        return {code: Errors.oauthErrorCodes.UNEXPECTED_ERROR, message: Errors.oauthErrorMessages.UNEXPECTED_ERROR};
    }
}

export class BlinkOAuthService {
    static requestClientAuthorization(clientInfo) {
        try {
            return apiConnection.get("/oauth/authorize/", clientInfo);
        } catch (error) {
            throw OAuthError.getErrorObjectByCode(error.code);
        }
    }

    static async getAuthorizedClients() {
        try {
            return apiConnection.get("/oauth/applications/authorized_applications/", {});
        } catch (error) {
            throw OAuthError.getErrorObjectByCode(error.code);
        }
    }
}
