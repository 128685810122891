import {UI, registerStyle, styleRule} from "../../../../../../../stem-core/src/ui/UI";
import {Modal, ModalStyle} from "../../../../../../ui/Modal";
import {Messages} from "../../../../../../Messages";
import {NOOP_FUNCTION} from "../../../../../../../stem-core/src/base/Utils";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {DefaultCodeConfirmationForm} from "../../../../../../common/DefaultCodeConfirmationForm";
import {apiConfirmUserEmailChange} from "../../../../../../../client/state/UserProfileStore";
import {MessageElement} from "../../../../../../widget/ui/MessageElement";


export class EmailChangeConfirmationModalStyle extends ModalStyle {
    @styleRule
    cancel = {
        cursor: "pointer",
        padding: 6,
        width: this.themeProps.FIT_CONTENT,
        color: this.themeProps.EMAIL_CHANGE_CANCEL_COLOR,
        margin: "6px auto 0",
    };

    @styleRule
    textLine = {
        lineHeight: 21,
    };

    @styleRule
    textContainer = {
        ">:not(:last-child)": {
            marginBottom: 12,
        }
    };
}

@registerStyle(EmailChangeConfirmationModalStyle)
export class EmailChangeConfirmationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hideOnScrimClick: false,
            title: Messages.confirmEmailChangeModalTitle,
            onConfirmSuccess: NOOP_FUNCTION,
            userProfile: null,
            newEmail: "",
            closeTestId: "confirmationModalClose",
        };
    }

    render() {
        const {styleSheet} = this;
        const {userProfile, newEmail} = this.options;

        return [
            <div className={styleSheet.textContainer}>
                <MessageElement className={styleSheet.textLine} message={Messages.confirmEmailChangeLine1(newEmail)}/>
                <MessageElement className={styleSheet.textLine}
                                message={Messages.confirmEmailChangeLine2(newEmail, userProfile.getEmail())}/>
            </div>,
            <DefaultCodeConfirmationForm onSubmit={code => this.handleConfirmationCodeSubmit(code)}/>,
            <div className={styleSheet.cancel} onClick={() => this.hide()}>{Messages.cancel}</div>
        ]
    }

    async handleConfirmationCodeSubmit(code) {
        const {onConfirmSuccess} = this.options;
        LoadingSpinner.show();
        try {
            const response = await apiConfirmUserEmailChange({code});
            this.hide();
            onConfirmSuccess();
            return response;
        } finally {
            LoadingSpinner.hide();
        }
    }
}
