import {MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {field} from "./misc/StoreField";
import {BaseEnum, makeEnum} from "./misc/BaseEnum";

// TODO The concept of SocialAccount should probably be renamed to ExternalAccount
@makeEnum
export class SocialAccountProvider extends BaseEnum {
    static FACEBOOK;
    static GOOGLE;
    static TWITTER;
    static LINKEDIN;
    static GITHUB;
    static CDS = {value: "cds", name: "CDS"};
}

class SocialAccount extends StoreObject {
    @field("UserProfile") user;
    @field(SocialAccountProvider) provider;
    @field(String) uid;
    @field(Object) extraData;
    @field(Date) createdAt;
    @field(Date) lastModified;

    getCDSProductId() {
        return this.uid.split("-")[0];
    }

    // For CDS accounts, returns the account number
    getCDSAccountNumber() {
        return this.uid.split("-")[1];
    }
}

export const SocialAccountStore = MakeStore("SocialAccount", SocialAccount);
