import {Theme} from "../stem-core/src/ui/style/Theme";
import {isSmallScreen} from "./UtilsLib";
import {Color, enhance} from "../stem-core/src/ui/Color";

export const panelChildMargin = () => isSmallScreen() ? 12 : 14;

export function addThemeConstants(merchantThemeConstants={}) {
    const StemOverrides = {
        INPUT_BACKGROUND_COLOR: "",
        INPUT_BORDER_COLOR: "transparent",
        INPUT_BORDER_RADIUS: "",
    };
    Theme.setProperties(StemOverrides);

    const ThemeConstants = {
        FIT_CONTENT: ["fit-content", "-moz-fit-content"],
        FIT_CONTENT_IMPORTANT: ["fit-content !important", "-moz-fit-content !important"],
        MERCHANT_SPINNER_LOGO: Theme.props.WALLET_11,
        WALLET_SPINNER_COLOR: Theme.props.WALLET_8,
        WALLET_SPINNER_LOGO: Theme.props.WALLET_1,
        WALLET_SPINNER_BACKGROUND: "rgba(7,82,94,.72)",
        WALLET_ERROR_BACKGROUND: Theme.props.ERROR,
        WALLET_SUCCESS_BACKGROUND: Theme.props.MERCHANT_SUCCESS,
        WALLET_BANNER_SHADOW: "0 2px 4px 0 rgba(63,63,63,.37)",
        BLINK_INPUT_LABEL: Theme.props.MERCHANT_3,
        BLINK_INPUT_BORDER: Theme.props.WALLET_2,
        BLINK_INPUT_COLOR: Theme.props.MERCHANT_3,
        BLINK_INPUT_PLACEHOLDER: Theme.props.MERCHANT_2,
        BLINK_INPUT_FOCUS_BORDER: Theme.props.WALLET_4,
        BLINK_INPUT_FOCUS_SHADOW: "0 2px 6px 0 rgba(8,100,114,.24)",
        MERCHANT_BUTTON_SECONDARY_COLOR: Theme.props.MERCHANT_2,
        MERCHANT_BUTTON_SECONDARY_HOVER: Theme.props.BLACK,
        MERCHANT_CARD_TITLE: Theme.props.BLACK,
        MERCHANT_DROPDOWN_COLOR: Theme.props.MERCHANT_3,
        MERCHANT_DROPDOWN_PLACEHOLDER_COLOR: Theme.props.MERCHANT_3,
        BLINK_INPUT_AUTOCOMPLETE_BACKGROUND: Theme.props.WALLET_1,
        BLINK_INPUT_AUTOCOMPLETE_HOVER: "rgba(0, 195, 235, 0.12)",
        WALLET_BUTTON_COLOR: Theme.props.WHITE,
        WALLET_BUTTON_BACKGROUND: Theme.props.WALLET,
        WALLET_BUTTON_DISABLED_COLOR: Theme.props.WALLET_2,
        WALLET_BUTTON_SHADOW: "0  -1px 0 0 rgba(7,82,94,.48), 0 2px 8px 0 rgba(6,73,83,.36)",
        WALLET_BUTTON_TEXT_SHADOW: "0 1px 2px rgba(6,73,83,.12)",
        WALLET_ACTIVE_BUTTON_INACTIVE_COLOR: Theme.props.WALLET_4,
        WALLET_ACTIVE_BUTTON_INACTIVE_BACKGROUND: Theme.props.WALLET_12,
        WALLET_ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND: Theme.props.WALLET_11,
        WALLET_ACTIVE_BUTTON_COLOR: Theme.props.WHITE,
        WALLET_ACTIVE_BUTTON_BACKGROUND: Theme.props.WALLET,
        WALLET_TOGGLE_DISABLED_BACKGROUND: Theme.props.WALLET_17,
        WALLET_TOGGLE_DISABLED_COLOR: Theme.props.WALLET_7,
        WALLET_TOGGLE_SHADOW:
            "0 1px 1px rgba(0,0,0,.14), 0 2px 1px  -1px rgba(0,0,0,.12), 0 1px 3px rgba(0,0,0,.2)",
        WALLET_TOGGLE_BACKGROUND: Theme.props.WALLET,
        WALLET_TOGGLE_COLOR: Theme.props.WALLET_2,
        WALLET_BUTTON_SECONDARY_COLOR: Theme.props.WALLET_1,
        WALLET_BUTTON_SECONDARY_HOVER_COLOR: Theme.props.WHITE,
        WALLET_CARD_TITLE: Theme.props.WHITE,
        MERCHANT_LOGO_BOTTOM_SHADOW: "0 4px 6px rgba(0,0,0,.12)",
        MERCHANT_ARTICLE_PRICE_COLOR: "#6d6d6d",
        MERCHANT_ARTICLE_COLOR: Theme.props.MERCHANT_3,
        SERIF_FONT: "pt serif",
        MERCHANT_ACTIVE_SUBSCRIPTION_TITLE: Theme.props.MERCHANT_5,
        MERCHANT_ACTIVE_SUBSCRIPTION_PRICE: Theme.props.MERCHANT_2,
        WALLET_TOOLTIP_BACKGROUND: Theme.props.WALLET_15,
        WALLET_TOOLTIP_BORDER: Theme.props.WALLET_16,
        WALLET_TOOLTIP_SHADOW: "0 2px 6px 0 rgba(19,41,61,.24)",
        WALLET_TOOLTIP_TEXT_1: "#D8D8D8",
        LABEL: "rgba(0, 0, 0, 0.72)",
        WALLET_BALANCE_COLOR: Theme.props.WHITE,
        WALLET_AUTO_RELOAD_HOVER: Theme.props.WALLET_3,
        WALLET_WELCOME_TEXT_COLOR: Theme.props.WALLET_3,
        MERCHANT_AUTOPAY_TEXT_COLOR: Theme.props.MERCHANT_3,
        MERCHANT_POPUP_BACKGROUND: Theme.props.WHITE,
        MERCHANT_POPUP_SHADOW: "0 0 12px 4px rgba(0,0,0,.14)",
        WALLET_CREDIT_CARD_BORDER: Theme.props.WALLET_15,
        WALLET_CREDIT_CARD_INPUT_PLACEHOLDER_COLOR: Theme.props.WALLET_8,
        WALLET_CREDIT_CARD_INPUT_COLOR: Theme.props.WALLET_2,
        MERCHANT_LIST_ITEM_BORDER: Theme.props.WALLET_2,
        MERCHANT_LIST_ITEM_COLOR: Theme.props.MERCHANT_3,
        MERCHANT_LIST_RADIO_COLOR: Theme.props.MERCHANT_3,
        CLOSE_ACCOUNT_REASON_LIST_ITEM_COLOR: Theme.props.MERCHANT_4,
        CLOSE_ACCOUNT_REASON_SECONDARY_TEXT_COLOR: Theme.props.MERCHANT_2,
        CLOSE_ACCOUNT_REASON_TEXT_AREA_BACKGROUND: "rgba(232, 240, 242, 0.72)",
        CLOSE_ACCOUNT_REASON_TEXT_AREA_COLOR: Theme.props.MERCHANT_4,
        CLOSE_ACCOUNT_REASON_TEXT_AREA_BORDER: "solid 0.75px #0080FF",
        CLOSE_ACCOUNT_REASON_TEXT_AREA_SHADOW: "0px 3px 6px 0 rgba(50, 50, 93, 0.08)",
        CLOSE_ACCOUNT_CANCEL_COLOR: Theme.props.MERCHANT_2,
        WALLET_LIST_ITEM_COLOR: Theme.props.WALLET_2,
        WALLET_LIST_ITEM_BORDER: Theme.props.WALLET_15,
        WALLET_LIST_RADIO_COLOR: Theme.props.WALLET_3,
        WALLET_CARD_EXPIRY_DATE: Theme.props.WALLET_5,
        WALLET_CARD_ERROR: "#FF8800",
        WALLET_GRADIENT_BACKGROUND: "linear-gradient(60deg, #085B68, #064953)",
        WALLET_COLOR: Theme.props.WALLET_2,
        WALLET_ADD_FUNDS_LINK: Theme.props.WALLET_3,
        WALLET_ADD_FUNDS_LINK_HOVER: Theme.props.WHITE,
        PANEL_BUTTON_LOGO_FILL: Theme.props.WHITE,
        PANEL_MANUAL_CONFIRMATION_COLOR: Theme.props.BLACK,
        PANEL_MANUAL_CONFIRMATION_LINK_BORDER: Theme.props.BLACK,
        WIDGET_BACKGROUND: Theme.props.TEAL_2,
        APP_BACKGROUND: "#FAFDFD",
        NAV_MOBILE_COLOR: Theme.props.WHITE,
        NAV_MOBILE_SHADOW: "0 8px 16px -8px rgba(0, 0, 0, 0.15), 0 13px 27px -5px rgba(50, 50, 93, 0.12)",
        BUTTON_PRIMARY_COLOR: Theme.props.WHITE,
        BUTTON_PRIMARY_BACKGROUND: Theme.props.WALLET_11,
        BUTTON_PRIMARY_TEXT_SHADOW: "0 1px 2px rgba(6,73,83,.12)",
        BUTTON_PRIMARY_SHADOW: "0 2px 6px 0 rgba(6, 73, 83, 0.24)",
        BUTTON_PRIMARY_DISABLED_TEXT_SHADOW: "0 1px 2px rgba(6,73,83,.12)",
        BUTTON_PRIMARY_DISABLED_BACKGROUND: Theme.props.WALLET_9,
        BUTTON_PRIMARY_DISABLED_COLOR: Theme.props.WALLET_4,
        BUTTON_PRIMARY_HOVER_BACKGROUND: Theme.props.WALLET_13,
        BUTTON_PRIMARY_HOVER_SHADOW: "0 2px 10px 0 rgba(6,73,83,.48)",
        BUTTON_SECONDARY_BACKGROUND: Theme.props.WHITE,
        BUTTON_SECONDARY_COLOR: Theme.props.WALLET_7,
        BUTTON_SECONDARY_SHADOW: "0 2px 8px rgba(6,73,83,.12), inset 0 0 0 1px " + Theme.props.WALLET_11,
        BUTTON_SECONDARY_HOVER_SHADOW: "0 2px 8px rgba(6,73,83,.24), inset 0 0 0 1px " + Theme.props.WALLET_11,
        SOCIAL_BUTTON_SHADOW: "0 5px 8px 0 rgba(50, 50, 93, 0.11), 0 2px 4px 0 rgba(0, 0, 0, 0.08)",
        SOCIAL_BUTTON_HOVER_SHADOW: "0 4px 7px 0 rgba(50, 50, 93, 0.22), 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        SOCIAL_BUTTON_FACEBOOK_COLOR: Theme.props.WHITE,
        SOCIAL_BUTTON_FACEBOOK_BACKGROUND: "#3B5A9A",
        SOCIAL_BUTTON_GOOGLE_COLOR: "rgba(0, 0, 0, 0.72)",
        SOCIAL_BUTTON_GOOGLE_BACKGROUND: Theme.props.WHITE,
        SOCIAL_BUTTON_LINKEDIN_COLOR: Theme.props.WHITE,
        SOCIAL_BUTTON_LINKEDIN_BACKGROUND: "#0077B5",
        SOCIAL_BUTTON_TWITTER_COLOR: Theme.props.WHITE,
        SOCIAL_BUTTON_TWITTER_BACKGROUND: "#1da1f2",
        TOGGLE_BACKGROUND: Theme.props.WALLET_2,
        TOGGLE_COLOR: Theme.props.WALLET_11,
        TOGGLE_SHADOW: "0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.2)",
        TOGGLE_DISABLED_BACKGROUND: Theme.props.WALLET_6,
        ACTIVE_BUTTON_INACTIVE_COLOR: Theme.props.WALLET_9,
        ACTIVE_BUTTON_INACTIVE_BACKGROUND: Theme.props.WALLET_3,
        ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND: Theme.props.WALLET_4,
        ACTIVE_BUTTON_COLOR: Theme.props.WHITE,
        ACTIVE_BUTTON_BACKGROUND: Theme.props.WALLET_11,
        BALANCE_COLOR: Theme.props.WALLET_11,
        DROPDOWN_COLOR: Theme.props.MERCHANT_3,
        CHECKBOX_BORDER_RADIUS: 2,
        CHECKBOX_BORDER_COLOR: Theme.props.WALLET_11,
        CHECKBOX_ENABLED_BACKGROUND_COLOR: Theme.props.WALLET_11,
        CHECKBOX_CHECKMARK_COLOR: Theme.props.WHITE,
        DROPDOWN_BUTTON_HOVER_BACKGROUND: Theme.props.WALLET_2,
        DROPDOWN_BUTTON_SELECTED_BACKGROUND: Theme.props.WALLET_2,
        DROPDOWN_EXPANDED_BACKGROUND: Theme.props.WHITE,
        DROPDOWN_EXPANDED_SHADOW: "0 2px 8px 5px rgba(0,0,0,.1), 0 5px 27px -5px rgba(0,0,0,.37)",
        DROPDOWN_COLLAPSED_BACKGROUND: "rgba(2, 92, 150, 0.06)",
        DROPDOWN_COLLAPSED_HOVER_BACKGROUND: "rgba(2, 92, 150, 0.16)",
        DROPDOWN_ITEM_BORDER: "rgba(27,126,140,.24)",
        DROPDOWN_ITEM_HOVER_BACKGROUND: "rgba(2, 92, 150, 0.06)",
        DROPDOWN_PLACEHOLDER_COLOR: "rgba(102,102,102,.72)",
        EMPTY_LIST_COLOR: "#00000054",
        AUTHENTICATION_LINE_COLOR: "rgba(0, 0, 0, 0.24)",
        DIVIDER_TEXT_COLOR: "rgba(0, 0, 0, 0.48)",
        DIVIDER_LINE_COLOR: "rgba(0,0,0, 0.1)",
        BASE_INPUT_BACKGROUND: "#F0F6F8",
        BASE_INPUT_COLOR: Theme.props.TEAL_1,
        BASE_INPUT_PLACEHOLDER_COLOR: "rgba(102,102,102,.72)",
        BASE_INPUT_BUTTON_COLOR: Theme.props.TEAL_2,
        BASE_INPUT_NOT_EDITABLE_COLOR: Theme.props.MERCHANT_4,
        BASE_INPUT_NOT_EDITABLE_LABEL_COLOR: Theme.props.MERCHANT_2,
        SPINNER_COLOR: Theme.props.WALLET_8,
        SPINNER_LOGO: Theme.props.WALLET_11,
        SPINNER_BACKGROUND: "rgba(255,255,255,.72)",
        LOGO_CARD_SHADOW: "0 6px 10px -6px rgba(0, 0, 0, 0.3), 0 9px 18px -4px rgba(50, 50, 93, 0.25)",
        LOGO_CARD_CONTENT_BACKGROUND: Theme.props.WHITE,
        MODAL_BACKGROUND: Theme.props.WALLET_0,
        MODAL_SHADOW: "0 0 6px 0 rgba(0,0,0,.12), 0 8px 16px -8px rgba(0,0,0,.15), 0 13px 27px -5px rgba(50,50,93,.12)",
        SAVABLE_CURRENCY_INPUT_EDITING_BUTTON_FILL: Theme.props.TEAL_2,
        SELECT_COLOR: "#636676",
        SELECT_BACKGROUND: "#EEEEEE",
        BASE_CARD_SHADOW: "0 6px 12px -6px rgba(0,0,0,.12), 0 10px 20px -4px rgba(0,0,0,0.12)",
        BASE_CARD_BACKGROUND: Theme.props.WALLET_0,
        BASE_CARD_DESCRIPTION_COLOR: "rgba(0, 0, 0, 0.6)",
        BASE_CARD_TITLE_COLOR: Theme.props.WALLET_11,
        BASE_CARD_WIDTH: 480,
        BASE_CARD_MAX_WIDTH: 480,
        TOAST_COLOR: Theme.props.WHITE,
        TOAST_BACKGROUND: "rgba(0,0,0,.84)",
        TOAST_ACTION_COLOR: "#FFFA6E",
        TOOLTIP_BACKGROUND: Theme.props.BLACK,
        TOOLTIP_COLOR: Theme.props.WHITE,
        EMAIL_CONFIRMATION_COLOR: Theme.props.MERCHANT_3,
        EMAIL_CONFIRMATION_INPUT_BACKGROUND: Theme.props.WALLET_1,
        EMAIL_CONFIRMATION_INPUT_BORDER: Theme.props.WALLET_2,
        EMAIL_CONFIRMATION_INPUT_FOCUS_SHADOW: "0 2px 6px 0 rgba(8,100,114,.12)",
        EMAIL_CONFIRMATION_INPUT_FOCUS_BORDER: Theme.props.WALLET_4,
        EMAIL_CONFIRMATION_INPUT_SEPARATOR_COLOR: Theme.props.MERCHANT_1,
        SINGLE_ITEM_MENU_SHADOW: "0 2px 8px 5px rgba(0,0,0,.05), 0 5px 27px -5px rgba(0,0,0,.17)",
        SINGLE_CARD_EXPIRY_DATE_COLOR: Theme.props.MERCHANT_1,
        SINGLE_CARD_PRIMARY_TEXT_COLOR: Theme.props.MERCHANT_SUCCESS,
        SINGLE_CARD_NUMBER_COLOR: Theme.props.MERCHANT_4,
        AUTOPAY_COLOR: "rgba(0, 0, 0, 0.6)",
        LOADER_BETWEEN_BACKGROUND: Theme.props.WHITE,
        TEXT_LOADER_BACKGROUND: "rgba(41,118,132,.49)",
        CARD_LOADER_BACKGROUND: "rgba(100,100,100,.3)",
        LOADER_BAR_BACKGROUND: Theme.props.WHITE,
        AUTHORIZATION_PANEL_CANCEL_COLOR: "rgba(0, 0, 0, 0.6)",
        NAVBAR_BOX_SHADOW: "0 12px 12px -6px rgba(0, 0, 0, 0.12)",
        NAVBAR_BACKGROUND: Theme.props.WALLET_11,
        NAVBAR_CENTER_ITEM_ACTIVE: Theme.props.WHITE,
        NAVBAR_CENTER_ITEM_INACTIVE: Theme.props.WALLET_3,
        CARD_HEADER_TEXT_COLOR: "#005A64", // TODO merge with BUTTON_COLOR into PRIMARY
        CARD_HEADER_BACKGROUND_COLOR: "#F3F8F7",
        TABLE_HEADER_TEXT_COLOR: props => props.CARD_HEADER_TEXT_COLOR,
        TABLE_HEADER_BACKGROUND_COLOR: props => props.CARD_HEADER_BACKGROUND_COLOR,
        TABLE_HEADER_BACKGROUND_ALTERNATE: "#f5f5f5",
        TABLE_HEADER_STYLE: {
            textTransform: "uppercase",
            verticalAlign: "center",
            fontSize: 12,
        },
        TABLE_ENTRY_TEXT_COLOR: Theme.props.MERCHANT_3,
        TABLE_BORDER_COLOR: Theme.props.WALLET_2,
        TABLE_CHEVRON: Theme.props.MERCHANT_1,
        EXPANSION_PANEL_HEADING_COLOR: Theme.props.MERCHANT_2,
        EXPANSION_PANEL_PARAGRAPH_COLOR: Theme.props.MERCHANT_4,
        EXPANSION_PANEL_BUTTON_CLOSE: Theme.props.ERROR,
        EXPANSION_PANEL_BUTTON_RENEW: Theme.props.MERCHANT_LINK,
        CARD_INPUT_COLOR: Theme.props.MERCHANT_4,
        SETTINGS_LABEL_COLOR: Theme.props.MERCHANT_4,
        SETTINGS_DESCRIPTION_COLOR: Theme.props.MERCHANT_2,
        ADD_NEW_ADDRESS_COLOR: Theme.props.MERCHANT_4,
        PAYMENT_METHOD_COLOR: Theme.props.MERCHANT_4,
        LOGOUT_COLOR: Theme.props.MERCHANT_LINK,
        USER_DASHBOARD_BODY_BACKGROUND: "rgba(232,240,242,0.6)",
        EMAIL_TEXT_COLOR: Theme.props.MERCHANT_2,
        LANDING_PAGE_HEADER_BACKGROUND: "linear-gradient(52deg, #033840, #005a65 35%,  #005a65 40%, #033840 80%, #033840)",
        LANDING_PAGE_MOBILE_HEADER_BACKGROUND: Theme.props.WALLET_15,
        LANDING_PAGE_INPUT_COLOR: Theme.props.WALLET_14,
        LANDING_PAGE_INPUT_DARK_THEME_PLACEHOLDER_COLOR: Theme.props.WALLET_9,
        LANDING_PAGE_INPUT_LIGHT_THEME_PLACEHOLDER_COLOR: "rgba(29,29,31,.66)",
        LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_DARK_BACKGROUND: "0 8px 18px  -3px rgba(0,0,0,1)",
        LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 8px 18px  -3px rgba(0,0,0,.5)",
        LANDING_PAGE_DARK_BUTTON_SHADOW_ON_DARK_BACKGROUND: "0 10px 20px  -6px rgba(0,0,0,1)",
        LANDING_PAGE_DARK_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 7px 12px  -3px rgba(0,0,0,.9)",
        LANDING_PAGE_LOGIN_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 .65rem 12px  -4px rgba(0,0,0,1)",
        LANDING_PAGE_BUTTON_SHADOW: "0 8px 16px -11px rgba(0,0,0,.8)",
        LANDING_PAGE_INPUT_SHADOW: "0 8px 16px -11px rgba(0,0,0,.6)",
        LANDING_PAGE_BUTTON_DARK_THEME_BACKGROUND: Theme.props.WALLET,
        LANDING_PAGE_BUTTON_LIGHT_THEME_BACKGROUND: Theme.props.WALLET_13,
        LANDING_PAGE_MISSION_COLOR: Theme.props.WHITE,
        LANDING_PAGE_CHAT_BUTTON_BACKGROUND: "#00717d",
        LANDING_PAGE_CHAT_BUTTON_HOVER_SHADOW: "0 2px 14px 2px rgba(0,0,0,.4)",
        LANDING_PAGE_DEMO_BUTTON_BACKGROUND: Theme.props.WHITE,
        LANDING_PAGE_DEMO_BUTTON_COLOR: Theme.props.LANDING_1,
        LANDING_PAGE_LIGHT_BACKGROUND: Theme.props.LANDING_0,
        LANDING_PAGE_DARK_FONT_COLOR: Theme.props.LANDING_2,
        LANDING_PAGE_LIGHT_FONT_COLOR: Theme.props.WHITE,
        LANDING_PAGE_CARD_BACKGROUND: Theme.props.WHITE,
        LANDING_PAGE_CARD_SHADOW: "0 8px 16px -11px rgba(0,0,0,0.6)",
        LANDING_PAGE_CARD_HOVER_SHADOW: "0 3px 20px  -1px rgba(0,0,0,0.3)",
        LANDING_PAGE_DARK_CARD_BACKGROUND: "rgba(0,0,0,0.22)",
        LANDING_PAGE_DARK_CARD_SHADOW: "inset 50px 50px 170px -70px #007a8a4a, 0 16px 24px -13px rgb(2, 38, 43)",
        LANDING_PAGE_VERTICAL_LINE_COLOR: "#C4C4C4",
        LANDING_PAGE_HORIZONTAL_LINE_COLOR: "#D7D7D7",
        LANDING_PAGE_LOGIN_BUTTON_BACKGROUND: Theme.props.WALLET,
        LANDING_PAGE_NAV_FLOATING_BACKGROUND: Theme.props.WHITE,
        LANDING_PAGE_NAV_FLOATING_COLOR: Theme.props.LANDING_1,
        LANDING_PAGE_NAV_FLOATING_BUTTON_BACKGROUND: Theme.props.WALLET_13,
        LANDING_PAGE_TEAM_NAME_FONT_COLOR: Theme.props.LANDING_1,
        LANDING_PAGE_LINK: Theme.props.MERCHANT_LINK,
        VALUE_PROPOSITION_BACKGROUND: "linear-gradient(52deg, #033840, #005a65 35%,  #005a65 40%, #033840 80%, #033840)",
        VALUE_PROPOSITION_MOBILE_BACKGROUND: Theme.props.WALLET_15,
        FOOTER_ITEM_COLOR: Theme.props.MERCHANT_4,
        FOOTER_ITEM_HOVER_COLOR: Theme.props.BLACK,
        DONATION_RECURRING_DONOR_COLOR: Theme.props.MERCHANT_5,
        DONATION_AMOUNT_FREQUENCY_COLOR: Theme.props.MERCHANT_2,
        DONATION_DESCRIPTION_COLOR: Theme.props.MERCHANT_3,
        DONATE_ACTIVE_BUTTON_INACTIVE_COLOR: "rgba(52, 128, 139, .76)",
        DONATE_ACTIVE_BUTTON_INACTIVE_BORDER: Theme.props.WALLET_2,
        DONATE_ACTIVE_BUTTON_INACTIVE_BACKGROUND: Theme.props.WALLET_1,
        DONATE_ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND: Theme.props.WALLET_2,
        DONATE_ACTIVE_BUTTON_ACTIVE_COLOR: Theme.props.WHITE,
        DONATE_ACTIVE_BUTTON_ACTIVE_BACKGROUND: Theme.props.WALLET_11,
        WIDGET_SCRIM_BACKGROUND: "rgba(0,0,0,.6)",
        WIDGET_LIGHT_SCRIM_BACKGROUND: "rgba(255,255,255,.84)",
        BLINK_INPUT_BACKGROUND: "#E8F0F2",
        DISCOUNT_INPUT_MESSAGE_COLOR: Theme.props.MERCHANT_3,
        AUTH_RESEND_CODE_COLOR: Theme.props.MERCHANT_2,
        CARD_BRANDING_DESCRIPTION_LIGHTER: Theme.props.MERCHANT_2,
        EMPTY_INDEX_EXPANDED_COLOR: "#555555",
        MODAL_DESCRIPTION_COLOR: Theme.props.MERCHANT_2,
        MODAL_LIGHTER_TITLE_COLOR: Theme.props.BLACK,
        MODAL_LIGHTER_SHADOW: "0 6px 12px -6px rgba(0, 0, 0, 0.3), 0 10px 20px -4px rgba(0, 0, 0, 0.25)",
        EMAIL_CHANGE_CANCEL_COLOR: Theme.props.MERCHANT_2,
        INLINE_SUBSCRIBE_FLOW_PAGE_PAGE_TITLE_COLOR: Theme.props.BLACK,
        INLINE_SUBSCRIBE_FLOW_LINE_SEPARATOR_COLOR: "#DDDDDD",
        OLD_SUBSCRIBE_OFFER_TEXT_COLOR: "#999999",
        DISCOUNT_CODE_LABEL_COLOR: "#242424",
        AD_BLOCKER_SEPARATOR_LINE_COLOR: "#E4E4E4",
        AD_BLOCKER_INPUT_BORDER_COLOR: "#D4D4D4",
        AD_BLOCKER_INPUT_PLACEHOLDER_COLOR: "#BBBBBB",
        DONATE_PAGE_COLOR: Theme.props.BLACK,
        DONATE_PAGE_TEXT_COLOR: "#666666",
        DONATE_PAGE_SWITCHER_BORDER_COLOR: "#DDDDE5",
        DONATE_PAGE_ACTIVE_BUTTON_COLOR: "#242424",
        DONATE_PAGE_ACTIVE_BUTTON_BACKGROUND: "transparent",
        DONATE_PAGE_ACTIVE_BUTTON_BORDER_COLOR: "#AAAAAA",
        DONATE_PAGE_ACTIVE_BUTTON_HOVER_BACKGROUND: "rgba(0,0,0,.05)",
        DONATE_PAGE_ACTIVE_BUTTON_ACTIVE_BACKGROUND: "#0099FF",
        DONATE_PAGE_ACTIVE_BUTTON_ACTIVE_HOVER_BACKGROUND: "#0c72e3",
        DONATE_PAGE_ACTIVE_BUTTON_ACTIVE_COLOR: Theme.props.WHITE,
        DONATE_PAGE_INPUT_FOCUSED_BORDER_COLOR: "#adadb5",
        DONATE_PAGE_INPUT_FOCUSED_SHADOW: "0 2px 6px 0 rgba(0,0,0,.12)",
        DONATE_PAGE_FONT_FAMILY: "Helvetica, Lato, 'Segoe UI', 'Lucida Sans Unicode', 'Helvetica Neue', Arial, sans-serif",
        DONATE_PAGE_CARD_INPUT_COLOR: "#666666",
        GENERAL_LINE_HEIGHT: "1.5",
        RADIO_BUTTON_PADDING: "12px",
        RADIO_BUTTON_FLEX: 1,
        ACTIVE_BUTTON_FONT_SIZE: 18,
        ACTIVE_BUTTON_INACTIVE_BORDER: "none",
        ACTIVE_BUTTON_ACTIVE_BORDER: "none",
        WALLET_MERCHANT_BUTTON_BACKGROUND: Theme.props.BLACK,
        CHECKBOX_SIZE: "1.14em",
    };

    Theme.setProperties(ThemeConstants);

    // TODO @branch remove the MERCHANT_
    const MerchantDefaultConstants = {
        // Typography
        // -- Font family
        MERCHANT_FONT_FAMILY: Theme.props.FONT_FAMILY_DEFAULT,
        // -- Font size
        MERCHANT_FONT_SIZE_SMALL: "12px",
        MERCHANT_FONT_SIZE_NORMAL: "14px",
        MERCHANT_FONT_SIZE_LARGE: "16px",
        MERCHANT_FONT_SIZE_TITLE: "21px",
        MERCHANT_FONT_SIZE_VERIFICATION_CODE: "40px",
        // -- Font weight
        MERCHANT_FONT_WEIGHT_LIGHT: "lighter",
        MERCHANT_FONT_WEIGHT_REGULAR: "normal",
        MERCHANT_FONT_WEIGHT_BOLD: "bold",
        // Border radius
        MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS: "6px",
        MERCHANT_RADIO_BUTTON_BORDER_RADIUS: "4px",
        // Color
        // -- Button
        MERCHANT_BUTTON_PRIMARY_BACKGROUND: "linear-gradient(180deg, #125C67 0%, #0F4851 100%)",
        MERCHANT_BUTTON_PRIMARY_BORDER_COLOR: "transparent",
        MERCHANT_BUTTON_PRIMARY_TEXT_COLOR: Theme.props.WHITE,
        MERCHANT_BUTTON_PRIMARY_INACTIVE_TEXT_COLOR: "rgba(255,255,255,0.5)",

        MERCHANT_BUTTON_SECONDARY_BACKGROUND: Theme.props.WHITE,
        MERCHANT_BUTTON_SECONDARY_BORDER_COLOR: Color.addOpacity("#535558", 0.3).toString(),
        MERCHANT_BUTTON_SECONDARY_TEXT_COLOR: "#535558",
        MERCHANT_BUTTON_SECONDARY_INACTIVE_TEXT_COLOR: "rgba(83,85,88,0.5)",

        MERCHANT_BUTTON_TERTIARY_TEXT_COLOR: "linear-gradient(180deg, #125C67 0%, #0F4851 100%)",
        MERCHANT_BUTTON_TERTIARY_INACTIVE_TEXT_COLOR: "rgba(108,108,108,0.5)",
        // -- Input
        MERCHANT_INPUT_BACKGROUND: "#FDFFFE",
        MERCHANT_INPUT_BORDER_COLOR: Color.addOpacity("#545F69", 0.24).toString(),
        MERCHANT_INPUT_BORDER_ACTIVE_COLOR: Color.addOpacity("#545F69", 0.5).toString(),
        MERCHANT_INPUT_TEXT_COLOR: "#444F59",
        MERCHANT_INPUT_PLACEHOLDER_COLOR: "#99B3BB",
        // -- Radio button
        MERCHANT_RADIO_BUTTON_PRIMARY_BACKGROUND: "linear-gradient(180deg, #125761 0%, #0F4851 100%)",
        MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR: "linear-gradient(180deg, #125761 0%, #0F4851 100%)",
        MERCHANT_RADIO_BUTTON_PRIMARY_TEXT_COLOR: Theme.props.WHITE,

        MERCHANT_RADIO_BUTTON_SECONDARY_BACKGROUND: Theme.props.WHITE,
        MERCHANT_RADIO_BUTTON_SECONDARY_BORDER_COLOR: Color.addOpacity("#545F69", 0.24).toString(),
        MERCHANT_RADIO_BUTTON_SECONDARY_TEXT_COLOR: "#767F87",
        // Frame
        MERCHANT_FRAME_BACKGROUND: "linear-gradient(180deg, #FBFDFD 32.81%, #EEF5F5 100%)",
        MERCHANT_FRAME_HEADER_BACKGROUND: "#F3F7F8",
        MERCHANT_FRAME_CLOSE_BUTTON_COLOR: "#242424",
        MERCHANT_FRAME_BORDER_COLOR: "rgba(84, 95, 105, 0.18)",
        MERCHANT_FRAME_BORDER_RADIUS: "10px",
        MERCHANT_FRAME_HEADER_BORDER_COLOR: Color.addOpacity("#545F69", 0.12).toString(),
        // Text color
        MERCHANT_TEXT_PRIMARY_COLOR: "#232529",
        MERCHANT_TEXT_SECONDARY_COLOR: "#545F69",
        MERCHANT_TEXT_LINK_COLOR: Theme.props.MERCHANT_LINK,
        // Card
        MERCHANT_CARD_BACKGROUND: Theme.props.WHITE,
        MERCHANT_CARD_BOX_SHADOW: "0 12px 12px 0px rgba(0, 0, 0, 0.12)",
        MERCHANT_CARD_BORDER_COLOR: "#E3E3E3",
        // Subscription header
        MERCHANT_SUBSCRIPTION_HEADER: "#EFF4F5",
        MERCHANT_SUBSCRIPTION_HEADER_GRADIENT: "#EFF4F5",
        // Footer
        MERCHANT_FOOTER_TEXT: "#545F69",
        MERCHANT_FOOTER_LOGO: "#545F69",
        // Spinner
        MERCHANT_SPINNER_BACKGROUND: "rgba(255,255,255,.84)",
        MERCHANT_SPINNER_COLOR: "#125C67", // This is also used in the icons in the benefits section.
        // Error / Success
        MERCHANT_SUCCESS: "#11BA87",
        MERCHANT_ERROR: "#FF4848",
        // Checkbox
        MERCHANT_CHECKBOX_BORDER_RADIUS: props => props.CHECKBOX_BORDER_RADIUS,
        MERCHANT_CHECKBOX_BORDER_COLOR: props => props.CHECKBOX_BORDER_COLOR,
        MERCHANT_CHECKBOX_ENABLED_BACKGROUND_COLOR: props => props.CHECKBOX_ENABLED_BACKGROUND_COLOR,
        MERCHANT_CHECKBOX_CHECKMARK_COLOR: props => props.CHECKBOX_CHECKMARK_COLOR,
        // Scrim
        MERCHANT_SCRIM_BACKGROUND: "rgba(0, 0, 0, 0.5)",

        MERCHANT_USER_DASHBOARD_TAB_STYLE: {
            background: "#E0E0E0",
            textDecoration: "none !important",
        },
        MERCHANT_USER_DASHBOARD_ACTIVE_TAB_STYLE: {
            background: Theme.props.WHITE,
        },
        MERCHANT_BUTTON_PRIMARY_STYLE: {},
        MERCHANT_INPUT_STYLE: {},
        MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL: false,
        MERCHANT_TITLE_STYLE: {},
    };

    Theme.setProperties({...MerchantDefaultConstants, ...merchantThemeConstants});

    const magicColorEnhance = color => enhance(color, (Color.isLight(color) || Color.isBlack(color)) ? 0.03 : -0.15, (Color.isLight(color) || Color.isBlack(color)) ? 0.1 : 0.2);

    const MerchantThemeHoverConstants = {
        MERCHANT_BUTTON_PRIMARY_BACKGROUND_HOVER: magicColorEnhance(Theme.props.MERCHANT_BUTTON_PRIMARY_BACKGROUND),
        MERCHANT_BUTTON_PRIMARY_BORDER_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_BUTTON_PRIMARY_BORDER_COLOR),
        MERCHANT_BUTTON_SECONDARY_BACKGROUND_HOVER: magicColorEnhance(Theme.props.MERCHANT_BUTTON_SECONDARY_BACKGROUND),
        MERCHANT_BUTTON_SECONDARY_BORDER_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_BUTTON_SECONDARY_BORDER_COLOR),
        MERCHANT_BUTTON_TERTIARY_TEXT_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_BUTTON_TERTIARY_TEXT_COLOR),
        MERCHANT_RADIO_BUTTON_PRIMARY_BACKGROUND_HOVER: magicColorEnhance(Theme.props.MERCHANT_RADIO_BUTTON_PRIMARY_BACKGROUND),
        MERCHANT_BUTTON_PRIMARY_TEXT_COLOR_HOVER: Theme.props.MERCHANT_BUTTON_PRIMARY_TEXT_COLOR,
        MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR),
        MERCHANT_RADIO_BUTTON_SECONDARY_BACKGROUND_HOVER: magicColorEnhance(Theme.props.MERCHANT_RADIO_BUTTON_SECONDARY_BACKGROUND),
        MERCHANT_RADIO_BUTTON_SECONDARY_BORDER_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_RADIO_BUTTON_SECONDARY_BORDER_COLOR),
        MERCHANT_INPUT_BACKGROUND_HOVER: magicColorEnhance(Theme.props.MERCHANT_INPUT_BACKGROUND),
        MERCHANT_TEXT_LINK_COLOR_HOVER: magicColorEnhance(Theme.props.MERCHANT_TEXT_LINK_COLOR),
        MERCHANT_ERROR_HOVER: magicColorEnhance(Theme.props.MERCHANT_ERROR),
    };
    Theme.setProperties({...MerchantThemeHoverConstants, ...merchantThemeConstants});

    const ThemeObjectConstants = {
        USER_DASHBOARD_CHANGE_BUTTON: {
            cursor: "pointer",
            whiteSpace: "nowrap",
            color: Theme.props.MERCHANT_LINK,
            display: "flex",
            alignItems: "center",
            fontSize: Theme.props.MERCHANT_FONT_SIZE_NORMAL,
            ":hover": {
                opacity: 1,
            },
        },
        USER_DASHBOARD_SETTING_LABEL: {
            fontSize: Theme.props.MERCHANT_FONT_SIZE_LARGE,
            color: Theme.props.SETTINGS_LABEL_COLOR,
        },
        USER_DASHBOARD_SETTING_DESCRIPTION: {
            fontSize: Theme.props.MERCHANT_FONT_SIZE_NORMAL,
            color: Theme.props.SETTINGS_DESCRIPTION_COLOR,
        },
        AUTH_BUTTON_STYLE: {
            width: "100%",
            marginTop: 18,
            fontSize: Theme.props.MERCHANT_FONT_SIZE_NORMAL + " !important",
            padding: "12px !important",
            textAlign: "center",
        },
        AUTH_LINK_STYLE: {
            color: Theme.props.MERCHANT_TEXT_LINK_COLOR,
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_REGULAR,
            fontSize: Theme.props.MERCHANT_FONT_SIZE_SMALL,
            ":hover": {
                textDecoration: "underline",
            },
        },
        INPUT_LABEL_STYLE: {
            textAlign: "left",
            fontSize: Theme.props.MERCHANT_FONT_SIZE_SMALL,
            color: Theme.props.MERCHANT_TEXT_SECONDARY_COLOR,
            marginBottom: 8,
        },
        INLINE_DONATE_FLOW_CHECKBOX: {
            marginTop: panelChildMargin,
            fontSize: Theme.props.MERCHANT_FONT_SIZE_NORMAL + " !important",
            marginBottom: 18,
            color: Theme.props.MERCHANT_TEXT_SECONDARY_COLOR + " !important",
        },
        INLINE_DONATE_FLOW_PAGE: {
            width: 460,
        },
        INLINE_SUBSCRIBE_FLOW_PAGE: {
            minWidth: () => isSmallScreen() ? "100%" : 420,
            width: () => isSmallScreen() ? "" : "max-content",
            overflow: () => isSmallScreen() ? "hidden" : "auto",
            height: "max-content",
            overflowX: "hidden",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
        },
        DONATE_PAGE_INPUT_CONTAINER: {
            background: "transparent",
            borderColor: Theme.props.DONATE_PAGE_SWITCHER_BORDER_COLOR,
            borderRadius: 0,
            ":focus-within": {
                borderColor: Theme.props.DONATE_PAGE_INPUT_FOCUSED_BORDER_COLOR,
                boxShadow: Theme.props.DONATE_PAGE_INPUT_FOCUSED_SHADOW,
            }
        },
        DONATE_PAGE_BUTTON: {
            fontSize: "16px !important",
            width: "100%",
            fontWeight: Theme.props.MERCHANT_FONT_SIZE_NORMAL,
            borderRadius: "2px !important",
            padding: "12px !important",
        },
        DONATE_PAGE_CARD_TITLE: {
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_BOLD,
            fontSize: 24,
            marginBottom: 20,
        },
        PANEL_LINK: {
            cursor: "pointer",
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_REGULAR,
            color: Theme.props.MERCHANT_TEXT_LINK_COLOR,
            ":hover": {
                color: Theme.props.MERCHANT_TEXT_LINK_COLOR_HOVER,
            },
        },
        PANEL_MESSAGE_STYLE: {
            color: Theme.props.MERCHANT_TEXT_SECONDARY_COLOR,
            textAlign: "center",
            width: "100%",
        },
        PANEL_TITLE: {
            color: Theme.props.MERCHANT_TEXT_PRIMARY_COLOR,
            fontSize: Theme.props.MERCHANT_FONT_SIZE_TITLE,
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_LIGHT,
            marginBottom: panelChildMargin,
            ...Theme.props.MERCHANT_TITLE_STYLE,
        },
        DONATE_PAGE_LINK: {
            color: "#0099FF",
            fontSize: Theme.props.MERCHANT_FONT_SIZE_LARGE,
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_REGULAR,
        },
        PANEL_ACCESS_RESOURCES: {
            display: "inline-block",
            marginLeft: 8,
            ">:last-child": {
                display: "inline-block",
                marginLeft: 8,
            }
        },
        AD_BLOCK_BUTTON: {
            background: Theme.props.BUTTON_PRIMARY_BACKGROUND,
            padding: "8px 12px",
            cursor: "pointer",
            borderRadius: "4px",
            transition: ".3s ease",
            userSelect: "none",
            "-webkit-user-select": "none",
            color: Theme.props.BUTTON_PRIMARY_COLOR,
            ":hover": {
                background: Theme.props.BUTTON_PRIMARY_HOVER_BACKGROUND,
            },
            ":disabled": {
                opacity: ".7",
                cursor: "not-allowed",
            },
        },
        TERTIARY_BUTTON: {
            color: Theme.props.MERCHANT_BUTTON_TERTIARY_TEXT_COLOR,
            transition: ".2s ease",
            display: "inline-block",
            margin: "0 auto",
            padding: "0 18px",
            cursor: "pointer",
            userSelect: "none",
            textAlign: "center",
            width: Theme.props.FIT_CONTENT,
            fontWeight: Theme.props.MERCHANT_FONT_WEIGHT_REGULAR,
            fontSize: Theme.props.MERCHANT_FONT_SIZE_LARGE,
            ":disabled": {
                color: Theme.props.MERCHANT_BUTTON_TERTIARY_INACTIVE_TEXT_COLOR,
                cursor: "not-allowed",
            },
            ":disabled:hover": {
                color: Theme.props.MERCHANT_BUTTON_TERTIARY_INACTIVE_TEXT_COLOR
            },
            background: Theme.props.MERCHANT_BUTTON_TERTIARY_TEXT_COLOR,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            ":hover": {
                background: Theme.props.MERCHANT_BUTTON_TERTIARY_TEXT_COLOR_HOVER,
                "-webkit-background-clip": "text",
            },
        },
        PANEL_AUTH_PANEL_TITLE: {
            fontSize: 20,
            textAlign: "center",
            marginBottom: 18,
        }
    };

    Theme.setProperties(ThemeObjectConstants);
}

export function initializeTheme() {
    const BlinkpayConstants = {
        WALLET_0: "#F8FBFB",
        WALLET_1: "#E8F0F2",
        WALLET_2: "#D2E2E5",
        WALLET_3: "#BBD4D8",
        WALLET_4: "#A5C6CB",
        WALLET_5: "#8EB8BE",
        WALLET_6: "#78AAB2",
        WALLET_7: "#619CA5",
        WALLET_8: "#4B8E98",
        WALLET_9: "#34808B",
        WALLET_10: "#1E727E",
        WALLET_11: "#086472",
        WALLET_12: "#085B68",
        WALLET_13: "#07525E",
        WALLET_14: "#064953",
        WALLET_15: "#064049",
        WALLET_16: "#05373F",
        WALLET_17: "#042E34",
        WALLET_18: "#03252A",
        WALLET_19: "#031C20",
        WALLET_20: "#021315",
        WALLET_21: "#010506",
        WALLET_LINK: "#00C3EB",
        WALLET: "#1A8289",
        MERCHANT_1: "#A0A0A0",
        MERCHANT_2: "#6C6C6C",
        MERCHANT_3: "#3F3F3F",
        MERCHANT_4: "#232323",
        MERCHANT_5: "#151515",
        MERCHANT_LINK: "#0080FF",
        LANDING_0: "#F5F7F7",
        LANDING_1: "#1D1F1F",
        LANDING_2: "#1D1D1F",
        BLACK: "#000000",
        WHITE: "#FFFFFF",
        ERROR: "#FF4848",
        SUCCESS: "#11BA87",
        TEAL_1: "#086375",
        TEAL_2: "#1B7E8C",
        DARK_GRAY: "#666666",
    };

    Theme.setProperties(BlinkpayConstants);
    addThemeConstants();

    window.Theme = Theme;
}

