import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {Image} from "../../stem-core/src/ui/UIPrimitives";
import {styleRule} from "../../stem-core/src/decorators/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {iFrameMerchantService} from "../services/IframeMerchantService";
import {iFrameState} from "../services/IFrameState";


class MerchantLogoStyle extends StyleSheet {
    @styleRule
    logoContainer = {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: 35,
        width: 225,
    };

    @styleRule
    logoImage = {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    };
}

@registerStyle(MerchantLogoStyle)
export class MerchantLogo extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {logoSrc, logoAlt} = iFrameState;
        const merchant = iFrameMerchantService.getMerchant();

        return [
            <div className={styleSheet.logoContainer}>
                <Image src={logoSrc || merchant.wideLogoUrl} alt={logoAlt || merchant.name}
                       className={styleSheet.logoImage} />
            </div>,
        ];
    }
}
