import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {getQueryParam} from "../UtilsLib";


class SocialAuthService extends Dispatchable {
    constructor() {
        super();
        this.merchantId = getQueryParam("clientId");
    }

    setMerchantId(merchantId) {
        this.merchantId = merchantId;
        this.dispatch("update");
    }
}

export const socialAuthService = new SocialAuthService();
