import {styleRule, styleRuleInherit, UI} from "../../../../../../stem-core/src/ui/UI";

import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../../ui/BaseCard";

import {Messages} from "../../../../../Messages";
import {TopUpPaymentStore, DonationStore, SubscriptionStore, PaymentStore} from "../../../../../State";

import {FakeTable, FakeTableRow, FakeTableRowStyle} from "../../../../../ui/FakeTable";
import {DEFAULT_DATE_FORMAT, DONATIONS_URL, SUBSCRIPTIONS_URL} from "../../../../../Constants";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {PaymentDescription} from "../components/PaymentDescription";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {EndpointPaginator} from "../../../../../../client/state/EndpointPaginator";
import {TopUpStatus} from "../../../../../../client/state/TopUpPaymentStore";


class TopUpHistoryTableRowStyle extends FakeTableRowStyle {
    @styleRuleInherit
    bodyRow = {
        padding: "8px 0px",
        height: "auto",
    };

    @styleRuleInherit
    column = {
        lineHeight: 14,
    };

    @styleRuleInherit
    fakeTableRow = {
        padding: 0,
        ":last-child > *": {
            paddingBottom: 0,
        },
    };
}

@registerStyle(TopUpHistoryTableRowStyle)
class TopUpHistoryTableRow extends FakeTableRow {}

class TopUpHistoryTable extends FakeTable {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            TableRowClass: TopUpHistoryTableRow
        };
    }
}

export class TopUpHistoryStyle extends BaseCardStyle {
    @styleRule
    paymentTitle = {
        marginBottom: 4,
        fontSize: 14,
        fontWeight: "initial",
    };
}

// List of all the credit card charges of this user
@registerStyle(TopUpHistoryStyle)
export class TopUpHistory extends BaseCard {
    paginator = new EndpointPaginator(
        TopUpPaymentStore,
        "/top_up_payments/list/",
        {source: TopUpPaymentStore.Source.PAYMENT_METHOD}, // TODO: nicer enum lookup
        {status: [TopUpStatus.REVERTED, TopUpStatus.SUCCEEDED]}
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.paymentHistory,
            description: Messages.paymentHistoryDescription,
        };
    }

    getTransactions() {
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);
    }

    getTransactionDescription(transaction) {
        const defaultDescription = "";
        if (!transaction) {
            return {title: defaultDescription};
        }

        if (transaction.status === TopUpStatus.REVERTED) {
            return {title: Messages.chargeback};
        }

        if (transaction.resourceType == null) {
            // Top up payment.
            return {
                title: transaction.isAutomatic ? Messages.walletTopUpAutomatic : Messages.walletTopUp
            };
        }

        if (transaction.resourceType === PaymentStore.ResourceType.SUBSCRIPTION) {
            const subscription = SubscriptionStore.get(transaction.resourceId);
            return {
                title: Messages.subscriptionPaymentDescription(subscription && subscription.getMerchantTitle()),
                url: `/${SUBSCRIPTIONS_URL}?focus="${transaction.resourceId}"`
            }
        }
        if (transaction.resourceType === PaymentStore.ResourceType.DONATION) {
            const donation = DonationStore.get(transaction.resourceId);
            if (!donation) {
                return {title: defaultDescription};
            }

            if (donation.isRecurring()) {
                return {
                    title: Messages.recurringDonationPaymentDescription(donation && donation.getMerchantTitle()),
                    url: `/${DONATIONS_URL}?focus="${transaction.resourceId}"`
                }
            }

            return {
                title: Messages.oneTimeDonationPaymentDescription(donation && donation.getMerchantTitle()),
                url: DONATIONS_URL,
            }
        }

        return {title: defaultDescription};
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        const {title, url} = this.getTransactionDescription(payment);

        return <PaymentDescription payment={payment}>
            {url ? <Link className={styleSheet.paymentTitle} href={url}
                         style={{color: styleSheet.themeProps.MERCHANT_LINK}}>
                        {title}
                    </Link>
                : <div className={styleSheet.paymentTitle}>{title}</div>
            }
        </PaymentDescription>
    }

    render() {
        const transactions = this.getTransactions();

        if (!transactions.length) {
            return [];
        }

        return <TopUpHistoryTable
                columns={[
                    {
                        headerName: Messages.description,
                        width: 0.5,
                        align: "left",
                        value: transaction => this.getDescriptionColumnValue(transaction),
                    },
                    {
                        headerName: Messages.amount,
                        width: 0.2,
                        align: "right",
                        value: transaction =>
                            `${transaction.status === TopUpStatus.REVERTED ? "-" : ""}${transaction.getAmount()}`,
                    },
                    {
                        headerName: Messages.date,
                        width: 0.3,
                        align: "right",
                        value: transaction => transaction.createdAt.format(DEFAULT_DATE_FORMAT),
                        style: {color: this.styleSheet.themeProps.MERCHANT_2},
                    },
                ]}
                entries={transactions}
        />;
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
