import {UI, styleRule, registerStyle, StyleSheet} from "../../../../../stem-core/src/ui/UI";

import {authService} from "../../../../../client/connection/Services";
import {PaymentMethodStore, UserPreferencesStore, UserProfileStore, UserAddressStore} from "../../../../State";
import {Messages} from "../../../../Messages";

import {LoadingSpinner} from "../../../../ui/LoadingSpinner";
import {PersonalDetails} from "./sections/PersonalDetails";
import {MyAddresses} from "./sections/MyAddresses";
import {PaymentMethods} from "./sections/PaymentMethods";
import {UserSettings} from "./sections/UserSettings";
import {SecurityCard} from "./sections/SecurityCard";


class AccountPageStyle extends StyleSheet {
    @styleRule
    logout = {
        fontSize: 16,
        color: this.themeProps.LOGOUT_COLOR,
        padding: "0.3em",
        opacity: 1,
        cursor: "pointer",
        textAlign: "center",
        width: this.themeProps.FIT_CONTENT,
        margin: "0 auto",
        ":hover": {
            opacity: 0.8,
        },
    };
}

@registerStyle(AccountPageStyle)
export class AccountPage extends UI.Element {
    render() {
        console.assert(this.getUserProfile() && this.getUserPreferences() &&
            this.getUserPaymentMethods() && this.getUserAddresses());

        const userProfile = this.getUserProfile();

        return [
            <PersonalDetails testId="accountPersonalDetails"/>,
            <MyAddresses userAddresses={this.getUserAddresses()} testId="accountAddNewAddress"/>,
            <PaymentMethods userPaymentMethods={this.getUserPaymentMethods()} testId="accountPaymentMethods"/>,
            <UserSettings userPreferences={this.getUserPreferences()} testId="accountUserSettings"/>,
            <SecurityCard userProfile={userProfile} testId="accountSecuritySettings"/>,
            <div testId="accountLogoutButton" className={this.styleSheet.logout} onClick={() => this.handleLogout()}>
                {Messages.logOut}
            </div>,
        ];
    }

    getUserProfile() {
        return UserProfileStore.getUserProfile();
    }

    getUserPreferences() {
        return UserPreferencesStore.getPreferences();
    }

    getUserPaymentMethods() {
        return PaymentMethodStore.getAvailablePaymentMethods();
    }

    getUserAddresses() {
        return UserAddressStore.all();
    }

    async handleLogout() {
        LoadingSpinner.show();
        await authService.logout();
        window.location.href = "/";
        LoadingSpinner.hide();
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserProfileStore, () => this.redraw());
        this.attachChangeListener(UserPreferencesStore, () => this.redraw());
        this.attachChangeListener(PaymentMethodStore, () => this.redraw());
        this.attachChangeListener(UserAddressStore, () => this.redraw());
    }
}
