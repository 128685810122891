import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {styleRule} from "../../stem-core/src/decorators/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Messages} from "../../blinkpay/Messages";
import {Device} from "../../stem-core/src/base/Device";
import {FacebookIcon, LinkedInIcon, MediumIcon, TwitterIcon} from "../SVGElements";
import {StemDate} from "../../stem-core/src/time/Date";
import {TermsMessage} from "../../blinkpay/website/unauthenticated/components/TermsMessage.jsx";

class ContactStyle extends StyleSheet {
    @styleRule
    contact = {
        color: this.themeProps.FOOTER_ITEM_COLOR,
        fontSize: () => (Device.isMobileDevice() ? 16 : 18),
        textAlign: "center",
    };

    @styleRule
    emailContainer = {
        display: "inline-block",
        textDecoration: "none",
        fontWeight: "normal",
        color: "inherit !important",
        ":hover": {
            color: this.themeProps.FOOTER_ITEM_HOVER_COLOR,
        },
        padding:  "4px 4px",
        marginBottom: () => (Device.isMobileDevice() ? 12 : 4),
    };

    @styleRule
    iconAnchor = {
        ":not(:first-child)": {
            marginLeft: () => (Device.isMobileDevice() ? 36 : 32),
        },
        display: "inline-block",
        padding:  "4px 8px",
    };

    @styleRule
    icon = {
        fontSize: 22,
        opacity: 0.8,
        ":hover": {
            opacity: 1,
        },
    };

    @styleRule
    iconsContainer = {};

    @styleRule
    copyright = {
        fontSize: () => (Device.isMobileDevice() ? 12 : ""),
    };

    @styleRule
    terms = {
        fontSize: "inherit !important",
        color: "inherit !important",
        maxWidth: 600,
        margin: "auto",
        marginBottom: 18,
    };
}

@registerStyle(ContactStyle)
export class Contact extends UI.Element {
    social = [
        {Icon: MediumIcon, url: "https://medium.com/blinkwallet"},
        {Icon: LinkedInIcon, url: "https://www.linkedin.com/company/blinkwallet/"}, // TODO @cleanup delete this and maybe add a blog
    ];

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.contact);
    }

    render() {
        const {styleSheet} = this;

        return [
            <a href={"mailto:contact@blink.net"} className={styleSheet.emailContainer} target="_blank">
                contact@blink.net
            </a>,
            <div className={styleSheet.iconsContainer}>
                {this.social.map(({Icon, url}) => (
                    <Link href={url} className={styleSheet.iconAnchor} newTab>
                        <Icon className={styleSheet.icon} />
                    </Link>
                ))}
            </div>,
            <TermsMessage className={styleSheet.terms} preMessage={Messages.termsPreMessage} postMessage={Messages.termsLandingPage}/>,
            <div className={styleSheet.copyright}>{Messages.footerCopyright((new StemDate()).format("YYYY"))}</div>,
        ];
    }
}
