import {UI} from "../../../stem-core/src/ui/UI";
import {RawHTML} from "../../../stem-core/src/ui/UIPrimitives";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {apiConnection} from "../../../client/connection/BlinkApiConnection";
import {PolicyType} from "../../../client/state/PolicyTermsStore";

export function RenderPolicyHTML(htmlTemplate) {
    return <RawHTML style={{padding: 20, maxWidth: 800, margin: "auto", fontFamily: "serif", color: "#1E1C29"}} __innerHTML={htmlTemplate}/>
}


const PolicyPage = (policyType) => class extends UI.Element {
    getDefaultState() {
        return {
            loaded: false,
            innerHTML: null,
            error: null,
        };
    }

    async fetchHTML() {
        try {
            const response = await apiConnection.get("/compliance/policy/", {type: policyType});
            this.updateState({
                loaded: true,
                innerHTML: response.htmlTemplate,
            });
        } catch (error) {
            this.updateState({
                loaded: true,
                error,
            });
        }
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingSpinner/>;
        }

        if (this.state.error) {
            return "Error loading terms, please try again later";
        }

        return RenderPolicyHTML(this.state.innerHTML);
    }

    onMount() {
        this.fetchHTML();
    }
}

export const CookiePolicyPage = PolicyPage(PolicyType.COOKIE);
export const TermsPolicyPage = PolicyPage(PolicyType.TOS);
export const PrivacyPolicyPage = PolicyPage(PolicyType.PRIVACY);
