import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../../Messages";
import {Link} from "../../../../../../../stem-core/src/ui/UIPrimitives";
import {styleRule, styleRuleInherit} from "../../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {SubscriptionStore} from "../../../../../../../client/state/SubscriptionStore";


class AddressInUseModalStyle extends ConfirmationModalStyle {
    @styleRule
    link = {
        ...this.themeProps.USER_DASHBOARD_CHANGE_BUTTON,
        fontSize: 16,
        display: "inline-block",
        fontWeight: "normal",
    };

    @styleRuleInherit
    buttons = {
        marginTop: 0,
        ">:nth-child(1)": {
            paddingRight: 0,
        },
        ">:last-child": {
            display: "none",
        }
    };

    @styleRuleInherit
    buttonContainer = {
        width: "100%",
    }
}

@registerStyle(AddressInUseModalStyle)
export class AddressInUseModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            address: null,
            cancelLabel: Messages.close,
            title: Messages.addressInUseTitle,
        };
    }

    getDescription() {
        return [
            Messages.addressInUseDescription,
            <Link href={`/subscriptions?focus="${SubscriptionStore.getActiveWithAddress(this.options.address)[0].id}"`}
                  value={Messages.addressInUseLink} className={this.styleSheet.link}/>,
            "."
        ];
    }
}
