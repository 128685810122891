import {UI} from "../../../../stem-core/src/ui/UIBase";
import {Messages} from "../../../Messages";
import {registerStyle, Theme} from "../../../../stem-core/src/ui/style/Theme";
import {BaseInput} from "../../../ui/Input";
import {AuthHelper} from "../../../AuthHelper";
import {authFormService} from "../../../services/AuthFormService";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style";
import {authService} from "../../../../client/connection/services/AuthService";
import {isIframe} from "../../../Utils.js";
import {iFrameState} from "../../../services/IFrameState.js";
import {ExternalAuthButtons} from "./ExternalAuthButtons";
import {TermsMessage} from "../components/TermsMessage.jsx";
import {MerchantButton} from "../../../common/MerchantButton.jsx";
import {panelChildMargin} from "../../../StyleConstants.js";
import {iFrameMerchantService} from "../../../services/IframeMerchantService.js";
import {TNR_ALIAS} from "../../../../blink-sdk/Constants.js";
import {PoweredByBlink} from "../../../panel/PoweredByBlink.jsx";


export class AuthStartPanelStyle extends StyleSheet {
    @styleRule
    textLink = this.themeProps.AUTH_LINK_STYLE;

    @styleRule
    authPanel = {
        position: "relative",
    };

    inputCommon = {
        marginTop: 18,
        marginBottom: 6,
    };

    @styleRule
    input = {
        ...this.inputCommon,
        marginTop: 0,
    };

    @styleRule
    button = {
        ...Theme.props.AUTH_BUTTON_STYLE,
        marginBottom: 24,
        margin: "14px 0",
    };

    @styleRule
    headerElement = {
        padding: "0 36px",
    };

    @styleRule
    continueLabel = {
        ...this.themeProps.PANEL_MESSAGE_STYLE,
        marginTop: panelChildMargin,
        marginBottom: panelChildMargin,
    };

    @styleRule
    authTitle = this.themeProps.PANEL_AUTH_PANEL_TITLE;

    @styleRule
    footerSection = {
        borderTop: () => "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR,
    }
}

// The first page in any auth flow: select either external auth or enter an email
@registerStyle(AuthStartPanelStyle)
export class AuthStartPanel extends UI.Element {
    emailInputError = null;

    getDefaultOptions() {
        return {
            useSecureCode: false,
            createUserAutomatically: true,
            emailButtonLabel: Messages.continue,
            emailInputLabel: Messages.emailAddress,
            InputClass: BaseInput,
            ButtonClass: MerchantButton,
            inputPlaceholder: "",
            showFooter: true,
            externalAuthProviders: iFrameState.authSocialProviders,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.authPanel);
    }

    render() {
        return [
            this.getHeaderSection(),
            this.getEmailInputSection(),
            this.getFooterSection(),
        ];
    }

    getHeaderSection() {
        const {styleSheet} = this;
        const {externalAuthProviders} = this.options;

        return [
            Messages.authenticationPanelTitle.toString().trim() &&
            <div className={styleSheet.authTitle}>{Messages.authenticationPanelTitle}</div>,

            externalAuthProviders.length > 0 ? [
                <div className={styleSheet.continueLabel}>{Messages.continueWithExternalAuth}</div>,
                <ExternalAuthButtons
                    className={styleSheet.headerElement}
                    externalAuthProviders={externalAuthProviders}
                />,
                <div className={styleSheet.continueLabel}>{Messages.orContinueWithEmailAddress}</div>,
            ] : <div className={styleSheet.continueLabel}>{Messages.continueWithEmail}</div>,
        ];
    }

    getEmailInputSection() {
        const {
            styleSheet,
            emailInputError,
        } = this;
        const {emailInputLabel, emailButtonLabel, inputPlaceholder, InputClass, ButtonClass} = this.options;
        const {registrationMail} = authFormService;

        return [
            <InputClass ref="emailInput" label={emailInputLabel} className={styleSheet.input}
                        initialValue={registrationMail}
                        errorMessageTestId="authEmailInputError" error={emailInputError} onFocus={() => this.clearErrors()}
                        inputAttributes={{type: "email", testId: "authEmail", placeholder: inputPlaceholder}}
                        onEnter={() => this.requestEmailCode()} onChange={() => this.onEmailInputChange()}/>,
            <ButtonClass testId="continueWithCode" ref="continueWithCode" label={emailButtonLabel}
                         className={styleSheet.button} disabled={!this.canRequestEmailCode()}
                         onClick={() => this.requestEmailCode()}/>
        ]
    }

    getFooterSection() {
        const {styleSheet} = this;
        const {showFooter} = this.options;
        if (!showFooter) {
            return null;
        }

        // TODO the terms text & links should be configurable.
        const extraTermsMessageOptions = {};
        if (iFrameMerchantService.isMerchant(TNR_ALIAS)) {
            extraTermsMessageOptions.termsLink = "https://newrepublic.com/pages/terms-and-conditions";
            extraTermsMessageOptions.policyLink = "https://newrepublic.com/pages/privacy";
        }

        return <div className={styleSheet.footerSection}>
            <TermsMessage preMessage={Messages.termsPreMessage} {...extraTermsMessageOptions}/>
            <PoweredByBlink/>
        </div>;
    }

    onEmailInputChange() {
        this.clearErrors();
        this.redraw();
    }

    getRedirectUrl() {
        if (isIframe()) {
            return iFrameState.merchantPageUrl;
        }

        return window.location.origin;
    }

    getRequestPayload() {
        return {
            firstName: "",
            lastName: "",
            email: this.emailInput.getValue().trim(),
            redirectUrl: this.getRedirectUrl(),
            withSignup: this.options.createUserAutomatically,
            secure: this.options.useSecureCode,
        };
    }

    canRequestEmailCode() {
        return this.hasFilledEmail();
    }

    async requestEmailCode() {
        if (!this.canRequestEmailCode()) {
            return;
        }

        // Hide the mobile keyboard.
        this.continueWithCode?.node.blur();
        try {
            const requestPayload = this.getRequestPayload();
            await AuthHelper.requestLoginCode(requestPayload);
            if (!authService.isAuthenticated()) {
                this.options.onEmailCodeRequested();
            }
        } catch (error) {
            console.log(error);
            this.emailInputError = error.message;
            this.redraw();
        }
    }

    hasFilledEmail() {
        return this.emailInput?.getValue();
    }

    clearErrors() {
        if (!this.emailInputError) {
            return;
        }
        this.emailInputError = null;
        this.redraw();
    }

    onMount() {
        super.onMount();

        const fillDataAndRedraw = () => {
            const {registrationMail} = authFormService;

            // We might have an email or a password saved in the app state previously by what the user typed on the auth form;
            // If the user hasn't typed anything in any current input, fill it with that value he previously filled.
            if (registrationMail && !this.hasFilledEmail()) {
                this.emailInput.setValue(registrationMail);
            }
        };
        this.attachUpdateListener(authFormService, fillDataAndRedraw);

        this.emailInput.focus();
        this.addListener("urlEnter", () => this.emailInput.focus());
        this.addListener("urlExit", () => authFormService.setRegistrationMail(this.emailInput.getValue()));
    }
}
