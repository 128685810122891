import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {Messages} from "../../Messages.js";


export class AddressController extends Dispatcher {
    addressType = null;
    addressSectionState = null;
    selectedAddress = null;
    continueLabel = "";
    onSelectSuccess = NOOP_FUNCTION;
    onSelectCancel = NOOP_FUNCTION;

    constructor(options={}) {
        super(options);
        this.update(options);
    }

    update(props) {
        Object.assign(this, props);
        this.dispatch();
    }

    getAddAddressLabel() {
        // TODO @Mihai maybe make this enum
        if (this.addressType === "billing") {
            return Messages.addBillingAddress;
        }
        return Messages.addNewAddress;
    }
}
