import {authService, userService} from "../../../client/connection/Services";
import {iFrameState} from "../../services/IFrameState";
import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {Messages} from "../../Messages";
import {startDashboardFlow, startFlow} from "./startFlow";
import {PANEL_TYPE} from "../../../blink-sdk/Constants";
import {iFrameConnection} from "../../services/IFrameConnection";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient";


export function sendFlowCheckpoint(flowType, flowCheckpoint, payload, extra) {
    dispatchAnalyticsEvent(AnalyticsEventType.FLOW_CHECKPOINT, {flowType, flowCheckpoint, ...payload});
    iFrameConnection.sendToSDK(IFrameMessages.FLOW_CHECKPOINT, {
        event: {
            flowType,
            flowCheckpoint,
            ...payload,
        },
        extra,
    });
}


export class FlowController extends Dispatchable {
    actionButtonTestId = "";
    actionButtonDefaultAnid = null;
    logInFooterDefaultAnid = null;

    onClick(event) {
        if (!authService.isAuthenticated()) {
            this.onClickNotAuthenticated();
        } else {
            userService.ensureUserDataRequested(() => {
                // We really want to be sure that this gets executed after blink service updates the sdk user data.
                setTimeout(() => this.onClickAuthenticated());
            });
        }
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = false;
    }

    onClickAuthenticated() {}

    onClickNotAuthenticated() {
        startFlow(null, {
            type: PANEL_TYPE.authentication,
            skipCTA: true,
        });
    }

    onLoginClick(event) {
        this.onClick(event);
    }

    onManageAccountClick(event) {
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = false;
        startDashboardFlow();
    }

    getHeaderDefaultText() {
        return iFrameState.panelText || "";
    }

    getActionButtonDefaultLabel() {
        return iFrameState.panelButtonText;
    }

    getLoginFooterMessage() {
        return Messages.alreadyHaveAnAccount;
    }
}

