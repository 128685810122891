import {UI} from "stem-core/src/ui/UI";
import {ConfirmationModal} from "../../../../../../ui/ConfirmationModal";
import {Toast} from "../../../../../../ui/Toast";
import {Messages} from "../../../../../../Messages";
import {StripeCardInput} from "./CardInput";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {Dispatcher} from "../../../../../../../stem-core/src/base/Dispatcher";
import {apiSetPrimaryPaymentMethod} from "../../../../../../../client/state/PaymentMethodStore";


export class AddCardModal extends ConfirmationModal {
    getDefaultOptions() {
        const handleSetPrimarySuccess = (card) => {
            Toast.show(Messages.cardAdded);
            Dispatcher.Global.dispatch("cardAdded", card);
            this.hide();
            LoadingSpinner.hide();
        }
        const handleSetPrimaryError = () => {
            this.hide();
            LoadingSpinner.hide();
        }

        return {
            ...super.getDefaultOptions(),
            testId: "addCardModal",
            title: Messages.addCard,
            confirmAction: () => {
                LoadingSpinner.show();
                // TODO: we should simplify this logic
                this.cardInput.confirmCard(
                    async card => {
                        try {
                            await apiSetPrimaryPaymentMethod(card.id);
                            handleSetPrimarySuccess(card);
                        } catch (error) {
                            handleSetPrimaryError();
                        }
                    },
                    () => {
                        LoadingSpinner.hide();
                    }
                );
            },
            confirmId: "addCardConfirm",
        };
    }

    render() {
        return [
            <StripeCardInput
                onChange={() => this.updateConfirmButton()}
                ref="cardInput"
                style={{marginTop: 36}}
            />,
        ];
    }

    updateConfirmButton() {
        this.confirm.setAttribute("disabled", !this.cardInput.hasValidSecureFields());
    }

    onMount() {
        super.onMount();
        this.confirm.setAttribute("disabled", true);
    }

    hide() {
        super.hide();
        Dispatcher.Global.dispatch("hideAddCard");
        // We need this to work like a stack so make sure the bottom modal hides the last.
    }
}
