import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {BlinkPayLogoText, BlinkPoweredByText} from "../SVGElements";
import {accessDashboardURLFromIFrame} from "../widget/misc/WidgetUtils";


export class PoweredByBlinkStyle extends StyleSheet {
    @styleRule
    element = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    @styleRule
    logo = {
        paddingLeft: 5,
    };
}

@registerStyle(PoweredByBlinkStyle)
export class PoweredByBlink extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes();
        attr.addClass(this.styleSheet.element);
    }

    getDefaultOptions() {
        return {
            poweredByColor: this.styleSheet.themeProps.MERCHANT_FOOTER_TEXT,
            blinkLogoColor: this.styleSheet.themeProps.MERCHANT_FOOTER_LOGO,
        };
    }

    render() {
        return [
            // Fixes Safari overflow. Maybe use this on all SVG Elements?
            <BlinkPoweredByText color={this.options.poweredByColor} style={{overflow: "hidden"}} />,
            <Link href="/" newTab onClick={accessDashboardURLFromIFrame}>
                <BlinkPayLogoText size={12} backgroundColor={this.options.blinkLogoColor} className={this.styleSheet.logo}/>
            </Link>
        ];
    }
}
