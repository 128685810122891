import {registerStyle, styleRule, styleRuleInherit, StyleSheet, UI} from "../../../../stem-core/src/ui/UI";
import {FacebookIcon, GoogleIcon, LinkedInIcon, TwitterIcon} from "../../../SVGElements";
import {BaseButton, BaseButtonStyle} from "../../../ui/Button";
import {SOCIAL_APPS} from "../../../../blink-sdk/Constants";
import {isSmallScreen} from "../../../UtilsLib.js";
import {GetExternalAuthClient} from "../socialaccount/ExternalAuthConfigs.jsx";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../../blink-sdk/utils/AnalyticsClient.js";

class ExternalAuthButtonStyle extends BaseButtonStyle {
    @styleRuleInherit
    button = {
        position: "relative",
        boxShadow: this.themeProps.SOCIAL_BUTTON_SHADOW,
        display: "flex",
        alignItems: "center",
        fontWeight: () => this.themeProps.MERCHANT_FONT_WEIGHT_BOLD + " !important",
        padding: 0,
        width: () => (isSmallScreen() ? 44 : 48),
        height: () => (isSmallScreen() ? 44 : 48),
        borderRadius: 2,
        ":hover": {
            opacity: 1,
            transition: ".2s",
            boxShadow: this.themeProps.SOCIAL_BUTTON_HOVER_SHADOW,
        },
    };

    @styleRule
    iconContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        ">*": {
            transform: "scale(2)",
        },
        ">*>*": {
            display: "flex",
            alignItems: "center",
        },
    };
}

@registerStyle(ExternalAuthButtonStyle)
class ExternalAuthButton extends BaseButton {
    render() {
        const {description, IconClass} = this.options;
        return [
            <div className={this.styleSheet.iconContainer}>
                <IconClass />
            </div>,
            description,
        ];
    }

    startExternalAuth() {
        const {clientName} = this.options;

        dispatchAnalyticsEvent(AnalyticsEventType.SOCIAL_LOGIN_BUTTON_CLICKED, {provider: clientName})

        const externalAuthClient = GetExternalAuthClient(this.options.clientName);
        externalAuthClient.setLoginCallback(this.options.onLoginCallback);
        externalAuthClient.login();
    }

    onMount() {
        this.addClickListener(() => {
            this.startExternalAuth();
        });
    }
}

class FacebookButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    button = {
        color: this.themeProps.SOCIAL_BUTTON_FACEBOOK_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_FACEBOOK_BACKGROUND,
    };
}

class GoogleButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    button = {
        color: this.themeProps.SOCIAL_BUTTON_GOOGLE_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_GOOGLE_BACKGROUND,
    };
}

class LinkedInButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    button = {
        color: this.themeProps.SOCIAL_BUTTON_LINKEDIN_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_LINKEDIN_BACKGROUND,
        ">*": {
            transform: "translateY(-2px)",
        },
    };
}

class TwitterButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    button = {
        color: this.themeProps.SOCIAL_BUTTON_TWITTER_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_TWITTER_BACKGROUND,
    };
}


const ExternalAuthDescriptors = {
    [SOCIAL_APPS.Google]: {
        icon: GoogleIcon,
        styleSheet: GoogleButtonStyle,
    },
    [SOCIAL_APPS.Facebook]: {
        icon: FacebookIcon,
        styleSheet: FacebookButtonStyle,
    },
    [SOCIAL_APPS.Twitter]: {
        icon: TwitterIcon,
        styleSheet: TwitterButtonStyle,
    },
    [SOCIAL_APPS.LinkedIn]: {
        icon: LinkedInIcon,
        styleSheet: LinkedInButtonStyle,
    },
};

class ExternalAuthButtonsStyle extends StyleSheet {
    @styleRule
    buttons = {
        display: "flex",
        justifyContent: "space-around",
        maxWidth: 300,
        margin: "auto",
    };

    @styleRule
    label = {
        fontSize: 13,
        color: this.themeProps.LABEL,
        marginBottom: 18,
    };
}

@registerStyle(ExternalAuthButtonsStyle)
export class ExternalAuthButtons extends UI.Element {
    getDefaultOptions() {
        return {
            externalAuthProviders: [],
        };
    }

    render() {
        const {styleSheet} = this;
        let {externalAuthProviders} = this.options;

        externalAuthProviders = externalAuthProviders.map(provider => provider.toLowerCase()).filter(provider => provider !== SOCIAL_APPS.CDS);

        const buttons = externalAuthProviders.map(authProviderName => {
            const {icon, styleSheet} = ExternalAuthDescriptors[authProviderName];
            return (
                <ExternalAuthButton
                    testId={authProviderName + "Button"}
                    clientName={authProviderName}
                    styleSheet={styleSheet}
                    IconClass={icon}
                />
            );
        })

        return [
            <div className={styleSheet.buttons}>{buttons}</div>,
        ];
    }
}
