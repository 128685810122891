import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {iFrameConnection} from "../../services/IFrameConnection";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient";
import {Router} from "../../../stem-core/src/ui/Router";
import {WidgetRoutes} from "../WidgetRoutes";
import {AuthHelper} from "../../AuthHelper";
import {BlinkGlobal, isFacebookBrowser} from "../../Utils";
import {BLINK_PAY_URL, IFRAME_APP_TYPE, POPUP_GATE_TRANSITION_TIME} from "../../../blink-sdk/Constants";
import {iFrameState} from "../../services/IFrameState";
import {startDashboardFlow} from "../../panel/flows/startFlow";


export function addCardAddedListener(dispatchableElement, callback) {
    // TODO: Create these strings in an events dictionary.
    Dispatcher.Global.removeAllListeners("widgetCardAdded");
    Dispatcher.Global.removeAllListeners("widgetHideAddCard");

    const handler = dispatchableElement.attachListenerOnce(Dispatcher.Global, "widgetCardAdded", callback);
    dispatchableElement.attachListenerOnce(Dispatcher.Global, "widgetHideAddCard", () => {
        handler.remove();
    });
}

export function redirectToMerchantHomepage() {
    iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: "/"});
}

// This should only be called for user events.
export function accessDashboardURLFromIFrame(event, url=BLINK_PAY_URL) {
    if (isFacebookBrowser()) {
        iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url});
        event.preventDefault();
        event.stopPropagation();
    }
}

function collapseIframeBase(expandWallet) {
    dispatchAnalyticsEvent(AnalyticsEventType.IFRAME_COLLAPSED, {route: Router.getCurrentPath()});
    AuthHelper.clearAuthenticationCallbacks();
    if (BlinkGlobal.appType === IFRAME_APP_TYPE.panel) {
        iFrameConnection.sendToSDK(IFrameMessages.DESTROY_PANEL, {expandWallet});
        Dispatcher.Global.dispatch("collapseIframe");
        return;
    }
    Router.changeURL(WidgetRoutes.INDEX_COLLAPSED);
    // We want to dispatch the event after the modal / widget has been collapsed because only then the correct page is rendered.
    setTimeout(() => {
        Dispatcher.Global.dispatch("collapseIframe");
        if (expandWallet) {
            Router.changeURL(WidgetRoutes.INDEX_EXPANDED);
        }
    }, POPUP_GATE_TRANSITION_TIME);
}

export function collapseIframe() {
    collapseIframeBase(false);
}

export function collapseIframeAndExpandWallet() {
    if (iFrameState.forceHideWallet) {
        startDashboardFlow();
        return;
    }
    collapseIframeBase(true);
}
