import {UI} from "../../../../../stem-core/src/ui/UI";
import {ActiveSubscriptions} from "./sections/ActiveSubscriptions";
import {SubscriptionPayments} from "./sections/SubscriptionPayments";
import {PastSubscriptions} from "./sections/PastSubscriptions";
import {SubscriptionStore} from "../../../../State";
import {Dispatcher} from "../../../../../stem-core/src/base/Dispatcher";
import {getQueryParam} from "../../../../UtilsLib";
import {userService} from "../../../../../client/connection/Services";


export class SubscriptionsPage extends UI.Element {
    render() {
        return [
            <ActiveSubscriptions testId="activeSubscriptionsCard"/>,
            SubscriptionStore.getPast().length ? <PastSubscriptions testId="pastSubscriptionsCard"/> : null,
            <SubscriptionPayments testId="subscriptionPaymentsCard"/>,
        ];
    }

    onMount() {
        super.onMount();
        this.attachChangeListener(SubscriptionStore, () => this.redraw());

        const dispatchEntryFocus = () => {
            userService.ensureUserDataRequested(() => {
                const focus = getQueryParam("focus");
                if (focus) {
                    Dispatcher.Global.dispatch("highlightSubscription", focus);
                }
            })
        };
        this.attachTimeout(dispatchEntryFocus);
        this.addListener("urlEnter", dispatchEntryFocus);
    }
}
