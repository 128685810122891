import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {styleRule} from "../../stem-core/src/decorators/Style";
import {removeScrollOnScrim} from "../../blink-sdk/utils/removeScrollOnScrim";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {MODAL_TRANSITION_TIME} from "../Constants";


class ScrimStyle extends StyleSheet {
    @styleRule
    scrim = {
        position: "fixed",
        cursor: "default",
        zIndex: 10005,
        width: "100%",
        height: "100%",
        background: this.themeProps.WIDGET_LIGHT_SCRIM_BACKGROUND,
        top: 0,
        left: 0,
        opacity: 0,
        outline: "none",
        transition: MODAL_TRANSITION_TIME + "ms ease",
    };
}

@registerStyle(ScrimStyle)
export class Scrim extends UI.Element {
    restoreScrollbar = NOOP_FUNCTION;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hideOnClick: true,
            onHide: NOOP_FUNCTION,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.scrim);
    }

    destroyNode() {
        this.restoreScrollbar();
        this.restoreScrollbar = NOOP_FUNCTION;

        super.destroyNode();
    }

    onMount() {
        super.onMount();

        const {restore} = removeScrollOnScrim();
        this.restoreScrollbar = restore;

        if (this.options.hideOnClick) {
            this.addClickListener(() => this.options.onHide());
        }
    }
}
