import {Messages} from "../../Messages.js";
import {iFrameConnection} from "../../services/IFrameConnection.js";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";

export function sanitizeUrlFromOptions(options, key) {
    if (!options[key]) {
        return options;
    }
    if (options[key]) {
        const invalidateUrl = () => {
            options[key] = "";
            options.value = options.label = options.alt = Messages.elementInvalidUrl(key);
        };
        try {
            const url = new URL(options[key]);
            if (["http:", "https:", "mailto:", "tel:"].indexOf(url.protocol) === -1) {
                invalidateUrl();
            }
        } catch (e) {
            invalidateUrl();
        }
    }
    return options;
}

export function redirectOnClickLink(event, element) {
    if (event.shiftKey || event.ctrlKey || event.metaKey || element.options.newTab || element.options.href == null ||
        (element.options.target && element.options.target !== "_self")) {
        // Leave it to the browser
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: element.options.href});
}

class MerchantLinkStyle extends StyleSheet {
    @styleRule
    link = {
        ...this.themeProps.AUTH_LINK_STYLE,
        fontSize: "inherit",
        whiteSpace: "nowrap",
    };
}

@registerStyle(MerchantLinkStyle)
export class PanelLink extends Link {
    static shouldSanitizeOptions = true;

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.link);
    }

    setOptions(options) {
        return super.setOptions(this.constructor.shouldSanitizeOptions ? sanitizeUrlFromOptions(options, "href") : options);
    }

    onMount() {
        super.onMount();
        this.addClickListener(event => {
            dispatchAnalyticsEvent(AnalyticsEventType.ELEMENT_CLICK);
            redirectOnClickLink(event, this);
        });
    }
}

