import {UI} from "../../../stem-core/src/ui/UIBase";

import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";
import {ExternalLinkIcon} from "../../SVGElements";


class ExternalLinkStyle extends StyleSheet {
    @styleRule
    link = {
        color: this.themeProps.MERCHANT_LINK,
        fontWeight: "initial",
    };

    @styleRule
    icon = {
        marginLeft: 5,
        display: "inline-flex",
    };
}

@registerStyle(ExternalLinkStyle)
export class ExternalLink extends Link {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            newTab: true,
            noIcon: false,
            label: "",
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.link);
    }

    getChildrenToRender() {
        return [
            this.render(),
            this.options.noIcon ? null : <ExternalLinkIcon className={this.styleSheet.icon}/>,
        ];
    }

    render() {
        return this.options.label;
    }
}
