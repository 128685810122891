import {UI} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {DonationStore} from "../../../../../State";
import {Donations} from "./Donations";


export class PastRecurringDonations extends Donations {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.pastRecurringDonations,
        };
    }

    getStore() {
        return DonationStore.getPastRecurring();
    }

    getExpandedRowContent() {
        return null;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            width: 0.55,
            align: "left",
            value: entry => entry.storeObject.getMerchantTitle(),
        }, {
            headerName: Messages.amount,
            width: 0.2,
            align: "left",
            value: entry => entry.storeObject.formatPriceShortened(),
        }, {
            headerName: Messages.lastPayment,
            width: 0.25,
            align: "right",
            value: entry => entry.lastPayment,
            style: {color: this.styleSheet.themeProps.MERCHANT_2},
        }];
    }
}
