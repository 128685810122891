import {keyframesRule, styleRule, styleRuleInherit, StyleSheet} from "../../../../stem-core/src/ui/Style";
import {isNetworkError} from "../../../Utils";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {UI} from "../../../../stem-core/src/ui/UIBase";
import {Messages} from "../../../Messages";
import {NOOP_FUNCTION} from "../../../../stem-core/src/base/Utils";
import {ShortCodeInput} from "./ShortCodeInput";
import {canWriteCookies} from "../../../AuthHelper";
import {authService} from "../../../../client/connection/Services";
import {Spinner} from "../../../SVGElements";
import {DefaultCodeConfirmationFormStyle, DefaultCodeConfirmationForm} from "../../../common/DefaultCodeConfirmationForm";
import {MerchantButton} from "../../../common/MerchantButton";
import {authFormService} from "../../../services/AuthFormService";


class SpinnerAnimation extends StyleSheet {
    @keyframesRule
    animation = {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    };
}

class BlockedCookiesCodeConfirmationFormStyle extends DefaultCodeConfirmationFormStyle {
    @styleRuleInherit
    button = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    @styleRule
    buttonSpinner = {
        animation: `${SpinnerAnimation.getInstance().animation.toString()} .6s linear infinite`,
        marginLeft: 4,
        verticalAlign: "middle",
        color: "inherit",
        ">:last-child g > path": {
            stroke: "currentColor",
        }
    };
}

@registerStyle(BlockedCookiesCodeConfirmationFormStyle)
class BlockedCookiesCodeConfirmationForm extends UI.Element {
    verifyCodeTimeout = null;
    lastSentCode = null;
    verifiedCode = false;
    loading = false;
    error = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
            onCodeAutomaticallyVerified: NOOP_FUNCTION,
            ButtonClass: MerchantButton,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.formComponent);
    }

    render() {
        const {styleSheet} = this;
        const {ButtonClass} = this.options;

        return [
            <ShortCodeInput ref={"codeInput"} success={this.verifiedCode}
                        onChange={() => this.redraw()} error={this.error}
                        onClearError={() => {
                            this.error = null;
                            this.redraw();
                        }}
                        onCodeInserted={() => this.handleCodeInserted()}
            />,
            !this.verifiedCode ?
                <ButtonClass className={styleSheet.button} disabled={true} onClick={() => this.verifyCode()}>
                    {Messages.confirm}
                    {this.loading ? <Spinner className={styleSheet.buttonSpinner}/>: null}</ButtonClass>
                : <ButtonClass className={styleSheet.button} onClick={() => this.handleContinueClick()}>
                    {Messages.continue}
                </ButtonClass>,
        ];
    }

    async verifyCode() {
        const code = this.codeInput.getCode();
        const setLoading = (isDisplayed) => {
            this.loading = isDisplayed;
            this.redraw();
        };

        setLoading(true);
        try {
            await authService.loginWithEmailCode({code});

            this.options.onCodeAutomaticallyVerified();
            this.codeInput.blur();
            this.verifiedCode = true;
        } catch (error) {
            this.setCodeInputError(error);
        }
        setLoading(false);
    }

    async handleContinueClick() {
        try {
            // Careful with the order, the "onSubmit" might delete the node.
            this.handleConfirmationSuccess();
            await this.options.onSubmit(this.codeInput.getCode());
        } catch (error) {
            if (this.node) {
                this.setCodeInputError(error);
            }
        }
    }

    handleConfirmationSuccess() {
        this.codeInput.blur();
        this.codeInput.updateOptions({success: true});
        this.error = null;
        this.redraw();
    }

    setCodeInputError(error) {
        this.codeInput.blur();
        this.error = isNetworkError(error) ? error.message : Messages.incorrectConfirmationCode;
        this.redraw();
    }

    getVerifyRequestThrottle() {
        return this.lastSentCode != null ? 2000 : 300;
    }

    handleCodeInserted() {
        const code = this.codeInput.getCode();
        if (this.lastSentCode === code) {
            return;
        }

        if (this.verifyCodeTimeout) {
            clearTimeout(this.verifyCodeTimeout)
        }

        this.verifyCodeTimeout = this.attachTimeout(() => {
            this.verifyCode();
        }, this.getVerifyRequestThrottle());
    }

    onMount() {
        super.onMount();
        this.attachUpdateListener(authFormService, () => this.redraw());
    }
}

export const ShortCodeEmailConfirmationForm = canWriteCookies() ? DefaultCodeConfirmationForm : BlockedCookiesCodeConfirmationForm;
