import {UI, styleRule, registerStyle, styleRuleInherit} from "../../../../../../../stem-core/src/ui/UI";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../../ui/ConfirmationModal";
import {AddressForm} from "./AddressForm";
import {Messages} from "../../../../../../Messages";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {apiCreateAddress} from "../../../../../../../client/state/UserAddressStore";
import {Toast} from "../../../../../../ui/Toast";


class AddressModalStyle extends ConfirmationModalStyle {
    @styleRule
    form = {
        marginBottom: 60,
    };

    @styleRuleInherit
    modal = {
        display: "block !important",
    };
}

@registerStyle(AddressModalStyle)
export class AddAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "addAddressModal",
            confirmLabel: Messages.save,
            title: Messages.addNewAddress,
            confirmAction: () => this.saveAddress()
        };
    }

    render() {
        return <AddressForm ref="form" className={this.styleSheet.form}/>;
    }

    async saveAddress() {
        const validInputs = this.form && this.form.validateInputs();
        if (!validInputs) {
            return;
        }

        LoadingSpinner.show();
        try {
            await apiCreateAddress(this.form.getValues());
            this.hide();
            Toast.show(Messages.addressAdded);
        } catch (error) {
            LoadingSpinner.hide();
            this.onErrorResponseReceived(error)
        } finally {
            LoadingSpinner.hide();
        }
    }

    onErrorResponseReceived(errorResponse) {
        this.form.displayFieldErrors(errorResponse);
        const nonFieldError = errorResponse.find(fieldError => fieldError.field === "non_field_errors");

        if (nonFieldError) {
            Toast.showError(nonFieldError);
        }
    }
}
