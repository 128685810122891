import {StoreObject, Store, MakeStore} from "../../../stem-core/src/state/Store";
import {Money} from "../misc/Money";
import {apiConnection} from "../../connection/BlinkApiConnection";
import {field} from "../misc/StoreField";
import {BLINK_PAY_URL} from "../../../blinkpay/Constants.js";


export class MerchantUser extends StoreObject {
    @field("Merchant") merchant;
    @field("UserProfile") user;

    @field("MerchantConversion") conversion;

    @field(Date) createdAt;
    @field(Boolean) autoPayEnabled;
    @field(Boolean) useCustomLimit;
    @field(Number) articlePriceLimit;  // TODO: This should be Money, but there is no currency field here!!!

    @field(Object) formResponses;
    @field(Boolean) canReceivePromotionalEmails;

    // Fields only available for merchants
    @field(String) email;
    @field(String) name;

    @field(String) emailNewsletterEditKey;
    @field(Date) consentDate;
    @field(Date) lastAccessDate;
    @field("MerchantUserSession") lastAccessSession;

    @field(Number) lifetimeRevenue;
    @field("Payment") latestDonationPayment;
    @field(Number) lifetimeDonatedAmount;
    @field("Donation") latestDonation;
    @field("Donation") latestOneTimeDonation;
    @field("Donation") latestRecurringDonation;

    @field(Object) metadata;

    getEmail() {
        return this.email;
    }

    getName() {
        return this.name;
    }

    getLastLocation() {
        return this.lastAccessSession?.location;
    }

    getLastDevice() {
        return this.lastAccessSession?.device;
    }

    getLifetimeRevenue() {
        return new Money(this.lifetimeRevenue, this.merchant.currency.id);
    }

    getLifetimeDonated() {
        return new Money(this.lifetimeDonatedAmount, this.merchant.currency.id);
    }

    getEmailPreferencesLink() {
        return `${BLINK_PAY_URL}/email-preferences?edit-key=${this.emailNewsletterEditKey}`;
    }
}

class MerchantUserStoreClass extends Store("MerchantUser", MerchantUser, {dependencies: ["MerchantUserSession"]}) {
    umMap = new Map(); // Map by userId and merchantId

    getUMKey(userId, merchantId) {
        return `${userId}__${merchantId}`;
    }

    getByUserAndMerchant(userId, merchantId) {
        const key = this.getUMKey(userId, merchantId);
        return this.umMap.get(key);
    }

    addObject(id, obj) {
        super.addObject(id, obj);
        const key = this.getUMKey(obj.userId, obj.merchantId);
        this.umMap.set(key, obj);
    }

    getFromObject(obj) {
        return this.getByUserAndMerchant(obj.userId, obj.merchantId);
    }
}

export const MerchantUserStore = new MerchantUserStoreClass();

class MerchantUserSession extends StoreObject {
    @field(String) key; // Optionally, in case we can login as that user
    @field(Date) expiresAt; // Again, optional
    @field(Object) location;
    @field(Object) device;
}

export const MerchantUserSessionStore = MakeStore("MerchantUserSession", MerchantUserSession);


export async function apiUpdateUserMerchantPreferences(preferences = {}) {
    const response = await apiConnection.post("/preferences/update_user_merchant_preferences/", preferences);
    return MerchantUserStore.loadObjectFromResponse(response);
}

export async function apiMerchantGenerateUserToken(request) {
    const response = await apiConnection.post("/merchant/fake_user_session/", request);
    return MerchantUserSessionStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditUser(merchantUser, request) {
    const response = await apiConnection.post("/merchant/edit_user/", {
        userId: merchantUser.userId,
        ...request,
    });
    return MerchantUserStore.loadObjectFromResponse(response);
}
