import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {NavbarConstants} from "../Constants";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {isSafari} from "../../blinkpay/Utils";
import {isSmallScreen} from "../../blinkpay/UtilsLib";


export class NavManager extends Dispatchable {
    activeCallbacks = [];

    constructor(sections, items) {
        super();

        this.sections = sections;
        this.items = items;

        this.attachEventListener(window, "scroll", () => {
            this.updateActiveSection();
        });
        this.attachEventListener(window, "resize", () => {
            this.updateActiveSection();
        });
    }

    getActiveItem() {
        const {items} = this;

        let itemToSelect = items[0];
        let bestOverlapWithScreen = 0;

        for (const item of items) {
            const itemElement = item.element.getInstance();
            if (!itemElement) {
                continue;
            }
            let {top, bottom, height} = itemElement.node.getBoundingClientRect();
            if (top < 0) {
                top = 0;
            }
            if (bottom > window.innerHeight) {
                bottom = window.innerHeight;
            }
            const currentOverlapWithScreen = (bottom - top) / height;
            if (currentOverlapWithScreen > bestOverlapWithScreen) {
                bestOverlapWithScreen = currentOverlapWithScreen;
                itemToSelect = item;
            }
        }

        return itemToSelect;
    }

    updateActiveSection() {
        const activeTabId = this.getActiveItem().id;

        if (this.isScrolling) {
            if (activeTabId === this.clickedElementId) {
                this.clickedElementId = null;
                this.isScrolling = false;
            }
        } else {
            this.updateActiveTab(activeTabId);
        }
    }

    updateActiveTab(activeTab) {
        this.activeTab = activeTab;
        this.dispatch("activeTabUpdate", activeTab);
    }

    getActiveTab() {
        return this.activeTab;
    }

    scrollTo(elementId) {
        const clickedSection = this.items.find(item => item.id === elementId);

        this.clickedElementId = clickedSection.id;

        // Flag to skip highlighting intermediary sections-publisher.
        this.isScrolling = true;

        const navbarScrollOffset = isSmallScreen() ? NavbarConstants.MOBILE_HEIGHT : NavbarConstants.HEIGHT;
        window.scrollTo({
            top: clickedSection.element.getInstance().node.offsetTop - navbarScrollOffset,
            behavior: "smooth",
        });

        if (isSafari()) {
            this.updateActiveSection();
        }
    }
}
