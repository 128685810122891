import {Messages} from "../Messages";
import {isFacebookBrowser} from "../Utils";
import {BLINK_PAY_URL} from "../Constants";
import {PanelLink} from "../panel/panel-elements/PanelLink.jsx";


export class MerchantUserResourcesAccessLink extends PanelLink {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hasAvailableResources: false,
            extraResourcesCount: null,
            href: BLINK_PAY_URL + "/login", // TODO @branch @auth insanely complex, wtf?
            newTab: true,
            message: null,
            rel: "opener",
        };
    }

    render() {
        if (isFacebookBrowser()) { // TODO: Maybe support these later.
            return null;
        }
        const {extraResourcesCount, message} = this.options;
        if (message) {
            return message;
        }
        return Messages.merchantUserResourcesAccessLink(extraResourcesCount);
    }
}
