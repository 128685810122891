import {StoreObject, Store} from "../../stem-core/src/state/Store";
import {apiConnection} from "../connection/BlinkApiConnection";
import {CurrencyStore} from "./CurrencyStore";

export class UserPreferences extends StoreObject {
    getFields() {
        const {
            userId,
            articlePriceLimit,
            autoPayEnabled,
            autoTopUpAmount,
            autoTopUpThreshold,
            autoTopUpEnabled,
            alwaysShowBalance,
            showPaymentAnimation,
            showOnboarding,
        } = this;

        return {
            userId,
            articlePriceLimit,
            autoPayEnabled,
            autoTopUpAmount,
            autoTopUpThreshold,
            autoTopUpEnabled,
            alwaysShowBalance,
            showPaymentAnimation,
            showOnboarding,
        };
    }

    getPaymentProcessor() {
        return this.paymentProcessor;
    }

    getDefaultCurrency() {
        return CurrencyStore.get(this.defaultCurrencyId);
    }

    shouldShowBalanceInline() {
        return this.alwaysShowBalance;
    }

    shouldShowPaymentAnimation() {
        return this.showPaymentAnimation;
    }
}

class UserPreferencesStoreClass extends Store("UserPreferences", UserPreferences) {
    getPreferences() {
        return this.all()[0];
    }
}

export const UserPreferencesStore = new UserPreferencesStoreClass();

export async function apiUpdatePreferences(preferences = {}) {
    const response = await apiConnection.post("/preferences/update_user_preferences/", preferences);
    return UserPreferencesStore.loadObjectFromResponse(response);
}
