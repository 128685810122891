// This file is meant to hold utility methods that don't have any Blink-specific logic,
// so they can be used anywhere

import {isMobileDevice} from "../stem-core/src/base/Device";
import {MOBILE_DEVICE_SCREEN_WIDTH_LIMIT, SMALL_SCREEN_WIDTH_LIMIT} from "./Constants";
import {isPlainObject} from "../stem-core/src/base/Utils.js";

export * from "../stem-core/src/base/Utils";

// BlinkGlobal can be used to prevent circular dependencies, and where we don't want to pollute window/self.
export const BlinkGlobal = {};

export function isDeepEqual(obj1, obj2) {
    // Different types means different variables.
    if (typeof obj1 !== typeof obj2) {
        return false;
    }
    // If one of them is null or undefined, the equality check is trivial.
    if (obj1 == null || obj2 == null) {
        return obj1 === obj2;
    }
    if (!isPlainObject(obj1) && !Array.isArray(obj1)) {
        // Not an object, so this is trivial.
        return obj1 === obj2;
    }

    const obj1Keys = Object.keys(obj1);
    if (obj1Keys.length !== Object.keys(obj2).length) {
        return false;
    }
    // If the entry count is the same, it's enough to check that the second object has each of the first's entries.
    for (const key of obj1Keys) {
        if (!isDeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // The objects have the same entries. That means they are equal.
    return true;
}

export function getUrlQueryString() {
    return window.location.search;
}

function parseQueryParam(value) {
    try {
        return JSON.parse(value);
    } catch {
        try {
            return JSON.parse(decodeURIComponent(value));
        } catch {
            try {
                return decodeURIComponent(value);
            } catch {
                return null;
            }
        }
    }
}

export function getQueryParam(param) {
    const urlSearch = new URLSearchParams(getUrlQueryString());
    return parseQueryParam(urlSearch.get(param));
}

export function formatQueryParam(param) {
    try {
        return encodeURIComponent(JSON.stringify(param));
    } catch (e1) {
        try {
            return encodeURIComponent(param);
        } catch (e2) {
            return "";
        }
    }
}

export function generateUniqueId() {
    // TODO @cleanup ugh
    // generate UUID v4
    const seq = Date.now().toString(16) + parseInt(Math.random() * 1e18).toString(16) + parseInt(Math.random() * 1e18).toString(16);

    return [seq.substr(0, 8), seq.substr(8, 4), `4${seq.substr(12, 3)}`, `8${seq.substr(15, 3)}`, seq.substr(18, 12)].join("-");
}

export function getWindowWidth() {
    if (BlinkGlobal.iFrameState?.screen && isMobileDevice()) {
        return BlinkGlobal.iFrameState.screen.width;
    }
    if (window.screen && isMobileDevice()) {
        return window.screen.width;
    }
    return window.innerWidth;
}

export function getWindowHeight() {
    return document.documentElement.clientHeight;
}

export function isSmallScreen() {
    if (BlinkGlobal.iFrameState?.screen?.isEmulatorScreen) {
        return BlinkGlobal.iFrameState.screen.width <= MOBILE_DEVICE_SCREEN_WIDTH_LIMIT;
    }
    if (window.screen && isMobileDevice()) {
        return getWindowWidth() <= MOBILE_DEVICE_SCREEN_WIDTH_LIMIT;
    }
    return getWindowWidth() <= SMALL_SCREEN_WIDTH_LIMIT;
}

export function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function getDocumentHiddenInfo() {
    if (document.hidden) {
        return {
            hidden: true,
            event: "visibilitychange"
        };
    }

    if (document.msHidden) {
        return {
            hidden: true,
            event: "msvisibilitychange"
        };
    }

    if (document.webkitHidden) {
        return {
            hidden: true,
            event: "webkitvisibilitychange"
        };
    }

    return {hidden: false};
}

export function executeWhenDocumentVisible(callback) {
    const hiddenInfo = getDocumentHiddenInfo();
    if (!hiddenInfo.hidden) {
        callback();
        return {removeHandler: null};
    }
    const handlerFunction = () => {
        setTimeout(callback, 300);
        removeHandler();
    };
    const removeHandler = () => document.removeEventListener(hiddenInfo.event, handlerFunction);
    document.addEventListener(hiddenInfo.event, handlerFunction);
    return {removeHandler};
}

export function openWindowWithOpener(url = "", target = "_blank") {
    return window.open(url, target, "opener");
}

export function convertHTMLStringToJSON(value, optionsWrapper = (x => x)) {
    const parser = new DOMParser();
    const document = parser.parseFromString(`<body>${value}</body>`, "text/xml");
    const parseElements = (root) => {
        if (root.nodeType === Node.TEXT_NODE) {
            const value = root.wholeText;
            let trimmedValue = value.trim();
            if (!trimmedValue) {
                return null;
            }
            // TODO why? it looks like if there were spaces at the end, we want to combine into one at most at each end
            if (trimmedValue[0] !== value[0]) {
                trimmedValue = " " + trimmedValue;
            }
            if (trimmedValue[trimmedValue.length - 1] !== value[value.length - 1]) {
                trimmedValue += " ";
            }
            return trimmedValue;
        }

        if (root.nodeType !== Node.ELEMENT_NODE) {
            return null;
        }

        let options = {};
        root.getAttributeNames().forEach(name => {
            options[name] = root.getAttribute(name);
        });
        return optionsWrapper({
            tag: root.tagName,
            children: [...root.childNodes].map(parseElements).filter(Boolean),
            ...options,
        });
    };

    // First child of the document will be the body.
    return [...document.childNodes[0].childNodes].map(parseElements).filter(Boolean);
}
