import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";
import {CheckboxChecked, CheckboxIndeterminate} from "../SVGElements";
import {KeyEvent} from "../KeyEvent";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {MessageElement} from "../widget/ui/MessageElement";
import {DashboardBaseInput} from "../../core/ui/BaseInput";
import {Device} from "../../stem-core/src/base/Device";
import {Theme} from "../../stem-core/src/ui/UI";


export class CheckboxStyle extends StyleSheet {
    @styleRule
    checkbox = {
        minHeight: () => `calc(${this.themeProps.CHECKBOX_SIZE} + 2px)`,
        minWidth: () => `calc(${this.themeProps.CHECKBOX_SIZE} + 2px)`,
        border: "1px solid " + this.themeProps.CHECKBOX_BORDER_COLOR,
        borderRadius: this.themeProps.CHECKBOX_BORDER_RADIUS,
        display: "inline-flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        ":hover": {
            cursor: "pointer",
        },
        "::before": {
            content: "\" \"",
            width: "calc(100% + 20px)",
            height: "calc(100% + 20px)",
            top: -10,
            left: -10,
            position: "absolute",
        },
        ">:first-child": {
            display: "flex",
        }
    };

    @styleRule // Tests depend on this being set.
    checkboxEnabled = {
        backgroundColor: this.themeProps.CHECKBOX_ENABLED_BACKGROUND_COLOR,
        checkmarkColor: this.themeProps.CHECKBOX_CHECKMARK_COLOR,
    };
}

@registerStyle(CheckboxStyle)
export class Checkbox extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            indeterminate: false,
            checkboxSize: null,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.checkbox);
        if (this.getValue() || this.options.indeterminate) {
            attr.addClass(this.styleSheet.checkboxEnabled);
        }
    }

    setValue(value, indeterminate=null) {
        if (indeterminate !== null) {
            this.setOptions({indeterminate});
        }
        super.setValue(value);
    }

    render() {
        let checkboxOptions = {
            color: this.styleSheet.checkboxEnabled.options.style.checkmarkColor,
        };
        if (this.options.checkboxSize) {
            checkboxOptions.size = this.options.checkboxSize;
        }
        if (this.options.indeterminate) {
            return <CheckboxIndeterminate {...checkboxOptions}/>;
        }
        if (this.getValue()) {
            return <CheckboxChecked {...checkboxOptions}/>;
        }
        return null;
    }

    onMount() {
        super.onMount();
        this.addClickListener((event) => {
            event.stopPropagation();
            this.setAndDispatchValue(!this.value);
        });
    }
}

export class LabeledCheckboxStyle extends StyleSheet {
    @styleRule
    element = {
        display: "flex",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "flex-start",
        lineHeight: this.themeProps.GENERAL_LINE_HEIGHT, // This is needed because this component is used in dashboard.
    };

    @styleRule
    label = {
        userSelect: "none",
        "-webkit-user-select": "none",
    };

    @styleRule
    checkbox = {
        pointerEvents: "none",
        marginLeft: 0,
        marginRight: 12,
        marginTop: ((parseFloat(this.themeProps.GENERAL_LINE_HEIGHT) || 1.5) - 1) / 2 + "em", // Align the checkbox top margin with the text top margin.
    };

    @styleRule
    disabled = {
        opacity: 0.7,
        pointerEvents: Device.isMobileDevice() ? "none" : "initial",
        cursor: "not-allowed",
    };
}

@registerStyle(LabeledCheckboxStyle)
export class LabeledCheckbox extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            CheckboxClass: Checkbox,
            onToggle: NOOP_FUNCTION,
            label: "",
            enabled: false,
            indeterminate: false,
            size: Theme.props.CHECKBOX_SIZE,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
        if (this.options.disabled) {
            attr.addClass(this.styleSheet.disabled);
        }
    }

    setValue(value, indeterminate=null) {
        if (indeterminate !== null) {
            this.setOptions({indeterminate});
        }
        super.setValue(value);
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {size, indeterminate, CheckboxClass} = this.options;

        return [
            <CheckboxClass onChange={enabled => this.setAndDispatchValue(enabled)} tabIndex="0" role="button"
                           initialValue={this.value} className={styleSheet.checkbox} size={size}
                           indeterminate={indeterminate}/>,
            this.options.label ? <MessageElement className={styleSheet.label} message={this.options.label}/>
                    : this.render(),
        ];
    }

    isIndeterminate() {
        return this.options.indeterminate;
    }

    onMount() {
        super.onMount();
        this.addClickListener(event => {
            event.stopPropagation();
            this.setAndDispatchValue(!this.value);
        });
        this.addNodeListener("keyup", event => {
            if (this.options.disabled) {
                return;
            }
            const key = new KeyEvent(event);
            if (key.isEnter() || key.isSpace()) {
                this.setAndDispatchValue(!this.value);
            }
        });
    }
}
