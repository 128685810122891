import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {UI} from "../../stem-core/src/ui/UIBase";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";

export class WalletListItemStyle extends StyleSheet {
    @styleRule
    item = {
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        color: this.themeProps.WALLET_LIST_ITEM_COLOR,
        display: "-webkit-flex",
        padding: "14px 0",
        cursor: "pointer",
        borderTop: () => "1px solid " + this.themeProps.WALLET_LIST_ITEM_BORDER,
        borderBottom: () => "1px solid " + this.themeProps.WALLET_LIST_ITEM_BORDER,
        ":not(:last-child)": {
            marginBottom: -1,
        },
        ">:last-child": {
            flex: 1,
        },
    };

    @styleRule
    radioInput = {
        border: () => "2px solid " + this.themeProps.WALLET_LIST_RADIO_COLOR,
        borderRadius: "100%",
        position: "relative",
        width: 18,
        height: 18,
        marginRight: 12,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    @styleRule
    selected = {
        cursor: "pointer",
        "::after": {
            height: 8,
            width: 8,
            background: this.themeProps.WALLET_LIST_RADIO_COLOR,
            content: "\"\"",
            borderRadius: "100%",
        },
    };
}

@registerStyle(WalletListItemStyle)
export class WalletListItem extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSelect: NOOP_FUNCTION,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.item);
    }

    getItem() {
        return this.getGivenChildren()[0];
    }

    render() {
        const {styleSheet} = this;
        const item = this.getItem();

        return [
            <div className={styleSheet.radioInput + (item.options.selected ? styleSheet.selected : "")} />,
            <div testId="listItem">{item}</div>
        ];
    }

    onMount() {
        super.onMount();
        this.addClickListener(() => this.options.onSelect());
    }
}
