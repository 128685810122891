import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";

import {DropdownIcon} from "../../SVGElements";
import {isSmallScreen} from "../../Utils";
import {Messages} from "../../Messages";
import {DropdownList} from "./DropdownList";
import {DashboardBaseInput} from "../../../core/ui/BaseInput";


export class DropdownStyle extends StyleSheet {
    @styleRule
    label = {
        marginTop: 18,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_SMALL,
        color: this.themeProps.MERCHANT_TEXT_SECONDARY_COLOR,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
        marginBottom: 8,
    };

    @styleRule
    dropdown = {
        width: "100%",
    };

    @styleRule
    selected = {
        color: this.themeProps.DROPDOWN_COLOR,
        whiteSpace: "nowrap",
        cursor: "pointer",
        width: "100%",
        transition: ".2s  ease",
        fontSize: () => (isSmallScreen() ? this.themeProps.MERCHANT_FONT_SIZE_NORMAL : this.themeProps.MERCHANT_FONT_SIZE_LARGE),
        background: this.themeProps.DROPDOWN_COLLAPSED_BACKGROUND,
        ":hover": {
            background: this.themeProps.DROPDOWN_COLLAPSED_HOVER_BACKGROUND,
        },
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        ">:first-child": {
            flex: 1,
        },
        borderRadius: "6px",
    };

    @styleRule
    activeCenter = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 15,
    };

    @styleRule
    placeholder = {
        color: this.themeProps.DROPDOWN_PLACEHOLDER_COLOR,
    };

    @styleRule
    dropdownIcon = {
        display: "flex",
        padding: "9px 18px 9px 9px",
    };
}

@registerStyle(DropdownStyle)
export class Dropdown extends DashboardBaseInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onExpand: NOOP_FUNCTION,
            onCollapse: NOOP_FUNCTION,
            options: [],
            DropdownListClass: DropdownList,
            testId: "dropdownComponent",
            label: "",
            renderOption: x => x,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.dropdown);
    }

    handleDropdownSelectedClick(event) {
        event.stopPropagation();
        event.preventDefault();
        // the default behavior we don't want to get rid of:
        // blur the active element to hide keyboard on mobile
        if (document.activeElement && document.activeElement.blur) {
            document.activeElement.blur();
        }
        this.expand();
    }

    render() {
        const {styleSheet} = this;
        const {placeholder, label, renderOption} = this.options;
        const {MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL} = this.styleSheet.themeProps;

        return [
            label && !MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL ? <div className={styleSheet.label}>{label}</div> : <div />,
            <div className={`${styleSheet.selected} ${this.value ? "" : styleSheet.placeholder}`} ref="selected">
                <div className={styleSheet.activeCenter}>
                    <div>{this.value ? renderOption(this.value) : ((MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL ? placeholder : label)
                            || Messages.choose + "...")}</div>
                </div>
                <DropdownIcon className={styleSheet.dropdownIcon}/>
            </div>
        ];
    }

    setOptions(options) {
        super.setOptions(options);
        if (this.expandedList) {
            const {options, active} = this.getDropdownListOptions();
            this.expandedList.updateOptions({options, active});
        }
    }

    getDropdownListOptions() {
        return {
            options: this.options.options,
            anchor: this.selected,
            value: this.value,
            renderOption: this.options.renderOption,
        };
    }

    expand() {
        if (!this.expandedList?.node && this.isInDocument()) {
            const {options, value, renderOption} = this.getDropdownListOptions();
            this.expandedList = this.options.DropdownListClass.expand(this, this.selected, options, value, renderOption);
            this.options.onExpand();
        }
    }

    destroyNode() {
        if (this.expandedList) {
            this.expandedList.destroyNode();
        }
        return super.destroyNode();
    }

    onMount() {
        super.onMount();

        this.addListener("changeActive", item => this.setAndDispatchValue(item));

        this.addListener("collapse", () => {
            this.expandedList = null;
            this.options.onCollapse();
        });

        this.selected.addClickListener(event => this.handleDropdownSelectedClick(event));
    }
}
