import {UI} from "../../../stem-core/src/ui/UIBase";

import {Dropdown, DropdownStyle} from "../../ui/dropdown/Dropdown";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {DropdownList, DropdownListStyle} from "../../ui/dropdown/DropdownList";


class MerchantDropdownListStyle extends DropdownListStyle {
    @styleRuleInherit
    item = {
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        ">*": {
            padding: "10px 15px 10px",
        }
    };

    @styleRuleInherit
    popup = {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 180,
        background: this.themeProps.MERCHANT_INPUT_BACKGROUND,
        boxShadow: this.themeProps.MERCHANT_CARD_BOX_SHADOW,
        border: "1px solid " + this.themeProps.MERCHANT_CARD_BORDER_COLOR,
        ">*": {
            borderTop: () => "1px solid " + this.themeProps.MERCHANT_CARD_BORDER_COLOR,
        },
    };

    @styleRuleInherit
    hovered = {
        background: this.themeProps.MERCHANT_INPUT_BACKGROUND_HOVER,
    };
}

@registerStyle(MerchantDropdownListStyle)
class MerchantDropdownList extends DropdownList {}

class MerchantDropdownStyle extends DropdownStyle {
    @styleRuleInherit
    selected = () => ({
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        color: this.themeProps.MERCHANT_INPUT_TEXT_COLOR,
        background: this.themeProps.MERCHANT_INPUT_BACKGROUND,
        border: "1px solid " + this.themeProps.MERCHANT_INPUT_BORDER_COLOR,
        ":hover": {
            background: this.themeProps.MERCHANT_INPUT_BACKGROUND,
            border: "1px solid " + this.themeProps.MERCHANT_INPUT_BORDER_ACTIVE_COLOR,
        },
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        ...this.themeProps.MERCHANT_INPUT_STYLE,
    });

    @styleRuleInherit
    placeholder = {
        color: this.themeProps.MERCHANT_INPUT_PLACEHOLDER_COLOR,
    };
}

@registerStyle(MerchantDropdownStyle)
export class MerchantDropdown extends Dropdown {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            DropdownListClass: MerchantDropdownList,
        };
    }
}
