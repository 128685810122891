import {UI} from "../../../stem-core/src/ui/UIBase";

import {Button} from "../../../stem-core/src/ui/button/Button";
import {styleRule, styleRuleInherit, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Device} from "../../../stem-core/src/base/Device";
import {ActiveButton, ActiveButtonStyle, RadioButtons, RadioButtonsStyle, ToggleButton, ToggleButtonStyle}
from "../../ui/Button";


export class WalletButtonStyle extends StyleSheet {
    @styleRule
    blinkButton = {
        padding: "12px 24px",
        outline: 0,
        fontWeight: "bold",
        cursor: "pointer",
        transition: ".2s",
        border: "none",
        "-webkit-user-select": "none",
        userSelect: "none",
        color: this.themeProps.WALLET_BUTTON_COLOR,
        background: this.themeProps.WALLET_BUTTON_BACKGROUND,
        ":hover": {},
        ":disabled": {
            color: this.themeProps.WALLET_BUTTON_DISABLED_COLOR,
            cursor: "not-allowed",
            pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
        },
        ":hover:disabled": {},
        fontSize: 16,
        borderRadius: 4,
        boxShadow: this.WALLET_BUTTON_SHADOW,
        textShadow: this.WALLET_BUTTON_TEXT_SHADOW,
    };

    Size() {}
    Level() {}
}

@registerStyle(WalletButtonStyle)
export class WalletButton extends Button {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.blinkButton);
    }
}

class WalletActiveButtonStyle extends ActiveButtonStyle {
    @styleRule
    button = {
        userSelect: "none",
        "-webkit-user-select": "none",
        outline: 0,
        cursor: "pointer",
        padding: 0,
        flex: 1,
        margin: "0 .066rem",
        border: "none",
        height: 43,
        fontWeight: "regular",
        fontSize: 16,
        color: this.themeProps.WALLET_ACTIVE_BUTTON_INACTIVE_COLOR,
        background: this.themeProps.WALLET_ACTIVE_BUTTON_INACTIVE_BACKGROUND,
        ":hover": {
            background: this.themeProps.WALLET_ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND,
        },
    };

    @styleRule
    active = {
        transition: ".2s ease",
        color: this.themeProps.WALLET_ACTIVE_BUTTON_COLOR,
        fontWeight: "bold",
        background: this.themeProps.WALLET_ACTIVE_BUTTON_BACKGROUND,
        ":hover": {
            background: this.themeProps.WALLET_ACTIVE_BUTTON_BACKGROUND,
        },
    };
}

@registerStyle(WalletActiveButtonStyle)
export class WalletActiveButton extends ActiveButton {}

class WalletRadioButtonsStyle extends RadioButtonsStyle {
    @styleRuleInherit
    button = {
        height: 43,
    };
}

@registerStyle(WalletRadioButtonsStyle)
export class WalletRadioButtons extends RadioButtons {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            ActiveButtonClass: WalletActiveButton,
        };
    }
}

class WalletToggleButtonStyle extends ToggleButtonStyle {
    @styleRuleInherit
    button = {
        background: this.themeProps.WALLET_TOGGLE_DISABLED_BACKGROUND,
        ">:first-child": {
            height: 20,
            width: 20,
            top: -3,
            borderRadius: "100%",
            position: "absolute",
            left: 0,
            background: this.themeProps.WALLET_TOGGLE_DISABLED_COLOR,
            boxShadow: this.themeProps.WALLET_TOGGLE_SHADOW,
        },
        ">:nth-child(2)": {
            position: "absolute",
            top: -10,
            left: -10,
            width: "calc(100% + 20px)",
            height: "calc(100% + 20px)",
        },
    };

    @styleRuleInherit
    active = {
        background: this.themeProps.WALLET_TOGGLE_BACKGROUND,
        ">:first-child": {
            background: this.themeProps.WALLET_TOGGLE_COLOR,
        },
    };
}

@registerStyle(WalletToggleButtonStyle)
export class WalletToggleButton extends ToggleButton {}
