import {BaseEnum, makeEnum} from "./BaseEnum";

@makeEnum
export class EditableObjectStatus extends BaseEnum {
    static DRAFT;
    static ACTIVE;
    static INACTIVE;
}

@makeEnum
export class VisibilityStatus extends BaseEnum {
    static PRIVATE; // Only admins can access it
    static HIDDEN; // Users can access it by id, but don't see it by default
    static PUBLIC; // Users should see and access it
}

@makeEnum
export class TaskStatus extends BaseEnum {
    static PENDING;
    static IN_PROGRESS;
    static FAILED;
    static SUCCEEDED
}

@makeEnum
export class InputFieldRequiredType extends BaseEnum {
    static REQUIRED;
    static OPTIONAL;  // Presented, but the user can choose not to use it
    static NOT_REQUESTED;  // Not even asked from the user
}