import {UI, Theme, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";
import {capitalize, toArray} from "../Utils";

export const DividerPositions = {
    TOP: "top",
    BOTTOM: "bottom"
};

class ListItem extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            /*
                Each element of the list can be visually separated from other list items.
                The divider renders as a ListItem border and could be configured using dividerOptions object.
                This object has the following format:
                {
                    position: <DividerPositions value / Array of DividerPositions> | Default value: " ,
                    width: <String> | Default value: "1px",
                    color: <String> | Default value: DIVIDER_LINE_COLOR from theme properties
                }
             */
            dividerOptions: {}
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        const dividerSettings = {
            position: DividerPositions.BOTTOM,
            width: "1px",
            color: Theme.props.DIVIDER_LINE_COLOR,
            ...this.options.dividerOptions,
        };

        attr.addClass()

        // add border (divider) styles
        attr.setStyle("border", "0px solid " + dividerSettings.color);

        for (let position of toArray(dividerSettings.position)) {
            attr.setStyle(`border${capitalize(position)}Width`, dividerSettings.width);
        }
    };
}

export class List extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            /*
                Each element of the list can be visually separated from other list items.
                The divider renders as a ListItem border and could be configured using dividerOptions object.
                This object has the following format:
                {
                    position: <DividerPositions value> | <Array of DividerPositions>,
                    width: <String> | Default value: "1px",
                    color: <String> | Default value: DIVIDER_LINE_COLOR from theme properties
                }
             */
            dividerOptions: {},
            testId: "list",
        };
    }

    render() {
        return this.getGivenChildren().map(child => <ListItem testId="listItem" dividerOptions={this.options.dividerOptions}>{child}</ListItem>)
    }
}

export class EmptyListStyle extends StyleSheet {
    @styleRule
    list = {
        color: this.themeProps.EMPTY_LIST_COLOR,
        fontSize: 20,
        marginBottom: 24,
    };
}

@registerStyle(EmptyListStyle)
export class EmptyList extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.list);
    }
}
