import {registerStyle, styleRule, styleRuleInherit, UI} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {SubscriptionStore, PaymentStore} from "../../../../../State";
import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../../ui/BaseCard";
import {FakeTable, FakeTableRow, FakeTableRowStyle} from "../../../../../ui/FakeTable";
import {DEFAULT_DATE_FORMAT} from "../../../../../Constants";
import {PaymentDescription} from "../../wallet/components/PaymentDescription";
import {Link} from "../../../../../../stem-core/src/ui/UIPrimitives";
import {Dispatcher} from "../../../../../../stem-core/src/base/Dispatcher";
import {EndpointPaginator} from "../../../../../../client/state/EndpointPaginator";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";


class SubscriptionPaymentTableRowStyle extends FakeTableRowStyle {
    @styleRuleInherit
    bodyRow = {
        padding: "8px 0px",
        height: "auto",
    };
}

@registerStyle(SubscriptionPaymentTableRowStyle)
class SubscriptionPaymentTableRow extends FakeTableRow {}

export class SubscriptionPaymentsStyle extends BaseCardStyle {
    @styleRule
    paymentTitle = {
        fontSize: 14,
        fontWeight: "initial",
    };
}

@registerStyle(SubscriptionPaymentsStyle)
@autoredraw(PaymentStore)
export class SubscriptionPayments extends BaseCard {
    paginator = new EndpointPaginator(
        PaymentStore,
        PaymentStore.API_LIST_ENDPOINT,
        {resourceTypes: [PaymentStore.ResourceType.SUBSCRIPTION]},
        {resourceType: PaymentStore.ResourceType.SUBSCRIPTION},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.subscriptionPayments,
            description: () => (this.getTransactions().length || !this.paginator.lastPageLoaded) ? "" : Messages.emptySubscriptionPayments,
        };
    }

    getTransactions() {
        // TODO @Mihai seems to be a lot of sorting
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);;
    }

    getTransactionDescription(transaction) {
        const subscription = SubscriptionStore.get(transaction.resourceId);
        return {title: subscription ? subscription.getMerchantTitle() : "",};
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        return <PaymentDescription payment={payment}>
            <Link className={styleSheet.paymentTitle}
                  style={{color: styleSheet.themeProps.MERCHANT_LINK}}
                  onClick={() => Dispatcher.Global.dispatch("highlightSubscription", payment.resourceId)}>
                {this.getTransactionDescription(payment)?.title}
            </Link>
        </PaymentDescription>;
    }

    render() {
        const transactions = this.getTransactions();

        if (!transactions.length) {
            return [];
        }

        return (
            <FakeTable
                columns={[
                    {
                        headerName: Messages.description,
                        width: 0.5,
                        align: "left",
                        value: transaction => this.getDescriptionColumnValue(transaction),
                    },
                    {
                        headerName: Messages.amount,
                        width: 0.2,
                        align: "right",
                        value: transaction =>
                            `${transaction.status === PaymentStore.Status.REVERTED ? "-" : ""}${transaction.getDebitedAmount()}`,
                    },
                    {
                        headerName: Messages.date,
                        width: 0.3,
                        align: "right",
                        value: transaction => transaction.createdAt.format(DEFAULT_DATE_FORMAT),
                        style: {color: this.styleSheet.themeProps.MERCHANT_2},
                    },
                ]}
                entries={transactions}
                TableRowClass={SubscriptionPaymentTableRow}
            />
        );
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
