import {UI} from "../../../stem-core/src/ui/UI";
import {Router} from "../../../stem-core/src/ui/Router";
import {getQueryParam} from "../../UtilsLib";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {authService} from "../../../client/connection/Services";
import {apiUndoEmailChange} from "../../../client/state/UserProfileStore";
import {EmailChangeUndoSuccessModal} from "../authenticated/pages/account/components/email-change/EmailChangeUndoSuccessModal";
import {Toast} from "../../ui/Toast.jsx";


export class UndoChangeEmailPage extends UI.Element {
    async undoEmailChange(undoToken) {
        requestAnimationFrame(() => LoadingSpinner.show());

        try {
            const response = await apiUndoEmailChange(undoToken);
            authService.setAuthToken(response);
            Router.changeURL("/account")
            EmailChangeUndoSuccessModal.show();
        } catch (error) {
            Router.changeURL(authService.isAuthenticated() ? "/account" : "/");
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    onMount() {
        super.onMount();

        const undoToken = getQueryParam("undoToken");
        if (!undoToken) {
            Router.changeURL("/");
            return;
        }

        requestAnimationFrame(() => this.undoEmailChange(undoToken));
    }
}
