import {registerStyle, styleRule, UI} from "../../../../../../stem-core/src/ui/UI";

import {SubscriptionStore, SubscriptionOfferStore, RecurringPaymentStoreObject} from "../../../../../State";
import {Messages} from "../../../../../Messages";
import {MyRecurringPayment, MyRecurringPaymentStyle} from "./MyRecurringPayment";
import {CancelSubscriptionModal} from "../components/CancelSubscriptionModal";
import {ReactivateSubscriptionModal} from "../components/ReactivateSubscriptionModal";
import {ChangeSubscriptionAddressModal} from "../components/ChangeSubscriptionAddressModal";
import {CardDetails} from "../../account/components/payment-method/CardDetails";
import {ChoosePaymentMethodModal} from "../components/ChoosePaymentMethodModal";
import {ExclamationTriangleIcon} from "../../../../../SVGElements";
import {BlinkButton} from "../../../../../ui/Button";
import {UserAddressStore} from "../../../../../../client/state/UserAddressStore";
import {PaymentMethodStore} from "../../../../../../client/state/PaymentMethodStore";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";
import {AddCardModal} from "../../wallet/components/cards/AddCardModal";
import {getPaymentMethodEmptySubscriptionMessage} from "../../../../../../blink-sdk/messaging/MessagingUtils";


class ActiveSubscriptionsStyle extends MyRecurringPaymentStyle {
    @styleRule
    change = {
        ...this.themeProps.USER_DASHBOARD_CHANGE_BUTTON,
        marginRight: 20,
        display: "inline-block",
    };

    @styleRule
    flexContainer = {
        display: "flex",
        justifyContent: "space-between",
    };
}

@registerStyle(ActiveSubscriptionsStyle)
@autoredraw(
    UserAddressStore,
    PaymentMethodStore,
    SubscriptionStore,
    SubscriptionOfferStore
)
export class ActiveSubscriptions extends MyRecurringPayment {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.activeSubscriptions,
            description: () => this.getStore().length ? "" : Messages.emptyActiveSubscriptions,
            expandedRows: true,
        };
    }

    getStore() {
        return [...SubscriptionStore.getActive(), ...SubscriptionStore.getUnpaid()];
    }

    getExpandedRowContent(entry) {
        const {styleSheet} = this;
        const contentRows = [];
        const shippingAddress = entry.storeObject.getShippingAddress();
        if (shippingAddress) {
            contentRows.push({
                label: Messages.shippingAddress,
                value: <div className={styleSheet.flexContainer}>
                    <div style={{overflow: "hidden", textOverflow: "ellipsis", flex: 1}}>
                        {shippingAddress}
                    </div>
                    <div testId="changeAddress" className={styleSheet.change} style={{position: "initial"}}
                         onClick={() => ChangeSubscriptionAddressModal.show({subscription: entry.storeObject})}>
                        {Messages.change}
                    </div>
                </div>
            });
        }
        if (entry.storeObject.status !== SubscriptionStore.Status.CANCELED) {
            const card = entry.storeObject.getPaymentMethod();
            contentRows.push({
                label: Messages.paymentMethod,
                value: <div className={styleSheet.flexContainer}>
                    {card ? <CardDetails card={card} iconSize={16} iconMargin={8} cardDetailsMessage={
                        Messages.usingCardBoldEnding(card.getBrand(), card.getLastDigits())}/> :
                        <div>{getPaymentMethodEmptySubscriptionMessage(entry.storeObject)}</div>}
                    <div className={styleSheet.change} testId="changeCard"
                         onClick={() => card ? ChoosePaymentMethodModal.show({entry: entry.storeObject}) :
                                    AddCardModal.show()}>
                        {card ? Messages.change : Messages.addCard}
                    </div>
                </div>
            });
        }
        if (entry.storeObject.isUnpaid()) {
            contentRows.push({
                label: Messages.lastPayment,
                value: [
                    <div className={this.styleSheet.failedPayment}>
                        <ExclamationTriangleIcon fill={this.styleSheet.themeProps.ERROR} size={20}
                                                 className={this.styleSheet.exclamationIcon}/>
                        <span>{Messages.subscribePaymentFailed}</span>
                    </div>,
                    <div className={styleSheet.center}>
                        <BlinkButton className={styleSheet.retryPaymentButton} onClick={() => this.retryPayment(entry)}>
                            {Messages.retryPaymentSubscribe}
                        </BlinkButton>
                    </div>
                ]
            });
        } else {
            contentRows.push({
                label: Messages.lastPayment,
                value: entry.lastPayment || "-",
            });
        }

        const subscriptionButton =
            entry.storeObject.status === RecurringPaymentStoreObject.Status.ACTIVE ?
                <div className={styleSheet.button + styleSheet.buttonClose}
                     onClick={() => CancelSubscriptionModal.show({subscription: entry.storeObject})}>
                    {Messages.cancelSubscription}
                </div> : entry.storeObject.status === RecurringPaymentStoreObject.Status.CANCELED ?
                <div className={styleSheet.button + styleSheet.buttonRenew}
                     onClick={() => ReactivateSubscriptionModal.show({subscription: entry.storeObject})}>
                    {Messages.resubscribe}
                </div> : null;
        if (subscriptionButton) {
            contentRows.push({
                value: subscriptionButton,
            });
        }

        return contentRows;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            width: 0.55,
            align: "left",
            overflowEllipsis: true,
            value: entry => `${entry.storeObject.getMerchantTitle()} - ${entry.storeObject.coverage.name}`,
        }, {
            headerName: Messages.amount,
            width: 0.2,
            align: "left",
            value: entry => entry.storeObject.formatPriceShortened(),
        }, {
            headerName: Messages.nextPayment,
            width: 0.25,
            align: "right",
            value: entry => entry.nextPayment,
            style: {color: this.styleSheet.themeProps.MERCHANT_2},
        }];
    }
}
