import {SUBSCRIBE_PLAN_STATE, SubscriptionPlanController} from "./SubscriptionPlanController";
import {Router} from "../../../stem-core/src/ui/Router";
import {
    ADDRESS_PANEL_URL,
    INLINE_GIFT_SUBSCRIPTION_URLS,
    PAYMENT_FAILED_PANEL_URL,
    SUBSCRIPTION_PLAN_PANEL_URL
} from "../PanelConstants";
import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {apiSubscribe} from "../../../client/state/SubscriptionStore";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {CARD_SECTION_STATE, PaymentMethodController} from "../inline-donation/PaymentMethodController";
import {Messages} from "../../Messages";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore";
import {ADDRESS_SECTION_STATE} from "../pages/panels/address/AddressPanel";
import {getSubscribeEndpointPayload} from "./SubscribeFlowState";
import {Controller} from "../Controller";
import {AddressController} from "./AddressController";
import {iFrameState} from "../../services/IFrameState";
import {GATE_TYPE, PANEL_TYPE} from "../../../blink-sdk/Constants";
import {iFrameAuthService} from "../../services/IFrameAuthService";


export class GiftSubscriptionFlowState extends Dispatchable {
    giftBeneficiaryEmailAddress = "";
    name = "";
    message = "";

    start() {
        const {panelType, gateType, skipCTA} = iFrameState;

        if (panelType !== PANEL_TYPE.giftSubscription) {
            return;
        }

        Controller.subscriptionPlan = new SubscriptionPlanController({
            subscriptionPlanState: SUBSCRIBE_PLAN_STATE.giftSubscription,
            selectedOffer: null,
            onSelectSuccess: async () => {
                await iFrameAuthService.promptAuthenticationIfNeeded();
                // If we come from login page and switch to review, we want the back to take us to the plans
                // page. Currently, this is how we achieve that without disrupting the flow in any way.
                Router.localHistory.push(SUBSCRIPTION_PLAN_PANEL_URL);
                if (this.giftBeneficiaryEmailAddress) {
                    await this.onGiftDetailsProvided();
                    return;
                }
                Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.giftDetails);
            }
        });

        if (skipCTA || gateType === GATE_TYPE.popup) {
            Router.changeURL(SUBSCRIPTION_PLAN_PANEL_URL);
        } else {
            Router.changeToCustomPanel();
        }
    }

    async onGiftDetailsProvided() {
        Controller.paymentMethod = new PaymentMethodController({
            cardSectionState: CARD_SECTION_STATE.chooseCard,
            savePaymentMethod: true,
            getScope: () => PaymentMethodStore.Scope.PRIVATE,
            onSelectSuccess: () => this.giftSubscription(),
        });
        if (!Controller.subscriptionPlan.selectedOffer.shouldCollectAddress()) {
            LoadingSpinner.show();
            await Controller.subscriptionPlan.fetchPrices(Controller.address?.selectedAddress);
            LoadingSpinner.hide();
            Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.reviewOrder);
            return;
        }
        Controller.address = new AddressController({
            addressType: Controller.subscriptionPlan.selectedOffer.coverage.requiresAddress ? null : "billing",
            addressSectionState: ADDRESS_SECTION_STATE.chooseAddress,
            onSelectSuccess: async () => {
                await Controller.subscriptionPlan.fetchPrices(Controller.address?.selectedAddress);
                Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.reviewOrder);
            },
            onSelectCancel: () => Router.back(),
            continueLabel: Messages.reviewOrder,
        });
        Router.changeURL(ADDRESS_PANEL_URL);
    }

    async giftSubscription() {
        LoadingSpinner.show();
        const subscriptionDetails = await getSubscribeEndpointPayload();
        Object.assign(subscriptionDetails.metadata, {
            gift: true,
            giftFrom: this.name,
            giftMessage: this.message,
        });

        let subscription = null;
        try {
            subscription = await apiSubscribe({
                ...subscriptionDetails,
                gift: true,
                giftBeneficiaryEmailAddress: this.giftBeneficiaryEmailAddress,
            });
        } catch (error) {
            Controller.paymentMethod.update({
                paymentError: error,
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
        } finally {
            LoadingSpinner.hide();
        }
        if (!subscription || !subscription.isActive()) {
            console.warn("Subscribe error: ", Messages.subscribeFailed);
            return;
        }
        Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.success);
    }
}
