import {field} from "./misc/StoreField";
import {MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {BaseEnum, makeEnum} from "./misc/BaseEnum";
import {SocialAccountProvider} from "./SocialAccountStore";


@makeEnum
export class UserSessionSource extends BaseEnum {
    static PASSWORD;
    static PASSWORD_RESET;
    static SECURE_PASSWORD;
    static SOCIAL;
    static EMAIL_LINK;
    static EMAIL_SHORT_CODE;
    static EMAIL_LONG_CODE;
    static REGISTRATION;
    static CANCEL_CLOSE_ACCOUNT_TOKEN;
    static UNDO_CHANGE_EMAIL_TOKEN;
    static ADMIN_GENERATED;
    static MERCHANT_GENERATED;
}


class UserSession extends StoreObject {
    @field("UserProfile") user;
    @field(String) key;
    @field(UserSessionSource) source;
    @field(SocialAccountProvider) provider;
    @field("SocialAccount") socialAccount;
    @field(Date) createdAt;
    @field(Date) expiresAt;
    @field(Date) revokedAt;
    @field(String) ip;
    @field(String) countryCode;
    @field("Jurisdiction") jurisdiction;
    @field(String) userAgent;
    @field("Merchant") originMerchant;
    @field("Merchant") sharedWithMerchant;

}

export const UserSessionStore = MakeStore("UserSession", UserSession);
