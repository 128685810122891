import {UI, keyframesRule, styleRule, StyleSheet, registerStyle, Theme} from "../../stem-core/src/ui/UI";
import {Router} from "../../stem-core/src/ui/Router";
import {SVG} from "../../stem-core/src/ui/svg/SVGBase";
import "../../stem-core/src/ui/svg/SVGPrimitives";

import {BlinkPayLogo} from "../SVGElements";
import {isIframe} from "../Utils";


export class SpinnerAnimation extends StyleSheet {
    @keyframesRule
    dash = {
        "0%": {
            strokeDasharray: "10, 110",
            strokeDashoffset: "0",
        },
        "50%": {
            strokeDasharray: "90, 110",
            strokeDashoffset: "-60",
        },
        "100%": {
            strokeDasharray: "90, 110",
            strokeDashoffset: "-120",
        },
    };

    @keyframesRule
    rotate = {
        "100%": {
            transform: "rotate(360deg)",
        },
    };
}

export class SpinnerStyle extends StyleSheet {
    size = 46;

    @styleRule
    spinner = {
        display: "inline-block",
        position: "relative",
        margin: `-${this.size}px 0 0 -${this.size}px`,
        width: this.size * 2,
        height: this.size * 2,
        animation: `${SpinnerAnimation.getInstance().rotate.toString()} 1.6s linear infinite`,
        " circle": {
            fill: "none",
            strokeLinecap: "round",
            animation: `${SpinnerAnimation.getInstance().dash.toString()} 1.6s linear infinite`,
        },
    };
}

@registerStyle(SpinnerStyle)
export class Spinner extends SVG.SVGRoot {
     getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            strokeWidth: 3,
            strokeColor: Theme.props.SPINNER_COLOR,
            radius: 20,
        }
    }

    getNodeType() {
        return "svg";
    }

    render() {
        const {strokeWidth, strokeColor, radius} = this.options;
        return <SVG.Circle center={{x: 25, y: 25}} radius={radius} strokeWidth={strokeWidth} stroke={strokeColor} />
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.spinner);
        attr.setAttribute("viewBox", "0 0 50 50")
    }
}

export class BlinkLogoSpinnerStyle extends StyleSheet {
    @styleRule
    blinkLogoSpinner = {
        position: "relative",
        width: "100%",
        height: 70,
        zIndex: 100,
        ">*": {
            left: "50%",
            top: "50%",
            position: "absolute",
        },
        ">:last-child": {
            transform: "translate(-50%,-50%)",
        },
        ">:last-child g": {
            fill: this.themeProps.MERCHANT_SPINNER_COLOR,
        },
    };
}


@registerStyle(BlinkLogoSpinnerStyle)
export class BlinkLogoSpinner extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            size: 25,
            strokeWidth: 2,
            strokeColor: Theme.props.MERCHANT_SPINNER_COLOR,
            radius: 15,
        };
    }
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.blinkLogoSpinner);
    }

    render() {
        const {size, strokeColor, strokeWidth, radius} = this.options;
        return [<Spinner strokeWidth={strokeWidth} strokeColor={strokeColor} radius={radius} />, <BlinkPayLogo size={size} />];
    }
}


export class LoadingSpinnerStyle extends StyleSheet {
    @styleRule
    loadingSpinner = {
        background: this.themeProps.MERCHANT_SPINNER_BACKGROUND,
        position: isIframe() ? "absolute" : "fixed",
        width: "100%",
        height: "100%",
        marginTop: 0,
        top: 0,
        left: 0,
        zIndex: 11000,
        ">*": {
            top: "50%",
            transform: "translateY(-50%)",
            position: "absolute",
        },
    };
}

// TODO: This is identical to the MerchantLoadingSpinner
@registerStyle(LoadingSpinnerStyle)
export class LoadingSpinner extends UI.Element {
    static instance = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "loadingSpinner",
        };
    }

    static show() {
        if (LoadingSpinner.instance) {
            return;
        }
        LoadingSpinner.instance = this.create(Router.Global?.node || document.body);
    }

    // Will bubble up error, but hide the scrim. Not quite there, maybe have decorator?
    static async wrap(callback) {
        try {
            this.show();
            return await callback();
        } finally {
            this.hide();
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.loadingSpinner);
    }

    render() {
        return <BlinkLogoSpinner size={48} strokeWidth={3} radius={20}/>;
    }

    static hide() {
        if (!LoadingSpinner.instance) {
            return;
        }
        LoadingSpinner.instance.destroyNode();
        delete LoadingSpinner.instance;
        LoadingSpinner.instance = null;
    }

    onMount() {
        super.onMount();
        if (Router.Global) {
            this.attachListener(Router.Global, "change", () => this.constructor.hide());
        }
    }
}
