import {UI, StyleSheet, registerStyle, styleRule} from "../../../../stem-core/src/ui/UI";
import {SUPPORT_EMAIL_ADDRESS} from "../../../Constants";
import {Messages} from "../../../Messages";
import {TermsMessage} from "../../unauthenticated/components/TermsMessage.jsx";


class FooterStyle extends StyleSheet {
    @styleRule
    footer = {
        margin: "auto",
        width: "100%",
        maxWidth: this.themeProps.BASE_CARD_WIDTH,
        textAlign: "center",
        color: this.themeProps.EMAIL_TEXT_COLOR,
        padding: 12,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
    };

    @styleRule
    email = {
        fontWeight: "bold",
        color: "inherit",
        textDecoration: "none",
    };

    @styleRule
    terms = {
        color: () => this.themeProps.EMAIL_TEXT_COLOR + "!important",
        display: "block",
        margin: "auto",
        fontSize: "inherit !important",
        marginBottom: 8,
        ">*": {
            marginTop: 0,
        }
    }
}

@registerStyle(FooterStyle)
export class Footer extends UI.Element {

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.footer);
    }

    render() {
        const {styleSheet} = this;

        return [
            <TermsMessage className={styleSheet.terms} preMessage={Messages.termsPreMessage} postMessage={Messages.termsAuthenticated}/>,
            <div>
                <span>{Messages.footerSupportEmailText}</span>{" "}
                <a target="_blank" href={"mailto:" + SUPPORT_EMAIL_ADDRESS} className={styleSheet.email}>
                    {SUPPORT_EMAIL_ADDRESS}
                </a>
                .
            </div>,
        ];
    }
}
