import {UI} from "../../../../../../stem-core/src/ui/UI";
import {DonationStore, DonationOfferStore, MerchantStore} from "../../../../../State";
import {Toast} from "../../../../../ui/Toast";
import {ConfirmationModal} from "../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../Messages";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";
import {apiCancelDonation} from "../../../../../../client/state/DonationStore";


@autoredraw(DonationStore)
export class CancelDonationModal extends ConfirmationModal {
    getDefaultOptions() {
        const {donation} = this.options;
        const offer = DonationOfferStore.get(donation.recurringOfferId);
        const merchant = MerchantStore.get(offer.merchantId);
        return Object.assign({}, super.getDefaultOptions(), {
            title: Messages.cancelDonationTitle,
            description: Messages.cancelDonationDescription(merchant.name),
            confirmAction: () => this.cancel(),
            cancelLabel: Messages.close,
            confirmLabel: Messages.cancelDonation,
        });
    }

    async cancel() {
        const {donation} = this.options;

        if (!donation) {
            return;
        }

        try {
            await apiCancelDonation(donation.id);
            Toast.show(Messages.cancelDonationSuccess);
            this.hide();
        } catch (error) {
            Toast.showError(error);
            this.redraw();
        }
    }
}
