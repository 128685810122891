import {UI} from "../../../../../../../stem-core/src/ui/UIBase";

import {ConfirmationModal} from "../../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../../Messages";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {SubscriptionStore} from "../../../../../../../client/state/SubscriptionStore";
import {apiDeleteAddress, UserAddressStore} from "../../../../../../../client/state/UserAddressStore";
import {autoredraw} from "../../../../../../../stem-core/src/decorators/AutoRedraw";
import {Toast} from "../../../../../../ui/Toast";


@autoredraw(SubscriptionStore, UserAddressStore)
export class DeleteAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.deleteAddressTitle,
            cancelLabel: Messages.close,
            confirmLabel: Messages.removeAddress,
            description: Messages.deleteAddressDescription(this.options.address.toString()),
            confirmAction: () => this.deleteAddress(),
            address: null,
        };
    }

    async deleteAddress() {
        LoadingSpinner.show();
        try {
            await apiDeleteAddress(this.options.address.id);
            this.hide();
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }
}
