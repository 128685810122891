import {UI} from "../../stem-core/src/ui/UIBase";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";


export class RadioList extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onItemSelect: NOOP_FUNCTION,
            ListItemClass: null,
            padListItem: false,
            testId: "radioList",
        }
    }

    render() {
        const {ListItemClass, padListItem} = this.options;

        return this.getGivenChildren().map(child =>
            <ListItemClass onSelect={() => this.options.onItemSelect(child)}
                           style={padListItem ? {paddingRight: 24} : {}}>
                {child}
            </ListItemClass>);
    }
}
