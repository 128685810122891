import {apiConnection} from "../connection/BlinkApiConnection";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {CycleUnit, RecurringPaymentStoreObject} from "./RecurringPaymentStoreObject";
import {field} from "./misc/StoreField";


function getFeeRawPercentage(merchantId) {
    // TODO @Mihai @Denis this is still wrong, needs to take into account the Blink free and something more complex
    return 0.079;
}

export class Donation extends RecurringPaymentStoreObject {
    @field(CycleUnit) frequency;

    isRecurring() {
        return !this.frequency.isOneTime();
    }

    serializeForSDK() {
        // TODO @branch check if this works
        const {id, blinkSignature, userId, amount, createdAt, canceledAt, frequency, activeUntil} = this;
        let obj = {id, blinkSignature, userId, amount, createdAt, canceledAt, frequency, activeUntil};

        obj.currencyCode = this.getCurrency().getIsoCode();

        return obj;
    }

    updatePaymentMethod(paymentMethodId) {
        return apiUpdateDonationPaymentMethod(this.id, paymentMethodId);
    }

    reactivate() {
        return apiReactivateDonation(this.id);
    }

    getShortenedFrequency() {
        return this.frequency.getAbrev();
    }

    // TODO @branch this should be shared with Subscription Offer
    formatPriceShortened() {
        if (this.isRecurring()) {
            return `${this.getPrice()}/${this.getShortenedFrequency()}`;
        }
        return this.getPrice();
    }

    formatPrice() {
        if (this.isRecurring()) {
            return `${this.getPrice()}/${this.frequency.name.toLowerCase()}`;
        }
        return this.getPrice();
    }

    isDonationFeePaid() {
        return this.coversFees;
    }

    computeAmount() {
        // TODO @branch this code should be cleaned up
        return this.isDonationFeePaid() ? Math.floor((this.amount - 3e5) / (1 + getFeeRawPercentage(this.getMerchant().id)) / 1e6) * 1e6 : this.amount;
    }

    isActive() {
        return super.isActive() && this.status === RecurringPaymentStoreObject.Status.ACTIVE;
    }
}

class DonationStoreClass extends PaymentStatusDependentStore("donation", Donation) {
    Status = Donation.Status;

    getRecurring() {
        return this.all().filter(entry => entry.isRecurring());
    }

    getPresentRecurring() {
        return [...this.getActiveRecurring(), ...this.getRecurringUnpaid()];
    }

    getActiveRecurring() {
        return this.getRecurring().filter(donation => donation.isActive());
    }

    getRecurringUnpaid() {
        return this.getRecurring().filter(donation => donation.isUnpaid());
    }

    getPastRecurring() {
        return this.getRecurring().filter(donation => !donation.isActive() && !donation.isUnpaid());
    }

    getOneTime() {
        return this.all().filter(entry => !entry.isRecurring());
    }

    computeFeeFromAmount(amount, merchantId) {
        return Math.ceil((getFeeRawPercentage(merchantId) * amount / 1e6 + 0.3) * 2) * 5e5;
    }
}

export const DonationStore = new DonationStoreClass();

export async function apiDonate(donationDetails) {
    const response = await apiConnection.post("/donations/donate/", donationDetails);
    return DonationStore.loadObjectFromResponse(response);
}

/*
Example usage:
    await apiDonateWithCds({
        merchantId: 1,
        recurringOfferId: 1,
        amount: 10 * 1e6,
        frequency: CycleUnit.ONE_TIME, // or CycleUnit.MONTH
        userAddressId: 1,
        paymentInfo: {
            card_number: "4111",
            card_expiry: "MMYY",
            card_cvv: "123,"
        }
    })
* */
export async function apiDonateWithCds(donationDetails = {}) {
    const response = await apiConnection.post("/external_providers/donate_with_cds/", donationDetails);
    return DonationStore.loadObjectFromResponse(response);
}

export async function apiEditDonationWithCds(donationDetails = {}) {
    const response = await apiConnection.post("/external_providers/edit_donation_with_cds/", donationDetails);
    return DonationStore.loadObjectFromResponse(response);
}

export async function apiUpdateDonationPaymentMethod(donationId, preferredPaymentMethodId) {
    return apiConnection.post("/donations/update_payment_method/", {donationId, preferredPaymentMethodId});
}

export async function apiUpdateDonationAmount(donationId, amount, coversFees= false) {
    return apiConnection.post("/donations/update_amount/", {donationId, amount, coversFees});
}

export async function apiCancelDonation(donationId) {
    return apiConnection.post("/donations/cancel/", {donationId});
}

export async function apiReactivateDonation(donationId) {
    return apiConnection.post("/donations/reactivate/", {donationId});
}
