import {UI, styleRule, registerStyle} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {DonationStore, RecurringPaymentStoreObject, SubscriptionStore} from "../../../../../State";
import {EditDonationModal} from "../components/EditDonationModal";
import {CancelDonationModal} from "../components/CancelDonationModal";
import {Donations} from "./Donations";
import {CardDetails} from "../../account/components/payment-method/CardDetails";
import {ChoosePaymentMethodModal} from "../../subscriptions/components/ChoosePaymentMethodModal";
import {ExclamationTriangleIcon} from "../../../../../SVGElements";
import {BlinkButton} from "../../../../../ui/Button";
import {MyRecurringPaymentStyle} from "../../subscriptions/sections/MyRecurringPayment";


export class RecurringDonationsStyle extends MyRecurringPaymentStyle {
    @styleRule
    manageDonationButton = {
        color: this.themeProps.MERCHANT_LINK,
        cursor: "pointer",
        userSelect: "none",
    };

    @styleRule
    change = {
        ...this.themeProps.USER_DASHBOARD_CHANGE_BUTTON,
        position: "absolute",
        right: 20,
        display: "inline-block",
    };

    @styleRule
    paymentMethodRow = {
        display: "flex",
        justifyContent: "space-between",
    };
}

@registerStyle(RecurringDonationsStyle)
export class RecurringDonations extends Donations {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.recurringDonations,
            description: this.getStore().length ? "" : Messages.emptyRecurringDonations,
            expandedRows: true,
        };
    }

    getStore() {
        return DonationStore.getPresentRecurring();
    }

    getExpandedRowContent(entry) {
        const {styleSheet} = this;
        const contentRows = [];

        if (entry.storeObject.status === RecurringPaymentStoreObject.Status.ACTIVE) {
            contentRows.push({
                value: (
                    <div
                        className={styleSheet.manageDonationButton}
                        onClick={() => EditDonationModal.show({donation: entry.storeObject})}>
                        {Messages.editRecurringDonation}
                    </div>
                ),
            });
        }
        if (entry.storeObject.getPaymentMethod() && entry.storeObject.status !== SubscriptionStore.Status.CANCELED) {
            const card = entry.storeObject.getPaymentMethod();
            contentRows.push({
                label: Messages.paymentMethod,
                value: <div className={styleSheet.paymentMethodRow}>
                    <CardDetails card={card} iconSize={16} iconMargin={8} cardDetailsMessage={
                        Messages.usingCardBoldEnding(card.getBrand(), card.getLastDigits())}/>
                    <div className={styleSheet.change} testId="changeCard"
                         onClick={() => ChoosePaymentMethodModal.show({entry: entry.storeObject})}>
                        {Messages.change}
                    </div>
                </div>
            });
        }

        if (entry.storeObject.isUnpaid()) {
            contentRows.push({
                label: Messages.lastPayment,
                value: [
                    <div className={this.styleSheet.failedPayment}>
                        <ExclamationTriangleIcon fill={this.styleSheet.themeProps.ERROR} size={20}
                                                 className={this.styleSheet.exclamationIcon}/>
                        <span>{Messages.donatePaymentFailedRetry(entry.storeObject.getMerchantTitle())}</span>
                    </div>,
                    <div className={styleSheet.center}>
                        <BlinkButton className={styleSheet.retryPaymentButton} onClick={() => this.retryPayment(entry)}>
                            {Messages.retryPaymentDonation}
                        </BlinkButton>
                    </div>
                ]
            });
        } else {
            contentRows.push({
                label: Messages.lastPayment,
                value: entry.lastPayment || "-",
            });
        }

        const donationButton =
            entry.storeObject.status === RecurringPaymentStoreObject.Status.ACTIVE ?
                <div
                    className={styleSheet.button + styleSheet.buttonClose}
                    onClick={() => CancelDonationModal.show({donation: entry.storeObject})}>
                    {Messages.cancelDonation}
                </div> : null;

        if (donationButton) {
            contentRows.push({
                value: donationButton,
            });
        }

        return contentRows;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            width: 0.55,
            align: "left",
            value: entry => entry.storeObject.getMerchantTitle(),
        }, {
            headerName: Messages.amount,
            width: 0.2,
            align: "left",
            value: entry => entry.storeObject.formatPriceShortened(),
        }, {
            headerName: Messages.nextPayment,
            width: 0.25,
            align: "right",
            value: entry => entry.nextPayment,
            style: {color: this.styleSheet.themeProps.MERCHANT_2},
        }];
    }
}
