import {UI} from "../../../../stem-core/src/ui/UIBase";
import {MerchantLogo} from "../../../common/MerchantLogo";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {iFrameState} from "../../../services/IFrameState";
import {BASE_PANEL_WIDTH, GATE_TYPE, PANEL_FRAME} from "../../../../blink-sdk/Constants";
import {isSmallScreen} from "../../../UtilsLib";
import {panelChildMargin} from "../../../StyleConstants";


class PanelFrameStyle extends StyleSheet {
    paddingFunction = () => iFrameState.gateType === GATE_TYPE.popup ?
        (isSmallScreen() ? "12px 18px 24px" : "18px 36px 36px") : "18px 14px";

    // TODO: This should not be in the style sheet
    shouldDisplayFrame = () => iFrameState.gateType !== GATE_TYPE.banner && iFrameState.frame === PANEL_FRAME.logo;

    containerStyle = {
        justifyContent: "space-between",
        ">:not(:last-child, :first-child)": {
            marginBottom: panelChildMargin,
        },
    };

    @styleRule
    frame = {
        margin: "auto",
        width: BASE_PANEL_WIDTH,
        maxWidth: "100%",
        border: () => "1px solid " + this.themeProps.MERCHANT_FRAME_BORDER_COLOR,
        background: this.themeProps.MERCHANT_FRAME_BACKGROUND,
        borderRadius: this.themeProps.MERCHANT_FRAME_BORDER_RADIUS,
        display: "flex",
        flexDirection: "column",
        ...(this.shouldDisplayFrame() ? {
            overflow: "hidden",
        } : {
            overflow: ["overlay", "auto"],
            padding: this.paddingFunction,
            ...this.containerStyle,
        })
    };

    @styleRule
    header = {
        width: "100%",
        background: this.themeProps.MERCHANT_FRAME_HEADER_BACKGROUND,
        padding: 12,
        borderBottom: () => "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR,
    };

    @styleRule
    body = {
        width: "100%",
        overflow: ["overlay", "auto"],
        flex: 1,
        padding: this.paddingFunction,
        display: "flex",
        flexDirection: "column",
        ...this.containerStyle,
    };

    @styleRule
    scrollFade = {
        boxShadow: "0 12px 12px 0px rgb(0 0 0 / 12%) inset, 0 -12px 12px 0px rgb(0 0 0 / 12%) inset",
    };

    @styleRule
    scrollTopFade = {
        boxShadow: "0 12px 12px 0px rgb(0 0 0 / 12%) inset",
    };

    @styleRule
    scrollBottomFade = {
        boxShadow: "0 -12px 12px 0px rgb(0 0 0 / 12%) inset",
    };

    @styleRule
    leftAlign = {
        textAlign: "left !important",
    };

    @styleRule
    shadowScrollScrim = {
        position: "absolute",
        width: "100%",
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: 2
    };
}

@registerStyle(PanelFrameStyle)
export class PanelFrame extends UI.Element {
    scrollListener = null;
    shadowScrollClass = "";
    shadowScrollStyle = {};

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.frame);
    }

    handleScroll() {
        try {
            this.scrollListener?.remove();
        } catch (e) {
            // Somehow magically this scroll listener gets removed.
        }
        const applyScrollStyle = element => {
            if (!element) {
                return; // Some weird redraws occurred.
            }
            this.shadowScrollScrim.removeClass(this.styleSheet.scrollTopFade);
            this.shadowScrollScrim.removeClass(this.styleSheet.scrollBottomFade);
            this.shadowScrollScrim.removeClass(this.styleSheet.scrollFade);
            if (element.node.scrollHeight > element.node.clientHeight) {
                if (element.node.scrollTop && element.node.scrollTop + element.node.clientHeight < element.node.scrollHeight) {
                    this.shadowScrollClass = this.styleSheet.scrollFade;
                } else if (element.node.scrollTop) {
                    this.shadowScrollClass = this.styleSheet.scrollTopFade;
                } else if (element.node.scrollTop + element.node.clientHeight < element.node.scrollHeight) {
                    this.shadowScrollClass = this.styleSheet.scrollBottomFade;
                }
            } else {
                this.shadowScrollClass = "";
            }
            this.shadowScrollStyle = {
                height: `calc(100% - ${this.header ? this.header.getHeight() : 0}px)`,
            };
            this.shadowScrollScrim.setStyle(this.shadowScrollStyle);
            this.shadowScrollScrim.addClass(this.shadowScrollClass);
        };
        if (this.styleSheet.shouldDisplayFrame() && this.body && this.header && this.shadowScrollScrim) {
            applyScrollStyle(this.body);
            this.scrollListener = this.body.addNodeListener("scroll", () => applyScrollStyle(this.body));
        } else if (this.shadowScrollScrim) {
            applyScrollStyle(this);
            this.scrollListener = this.addNodeListener("scroll", () => applyScrollStyle(this));
        }
    }

    getChildrenToRender() {
        this.attachTimeout(() => this.handleScroll());
        const {styleSheet} = this;

        if (!styleSheet.shouldDisplayFrame()) {
            return [<div ref="shadowScrollScrim" className={styleSheet.shadowScrollScrim + this.shadowScrollClass}
                         style={this.shadowScrollStyle}/>, this.render()];
        }
        return [
            <div className={styleSheet.header} ref="header">
                <MerchantLogo />
            </div>,
            <div className={styleSheet.body} ref="body">
                <div ref="shadowScrollScrim" className={styleSheet.shadowScrollScrim + this.shadowScrollClass}
                     style={this.shadowScrollStyle}/>
                {this.render()}
            </div>
        ];
    }
}
