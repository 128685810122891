import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI";
import {isSmallScreen} from "../../Utils";
import {ExclamationTriangleIcon} from "../../SVGElements";


class ErrorPanelStyle extends StyleSheet {
    @styleRule
    errorPanel = {
        textAlign: "center",
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
    };

    @styleRule
    icon = {
        margin: () => isSmallScreen() ? "70px auto" : "0px auto 12px",
    };
}

@registerStyle(ErrorPanelStyle)
export class ErrorPanel extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.errorPanel);
    }

    render() {
        const {styleSheet} = this;
        return [
                <ExclamationTriangleIcon className={styleSheet.icon} />,
                <div>{this.options.errorMessage}</div>
        ];
    }
}

