import {UI} from "../../../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../../../stem-core/src/ui/Style";

import {Messages} from "../../../../../../Messages";
import {PaymentMethodStore} from "../../../../../../State";
import {styleRule} from "../../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../../stem-core/src/ui/style/Theme";
import {AddCardModal} from "./AddCardModal";
import {SelectPrimaryCardModal} from "./SelectPrimaryCardModal";
import {autoredraw} from "../../../../../../../stem-core/src/decorators/AutoRedraw";
import {MessageElement} from "../../../../../../widget/ui/MessageElement";


class CardInlineWidgetStyle extends StyleSheet {
    @styleRule
    element = {
        display: "flex",
        justifyContent: "space-between",
    };

    @styleRule
    change = this.themeProps.USER_DASHBOARD_CHANGE_BUTTON;
}

@registerStyle(CardInlineWidgetStyle)
@autoredraw(PaymentMethodStore)
export class CardInlineWidget extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
    }

    render() {
        const {styleSheet} = this;
        const card = PaymentMethodStore.getPrimary();

        const onClick = () => card ? SelectPrimaryCardModal.show() : AddCardModal.show();

        return [
            <MessageElement testId="walletPrimaryCardDetails" message={
                card ? Messages.usingCard(card.getBrand(), card.getLastDigits()) : Messages.addCardOnce} />,
            <div className={styleSheet.change} onClick={onClick} testId="walletChangeCardButton">
                {card ? Messages.change : Messages.addCardInlineWidget}
            </div>
        ];
    }
}
