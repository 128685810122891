import {UI, styleRule, registerStyle, styleRuleInherit, StyleSheet} from "../../../../../../../stem-core/src/ui/UI";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../../ui/ConfirmationModal";
import {Messages} from "../../../../../../Messages";
import {RadioList} from "../../../../../../common/RadioList";
import {MerchantListItem, MerchantListItemStyle} from "../../../../../../common/MerchantListItem";
import {TextArea} from "../../../../../../../stem-core/src/ui/input/Input";
import {NOOP_FUNCTION} from "../../../../../../UtilsLib";
import {LoadingSpinner} from "../../../../../../ui/LoadingSpinner";
import {Toast} from "../../../../../../ui/Toast";
import {apiRequestCloseAccount} from "../../../../../../../client/state/UserProfileStore";

class ReasonListItemStyle extends MerchantListItemStyle {
    @styleRuleInherit
    item = {
        color: this.themeProps.CLOSE_ACCOUNT_REASON_LIST_ITEM_COLOR,
        border: "none",
        padding: "6px 0",
        width: this.themeProps.FIT_CONTENT,
    };
}

@registerStyle(ReasonListItemStyle)
class ReasonListItem extends MerchantListItem {}

class ReasonTextAreaStyle extends StyleSheet {
    @styleRule
    textAreaComponent = {
        background: this.themeProps.CLOSE_ACCOUNT_REASON_TEXT_AREA_BACKGROUND,
        color: this.themeProps.CLOSE_ACCOUNT_REASON_TEXT_AREA_COLOR,
        fontSize: 16,
        lineHeight: 20,
        resize: "none",
        border: "none",
        borderRadius: 4,
        padding: "12px 10px",
        outline: "none",
        cursor: "not-allowed",
    };

    @styleRule
    editable = {
        cursor: "text",
        ":focus": {
            border: this.themeProps.CLOSE_ACCOUNT_REASON_TEXT_AREA_BORDER,
            boxShadow: this.themeProps.CLOSE_ACCOUNT_REASON_TEXT_AREA_SHADOW,
        }
    }
}

@registerStyle(ReasonTextAreaStyle)
class ReasonTextArea extends TextArea {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        const {styleSheet} = this;
        attr.addClass(styleSheet.textAreaComponent);
        if (!this.options.readOnly) {
            attr.addClass(styleSheet.editable);
        }
    }
}

class CloseAccountReasonModalStyle extends ConfirmationModalStyle {
    @styleRuleInherit
    buttons = {
        marginTop: 0,
    };

    @styleRule
    secondaryTextLine = {
        fontSize: 16,
        color: this.themeProps.CLOSE_ACCOUNT_REASON_SECONDARY_TEXT_COLOR,
    };

    @styleRule
    radioList = {
        margin: "18px 0px 24px",
    };

    @styleRule
    textArea = {
        margin: "18px 0px 24px",
        width: "100%",
        height: 126,
    };
}

@registerStyle(CloseAccountReasonModalStyle)
export class CloseAccountReasonModal extends ConfirmationModal {
    selectedReason = null;
    confirmButtonDisabled = true;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "closeAccountReasonModal",
            title: Messages.closeAccountReasonModalTitle,
            confirmLabel: Messages.continue,
            cancelLabel: Messages.close,
            userProfile: null,
            onConfirmSuccess: NOOP_FUNCTION,
            confirmAction: () => this.submitCloseAccountRequest(),
        };
    }

    render() {
        const {styleSheet} = this;
        const closeAccountReasons = [
            "I accidentally signed up for this service.",
            "I have a privacy concern.",
            "I already have an account.",
            "I'm getting unwanted emails.",
            "Other",
        ]

        return [
            <div className={styleSheet.secondaryTextLine}>{Messages.closeAccountReasonModalDescription}</div>,
            <RadioList className={styleSheet.radioList} onItemSelect={(item) => this.handleListItemSelection(item)} ListItemClass={ReasonListItem}>
                {closeAccountReasons.map(reason => <div reason={reason} selected={reason === this.selectedReason}>{reason}</div>)}
            </RadioList>,
            <div className={styleSheet.secondaryTextLine}>{Messages.closeAccountFeedbackText}</div>,
            <ReasonTextArea ref="reasonTextArea" className={styleSheet.textArea} readOnly={!this.allowUserWriteReason()}
                            onInput={() => this.updateConfirmButtonDisabled()}/>
        ];
    }

    allowUserWriteReason() {
        return this.selectedReason === "Other";
    }

    getReasonText() {
        if (this.allowUserWriteReason()) {
            return this.reasonTextArea.getValue();
        }
        return this.selectedReason;
    }

    updateConfirmButtonDisabled() {
        this.confirmButtonDisabled = this.getReasonText().trim() === "";
        this.redraw();
    }

    handleListItemSelection(selectedListItem) {
        const {reason} = selectedListItem.options;
        this.selectedReason = reason;
        this.confirmButtonDisabled = !this.selectedReason || this.getReasonText().trim() === "";
        if (!this.allowUserWriteReason()) {
            this.reasonTextArea.setValue("");
        }
        this.redraw();
    }

    async submitCloseAccountRequest() {
        const {onConfirmSuccess} = this.options;

        LoadingSpinner.show();
        try {
            const response = await apiRequestCloseAccount({reason: this.getReasonText()});
            onConfirmSuccess(response)
            this.hide()
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide()
        }
    }

    onMount() {
        super.onMount();
        this.reasonTextArea.addNodeListener("keyup", () => this.updateConfirmButtonDisabled());
    }
}
