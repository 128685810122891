import {UI} from "../../stem-core/src/ui/UIBase";

// TODO @Mihai this file is a prototype of a generic input class lifecycle
// TODO @branch @Mihai @Denis merge the usage of this with InputableElement
// Default options:
// -- onChange - listener to when the value changes
// -- initialValue - the value we'll set initially
export class DashboardBaseInput extends UI.Element {
    getDefaultOptions(options) {
        return {
            onChange: null,
            initialValue: null,
        };
    }

    getValue() {
        return this.value;
    }

    // Try not to use this method outside the class. Consider if updating the initialValue will be what you actually
    // want to do.
    setValue(value) {
        this.value = value;
        if (this.node) {
            this.redraw();
        }
    }

    // This should be overwritten in some cases.
    isEqual(valueA, valueB) {
        return valueA === valueB;
    }

    isChanged() {
        return !this.isEqual(this.options.initialValue, this.getValue());
    }

    setAndDispatchValue(value) {
        this.setValue(value);
        this.dispatchChange(value);
    }

    setOptions(options) {
        const oldInitialValue = this.options.initialValue;
        super.setOptions(options);
        const {initialValue} = this.options;
        if (oldInitialValue == null && initialValue == null) {
            return;
        }
        if (!this.node || !this.isEqual(initialValue, oldInitialValue)) {
            this.setValue(initialValue);
        }
    }

    dispatchChange(value) {
        this.dispatch("change", value, this);
    }
}
