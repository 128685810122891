import {CleanupJobs, Dispatcher} from "../stem-core/src/base/Dispatcher";
import {isFunction} from "../stem-core/src/base/Utils.js";

// TODO: Use core-js's queueMicrotask polyfill implicitly with babel.
//  After issue https://github.com/rollup/plugins/issues/431 is resolved.
function getQueueMicrotaskFunc() {
    if (isFunction(self.queueMicrotask)) {
        return self.queueMicrotask;
    }
    return (task) => setTimeout(task, 0);
}
export const queueMicrotaskWrapper = getQueueMicrotaskFunc();

// TODO @Mihai merge with the autoredraw stuff, and maybe add a function enqueueRedraw(uiElement) that is global
export class AsyncAggregateDispatcher extends Dispatcher {
    constructor({listener, changeDispatchers, updateDispatchers, customDispatchers}) {
        super();
        this.cleanupJobs = new CleanupJobs();
        this.willDispatchEvent = false;
        for (const changeDispatcher of (changeDispatchers || [])) {
            this.attachChangeDispatcher(changeDispatcher);
        }
        for (const updateDispatcher of (updateDispatchers || [])) {
            this.attachUpdateDispatcher(updateDispatcher);
        }
        for (const customDispatcher of (customDispatchers || [])) {
            this.attachDispatcher(customDispatcher.dispatcher, customDispatcher.channel);
        }
        if (listener) {
            this.addListener(listener);
        }
    }

    attachChangeDispatcher(dispatcher) {
        const cleanupJob = dispatcher.addChangeListener(() => this.dispatch());
        this.cleanupJobs.add(cleanupJob);
    }

    attachUpdateDispatcher(dispatcher) {
        const cleanupJob = dispatcher.addUpdateListener(() => this.dispatch());
        this.cleanupJobs.add(cleanupJob);
    }

    attachDispatcher(dispatcher, channel) {
        const cleanupJob = dispatcher.addListener(channel, () => this.dispatch());
        this.cleanupJobs.add(cleanupJob);
    }

    dispatch() {
        if (this.willDispatchEvent) {
            return;
        }
        this.willDispatchEvent = true;
        queueMicrotaskWrapper(() => {
            super.dispatch();
            this.willDispatchEvent = false;
        });
    }

    cleanup() {
        this.removeAllListeners();
        this.cleanupJobs.cleanup();
    }
}
