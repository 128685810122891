import {UI} from "../../stem-core/src/ui/UIBase";
import {Router} from "../../stem-core/src/ui/Router";
import {authService, userService} from "../../client/connection/Services";
import {ReaderIndex} from "../../landingpage/ReaderIndex";
import {AuthToken} from "../../client/connection/AuthToken";
import {getQueryParam} from "../UtilsLib";


// TODO: is WebsiteIndex the right name for this?
// TODO: this needs to be redesigned given the new merger of landing page and webapp
const defaultRedirectURL = "/wallet";

export class WebsiteIndex extends UI.Element {
    static redirectURL = defaultRedirectURL;
    authenticationHandler = null;

    updateURL() {
        if (!authService.isAuthenticated()) {
            return;
        }

        let redirectURL = getQueryParam("redirectUrl");
        if (redirectURL) {
            window.open(redirectURL, "_self");
            return;
        }
        redirectURL = this.constructor.redirectURL;
        const redirectOptions = {replaceHistory: true};

        Router.changeURL(redirectURL, redirectOptions);
        window.scrollTo(0, 0);
    }

    onUrlEnter() {
        if (authService.isAuthenticated()) {
            userService.ensureUserDataRequested(() => {
                this.updateURL();
            });
        } else {
            this.authenticationHandler = authService.addListenerOnce(AuthToken.EventTypes.LOGIN, () => {
                userService.ensureUserDataRequested(() => {
                    this.updateURL();
                });
            });
        }
    }

    onUrlExit() {
        if (this.authenticationHandler) {
            this.authenticationHandler.remove();
        }
    }

    render() {
        // If we're not redirecting it means that the user is unauthenticated
        if (authService.isAuthenticated()) {
            console.warn("We need to fix this flow, and just properly match routes");
            return null;
        }

        return <ReaderIndex/>;
    }

    static setRedirectURL(url) {
        this.redirectURL = url;
    }

    onMount() {
        super.onMount();

        this.addListener("urlReload", () => this.redraw());
        this.addListener("urlEnter", () => this.onUrlEnter());
        this.addListener("urlExit", () => this.onUrlExit());
        this.onUrlEnter();
    }
}
