import {UI, StyleSheet, styleRule, registerStyle} from "../../../../stem-core/src/ui/UI";
import {isSmallScreen} from "../../../Utils";
import {LogoCard} from "../../../ui/LogoCard";


export class FormContainerPageStyle extends StyleSheet {
    @styleRule
    formContainerPage = {
        width: "100%",
        minHeight: this.themeProps.FIT_CONTENT,
        height: () => (isSmallScreen() ? "" : "100vh"),
    };

    @styleRule
    formContainer = {
        width: 416,
        margin: "auto !important",
        borderRadius: isSmallScreen() ? 0 : 12,
        overflow: "hidden",
    };

    @styleRule
    center = {
        height: "100%",
        display: "flex",
        alignItems: "center",
    };
}

export const FormContainerPage = (FormClass, formOptions) => {
    @registerStyle(FormContainerPageStyle)
    class FormContainer extends UI.Element {
        extraNodeAttributes(attr) {
            super.extraNodeAttributes(attr);
            attr.addClass(this.styleSheet.formContainerPage);
        }

        render() {
            return [
                <div className={this.styleSheet.center}>
                    <LogoCard className={this.styleSheet.formContainer}>
                        <FormClass ref="form" {...formOptions} />
                    </LogoCard>
                </div>,
            ];
        }

        onMount() {
            super.onMount();

            this.attachEventListener(window, "resize", () => this.redraw());
            this.addListener("urlEnter", () => this.form.dispatch("urlEnter"));
            this.addListener("urlExit", () => this.form.dispatch("urlExit"));
        }
    }

    return FormContainer;
};
