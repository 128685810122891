import {apiClient} from "../../connection/BlinkApiConnection";
import {loadVersionedObjectFromResponse} from "../misc/StoreUtils";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {MerchantSDKResource, MerchantSDKResourceStoreClass} from "../misc/MerchantSDKResource.js";


export class MerchantPanel extends MerchantSDKResource {
    isBasePanel() {
        return this.alias === "base" || Object.values(PANEL_TYPE).indexOf(this.alias) >= 0 && this.alias !== PANEL_TYPE.custom;
    }

    getParentPanel() {
        return this.getStore().getByIdOrAlias(this.options.parentPanel);
    }

    usesPanel(panel) {
        const parentPanel = this.getParentPanel();
        if (parentPanel == null) {
            return false;
        }
        return parentPanel === panel || parentPanel.usesPanel(panel);
    }
}

export async function apiCreateMerchantPanel(data) {
    const response = await apiClient.post("/merchant/create_panel", data);
    return loadVersionedObjectFromResponse(MerchantPanelStore, response, null);
}

export async function apiEditMerchantPanel(data) {
    const response = await apiClient.post("/merchant/edit_panel", data);
    return loadVersionedObjectFromResponse(MerchantPanelStore, response, data.panelId);
}

export async function apiDeleteMerchantPanel(data) {
    return apiClient.post("/merchant/delete_panel", data);
}

export const MerchantPanelStore = new MerchantSDKResourceStoreClass("MerchantPanel", MerchantPanel);

function getPanelOrderIndex(panel) {
    if (!panel.isBasePanel()) {
        return 3;
    }
    if (panel.alias !== "base") {
        return 2;
    }
    return 1;
}

export function panelSortCompareFn(panel1, panel2) {
    if (getPanelOrderIndex(panel1) !== getPanelOrderIndex(panel2)) {
        return getPanelOrderIndex(panel1) - getPanelOrderIndex(panel2);
    }
    return panel1.name < panel2.name ? -1 : 1;
}
