import {StoreObject} from "../../stem-core/src/state/Store";
import {Money} from "./misc/Money";
import {StemDate} from "../../stem-core/src/time/Date";
import {MerchantStore} from "./MerchantStore";
import {PaymentMethodStore} from "./PaymentMethodStore";
import {field} from "./misc/StoreField";
import {BaseEnum, makeEnum} from "./misc/BaseEnum";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {capitalize} from "../../stem-core/src/base/Utils";
import {MerchantConversionStore} from "./merchant/MerchantConversionStore.js";  // For visibility in Dashboard.

@makeEnum
export class CycleUnit extends BaseEnum {
    static ONE_TIME = {timeUnit: null, frequencyName: "One-time"};
    static DAY = {timeUnit: TimeUnit.DAY};
    static WEEK = {timeUnit: TimeUnit.WEEK, abrev: "wk"};
    static MONTH = {timeUnit: TimeUnit.MONTH, abrev: "mo"};
    static YEAR = {timeUnit: TimeUnit.YEAR, abrev: "yr"};

    // Returns "Monthly"/"Yearly", etc
    getFrequencyName() {
        return this.frequencyName || capitalize(this.timeUnit?.getFrequencyName() || this.getName());
    }

    getAbrev() {
        return this.abrev || this.value;
    }

    isOneTime() {
        return this === this.constructor.ONE_TIME;
    }

    static allRecurring() {
        return this.all().filter(cu => !cu.isOneTime());
    }
}


export class RecurringPaymentStoreObject extends StoreObject {
    @field(Date) createdAt;
    @field("Currency") currency;
    @field("UserAddress") deliveryAddress;
    @field("UserAddress") billingAddress;

    @field("MerchantConversion") conversion;

    // TODO @Mihai add here the shared fields
    static Status = {
        ACTIVE: "active",
        CANCELED: "canceled",
        UNPAID: "unpaid",
        REVOKED: "revoked",
        FINISHED: "finished",
    };

    isFinished() {
        return this.status === RecurringPaymentStoreObject.Status.FINISHED;
    }

    isCanceled() {
        return this.status === RecurringPaymentStoreObject.Status.CANCELED;
    }

    isUnpaid() {
        return this.status === RecurringPaymentStoreObject.Status.UNPAID;
    }

    isActive() {
        // TODO: investigate where this is used, should depend on the status probably
        return this.getActiveUntil() > new StemDate();
    }

    isActiveButCanceled() {
        return this.isActive() && this.isCanceled();
    }

    // TODO @Mihai deprecate
    getPaidUntil() {
        return StemDate.optionally(this.paidUntil);
    }

    getActiveUntil() {
        return StemDate.optionally(this.activeUntil);
    }

    hasGracePeriod() {
        const paidUntil = this.getPaidUntil();
        const activeUntil = this.getActiveUntil();

        return !paidUntil.equals(activeUntil);
    }

    getNextBillingDate() {
        return StemDate.optionally(this.nextPaymentAttempt);
    }

    getLastPaymentDate() {
        return StemDate.optionally(this.lastSuccessfulPayment);
    }

    getCurrency() {
        return this.currency;
    }

    getPrice() {
        return new Money(this.amount, this.currency);
    }

    getMerchant() {
        return MerchantStore.get(this.merchantId);
    }

    getMerchantTitle() {
        return this.getMerchant()?.getName();
    }

    getPaymentMethod() {
        if (this.preferredPaymentMethodId) {
            return PaymentMethodStore.get(this.preferredPaymentMethodId);
        }
        return PaymentMethodStore.getPrimary();
    }

    formatPrice() {
        return "";
    }

    getAvailablePaymentMethods() {
        const cards = [...PaymentMethodStore.getPaymentMethodsForRecurringPayment(this),
            ...PaymentMethodStore.getAvailablePaymentMethods()];
        return cards.filter((card, index) => cards.indexOf(card) === index);
    }

    // TODO: use the method bellow, not the id
    getShippingAddressId() {
        return this.deliveryAddressId;
    }

    getShippingAddress() {
        return this.deliveryAddress;
    }

    getBillingAddress() {
        return this.billingAddress || this.deliveryAddress;
    }
}
