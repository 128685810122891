import {iFrameState} from "../../../../services/IFrameState";
import {PANEL_TYPE} from "../../../../../blink-sdk/Constants";
import {INLINE_NEWSLETTER_URLS} from "../../../PanelConstants";
import {Router} from "../../../../../stem-core/src/ui/Router";
import {iFrameUserDataService} from "../../../../services/IFrameUserDataService";
import {MerchantAudienceStore} from "../../../../../client/state/merchant/MerchantAudienceStore";


export class NewsletterFlowState {
    start() {
        const {panelType, skipCTA} = iFrameState;

        if (panelType !== PANEL_TYPE.newsletter) {
            return;
        }

        if (skipCTA) {
            Router.changeURL(INLINE_NEWSLETTER_URLS.subscribe);
        } else {
            if (iFrameUserDataService.isUserSubscribedToAllGivenNewsletters()) {
                Router.changeURL(INLINE_NEWSLETTER_URLS.success);
            } else if (MerchantAudienceStore.all().length === 1) {
                Router.changeURL(INLINE_NEWSLETTER_URLS.subscribe);
            } else {
                Router.changeToCustomPanel();
            }
        }
    }
}
