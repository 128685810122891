import {UI, registerStyle} from "../stem-core/src/ui/UI";

import {NavBar} from "./nav/NavBar";
import {Header} from "./sections-common/Header";
import {MobileNavbar} from "./nav/MobileNavbar";
import {NavManager} from "./nav/NavManager";
import {Footer} from "./sections-common/Footer";
import {PUBLISHER_URL, READER_URL} from "./Constants";
import {Messages} from "../blinkpay/Messages";
import {LandingPageIndexStyle, LandingPageIndex} from "./LandingPageIndex";
import {EmptySection} from "./sections-user/EmptySection";
import {Team} from "./sections-company/Team";


@registerStyle(LandingPageIndexStyle)
export class CompanyIndex extends LandingPageIndex {
    // This ids are tied to the Messages so be careful when changing them.
    navManager = new NavManager(
        [
            {
                id: "navPublisher",
                href: PUBLISHER_URL,
                label: Messages.navPublisher,
            },
            {
                id: "navReader",
                href: READER_URL,
                label: Messages.navReader,
            },
        ],
        [
            {
                element: Header,
                id: "company",
                label: Messages.company,
            },
        ]
    );

    onPageEnter() {
        super.onPageEnter();

        this.navBar.refreshFloatingStatus();
    }

    render() {
        const {styleSheet} = this;

        const description = [
            <p>{Messages.companyHeaderDescription1}</p>,
            <p>{Messages.companyHeaderDescription2}</p>,
            <p>{Messages.companyHeaderDescription3}</p>,
        ];

        const headerOptions = {
            id: "company",
            title: Messages.companyHeaderTitle,
            description,
            ctaSection: null,
            hasIllustration: false,
        };


        return [
            <MobileNavbar className={styleSheet.navMobile} navManager={this.navManager} />,
            // Pass the page instance so you can access the right header easily.
            <NavBar
                ref="navBar"
                className={styleSheet.nav}
                navManager={this.navManager}
            />,
            <Header {...headerOptions} />,
            <EmptySection />,
            <Team />,
            <Footer />,
        ];
    }
}
