import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {authService, userService} from "../../client/connection/Services";
import {AuthService} from "../../client/connection/services/AuthService";
import {iFrameConnection} from "./IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {GlobalState} from "../../stem-core/src/state/State";
import {AuthToken} from "../../client/connection/AuthToken";
import {apiConnection} from "../../client/connection/BlinkApiConnection";
import {Router} from "../../stem-core/src/ui/Router";
import {LOGIN_URL} from "../Constants";
import {AuthHelper} from "../AuthHelper";
import {LoadingSpinner} from "../ui/LoadingSpinner";


export async function shiftToMerchantToken() {
    const userPreviouslyAuthenticated = authService.isAuthenticated();
    const {key, expiresAt} = iFrameAuthService.merchantToken;
    authService.token.setToken(key, expiresAt, true);

    if (!userPreviouslyAuthenticated) {
        return; // We have login listeners that can handle the user data fetch.
    }

    apiConnection.clearWsConnection();
    await userService.forceFetchUserData();
    for (const key of Object.keys(userService.userData?.state || {})) {
        const store = GlobalState.getStore(key);
        if (store) {
            for (const obj of store.all()) {
                if (!userService.userData.state[key].find(object => object.id === obj.id)) {
                    store.applyDeleteEvent({objectId: obj.id});
                }
            }
        }
    }
}

class IFrameAuthService extends Dispatchable {
    merchantToken = null;

    setMerchantToken(token) {
        this.merchantToken = token;
        // TODO @Denis @Mihai this logic is implicit, make it explicit (like, if the token is full or merchant)
        // TODO @Mihai @Denis always use a token object with t, e
        if (token && (AuthToken.getUserIdFromToken(token.key) !== authService.token.getUserId())) {
            shiftToMerchantToken();
        }
    }

    init(token) {
        authService.addListener(AuthService.EventTypes.SET_MERCHANT_TOKEN, token => {
            this.setMerchantToken(token);
            iFrameConnection.sendToSDK(IFrameMessages.SET_MERCHANT_TOKEN, {token});
        });
        iFrameConnection.addListener(IFrameMessages.UPDATE_MERCHANT_TOKEN, ({token}) => {
            this.setMerchantToken(token);
        });
        iFrameConnection.addIframesListener(IFrameMessages.USER_TOKEN_UPDATE, ({token, merchantToken, userData, doNotRebroadcast}) => {
            if (!token) {
                authService.token.clear();
            } else {
                const {t, e} = JSON.parse(token);
                authService.token.setToken(t, e);
            }

            if (merchantToken) {
                merchantToken = {
                    key: JSON.parse(merchantToken).t,
                    expiresAt: JSON.parse(merchantToken).e,
                }
                this.setMerchantToken(merchantToken);
                iFrameConnection.sendToSDK(IFrameMessages.SET_MERCHANT_TOKEN, {token: merchantToken});
            }

            if (userData) {
                const {state} = userData;
                if (state) {
                    GlobalState.importState(state);
                }
            }

            if (!doNotRebroadcast) {
                iFrameConnection.sendToIFrames(IFrameMessages.USER_TOKEN_UPDATE, {
                    token,
                    userData,
                    doNotRebroadcast: true,
                });
            }
        });

        this.setMerchantToken(token);
    }

    async promptAuthenticationIfNeeded() {
        if (authService.isAuthenticated()) {
            return null;
        }
        Router.changeURL(LOGIN_URL);

        // TODO @Mihai AuthHelper.authenticationPromise, with OnceDispatcher.awaitOnce()
        return new Promise(resolve => {
            AuthHelper.addAuthenticationCallback(() => {
                LoadingSpinner.show();
                userService.ensureUserDataRequested(() => {
                    LoadingSpinner.hide();
                    resolve();
                });
            })
        });
    }
}

export const iFrameAuthService = new IFrameAuthService();
