import {UI, StyleSheet, registerStyle, styleRule} from "../../stem-core/src/ui/UI";
import {isSmallScreen} from "../Utils";
import {VerticalDotsIcon} from "../SVGElements";
import {ClickPreprocessors} from "./ClickPreprocessors";
import {isMobileDevice} from "../../stem-core/src/base/Device";


export class PopupMenuStyle extends StyleSheet {
    @styleRule
    element = {
        position: "relative",
    };

    @styleRule
    menuButton = {
        display: "flex",
        width: 18,
        borderRadius: "2px",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        transition: ".2s ease",
    };

    @styleRule
    menuPopup = {
        opacity: 0,
        position: "absolute",
        width: this.themeProps.FIT_CONTENT,
        whiteSpace: "nowrap",
        right: () => (isSmallScreen() ? 0 : "-90%"),
        pointerEvents: "none",
        transform: "scale(.9) translateY(-10px)",
        transition: ".2s ease",
        borderRadius: "6px",
        background: this.themeProps.DROPDOWN_EXPANDED_BACKGROUND,
        boxShadow: this.themeProps.SINGLE_ITEM_MENU_SHADOW,
        zIndex: 3,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        color: this.themeProps.DROPDOWN_COLOR,
        marginTop: 3,
    };

    @styleRule
    expandedMenuPopup = {
        opacity: 1,
        pointerEvents: "initial",
        transform: "scale(1) translateY(0)",
    };

    @styleRule
    menuItem = {
        padding:  "6px 12px",
        cursor: "pointer",
        transition: ".2s ease",
        userSelect: "none",
        ":hover": {
            background: this.themeProps.DROPDOWN_ITEM_HOVER_BACKGROUND,
        },
    };

    @styleRule
    menuItemDisabled = {
        pointerEvents: "none",
        opacity: ".6",
        cursor: "not-allowed",
    }
}

@registerStyle(PopupMenuStyle)
export class PopupMenu extends UI.Element {
    opened = false;
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            icon: VerticalDotsIcon,
            iconSize: 16,
            items: [],
            testId: "popupMenu",
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
        attr.setAttribute("expanded", this.opened);
    }

    toggle(event) {
        event.stopPropagation();
        this.opened = !this.opened;
        this.redraw();
    }

    hide() {
        this.opened = false;
        if (this.node) {
            this.redraw();
        }
    }

    onMenuItemClick(item) {
        if (item && typeof item.onClick === "function") {
            item.onClick();
        }
        this.hide();
    }

    render() {
        const {styleSheet} = this;
        const {icon, iconSize, items} = this.options;
        const IconClass = icon;

        return [
            <div ref="menuButton" testId="menuButton" onClick={event => this.toggle(event)}
                 className={styleSheet.menuButton}>
                <IconClass size={iconSize} />
            </div>,
            <div testId="menuPopup" ref="popupMenu"
                 className={styleSheet.menuPopup + " " + (this.opened ? styleSheet.expandedMenuPopup : "")}>
                {items.map(item => {
                    const disabledItem = item.disabled && typeof item.disabled === "function" && item.disabled() === true;
                    return (
                        <div testId="menuItem" onClick={() => this.onMenuItemClick(item)}
                             className={styleSheet.menuItem + " " + (disabledItem ? styleSheet.menuItemDisabled : "")}>
                            {item.content}
                        </div>
                    );
                })}
            </div>,
        ];
    }

    onMount() {
        super.onMount();

        let timestamp = -100;
        const eventType = isMobileDevice() ? "touchend" : "click";
        this.attachEventListener(document.body, eventType, () => {
            if (performance.now() - timestamp > 100) {
                this.hide();
            }
        });
        this.popupMenu.addNodeListener(eventType, event => {
            event.stopPropagation();
        });
        ClickPreprocessors.addCallback((element) => {
            if (this.menuButton?.node !== element.node && this.popupMenu?.node !== element.node) {
                this.hide();
            } else {
                timestamp = performance.now();
            }
        });
    }
}
