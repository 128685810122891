import {UI, StyleSheet, styleRule, registerStyle, styleRuleInherit} from "../../stem-core/src/ui/UI";
import {isSmallScreen} from "../Utils";
import {Messages} from "../Messages";
import {BlinkButton} from "./Button";
import {BUTTON_TYPE} from "../Constants";
import {Toast} from "./Toast";
import {BlinkLogoSpinner, BlinkLogoSpinnerStyle} from "./LoadingSpinner";
import {MessageElement} from "../widget/ui/MessageElement";


export class BaseCardStyle extends StyleSheet {
    @styleRule
    card = {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        margin: () => isSmallScreen() ? "0 12px 24px" : "0 0 36px",
        boxShadow: this.themeProps.BASE_CARD_SHADOW,
        padding: () => (isSmallScreen() ? "18px" : "24px"),
        background: this.themeProps.BASE_CARD_BACKGROUND,
        width: this.themeProps.BASE_CARD_WIDTH,
        maxWidth: () => (isSmallScreen() ? "calc(100vw - 24px)" : this.themeProps.BASE_CARD_MAX_WIDTH),
        borderRadius: 12,
        zIndex: 2
    };

    cardSectionCommon = {
        width: "100%"
    };

    @styleRule
    cardHeader = {
        ...this.cardSectionCommon,
        display: "flex",
        "> *": {
            marginBottom: () => (isSmallScreen() ? 12 : 24)
        },
    };

    @styleRule
    cardContent = [
        this.cardSectionCommon,
        {
            height: "fit-content",
        }
    ];

    @styleRule
    cardFooter = [
        this.cardSectionCommon,
        {
            "> *": {
                marginTop: () => (isSmallScreen() ? 12 : 24),
            }
        }
    ];

    @styleRule
    title = {
        flex: 1,
        fontSize: () => (isSmallScreen() ? 18 : 21),
        color: this.themeProps.BASE_CARD_TITLE_COLOR,
    };

    @styleRule
    description = {
        fontSize: () => (isSmallScreen() ? 14 : 16),
        color: this.themeProps.BASE_CARD_DESCRIPTION_COLOR,
        lineHeight: () => (isSmallScreen() ? 18 : 20),
        marginBottom: 24,
        ":last-child": { // If the description is the last child, it shouldn't have a margin bottom.
            marginBottom: 0,
        }
    };
}

@registerStyle(BaseCardStyle)
export class BaseCard extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "",
            description: "",
            action: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.card);
    }

    getDescription() {
        return this.options.description;
    }

    getChildrenToRender() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.cardHeader}>{this.renderHeader()}</div>,
            <div className={styleSheet.cardContent}>{this.renderContent()}</div>,
            <div className={styleSheet.cardFooter}>{this.renderFooter()}</div>,
        ]
    }

    renderHeader() {
        const {styleSheet} = this;
        const {title, action} = this.options;
        let actionElement = null;

        if (typeof action === "function") {
            actionElement = action();
        }

        return [
            title ? <div className={styleSheet.title}>{title}</div> : null,
            actionElement ? <div className={styleSheet.actionElement}>{actionElement}</div> : null
        ];
    }

    renderContent() {
        const description = this.getDescription();
        let descriptionValue = description;

        if (typeof description === "function") {
            descriptionValue = description();
        }

        return [
            descriptionValue ?
                Array.isArray(descriptionValue) ?
                    <div className={this.styleSheet.description}>{descriptionValue}</div>
                    : <MessageElement className={this.styleSheet.description} message={descriptionValue}/>
            : null,
            this.render()
        ]
    }

    renderFooter() {
    }
}

export class EditableBaseCardStyle extends BaseCardStyle {
    @styleRule
    componentButton = {
        flex: 1,
    };

    @styleRule
    buttonsContainer = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        ">:not(:first-child)": {
            marginLeft: 24,
        }
    };
}

@registerStyle(EditableBaseCardStyle)
export class EditableBaseCard extends BaseCard {
    editing = false;
    confirmButtonDisabled = true;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            cancelButtonLabel: Messages.cancel,
            confirmButtonLabel: Messages.save,
            editButtonLabel: Messages.changeDetails,
        }
    }

    getFooterButtons() {
        const {cancelButtonLabel, confirmButtonLabel, editButtonLabel} = this.options;
        const {styleSheet} = this;

        if (!this.editing) {
            return <BlinkButton ref="editButton"
                                testId="editButton"
                                className={styleSheet.componentButton}
                                label={editButtonLabel}
                                onClick={() => this.toggleEditing()}
            />
        }

        return [
            <BlinkButton ref="cancelButton"
                         testId="cancelButton"
                         className={styleSheet.componentButton}
                         type={BUTTON_TYPE.SECONDARY}
                         label={cancelButtonLabel}
                         onClick={() => this.toggleEditing()}
            />,
            <BlinkButton ref="confirmButton"
                         testId="confirmButton"
                         className={styleSheet.componentButton}
                         label={confirmButtonLabel}
                         disabled={this.confirmButtonDisabled}
                         onClick={() => this.handleConfirmButtonClick()}
            />
        ]
    }

    renderFooter() {
        return <div className={this.styleSheet.buttonsContainer}>{this.getFooterButtons()}</div>
    }

    handleConfirmButtonClick() {}

    executeEditToggleActions() {}

    setConfirmButtonDisabled(disabled = true) {
        this.confirmButtonDisabled = disabled;
        this.redraw();
    }

    toggleEditing() {
        this.editing = !this.editing;
        this.redraw();
        this.executeEditToggleActions();
    }

    isEditingEnabled() {
        return this.editing;
    }
}

class PaginationLogoSpinnerStyle extends BlinkLogoSpinnerStyle {
    @styleRuleInherit
    blinkLogoSpinner = {
        marginTop: 24,
    }
}

@registerStyle(PaginationLogoSpinnerStyle)
class PaginationLogoSpinner extends BlinkLogoSpinner {}

// Logic class that binds to a ui element, and fetches from a paginator
// Calls the UI element to redraw
export class UIPaginationManager {
    constructor(uiElement) {
        this.loadingElement = null;
        this.uiElement = uiElement;
    }

    getPaginator() {
        return this.uiElement.paginator;
    }

    showLoadingElement() {
        if (this.loadingElement) {
            return;
        }

        this.loadingElement = PaginationLogoSpinner.create(this.uiElement)
    }

    hideLoadingElement() {
        if (!this.loadingElement) {
            return;
        }

        this.loadingElement.destroyNode();
    }

    async fetchPages() {
        const paginator = this.getPaginator();
        if (paginator.haveLoadedLastRequestedPage()) {
            this.hideLoadingElement();
            return;
        }

        this.showLoadingElement();
        try {
            await paginator.fetchNextPage();
            // Redraw uiElement even if no change event would be triggered
            this.uiElement.redraw();
            await this.fetchPages();
        } catch (error) {
            this.hideLoadingElement();
            Toast.showError(error);
        }
    }

    attach() {
        this.uiElement.attachChangeListener(this.getPaginator().store, () => this.uiElement.redraw());
        this.fetchPages();
    }
}
