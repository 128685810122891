// The default log level is ERROR in production and WARN otherwise.
// Warnings are what should be seen be external developers integrating the SDK,
// so they should see all these messages in the integration environments.
// The default level can be overwritten by the localStorage key "blink-|-Logger.loggerLevel".

import {LOG_LEVEL, MERCHANT_STORAGE_KEYS} from "../blink-sdk/Constants";
import {merchantLocalStorage} from "../blink-sdk/modules/merchant-state/MerchantStorage";

const Level = {
    DEBUG: 0,
    INFO: 1,
    WARN: 2,
    ERROR: 3,
};

function toNumericLevel(level) {
    if (!level || (typeof level === "number") || (level instanceof Number)) {
        return level;
    }
    const normalizedLevel = level.toString().toUpperCase();
    return Level[normalizedLevel];
}

const consoleHandlers = [
    console.debug,
    console.info,
    console.warn,
    console.error,
];

function GetDefaultLevel() {
    return merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.loggerLevel) ?? LOG_LEVEL;
}

export class Logger {
    static Level = Level; // so it can be exported
    static defaultLoggerName = "[BlinkSDK]";

    static currentLevel = toNumericLevel(GetDefaultLevel() || Level.DEBUG);
    static droppedMessages = 0;

    static setLevel(level) {
        const normalizedLevel = toNumericLevel(level);
        if (!normalizedLevel) {
            // This is actually a console error directly, since it can't really be debugged
            console.error("Invalid log level: ", level);
            return;
        }
        this.currentLevel = normalizedLevel;
    }

    static logMessage(level, loggerName, ...args) {
        if (level < this.currentLevel) {
            this.droppedMessages += 1;
            return;
        }

        if (loggerName === null) {
            loggerName = this.defaultLoggerName;
        }

        const newArgs = loggerName ? [loggerName, ...args] : args;

        consoleHandlers[level] && consoleHandlers[level](...newArgs);
        // Logs could also be collected, so that the client can randomly choose to send everything it collected.
        // We could also dispatch the message here, if we wanted to.
    }

    static log(...args) {
        this.logMessage(Level.INFO, this.defaultLoggerName, ...args);
    }

    static debug(...args) {
        this.logMessage(Level.DEBUG, this.defaultLoggerName, ...args);
    }

    static info(...args) {
        this.logMessage(Level.INFO, this.defaultLoggerName, ...args);
    }

    static warn(...args) {
        this.logMessage(Level.WARN, this.defaultLoggerName, ...args);
    }

    static error(...args) {
        this.logMessage(Level.ERROR, this.defaultLoggerName, ...args);
    }

    isDisabled = false;
    level = null; // An extra level filter on top of the global one, none by default.

    constructor(name, level) {
        this.name = name;
        if (level) {
            this.setLevel(level);
        }
    }

    setLevel(level) {
        this.level = toNumericLevel(level);
    }

    disable() {
        this.isDisabled = true;
    }

    enable() {
        this.isDisabled = false;
    }

    logMessage(level, ...args) {
        if (this.isDisabled || (this.level && level < this.level)) {
            // Dropping the message, level too low
            return;
        }
        this.constructor.logMessage(level, this.name, ...args);
    }

    log(...args) {
        this.logMessage(Level.INFO, ...args);
    }

    debug(...args) {
        this.logMessage(Level.DEBUG, ...args);
    }

    info(...args) {
        this.logMessage(Level.INFO, ...args);
    }

    warn(...args) {
        this.logMessage(Level.WARN, ...args);
    }

    error(...args) {
        this.logMessage(Level.ERROR, ...args);
    }
}

