import {styleRule, StyleSheet, registerStyle, UI} from "../../stem-core/src/ui/UI";
import {COMPONENT_MAX_WIDTH} from "../Constants";
import {isDesktopSmall} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";

export class SectionStyle extends StyleSheet {
    lateralPadding = 24;

    @styleRule
    section = {
        boxSizing: "border-box",
        paddingTop: () => (isDesktopSmall() ? 24 : 48),
        paddingBottom: () => (isDesktopSmall() ? 24 : 48),
    };

    @styleRule
    container = {
        maxWidth: COMPONENT_MAX_WIDTH + 48,
        margin: "0 auto",
        padding: `0 ${this.lateralPadding}px`,
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 18 : 36),
        textAlign: "center",
        color: "inherit",
    };

    @styleRule
    subtitle = {
        fontSize: () => (Device.isMobileDevice() ? 16 : 24),
        marginTop: () => (Device.isMobileDevice() ? 10 : 16),
        textAlign: "center",
        color: "inherit",
    };
}

@registerStyle(SectionStyle)
export class Section extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            containerId: "",
        };
    }

    static getInstance() {
        return this.instances.find(instance => instance.isInDocument());
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.section);
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {title, subtitle, containerId} = this.options;

        return (
            <div className={styleSheet.container} id={containerId}>
                {" "}
                {[
                    title ? <div className={styleSheet.title}>{title}</div> : null,
                    subtitle ? <div className={styleSheet.subtitle}>{subtitle}</div> : null,
                    this.render(),
                ]}
            </div>
        );
    }

    onMount() {
        super.onMount();

        // This is a hack. The problem is that during a redraw the constructor of a UI Element gets called,
        // and because of that the instance is overwritten if we keep this code in the constructor, although
        // the rendered instance remains the same.
        if (!this.constructor.instances) {
            this.constructor.instances = [];
        }
        this.constructor.instances.push(this);
    }
}
