import {StyleSheet} from "../../../../../../../../stem-core/src/ui/Style";
import {styleRule} from "../../../../../../../../stem-core/src/decorators/Style";
import {isSmallScreen} from "../../../../../../../Utils";
import {registerStyle} from "../../../../../../../../stem-core/src/ui/style/Theme";
import {UI} from "../../../../../../../../stem-core/src/ui/UIBase";
import {Messages} from "../../../../../../../Messages";
import {AddCardModal} from "../AddCardModal";


class AddCardButtonStyle extends StyleSheet {
    @styleRule
    addCardButton = {
        padding: () => (isSmallScreen() ? "0 6px" : "0 24px"),
        fontSize: () => (isSmallScreen() ? 18 : 24),
        height: () => (isSmallScreen() ? 46 : 68),
        display: "flex",
        alignItems: "center",
        color: this.themeProps.DROPDOWN_COLOR,
    };

    @styleRule
    addSign = {
        padding: () => (isSmallScreen() ? "2px 0 !important" : "9px 0 !important"),
        fontSize: () => (isSmallScreen() ? 33 : 43),
        color: this.themeProps.DROPDOWN_COLOR,
    };
}

@registerStyle(AddCardButtonStyle)
export class AddCardButton extends UI.Element {
    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.addCardButton}>{Messages.addCard}...</div>,
            <div className={styleSheet.addSign}>+</div>,
        ];
    }

    onMount() {
        super.onMount();
        this.addClickListener(event => {
            event.stopPropagation();
            event.preventDefault();
            AddCardModal.show();
        });
    }
}
