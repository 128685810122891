import {
    ANALYTICS_ENDPOINT,
    ANALYTICS_MAX_QUEUED_MESSAGES,
    ANALYTICS_SUBMIT_DEBOUNCE_MS
} from "../Constants";
import {delayDebounced} from "./Utils";
import {makeUniqueSelector} from "./makeUniqueSelector";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {decapitalize} from "../../stem-core/src/base/Utils.js";

// TODO @branch deprecate a couple of these and delete the historic events
export const AnalyticsEventType = {
    PAGE_VIEW: {key: "pageView", flush: true},
    POPUP_PAGE_CHANGE: {key: "popupPageChange", flush: true},  // TODO @branch there seem to be too many of these
    JS_ERROR: {key: "jsError", flush: true, now: true},
    FLOW_START: {key: "flowStart", flush: true},
    ELEMENT_CLICK: {key: "elementClick", flush: true},
    SOCIAL_LOGIN_BUTTON_CLICKED: {key: "socialLoginButtonClicked", flush: true},
    REQUEST_EMAIL_ACCESS_CODE: {key: "requestEmailAccessCode", flush: true},
    ATTEMPT_CONFIRM_EMAIL_CODE: {key: "attemptConfirmEmailCode", flush: true},
    ATTEMPT_DONATE: {key: "attemptDonate", flush: true},
    ATTEMPT_SUBSCRIBE: {key: "attemptSubscribe", flush: true},
    ATTEMPT_NEWSLETTER_SIGNUP: {key: "attemptNewsletterSignup", flush: true},
    SUBSCRIPTION_SELECTED: {key: "subscriptionSelected", flush: true},
    SHOW_SUBSCRIPTION_COVERAGE_CARDS: {key: "showSubscriptionCoverageCards", flush: true},
    DONATE_SELECTED_AMOUNT: {key: "donateSelectedAmount", flush: true},
    IFRAME_COLLAPSED: {key: "iframeCollapsed", flush: true},
    USER_LOGIN: {key: "userLogin", flush: true},
    AJAX_REQUEST_FAILED: {key: "ajaxRequestFailed", flush: false},
    AJAX_REQUEST_SUCCEEDED: {key: "ajaxRequestSucceeded", flush: false},
    FLOW_CHECKPOINT: {key: "flowCheckpoint", flush: true},
    CUSTOM: {key: "custom", flush: true}, // Custom merchant event
};

export const EventSender = {
    sdk: "sdk",
    wallet: "wallet",
    panel: "panel",
};

let analyticsClient = null;

export class AnalyticsClient extends Dispatchable {
    constructor(origin) {
        super();

        analyticsClient = this;

        this.origin = origin;
        this.eventQueue = []; // TODO @branch rename to queue
        this.environment = {};
        this.identity = {};
        this.preprocessors = [];

        // TODO @branch maybe simplify this, or put in a try catch
        this.submitEnqueuedEventsDebounced = delayDebounced(
            () => this.submitEnqueuedEvents(),
            ANALYTICS_SUBMIT_DEBOUNCE_MS);
    }

    addNodeAnalyticsData(node, attr) {
        if (node.dataset) {
            for (const [key, value] of Object.entries(node.dataset)) {
                if (key.startsWith("analytics") && key !== "analytics") {
                    const newKey = decapitalize(key.slice(9));
                    if (!attr.hasOwnProperty(newKey)) {
                        attr[newKey] = value;
                    }
                }
            }
        }
        const parent = node.parentElement;
        if (parent) {
            this.addNodeAnalyticsData(parent, attr);
        }
    }

    addEventNodeDataToPayload(globalEvent, payload) {
        let target = globalEvent.currentTarget || globalEvent.target;
        // In case this is React, which attaches all listeners to the document for some reason.
        if (target === document) {
            target = globalEvent.target || globalEvent.currentTarget;
        }
        if (target?.tagName) {
            payload.domEventType = globalEvent.type;
            payload.domEventTarget = {
                selector: makeUniqueSelector(target),
            };

            let analyticsAttributes = {};
            this.addNodeAnalyticsData(target, analyticsAttributes);
            if (Object.keys(analyticsAttributes).length > 0) {
                payload.domEventTarget.attributes = analyticsAttributes;
            }
        }
    }

    submitEnqueuedEvents(onlyXHR) {
        // TODO @branch investigate and implement Do Not Track better
        if (this.eventQueue.length === 0) {
            return;
        }

        const serializePayload = (method) => JSON.stringify({
            method,
            origin: this.origin,
            identity: this.identity,
            events: this.eventQueue,
        });

        // TODO @branch, maybe we need to revisit this
        // if (!onlyXHR && navigator.sendBeacon) {
        //     const payload = serializePayload("beacon");
        //     if (navigator.sendBeacon(ANALYTICS_ENDPOINT, payload)) {
        //         this.eventQueue = [];
        //         return;
        //     }
        // }

        const payload = serializePayload("xhr");
        this.eventQueue = [];
        const xhr = new XMLHttpRequest();
        xhr.open("POST", ANALYTICS_ENDPOINT, true);
        xhr.send(payload);
    }

    enqueueEvent(eventType, payload = {}) {
        const globalEvent = self.event;
        // If the event is dispatched because of a user action, attach information about the user action.
        if (globalEvent) {
            this.addEventNodeDataToPayload(globalEvent, payload);
        }
        let event = {
            type: eventType.key,
            timestamp: +Date.now() / 1000.0,
            localSessionTime: performance.now() / 1000.0,
            // TODO @branch put in the userId here??
            payload: {
                ...this.environment,
                ...payload,
            },
        };
        for (const preprocessor of this.preprocessors) {
            event = preprocessor(event) || event;
        }
        // Intentionally make a copy of identity here, since this object is sent outside our SDK,
        // and accidental modification in the merchant's code shouldn't interfere with the payload
        // of our analytics events.
        this.dispatch("event", {
            origin: this.origin,
            identity: {...this.identity},
            ...event,
        });
        this.eventQueue.push(event);
        if (this.eventQueue.length >= ANALYTICS_MAX_QUEUED_MESSAGES) {
            this.submitEnqueuedEvents();
        } else if (eventType.flush) {
            if (eventType.now) {
                this.submitEnqueuedEvents(1);
            } else {
                this.submitEnqueuedEventsDebounced();
            }
        }
    }

    updateEnvironment(data) {
        Object.assign(this.environment, data);
    }

    updateIdentity(data) {
        Object.assign(this.identity, data);
    }
}

export function dispatchAnalyticsEvent(type, payload = {}) {
    try {
        analyticsClient?.enqueueEvent(type, payload);
    } catch (e) {
        console.error("Blink analytics caught an error:", e);
    }
}

export function getAnalyticsClient() {
    return analyticsClient;
}

// Useful for tests.
export function cleanupAnalyticsClient() {
    analyticsClient?.cleanup();
    analyticsClient = null;
}

export function handleFirstJSError(message, file, line, col, error) {
    self.onerror = null;
    // TODO @branch use a non-global one if needed
    analyticsClient = analyticsClient || new AnalyticsClient("errorHandler");
    analyticsClient?.enqueueEvent(AnalyticsEventType.JS_ERROR, {
        message,
        file,
        line,
        col,
        error: {
            message: error?.message.slice(0, 256),
            stack: error?.stack.slice(0, 6000),
            timestamp: self.event?.timestamp,
        }
    });
}
