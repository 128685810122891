import {resourceLoader} from "../../client/connection/ResourceLoader";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";

export const USStatesList = {
    "Alabama": "AL",
    "Alaska": "AK",
    "American Samoa": "AS",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "District of Columbia": "DC",
    "Florida": "FL",
    "Georgia": "GA",
    "Guam": "GU",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "MP",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virgin Islands": "VI",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY"
}

export function getStateShortCode(stateName) {
    const normalize = v => v.toLowerCase().replace(/\s/g, "");
    for (const [key, value] of Object.entries(USStatesList)) {
        if (normalize(key) === normalize(stateName) || normalize(value) === normalize(stateName)) {
            return value;
        }
    }
    return null;
}

// Return the 2 letter state code
export function getUSStateFromZip(zipCode) {
    zipCode = parseInt(zipCode, 10);
    let st = "";

    if (zipCode >= 35000 && zipCode <= 36999) {
        st = "AL";
    } else if (zipCode >= 99500 && zipCode <= 99999) {
        st = "AK";
    } else if (zipCode >= 85000 && zipCode <= 86999) {
        st = "AZ";
    } else if (zipCode >= 71600 && zipCode <= 72999) {
        st = "AR";
    } else if (zipCode >= 90000 && zipCode <= 96699) {
        st = "CA";
    } else if (zipCode >= 80000 && zipCode <= 81999) {
        st = "CO";
    } else if ((zipCode >= 6000 && zipCode <= 6389) || (zipCode >= 6391 && zipCode <= 6999)) {
        st = "CT";
    } else if (zipCode >= 19700 && zipCode <= 19999) {
        st = "DE";
    } else if (zipCode >= 32000 && zipCode <= 34999) {
        st = "FL";
    } else if ( (zipCode >= 30000 && zipCode <= 31999) || (zipCode >= 39800 && zipCode <= 39999) ) {
        st = "GA";
    } else if (zipCode >= 96700 && zipCode <= 96999) {
        st = "HI";
    } else if (zipCode >= 83200 && zipCode <= 83999) {
        st = "ID";
    } else if (zipCode >= 60000 && zipCode <= 62999) {
        st = "IL";
    } else if (zipCode >= 46000 && zipCode <= 47999) {
        st = "IN";
    } else if (zipCode >= 50000 && zipCode <= 52999) {
        st = "IA";
    } else if (zipCode >= 66000 && zipCode <= 67999) {
        st = "KS";
    } else if (zipCode >= 40000 && zipCode <= 42999) {
        st = "KY";
    } else if (zipCode >= 70000 && zipCode <= 71599) {
        st = "LA";
    } else if (zipCode >= 3900 && zipCode <= 4999) {
        st = "ME";
    } else if (zipCode >= 20600 && zipCode <= 21999) {
        st = "MD";
    } else if ( (zipCode >= 1000 && zipCode <= 2799) || (zipCode == 5501) || (zipCode == 5544 )) {
        st = "MA";
    } else if (zipCode >= 48000 && zipCode <= 49999) {
        st = "MI";
    } else if (zipCode >= 55000 && zipCode <= 56899) {
        st = "MN";
    } else if (zipCode >= 38600 && zipCode <= 39999) {
        st = "MS";
    } else if (zipCode >= 63000 && zipCode <= 65999) {
        st = "MO";
    } else if (zipCode >= 59000 && zipCode <= 59999) {
        st = "MT";
    } else if (zipCode >= 27000 && zipCode <= 28999) {
        st = "NC";
    } else if (zipCode >= 58000 && zipCode <= 58999) {
        st = "ND";
    } else if (zipCode >= 68000 && zipCode <= 69999) {
        st = "NE";
    } else if (zipCode >= 88900 && zipCode <= 89999) {
        st = "NV";
    } else if (zipCode >= 3000 && zipCode <= 3899) {
        st = "NH";
    } else if (zipCode >= 7000 && zipCode <= 8999) {
        st = "NJ";
    } else if (zipCode >= 87000 && zipCode <= 88499) {
        st = "NM";
    } else if ( (zipCode >= 10000 && zipCode <= 14999) || (zipCode == 6390) || (zipCode == 501) || (zipCode == 544) ) {
        st = "NY";
    } else if (zipCode >= 43000 && zipCode <= 45999) {
        st = "OH";
    } else if ((zipCode >= 73000 && zipCode <= 73199) || (zipCode >= 73400 && zipCode <= 74999) ) {
        st = "OK";
    } else if (zipCode >= 97000 && zipCode <= 97999) {
        st = "OR";
    } else if (zipCode >= 15000 && zipCode <= 19699) {
        st = "PA";
    } else if (zipCode >= 300 && zipCode <= 999) {
        st = "PR";
    } else if (zipCode >= 2800 && zipCode <= 2999) {
        st = "RI";
    } else if (zipCode >= 29000 && zipCode <= 29999) {
        st = "SC";
    } else if (zipCode >= 57000 && zipCode <= 57999) {
        st = "SD";
    } else if (zipCode >= 37000 && zipCode <= 38599) {
        st = "TN";
    } else if ((zipCode >= 75000 && zipCode <= 79999) || (zipCode >= 73301 && zipCode <= 73399) || (zipCode >= 88500 && zipCode <= 88599)) {
        st = "TX";
    } else if (zipCode >= 84000 && zipCode <= 84999) {
        st = "UT";
    } else if (zipCode >= 5000 && zipCode <= 5999) {
        st = "VT";
    } else if ( (zipCode >= 20100 && zipCode <= 20199) || (zipCode >= 22000 && zipCode <= 24699) || (zipCode == 20598) ) {
        st = "VA";
    } else if ( (zipCode >= 20000 && zipCode <= 20099) || (zipCode >= 20200 && zipCode <= 20599) || (zipCode >= 56900 && zipCode <= 56999) ) {
        st = "DC";
    } else if (zipCode >= 98000 && zipCode <= 99499) {
        st = "WA";
    } else if (zipCode >= 24700 && zipCode <= 26999) {
        st = "WV";
    } else if (zipCode >= 53000 && zipCode <= 54999) {
        st = "WI";
    } else if (zipCode >= 82000 && zipCode <= 83199) {
        st = "WY";
    }
    return st;
}

class CountriesDataService extends Dispatchable {
    pendingCallbacks = [];
    countries = [];
    countryByCode = new Map();
    cities = [];

    getStatesNames(countryCode) {
        const {states} = this.countryByCode.get(countryCode);
        const names = states ? Object.keys(states) : [];

        return names.sort();
    }

    getCountries() {
        return Array.from(this.countryByCode.values());
    }

    getCities() {
        return this.cities;
    }

    getCountryByCode(countryCode) {
        return this.countryByCode.get(countryCode);
    }

    onCountriesDataLoaded(countriesList = []) {
        this.countries = countriesList;

        for (let country of countriesList) {
            this.countryByCode.set(country.code2, country);
            for (const stateName of Object.keys(country.states)) {
                const cities = country.states[stateName].map(city => ({
                    name: `${city}, ${stateName}, ${country.code2}`,
                    display: city,
                    extra: {
                        city: city,
                        state: stateName,
                        countryCode: country.code2,
                    }
                }));
                this.cities.push(...cities);
            }
        }
    }

    loadCountriesData(callback = NOOP_FUNCTION) {
        if (this.pendingCallbacks && !this.pendingCallbacks.length) {
            this.pendingCallbacks.push(callback);
            resourceLoader.loadJSON("/resources/json/countries.json", (countriesList) => {
                this.onCountriesDataLoaded(countriesList);
                for (const pendingCallback of this.pendingCallbacks) {
                    pendingCallback();
                }
                this.pendingCallbacks = null;
            });
        } else if (this.pendingCallbacks) {
            this.pendingCallbacks.push(callback);
        } else {
            callback();
        }
    }
}

export const countriesDataService = new CountriesDataService();
