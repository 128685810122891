const imagePrefix = "https://d3ki0vovb6k3h1.cloudfront.net/images/team/";

export const TeamData = [{
    imageURL: imagePrefix + "MihaiCiucu.jpeg",
    name: "Mihai Ciucu",
    position: "Chief Executive Officer",
    locations: ["Palo Alto, CA", "Bucharest, RO"],
}, {
    imageURL: imagePrefix + "AndreiGrigorean.jpeg",
    name: "Andrei Grigorean",
    position: "Chief Operating Officer",
    locations: ["Palo Alto, CA", "Bucharest, RO"],
}, {
    imageURL: imagePrefix + "MannyRincon.jpg",
    name: "Manny Rincon-Cruz",
    position: "Chief Strategy Officer",
    locations: ["Palo Alto, CA"],
}, {
    imageURL: imagePrefix + "MelissaMcAdden-256px-3.jpg",
    name: "Melissa McAdden",
    position: "Industry Advisor",
    locations: ["London, UK"],
}, {
    imageURL: imagePrefix + "NiallFerguson.jpeg",
    name: "Niall Ferguson",
    position: "Advisor",
    locations: ["Stanford, CA"],
}, {
    imageURL: imagePrefix + "KatharinaSpence.png",
    name: "Katharine Spence",
    position: "Communications",
    locations: ["London, UK"],
}, {
    imageURL: imagePrefix + "MonicaSomandroiu-256px.jpg",
    name: "Monica Somandroiu",
    position: "Operations Manager",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "RickVandervoorn.jpeg",
    name: "Rick Vandervoorn",
    position: "Business Development Director",
    locations: ["New York, NY"],
}, {
    imageURL: imagePrefix + "MihaitaLeoveanu.jpeg",
    name: "Mihaita Leoveanu",
    position: "Engineering Manager",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "IonPetcu-256px.jpg",
    name: "Ion Petcu",
    position: "Back-End Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "JonnyMack-256px.jpg",
    name: "Jonny Mack",
    position: "Designer",
    locations: ["Seattle, WA"],
}, {
    imageURL: imagePrefix + "DenisMita.jpeg",
    name: "Denis Mita",
    position: "Front-End Developer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "MihaiStancu-256px.jpg",
    name: "Mihai Stancu",
    position: "Back-End Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "CristianConache.jpg",
    name: "Cristian Conache",
    position: "Full Stack Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "MariusCirstean.jpeg",
    name: "Marius Cirstean",
    position: "QA Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "DariusMarian.png",
    name: "Darius Marian",
    position: "Back-End Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "VladTarniceru-256px.jpg",
    name: "Vlad Tarniceru",
    position: "UI/UX Engineer",
    locations: ["Bucharest, RO"],
}];

