import {POPUP_GATE_TRANSITION_TIME} from "../../blink-sdk/Constants";

export const WALLET_CARD_CONTENT_HEIGHT = 167;
export const WIDGET_CARD_PADDING_TOP = 24;
export const WIDGET_CARD_PADDING_BOTTOM = 18;
export const BANNER_TRANSITION_TIME = POPUP_GATE_TRANSITION_TIME;
export const MERCHANT_NAV_MAX_HEIGHT = 641;
export const ADD_FUNDS_MINIMISED_HEIGHT = 39;
export const BANNER_HEIGHT = 46;
export const WIDGET_MAX_HEIGHT = MERCHANT_NAV_MAX_HEIGHT + ADD_FUNDS_MINIMISED_HEIGHT + BANNER_HEIGHT;
export const WIDGET_EXPANDED_DESKTOP_BOTTOM_OFFSET = 72;
export const MERCHANT_SCROLLABLE_CONTENT_HEIGHT = 370;
export const BANNER_TIMEOUT = 4000;
export const WALLET_COLLAPSED_WIDTH = 52;
export const WALLET_EXPANDED_WIDTH = 360;
export const WALLET_LOADING_SPINNER_DURATION = 400;
export const WALLET_WELCOME_CONTENT_HEIGHT = 227;
export const WALLET_COLLAPSED_VISIBLE_BALANCE_WIDTH = 114;
export const MOBILE_KEYBOARD_TOGGLE_TRANSITION_TIME = 200;

export const WIDGET_ANCHOR_OFFSET = 100;
export const WIDGET_SCREEN_MIN_PADDING = 12;
export const WIDGET_KEYBOARD_TOGGLED_THRESHOLD = 400;
export const ANDROID_KEYBOARD_TOGGLE_TIMEOUT = 1800;
