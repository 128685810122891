import {registerStyle, styleRule, styleRuleInherit, UI} from "../../stem-core/src/ui/UI";

import {Section, SectionStyle} from "../primitives/Section";
import {Contact} from "../components/Contact";
import {Device} from "../../stem-core/src/base/Device";

export class FooterStyle extends SectionStyle {
    @styleRuleInherit
    section = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (Device.isMobileDevice() ? "20px 0 24px" : "36px 0 48px"),
    };

    @styleRule
    contact = {};
}

@registerStyle(FooterStyle)
export class Footer extends Section {
    render() {
        const {styleSheet} = this;

        return <Contact className={styleSheet.contact} />;
    }
}
