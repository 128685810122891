import {registerStyle, styleRule, UI} from "../../../../../../../stem-core/src/ui/UI";

import {CardsDropdown} from "./dropdown/CardsDropdown";
import {Messages} from "../../../../../../Messages";
import {AddCardModal} from "./AddCardModal";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../../ui/ConfirmationModal";
import {Dispatcher} from "../../../../../../../stem-core/src/base/Dispatcher";

class SelectPrimaryCardModalStyle extends ConfirmationModalStyle {
    @styleRule
    dropdown = {
        width: "100%",
    };
}

@registerStyle(SelectPrimaryCardModalStyle)
export class SelectPrimaryCardModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "selectPrimaryCardModal",
            title: Messages.choosePaymentMethod,
            confirmLabel: Messages.addNewPaymentMethod,
            confirmAction: () => AddCardModal.show(),
        };
    }

    render() {
        const {styleSheet} = this;

        return [
            <CardsDropdown className={styleSheet.dropdown} testId="selectCardDropdown"
                           onChange={() => this.hide()}/>,
        ];
    }

    onMount() {
        super.onMount();
        this.attachListener(Dispatcher.Global, "cardAdded", () => this.hide());
    }
}
