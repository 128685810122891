import {registerStyle, styleRuleInherit, UI} from "../../stem-core/src/ui/UI";

import {Section, SectionStyle} from "../primitives/Section";
import {isDesktopSmall} from "../Utils";

class EmptySectionStyle extends SectionStyle {
    @styleRuleInherit
    section = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: 0,
        height: () => (isDesktopSmall() ? 0 : 140),
        overflow: "hidden",
        zIndex: 9,
    };
}

@registerStyle(EmptySectionStyle)
export class EmptySection extends Section {}
