import {UI, styleRule, StyleSheet, registerStyle, styleRuleInherit} from "../../stem-core/src/ui/UI";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {BlinkGlobal, isFacebookBrowser, isSmallScreen, isString} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {TEST_MODE} from "../Constants";
import {WidgetClose} from "../SVGElements";
import {MessageElement} from "../widget/ui/MessageElement";
import {Router} from "../../stem-core/src/ui/Router.jsx";
import {PANEL_TYPE} from "../../blink-sdk/Constants.js";


export class ToastStyle extends StyleSheet {
    transitionTime = 150;

    @styleRule
    toast = {
        padding: () => Device.isMobileDevice() ? 12 : "12px 18px",
        borderRadius: this.themeProps.MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS,
        background: this.themeProps.TOAST_BACKGROUND,
        color: this.themeProps.TOAST_COLOR,
        position: "fixed",
        display: "table",
        bottom: () => (isSmallScreen() ? 14 : 48),
        margin: "auto",
        left: "50%",
        transition: this.transitionTime / 1000 + "s ease",
        transform: "translate(-50%, 96px)",
        opacity: 0,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_NORMAL,
        zIndex: 12000,
        minWidth: this.themeProps.FIT_CONTENT,
        width: () => isFacebookBrowser() ? "" : this.themeProps.FIT_CONTENT,
        textAlign: "center",
    };

    @styleRule
    visible = {
        opacity: 1,
        transform: "translate(-50%, 0)",
    };

    @styleRule
    action = {
        display: "inline",
        marginLeft: 21,
        color: this.themeProps.TOAST_ACTION_COLOR,
        cursor: "pointer",
    };

    @styleRule
    message = {
        display: "inline-block",
    };

    @styleRule
    closeIcon = {
        marginLeft: 8,
        display: "inline-block",
        cursor: "pointer",
    }
}

@registerStyle(ToastStyle)
export class Toast extends UI.Element {
    // Options for a new instance on success or error
    static ERROR_OPTIONS = {
        style: {
            background: () => Theme.props.MERCHANT_ERROR
        },
        visibleTimeout: 8000,
    };

    static SUCCESS_OPTIONS = {
        style: {
            background: () => Theme.props.MERCHANT_SUCCESS
        }
    };

    active = null;
    hideTimeout = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            visibleTimeout: 5000,
            showClose: true,
            action: null, // The format is {label, callback}
        };
    }

    static show(message, options) {
        if (Router.getCurrentPath() === "/" + PANEL_TYPE.empty) {
            return;
        }

        if (Toast.active) {
            Toast.active.remove();
        }

        Toast.active = this.create(BlinkGlobal.appInstance?.node || document.body, {
            ...options,
            message
        });
    }

    static showError(error, fallbackMessage) {
        if (error.stack) {
            // This is a javascript error, at least print it
            // TODO @cleanup maybe massage the error for the user
            console.error("Error: ", error, error.stack);
        }
        if (!isString(error)) {
            error = error?.message;
        }
        this.show(error || fallbackMessage || "Unknown error", this.ERROR_OPTIONS);
    }

    static showSuccess(message) {
        this.show(message, this.SUCCESS_OPTIONS);
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.toast);
    }

    render() {
        const {styleSheet} = this;
        const {action, message, showClose} = this.options;

        return [
            <MessageElement message={message} className={styleSheet.message}/>,
            action ? <div className={styleSheet.action}
                          onClick={() => {action.callback(); this.removeAnimated();}}>{action.label}</div> : null,
            showClose ? <WidgetClose color={styleSheet.themeProps.TOAST_COLOR} className={styleSheet.closeIcon}
                                     onClick={() => this.constructor.hide(this)}/> : null,
        ];
    }

    static hide(instance=null) {
        if (Toast.active && Toast.active === instance) {
            Toast.active.removeAnimated();
        }
        return instance;
    }

    remove() {
        clearTimeout(this.hideTimeout);
        this.node.remove();
        delete this;
    }

    removeAnimated() {
        this.removeClass(this.styleSheet.visible);
        setTimeout(() => {
            this.remove();
        }, this.styleSheet.transitionTime);
    }

    onMount() {
        setTimeout(() => {
            this.addClass(this.styleSheet.visible);
        }, 100);

        if (this.options.visibleTimeout) {
            const toastVisibleTime = (TEST_MODE ? 2 : 1) * (this.options.visibleTimeout + this.styleSheet.transitionTime);
            this.hideTimeout = setTimeout(() => this.constructor.hide(this), toastVisibleTime);
        }

        this.attachListenerOnce(Router.Global, "change", () => {
            this.constructor.hide(this);
        });
    }
}

class PanelToastStyle extends ToastStyle {
    @styleRuleInherit
    toast = {
        bottom: "",
        top: 8,
        transform: "translate(-50%, -96px)",
    };
}

// Change some of the default Toast settings
export function SetupPanelToastSettings() {
    Theme.Global.register(Toast, PanelToastStyle);
    Object.assign(Toast.ERROR_OPTIONS, {
        visibleTimeout: null,
    });
}
