import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI";
import {NOOP_FUNCTION} from "../../../../../../Utils";
import {PaymentMethodStore} from "../../../../../../State";
import {isSmallScreen} from "../../../../../../Utils";
import {BLINK_PAY_URL, STRIPE_CARD_ERROR_MAX_LENGTH, STRIPE_SRC} from "../../../../../../Constants";
import {ensure} from "../../../../../../../stem-core/src/base/Require";
import {Messages} from "../../../../../../Messages";
import {apiAddPaymentMethodToken} from "../../../../../../../client/state/PaymentMethodStore";
import {PaymentProcessorStore, PaymentProcessorType} from "../../../../../../../client/state/PaymentProcessorStore.js";


class PaymentProcessorInputStyle extends StyleSheet {
    @styleRule
    element = {
        fontSize: () => (isSmallScreen() ? 14 : 16),
        color: this.themeProps.INPUT_COLOR,
        position: "relative",
        overflow: "hidden",
        cursor: "text",
    };

    @styleRule
    focused = {};
}

@registerStyle(PaymentProcessorInputStyle)
class PaymentProcessorInput extends UI.Element {
    focused = false;
    inputComplete = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onChange: NOOP_FUNCTION,
            label: "addCard",
            autofocus: true,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
    }

    constructor(...args) {
        super(...args);
        this.paymentProcessorSrc = null;
        this.card = null;
        this.validInputs = false;
    }

    getChildrenToRender() {
        return <div id="card-element" style={{flex: 1}}/>;
    }

    confirmCard(callback = NOOP_FUNCTION, errorCallback = NOOP_FUNCTION) {
        return null;
    }

    getCardElement() {
        return null;
    }

    hasValidSecureFields() {
        return this.validInputs;
    }

    isInputComplete() {
        return this.inputComplete;
    }

    isLoaded() {
        return !!this.card;
    }

    onMount() {
        super.onMount();
        this.addClickListener(() => {
            if (this.card) {
                this.card.focus();
            }
        });
        ensure(this.paymentProcessorSrc, () => {
            const insertCardInput = this.attachInterval(() => {
                if (!document.getElementById("card-element")) {
                    return;
                }
                clearInterval(insertCardInput);
                this.card = this.getCardElement();
                this.card.on("focus", () => {
                    this.focused = true;
                    this.error = null;
                    this.redraw();
                });
                this.card.on("blur", () => {
                    this.focused = false;
                    this.redraw();
                });
                this.card.mount("#card-element");
                this.dispatch("load");
            }, 200);
        });
    }
}

export class StripeCardInputStyle extends PaymentProcessorInputStyle {
    message = {
        paddingBottom: 12,
        fontSize: isSmallScreen() ? 14 : ".91em",
    };

    @styleRule
    errorMessage = {
        ...this.message,
        color: this.themeProps.MERCHANT_ERROR,
        marginTop: 10,
    };

    @styleRule
    inputContainer = {
        width: "100%",
        position: "relative",
        fontSize: "inherit",
        height: () => (isSmallScreen() ? 46 : "2.83em"),
        background: this.themeProps.INPUT_BACKGROUND,
        borderRadius: () => (isSmallScreen() ? 6 : 8),
    };

    @styleRule
    successMessage = {
        ...this.message,
        color: this.themeProps.MERCHANT_SUCCESS,
    };

    @styleRule
    error = {
        borderColor: this.themeProps.MERCHANT_ERROR,
        ":hover": {
            borderColor: this.themeProps.MERCHANT_ERROR + " !important",
        },
        " input": {
            color: this.themeProps.MERCHANT_ERROR + " !important",
        },
        " input::placeholder": {
            color: this.themeProps.MERCHANT_ERROR + " !important",
        }
    };
}

@registerStyle(StripeCardInputStyle)
export class StripeCardInput extends PaymentProcessorInput {
    paymentProcessorSrc = STRIPE_SRC;
    error = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            stripeStyle: {},
            fonts: [],
            // TODO @branch ??
            paymentProcessor: PaymentProcessorStore.findBy({provider: PaymentProcessorType.STRIPE}),
        };
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {success} = this.options;
        const inputContainerClasses = `${styleSheet.inputContainer} ${
            success ? styleSheet.success : this.error ? styleSheet.error : ""
        } ${this.focused ? styleSheet.focused : ""}`;

        return [
            <div className={inputContainerClasses}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: ".25rem 5px",
                        alignItems: "center",
                        paddingLeft: () => (isSmallScreen() ? 7 : "1em"),
                        height: "100%",
                    }}>
                    <div id="card-element" style={{flex: 1}}/>
                </div>
                <div className={styleSheet.successMessage}>{success}</div>
            </div>,
            this.error ? <div testId="cardInputError" className={styleSheet.errorMessage}>{this.error}</div> : <div/>,
        ];
    }

    confirmCard(callback = NOOP_FUNCTION, errorCallback = NOOP_FUNCTION, data = {}) {
        const {paymentProcessor} = this.options;
        this.error = null;
        this.redraw();
        this.stripe.createToken(this.card, data).then(async response => {
            if (response.error) {
                this.error = response.error.message.length > STRIPE_CARD_ERROR_MAX_LENGTH ?
                    Messages.genericCardError.toString() : response.error.message;
                this.redraw();
                errorCallback();
                return;
            }

            this.card.blur();
            try {
                const addPaymentMethodResponse = await apiAddPaymentMethodToken(
                    response.token,
                    PaymentMethodStore.Scope.PUBLIC,
                    paymentProcessor
                );
                callback(addPaymentMethodResponse);
            } catch (error) {
                this.error = error.message.length > STRIPE_CARD_ERROR_MAX_LENGTH ?
                    Messages.genericCardError.toString() : error.message;
                this.redraw();
                errorCallback(error);
            }
        });
    }

    getCardElement() {
        const {paymentProcessor} = this.options;
        const style = Object.assign(
            {
                base: {
                    fontSize: this.styleSheet.themeProps.MERCHANT_FONT_SIZE_LARGE,
                    color: this.styleSheet.themeProps.CARD_INPUT_COLOR,
                    iconColor: this.styleSheet.themeProps.WALLET,
                    fontFamily: "Lato, Helvetica, sans-serif",
                    fontWeight: this.styleSheet.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
                    "::placeholder": {
                        color: this.styleSheet.themeProps.BASE_INPUT_PLACEHOLDER_COLOR,
                    },
                },
            },
            this.options.stripeStyle
        );

        // TODO @branch take this from the payment processor
        this.stripe = Stripe(paymentProcessor.publicKey);
        this.card = this.stripe.elements({
            fonts: [...this.options.fonts, {
                family: "Lato",
                src: `url(${BLINK_PAY_URL}/resources/fonts/LatoLatin-Regular.woff)`,
                weight: "400",
            }],
        }).create("card", {style});

        this.card.addEventListener("change", event => {
            if (event.error) {
                this.validInputs = false;
                this.error = event.error.message;
                this.redraw();
            } else {
                this.validInputs = true;
                this.error = null;
                this.redraw();
            }
            this.inputComplete = event.complete;
            this.options.onChange();
        });
        if (this.options.autofocus) {
            this.card.addEventListener("ready", () => {
                this.card.focus();
            });
        }

        return this.card;
    }
}
