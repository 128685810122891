import {UI, styleRule, registerStyle} from "../../../../../../stem-core/src/ui/UI";

import {Messages} from "../../../../../Messages";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../ui/ConfirmationModal";
import {Toast} from "../../../../../ui/Toast";
import {NumberInput} from "../../../../../../stem-core/src/ui/input/Input";
import {DonationStore, DonationOfferStore, CurrencyStore, MerchantStore} from "../../../../../State";
import {MerchantCurrencyInput} from "../../../../../common/MerchantInput";
import {apiUpdateDonationAmount} from "../../../../../../client/state/DonationStore";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw";

class EditDonationModalStyle extends ConfirmationModalStyle {
    @styleRule
    amountInput = {
        textTransform: "capitalize",
        fontSize: 16,
    };
}

@registerStyle(EditDonationModalStyle)
@autoredraw(DonationStore)
export class EditDonationModal extends ConfirmationModal {
    inputChangesMade = false;

    getDefaultOptions() {
        // impure getDefaultOptions? we're in for interesting things to happen..
        return {
            ...super.getDefaultOptions(),
            title: Messages.editDonation,
            confirmAction: () => this.updateDonation(),
        }
    }

    getDescription() {
        const {donation} = this.options;
        const offer = DonationOfferStore.get(donation.recurringOfferId);
        const merchant = MerchantStore.get(offer.merchantId);

        return [
            Messages.editDonationDescription(merchant.name, donation.frequency.getFrequencyName()),
            <div>{Messages.donationDashboardUpdateDescription}</div>
        ];
    }

    render() {
        const {donation} = this.options;
        const offer = DonationOfferStore.get(donation.recurringOfferId);
        const currency = CurrencyStore.get(donation.currencyId);
        if (!this.inputChangesMade) {
            this.amount = currency.amountToMainUnits(donation.amount);
        }

        return (
            <MerchantCurrencyInput
                ref="amountInput"
                currency={offer.getCurrency()}
                className={this.styleSheet.amountInput}
                label={Messages.amount}
                InputClass={NumberInput}
                initialValue={this.amount}
                inputAttributes={{
                    type: "tel",
                    min: currency.amountToMainUnits(offer.minAmount),
                    max: currency.amountToMainUnits(offer.maxAmount),
                }}
                onChange={() => {
                    this.inputChangesMade = true;
                    this.amount = this.amountInput.getValue();
                    Toast.hide();
                    this.redraw();
                }}
            />
        );
    }

    async updateDonation() {
        const {donation} = this.options;
        const currency = CurrencyStore.get(donation.currencyId);

        let canonicalAmount;
        if (!this.inputChangesMade || (canonicalAmount = currency.mainUnitsToAmount(this.amount)) === donation.amount) {
            Toast.showError(Messages.donationErrorAmountNotChanged);
            return;
        }

        try {
            await apiUpdateDonationAmount(donation.id, canonicalAmount, donation.isDonationFeePaid());
            Toast.show(Messages.donationSuccessAmountChanged);
            this.hide();
        } catch (error) {
            Toast.showError(error);
            this.redraw();
        }
    }
}
